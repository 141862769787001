<div [ngClass]="containerClass" fxLayout="column" fxLayoutAlign="start center">
  <div *ngIf="label || icon" fxLayout="row" fxLayoutAlign="center center" class="gap-8 mb-8">
    <div *ngIf="icon" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon *ngIf="!isIconSymbol">{{ icon }}</mat-icon>
      <span class="material-symbols-outlined" *ngIf="isIconSymbol">{{ icon }}</span>
    </div>

    <span *ngIf="label" class="chillz-input-label">{{ label[localizationService.currentLang] || label['en'] }}</span>
  </div>

  <div class="floating-label">
    {{ 'ORDER.CHECKOUT.DETAILS.TICKET.PHONE' | translate }}
    <span *ngIf="required">*</span>
  </div>

  <ngx-intl-tel-input
    #intlTelInputComponent
    [cssClass]="'chillz-intl-tel-input'"
    [disabled]="disabled"
    [class.invalid]="invalid"
    [preferredCountries]="preferredCountries"
    [enableAutoCountrySelect]="true"
    [required]="required"
    [separateDialCode]="true"
    [enablePlaceholder]="false"
    [searchCountryFlag]="true"
    [searchCountryPlaceholder]="'ORDER.CHECKOUT.DETAILS.TICKET.SEARCH' | translate"
    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
    [selectedCountryISO]="selectedCountryISO"
    [selectFirstCountry]="true"
    [maxLength]="15"
    [phoneValidation]="true"
    [inputId]="id"
    [id]="id"
    [ngModel]="phoneValue"
    (ngModelChange)="phone.next($event)"
    name="phone"
  ></ngx-intl-tel-input>
  <div class="subscript-wrapper">
    <mat-error @fadeInOut *ngIf="errorMessage">{{ errorMessage }}</mat-error>
  </div>
</div>
