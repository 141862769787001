import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AppService } from '../../../app.service';
import { Entity } from '../../../main/entity/entity.model';
import { TicketTypesService } from '../../../main/entity/ticket-types.service';
import { Order } from '../../../main/orders/order/order.model';
import { OrdersService } from '../../../main/orders/orders.service';
import { BundleOffer } from '../../models/bundle-offer.model';
import { GeneralProduct } from '../../models/general-product.model';
import { OrderItem } from '../../models/order-item.model';
import { TicketType } from '../../models/ticket-type.model';
import { TrackingService } from '../../tracking.service';


export interface IOrderPreparationState {
  creating: boolean;
  disabled: boolean;
  hidden: boolean;
  loading?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrderPreparationService {
  currentEntity: Entity;

  selectedItems: { item: OrderItem; quantity }[] = [];
  onSelectedItemsChanged: BehaviorSubject<{ item: OrderItem; quantity }[]> = new BehaviorSubject<
    { item: OrderItem; quantity }[]
  >(undefined);

  itemsCount: number;
  onItemsCountChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  order: Order;
  onOrderChanged: BehaviorSubject<Order> = new BehaviorSubject<Order>(undefined);

  bundleOffer: BundleOffer;
  onBundleOfferChanged: BehaviorSubject<BundleOffer> = new BehaviorSubject<BundleOffer>(undefined);

  state: IOrderPreparationState = { disabled: true, creating: false, hidden: true };
  onStateChanged: BehaviorSubject<IOrderPreparationState> = new BehaviorSubject<IOrderPreparationState>(this.state);

  queryPromoCodes = [];
  loadingCount = 0;

  constructor (
    private _ordersService: OrdersService,
    private _ticketTypesService: TicketTypesService,
    private _route: ActivatedRoute,
    private _appService: AppService,
    private _trackingService: TrackingService,
    private _location: Location
  ) {}

  startLoading (): void {
    this.loadingCount++;
    this.state.loading = true;
    this.onStateChanged.next(this.state);
  }

  updateSelectedItems (items: { item: OrderItem; quantity }[] = [], eventParticipation?: Entity): void {
    if (
      this.order &&
      (this.currentEntity?._id !== this.order?.venue?._id ||
        this.order?.eventParticipation?._id !== eventParticipation?._id)
    ) {
      this.reset();
    }

    let updated = false;
    for (const item of items) {
      const existing = this.selectedItems.find((x) => x.item._id === item.item._id);
      if (item.quantity === null) {
        item.quantity = existing ? existing.quantity : 0;
      }

      if (existing) {
        if (existing.quantity !== item.quantity) {
          updated = true;
        }

        existing.quantity = item.quantity;
      } else {
        if (item.quantity > 0) {
          updated = true;
        }

        this.selectedItems.push(item);
      }
    }
    this.onSelectedItemsChanged.next(this.selectedItems);

    if (updated) {
      const convertedItems = [];
      this.selectedItems.forEach((s) => {
        const converted: any = {
          quantity: s.quantity,
        };

        if (s.item instanceof TicketType) {
          converted.ticketType = s.item as TicketType;
        }

        if (s.item instanceof GeneralProduct) {
          converted.product = s.item as GeneralProduct;
        }

        convertedItems.push(converted);
      });

      this.onStateChanged.next(this.state);
      if (!this.order) {
        this.state.creating = true;
        this.onStateChanged.next(this.state);
        this._ordersService
          .makeOrder(
            this.currentEntity,
            convertedItems,
            false,
            undefined,
            this.bundleOffer,
            this._location.path(),
            eventParticipation
          )
          .then((order) => {
            this.order = order;
            this.onOrderChanged.next(this.order);
            this._trackingService.triggerEvent('add-to-cart', {
              currency: this.order.currency,
              value: this.order.total || this.order.subtotal,
              items: this.order.items
                .filter((x) => x.quantity)
                .map((x) => ({
                  item: x.ticketType || x.product,
                  quantity: x.quantity,
                })),
            });
          })
          .catch((error: HttpErrorResponse) => {
            if (error.error.error === 'Availability changed') {
              this._ticketTypesService.onTicketTypesChanged.next([]);
              this.reset();

              this._appService
                .confirmationDialog(
                  { en: 'Tickets are selling fast' },
                  {
                    en: 'Not all of the tickets you selected are available. Your order has been updated to reflect current availability.',
                  },
                  undefined,
                  { en: 'Okay', he: 'Okay' },
                  undefined,
                  true
                )
                .then();

              this._ticketTypesService.getTicketTypes();
            }
          })
          .finally(() => {
            this.state.creating = false;
            this.stopLoading();
            this.onStateChanged.next(this.state);
          });
      } else if (this.order) {
        if (!this.state.loading) {
          this.startLoading();
        }

        this._ordersService
          .updateItems(convertedItems)
          .then((order) => {
            this.order = order;
            console.log(this.order);
            this.onItemsCountChanged.next(this.order.items.reduce((a, b) => a + b.quantity, 0));
            this.state.disabled = this.itemsCount === 0;
            this.onOrderChanged.next(this.order);
            this.onStateChanged.next(this.state);
            console.log(this.order);

            this._trackingService.triggerEvent('add-to-cart', {
              currency: this.order.currency,
              value: this.order.total || this.order.subtotal,
              items: this.order.items
                .filter((x) => x.quantity)
                .map((x) => ({
                  item: x.ticketType || x.product,
                  quantity: x.quantity,
                })),
            });
          })
          .finally(() => {
            this.stopLoading();
            this.onItemsCountChanged.next(this.order.items.reduce((a, b) => a + b.quantity, 0));
            this.state.disabled = this.itemsCount === 0;
            this.onStateChanged.next(this.state);
          });
      }
    }

    this.itemsCount = this.selectedItems.reduce((a, b) => a + b.quantity, 0);
    this.state.disabled = this.itemsCount === 0;

    this.onItemsCountChanged.next(this.itemsCount);

    if (!this.state.disabled) {
      this.state.hidden = false;
    }

    this.onStateChanged.next(this.state);
  }

  setOrder (order: Order): void {
    this.order = order;
    if (order.completed) {
      return this.reset();
    }

    this.onOrderChanged.next(this.order);
    this.selectedItems = order.items.map((x) => {
      let item;

      if (x.ticketType) {
        item = new TicketType(x.ticketType);
      } else if (x.product) {
        item = new GeneralProduct(x.product);
      }

      return {
        quantity: x.quantity,
        item,
      };
    });

    this.onSelectedItemsChanged.next(this.selectedItems);
  }

  reset (): void {
    console.log('RESET _orderPreparationService');
    this.selectedItems = [];
    this.onSelectedItemsChanged.next(undefined);

    this.state = { disabled: true, hidden: true, creating: false };
    this.onStateChanged.next(this.state);

    this.itemsCount = 0;
    this.onItemsCountChanged.next(0);

    this.order = undefined;
    this.onOrderChanged.next(this.order);
  }

  private stopLoading (): void {
    if (this.loadingCount > 1) {
      this.loadingCount--;
    } else {
      this.loadingCount = 0;
      this.state.loading = false;
    }
  }
}
