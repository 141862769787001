import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'chillz-loader',
  templateUrl: './chillz-loader.component.html',
  styleUrls: [ './chillz-loader.component.scss' ],
})
export class ChillzLoaderComponent implements OnInit {
  @Input() size = 40;

  constructor () {}

  ngOnInit (): void {}
}
