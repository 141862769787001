import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

import { AppService } from '../../app/app.service';
import { Platform } from '../../app/shared/models/platform.model';


@Injectable({
  providedIn: 'root',
})
export class SeoService implements Resolve<any> {
  private renderer2: Renderer2;

  language;
  hrefLinks = [];
  canonicalLink;

  platform: Platform;

  constructor (
    private _title: Title,
    private _meta: Meta,
    private _router: Router,
    private _translateService: TranslateService,
    private _appService: AppService,
    @Inject(DOCUMENT) private doc,
    rendererFactory: RendererFactory2
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
    this.init();
  }

  resolve (route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      combineLatest(
        this._translateService.onLangChange.pipe(startWith(null)),
        this._appService.onPlatformChanged.pipe(filter((x) => !!x))
      ).subscribe(([ lang, platform ]) => {
        this.platform = platform;
        this.language = lang?.lang || this.language || route.params['lang'];

        if (this.platform?.integrations.facebookAppId) {
          this.updateMeta({
            property: 'fb:app_id',
            content: this.platform.integrations.facebookAppId,
          });
        }

        this.reset();
        resolve(false);
      });
    });
  }

  set title (title: string) {
    this._title.setTitle(title);
    this.updateMeta({
      property: 'og:title',
      content: title,
    });
    this.updateMeta({
      property: 'twitter:title',
      content: title,
    });

    this._appService.hubspotPageView$.next(this._router.url);
  }

  get title (): string {
    return this._title.getTitle();
  }

  createLinkForCanonicalURL (url?): void {
    if (this.canonicalLink) {
      this.doc.head.removeChild(this.canonicalLink);
    }

    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.insertBefore(link, this.doc.head.firstChild);
    link.setAttribute('href', url || this.doc.URL);
    this.canonicalLink = link;
  }

  createHreflangLink (url, languages: string[] = []): void {
    if (!this.platform) {
      return;
    }

    this.removeActiveHrefLangLinks();
    if (!languages.includes('x-default')) {
      languages.push('x-default');
    }

    for (const lang of languages) {
      const link = this.renderer2.createElement('link');
      this.renderer2.setAttribute(link, 'rel', 'alternate');
      this.renderer2.setAttribute(link, 'hreflang', lang);
      this.renderer2.setAttribute(
        link,
        'href',
        `${this.platform?.baseUrl || 'https://chillz.com'}${lang !== 'x-default' ? '/' + lang : ''}${
          url ? '/' + url : ''
        }`
      );
      this.renderer2.insertBefore(this.doc.head, link, this.doc.head.firstChild);
      this.hrefLinks.push(link);
    }
  }

  removeActiveHrefLangLinks (): void {
    this.hrefLinks.forEach((link) => {
      this.renderer2.removeChild(this.doc.head, link);
    });
    this.hrefLinks = [];
  }

  set description (description: string) {
    if (description) {
      this.updateMeta({
        name: 'description',
        content: description,
      });
      this.updateMeta({
        property: 'og:description',
        content: description,
      });
      this.updateMeta({
        property: 'twitter:description',
        content: description,
      });
    }
  }

  set image (image: string) {
    if (image) {
      this.updateMeta({
        property: 'og:image',
        content: image,
      });
      this.updateMeta({
        property: 'twitter:image',
        content: image,
      });

      let imageType;
      if (image?.toLowerCase()?.endsWith('.jpg') || image?.toLowerCase()?.endsWith('.jpeg')) {
        imageType = 'image/jpeg';
      }

      if (image?.toLowerCase()?.endsWith('.png')) {
        imageType = 'image/png';
      }

      if (imageType) {
        this.updateMeta({
          property: 'og:image:type',
          content: imageType,
        });
      }
    }
  }

  set url (url: string) {
    this.updateMeta({
      property: 'og:url',
      content: url,
    });
    this.updateMeta({
      property: 'twitter:url',
      content: url,
    });
  }

  set keywords (keywords: string[]) {
    this.updateMeta({
      property: 'keywords',
      content: keywords.join(', '),
    });
  }

  private init (): void {
    this.updateMeta({
      property: 'og:type',
      content: 'website',
    });
    this.updateMeta({
      property: 'twitter:card',
      content: 'summary',
    });
  }

  public reset (): void {
    if (!this.platform) {
      return;
    }

    this.title = this.platform?.title?.b2c[this.language] || this.platform?.title?.b2c['en'];
    this.url = `${this.platform?.baseUrl || 'https://www.chillz.com'}/${this.language}`;
    this.keywords = this.platform?.keywords[this.language] || this.platform?.keywords['en'] || [];
    this.description = this.platform?.description[this.language] || this.platform?.description['en'];
    this.image = this.platform?.ogImage || 'https://media.chillzapp.com/images/fb-thumbnail.png';

    this.createHreflangLink('', [ 'he', 'en' ]);
  }

  private updateMeta (tag: MetaDefinition): void {
    const updated = this._meta.updateTag(tag);
    if (!updated) {
      console.warn('Meta tag not found!', tag);
    }
  }
}
