import * as _ from 'lodash';

import { ICustomField } from '../../shared/custom-field.interface';
import { EntityType } from '../../shared/models/entity-type.model';
import { ExploreEngagementAction } from '../../shared/models/explore-engagement-action.model';
import { EventGuest, ExploreItem } from '../../shared/models/explore-item.model';
import { ChillzPicture } from '../../shared/models/picture.model';
import { ItemsCollectionStyle } from '../../shared/models/style-components/collection-style.model';
import { EntityLayout } from '../../shared/models/style-components/entity-layout.model';
import { EntityPageLayoutStyle } from '../../shared/models/style-components/entity-page-layout-style.model';
import { ChillzVideo } from '../../shared/models/video.model';


export interface IPrivacySettings {
  isPrivate?: boolean;
  requestsAllowed?: boolean;
  welcome?: {
    text: {
      en: string;
      he: string;
    };
    picture: any;
  };

  inheritedMembers?: Entity[];
  customFields?: ICustomField[];
}

export class Entity {
  id: string;
  _id: string;
  venueType: string;
  entityType: EntityType;

  titleEng: string;
  titleLocal: string;
  descriptionEng: string;
  descriptionLocal: string;

  title: { [lang: string]: string };
  description: { [lang: string]: string };

  startsAt: {
    year: number;
    month: number;
    day: number;
    hours: number;
    minutes: number;
    dateObj: Date;
    formatted: { [lang: string]: string };
  };

  endsAt: {
    year: number;
    month: number;
    day: number;
    hours: number;
    minutes: number;
    dateObj: Date;
    formatted: { [lang: string]: string };
  };

  formattedTime: { [lang: string]: string };

  timeZone: number;
  parents: Entity[];

  pictures: ChillzPicture[]; // [{type: Schema.Types.ObjectId, ref: 'Picture'}]
  inlinePicture: ChillzPicture;
  minAge: string;

  localLanguage: any; // {type: Schema.Types.ObjectId, ref: 'Language'},

  tags: any[]; // [{type: Schema.Types.ObjectId, ref: 'Tag'}],

  locationDetails: {
    title: string;
    country: string;
    city: string;
    cityObj: {
      titles: { [lang: string]: string };
    };
    formatted: string;
    timezoneOffset: number;
    url: string;
    coords: {
      lat: number;
      lng: number;
    };
    liveStream: string;
  };

  pageIntegrations: {
    facebookPixel?: string[];
    googleAnalytics?: string[];
    gtag?: string[];
    gtm?: string[];
    snapchat?: string[];
    tiktok?: string[];
  };

  distance: number;

  soundCloudTrack: number;
  userStatuses: any;
  urls: {
    facebook: string;
    instagram: string;
    website: string;
    youtube: string;
  };

  contact: {
    phone: string;
    presentPhone: boolean;
    presentEmail: boolean;
    email: string;
  };

  sellingTickets: boolean;
  externalTickets: {
    url: string;
    location?: string;
  };

  pixelId: string;

  heroPicture?: { [ratio: string]: ChillzPicture };
  previewPicture?: { [ratio: string]: ChillzPicture };

  video?: ChillzVideo;

  soundCloud: {
    id: string;
    src: string;
    title: string;
    url: string;
    type: 'playlist' | 'track';
    author: {
      title: string;
      url: string;
    };
  }[];

  pageLayout: EntityPageLayoutStyle;
  layout: EntityLayout;
  albums: any;
  hidePromotionConsentInOrder: boolean;
  ticketTypes: any;

  guestsList: {
    guests: EventGuest[];
    style: ItemsCollectionStyle;
    isExposed?: boolean;
  };

  facebookURL: string;
  youtubeURL: string;
  soundcloudURL: string;
  website: string;
  instagramHandle: string;
  tiktokHandle: string;
  spotifyURL: string;
  appleMusicURL: string;

  accentColor: string;

  usersActivity;

  seating: {
    chart: string;
    chartBottom: string;
    chartTop: string;
  };
  privacy: IPrivacySettings;

  /**
   * Constructor
   *
   */
  constructor (entity?) {
    entity = entity || {};

    this._id = entity._id;
    this.id = entity.id;
    this.venueType = entity.venueType;
    this.entityType = new EntityType(entity.entityType || { id: entity.venueType, title: { en: entity.venueType } });
    this.localLanguage = entity.localLanguage;
    this.titleEng = entity.titleEng;
    this.titleLocal = entity.titleLocal;
    this.descriptionEng = entity.descriptionEng?.replace('\\', '');
    this.descriptionLocal = entity.descriptionLocal;

    this.locationDetails = entity.locationDetails || {};
    this.minAge = entity.minAge;
    this.parents = entity.parents ? entity.parents.map((parent) => new Entity(parent)) : [];
    this.timeZone = entity.timeZone;

    this.description = entity.description || {};
    if (!this.description['en']) {
      this.description['en'] = this.descriptionEng?.replace('\\', '');
    }

    if (!this.description['default'] && Object.keys(this.description)?.length) {
      this.description['default'] = this.description['en'] || this.description[Object.keys(this.description)[0]];
    }

    this.title = entity.title || {};
    if (!this.title['en']) {
      this.title['en'] = this.titleEng;
    }

    if (!this.title['default'] && Object.keys(this.title)?.length) {
      this.title['default'] = this.title['en'] || this.title[Object.keys(this.title)[0]];
    }

    this.startsAt = entity.startsAt || {};

    if (this.startsAt.dateObj) {
      this.startsAt.dateObj = new Date(this.startsAt.dateObj);
    }

    this.endsAt = entity.endsAt || {};
    if (this.endsAt.dateObj) {
      this.endsAt.dateObj = new Date(this.endsAt.dateObj);
    }

    this.instagramHandle = entity.instagramHandle;
    this.tiktokHandle = entity.tiktokHandle;

    this.appleMusicURL = entity.appleMusicURL?.trim();
    if (this.appleMusicURL && !this.appleMusicURL.startsWith('http')) {
      this.appleMusicURL = 'https://' + this.appleMusicURL;
    }

    this.spotifyURL = entity.spotifyURL?.trim();
    if (this.spotifyURL && !this.spotifyURL.startsWith('http')) {
      this.spotifyURL = 'https://' + this.spotifyURL;
    }

    this.facebookURL = entity.facebookURL?.trim();
    if (this.facebookURL && !this.facebookURL.startsWith('http')) {
      this.facebookURL = 'https://' + this.facebookURL;
    }

    this.youtubeURL = entity.youtubeURL?.trim();
    if (this.youtubeURL && !this.youtubeURL.startsWith('http')) {
      this.youtubeURL = 'https://' + this.youtubeURL;
    }

    this.website = entity.website?.trim();
    if (this.website && !this.website.startsWith('http')) {
      this.website = 'https://' + this.website;
    }

    this.soundcloudURL = entity.soundcloudURL?.trim();
    if (this.soundcloudURL && !this.soundcloudURL.startsWith('http')) {
      this.soundcloudURL = 'https://' + this.soundcloudURL;
    }

    this.formattedTime = entity.formattedTime;

    this.albums = entity.albums || [];

    this.ticketTypes = entity.ticketTypes;

    this.usersActivity = entity.usersActivity || {};

    this.urls = entity.urls || {};
    this.userStatuses = entity.userStatuses || {};

    if (this.locationDetails?.formatted) {
      this.locationDetails.url = `https://www.google.com/maps/search/?api=1&query=${encodeURI(this.locationDetails?.formatted)}`;
    }

    this.contact = entity.contact || {};

    this.soundCloudTrack = entity.soundCloudTrack;

    this.tags = entity.tags || [];

    this.hidePromotionConsentInOrder = entity.hidePromotionConsentInOrder;
    this.externalTickets = entity.externalTickets;
    this.sellingTickets = entity.sellingTickets || false;

    this.pictures = entity.pictures?.filter((x) => !!x).map((x) => new ChillzPicture(x)) || [];
    if (entity.inlinePicture) {
      this.inlinePicture = new ChillzPicture(entity.inlinePicture);
    }

    if (_.isArray(entity.heroPicture)) {
      if (entity.heroPicture.length) {
        this.heroPicture = {};

        for (const r of entity.heroPicture) {
          if (r.picture) {
            this.heroPicture[r['aspectRatio']] = new ChillzPicture(r.picture);
          }
        }
      }
    } else {
      this.heroPicture = entity.heroPicture;
    }

    if (!this.heroPicture && this.coverPicture) {
      this.heroPicture = {
        default: this.coverPicture,
      };
    }

    if (entity?.video) {
      this.video = new ChillzVideo(entity?.video);
    }

    this.seating = entity.seating || {};
    this.privacy = entity.privacy;

    this.accentColor = entity.accentColor;

    this.pixelId = entity.pixelId;

    if (entity.pageLayout) {
      this.pageLayout = new EntityPageLayoutStyle(entity.pageLayout);
    }

    if (entity.layout) {
      this.layout = new EntityLayout(entity.layout);
    }

    this.soundCloud = entity.soundCloud || [];

    if (this.soundCloud) {
      for (const sc of this.soundCloud) {
        sc.src =
          sc.src ||
          `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/${sc.type}s/${sc.id}&color=%23${
            this.accentColor?.replace('#', '') || '276783'
          }&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`;
      }
    }
  }

  get instagramURL (): string {
    if (this.instagramHandle) {
      return `https://instagram.com/${this.instagramHandle}`;
    }

    return;
  }

  get tiktokURL (): string {
    if (this.tiktokHandle) {
      return `https://tiktok.com/${!this.tiktokHandle.startsWith('@') ? '@' : ''}${this.tiktokHandle}`;
    }

    return;
  }

  get coverPicture (): ChillzPicture {
    if (this.pictures.length) {
      return this.pictures[0];
    }

    return;
  }

  getHeroPicture (ratio?: string): ChillzPicture {
    if (!this.heroPicture) {
      return null;
    }

    if (Object.keys(this?.heroPicture)?.length) {
      if (!ratio || !this.heroPicture[ratio]) {
        ratio = Object.keys(this?.heroPicture)[0];
      }

      return this.heroPicture[ratio];
    }
  }

  getPreviewPicture (ratio?: string): ChillzPicture {
    if (!this.previewPicture) {
      return null;
    }

    if (Object.keys(this?.previewPicture)?.length) {
      if (!ratio || !this.previewPicture[ratio]) {
        ratio = Object.keys(this?.previewPicture)[0];
      }

      return this.previewPicture[ratio];
    }
  }

  get mergedLocation (): string {
    const merged = [
      this.locationDetails?.title,
      this.locationDetails?.formatted || this.locationDetails?.cityObj?.titles?.en,
    ].filter((x) => !!x);

    if (merged.length) {
      return merged.join(', ');
    }

    return null;
  }

  toExploreItem (template = 'venue-standard', badge = true, quickOrder = false): ExploreItem {
    const result = new ExploreItem({
      template,
      title: {
        en: this.titleEng,
        he: this.localLanguage?.code === 'he' ? this.titleLocal : undefined,
      },
      ...(quickOrder || this.sellingTickets
        ? {
            quickOrder: {
              isAllowed: true,
            },
          }
        : {}),
    });

    result.entity = this;

    result.subTitle = this.startsAt?.formatted;

    result.action = new ExploreEngagementAction({
      isActive: true,
      target: 'venue',
      id: this.id,
    });

    // result.picture = this.inlinePicture;

    // if (!result.picture && this.pictures.length) {
    //   result.picture = this.pictures[0];
    // }

    if (badge) {
      if (this.sellingTickets) {
        result.badge = {
          en: 'Tickets',
          he: 'כרטיסים',
        };
      } else {
        // result.badge = {
        //   en: this.venueType,
        //   he: this.venueType
        // };
      }
    }

    if (this.privacy?.isPrivate) {
      result.isLocked = true;
    }

    result.entity = this;

    return result;
  }
}
