import { ButtonsGroupStyle } from './collection-style.model';


export class EntityEditingComponent {
  _id: string;
  id: string;
  label: {
    en: string;
    he: string;
  };
  description: {
    en: string;
    he: string;
  };

  type:
    | 'section'
    | 'entrance'
    | 'pictures'
    | 'soundcloud'
    | 'picture-set'
    | 'info'
    | 'date'
    | 'datetime'
    | 'openingTimes'
    | 'location'
    | 'minAge'
    | 'parents'
    | 'tags'
    | 'input'
    | 'dropdown'
    | 'textArea'
    | 'richText'
    | 'language'
    | 'checkboxGroup'
    | 'radioButton'
    | 'selection-group'
    | 'entity-type-picker'
    | 'style-template'
    | 'media-selector'
    | 'color-picker';

  spokenTitle: {
    en: string;
    he: string;
  };

  tooltip: {
    en: string;
    he: string;
  };

  mediaProperties: {
    mediaType: 'Video' | 'Picture';
    viewAspectRatio: number;
    borderRadius: string;
  };

  pictureProperties: {
    dimensions: {
      width: number;
      height: number;
    };
    viewAspectRatio: number;
    borderRadius: string;
    formats: string[];
    maxSize: number;
    instructions: { [lang: string]: string };
  };

  videoProperties: {
    formats: string[];
    maxSize: number;
    instructions: { [lang: string]: string };
  };
  requiredError: { [lang: string]: string };

  styleTemplateProperties: {};

  selectionGroupProperties: {
    style: ButtonsGroupStyle;
  };

  options: {
    checked?: boolean;
    value: any;
    label: { [lang: string]: string };
    icon?: string;
    svgIcon?: string;
    info?: { [lang: string]: string };
    badge?: { [lang: string]: string };
    selected?: boolean;
  }[];

  children: [
    {
      component: EntityEditingComponent;
      required: boolean;
      useSpoken: boolean;
      substitutions: { [key: string]: { en: string; he: string } };
      flex: {
        default: string;
        gtXs: string;
        gtSm: string;
        gtMd: string;
        gtLg: string;
      };
    }
  ];

  valid;

  /**
   * Constructor
   *
   */
  constructor (component?, substitutions?: { [key: string]: { en: string; he: string } }) {
    component = component || {};

    this.id = component.id;
    this._id = component._id;
    this.label = component.label || {};
    this.description = component.description || {};
    this.tooltip = component.tooltip || {};
    this.spokenTitle = component.spokenTitle;
    this.type = component.type;
    this.options = component.options;
    this.pictureProperties = component.pictureProperties;
    this.mediaProperties = component.mediaProperties;
    this.videoProperties = component.videoProperties;

    this.requiredError = component.requiredError;

    if (component.children) {
      this.children = component.children.map((x) => ({
        component: new EntityEditingComponent(x.component, { ...x.substitutions, ...substitutions }),
        required: x.required,
        useSpoken: x.useSpoken,
        substitutions: x.substitutions,
        flex: x.flex || {},
      }));
    }

    this.selectionGroupProperties = component.selectionGroupProperties;

    if (this.selectionGroupProperties?.style) {
      const style = new ButtonsGroupStyle();
      style.setValue(this.selectionGroupProperties?.style);
      this.selectionGroupProperties.style = style;
    }

    if (substitutions) {
      for (const [ key, subs ] of Object.entries(substitutions)) {
        for (const [ lang, sub ] of Object.entries(subs)) {
          if (this.description[lang]) {
            this.description[lang] = this.description[lang].replace(`{{${key}}}`, sub);
          }

          if (this.label[lang]) {
            this.label[lang] = this.label[lang].replace(`{{${key}}}`, sub);
          }

          if (this.spokenTitle && this.spokenTitle[lang]) {
            this.spokenTitle[lang] = this.spokenTitle[lang].replace(`{{${key}}}`, sub);
          }

          if (this.tooltip[lang]) {
            this.tooltip[lang] = this.tooltip[lang].replace(`{{${key}}}`, sub);
          }
        }
      }
    }
  }
}
