import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { SplashService } from '../../../splash.service';
import { ExploreItem } from '../../models/explore-item.model';
import { Platform as ChillzPlatform } from '../../models/platform.model';


@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: [ './splash-screen.component.scss' ],
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  @Input() splashItem: ExploreItem;
  platform: ChillzPlatform;
  private _unsubscribeAll: Subject<any>;

  constructor (private _splashService: SplashService, private _appService: AppService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit (): void {
    this._appService.onPlatformChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((platform) => {
      this.platform = platform;
    });
  }

  onDismiss (): void {
    this._splashService.dismissedSplashItem();
  }

  onClick (): void {
    if (this.splashItem?.action?.target === 'venue' && this.splashItem?.entity?.id) {
      window.open(
        `${this.platform?.baseUrl || 'https://chillz.com'}/${
          this.splashItem?.action?.id || this.splashItem?.entity?.id
        }`,
        '_blank'
      );
    }

    if (this.splashItem?.action?.target === 'explore' && this.splashItem?.action?.id) {
      window.open(`${this.platform?.baseUrl || 'https://chillz.com'}/explore/${this.splashItem?.action?.id}`, '_blank');
    }

    if (this.splashItem?.action?.target === 'url' && this.splashItem?.action?.url) {
      window.open(this.splashItem?.action?.url, '_blank');
    }
  }

  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
