import { Pipe, PipeTransform } from '@angular/core';

import { Entity } from '../../main/entity/entity.model';


@Pipe({
  name: 'showSearchRecentValue',
  pure: true,
})
export class ShowHistoryResentValuePipe implements PipeTransform {
  transform (searchResults: Entity[], searchValue: string): boolean {
    return (!searchResults || searchResults?.length === 0) && !searchValue;
  }
}
