import * as cleanDeep from 'clean-deep';

import { ChillzPicture } from '../../models/picture.model';
import { BackgroundStyle } from '../../models/style-components/background-style.model';


export function getBackgroundPicture (
  itemBackgroundPicture: ChillzPicture,
  itemBackgroundStyle: BackgroundStyle,
  containerWidth: number
): {
    src: string;
    isBlurred: boolean;
    isPlaceholder: boolean;
  } {
  let picture;
  let isBlurred;
  let isPlaceholder = false;
  if (itemBackgroundStyle?.mediaOverlay?.blurred) {
    picture = cleanDeep.default(itemBackgroundPicture?.blurred);
    isBlurred = true;
    if (!picture || !Object.keys(picture || {}).length) {
      picture = itemBackgroundPicture;
      isBlurred = false;
    }
  } else if (itemBackgroundPicture) {
    picture = cleanDeep.default(itemBackgroundPicture);
  }

  if (!picture) {
    if (itemBackgroundStyle?.mediaOverlay?.blurred) {
      picture = cleanDeep.default(itemBackgroundStyle?.mediaOverlay?.placeholder?.picture?.blurred);
      isBlurred = true;

      if (!picture || !Object.keys(picture || {}).length) {
        picture = itemBackgroundStyle?.mediaOverlay?.placeholder?.picture;
        isBlurred = false;
      }
    } else {
      picture = itemBackgroundStyle?.mediaOverlay?.placeholder?.picture;
    }

    if (picture) {
      isPlaceholder = true;
    }
  }

  if (!picture) {
    return;
  }

  if (picture && !itemBackgroundStyle?.mediaOverlay?.blurred) {
    if (!Object.entries(picture)?.filter((x) => !!x[1] && x[0] !== 'blurred' && x[0] !== '_id').length) {
      picture = picture.blurred;
    }
  }

  const src = getResponsiveSrc(picture, containerWidth);

  if (src) {
    return {
      src,
      isBlurred,
      isPlaceholder,
    };
  }
}

export function getResponsiveSrc (picture: Record<string, string>, containerWidth: number): string | undefined {
  if (containerWidth >= 1600 && picture['xl']) {
    return picture['xl'];
  }

  if (containerWidth >= 1200 && picture['lg']) {
    return picture['lg'];
  }

  if (containerWidth >= 992 && picture['md']) {
    return picture['md'];
  }

  if (containerWidth >= 500 && picture['sm']) {
    return picture['sm'];
  }

  if (containerWidth >= 200 && picture['xs']) {
    return picture['xs'];
  }

  return picture['thumb'];
}
