import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '../../../../@fuse/animations';
import { AppService } from '../../../app.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { ExploreTheme } from '../../models/explore-theme.model';


@Component({
  selector: 'app-explore-carousel',
  templateUrl: './explore-carousel.component.html',
  styleUrls: [ './explore-carousel.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ExploreCarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() theme: ExploreTheme;
  @Input() isPlaceholder: boolean;
  @Input() isFirstSection: boolean;
  @Output() goToTheme: EventEmitter<void> = new EventEmitter<void>();

  slideSize = 2;
  viewSize = 4;
  currentSlide = 0;
  currentSlideTranslate: string;
  itemFraction: number;
  currentWidth = 'md';
  textAttr: { direction: string; align: string };
  autoPlayInterval;
  isPaused = false;
  isResetTimerProcess = false;
  hideToolbar$: BehaviorSubject<boolean>;

  private touchStartX = 0;
  private swipeDistance = 0;
  private timerWorker: Worker = null;
  private _unsubscribeAll = new Subject<void>();

  constructor (
    public _translateService: TranslateService,
    private _router: Router,
    private _appService: AppService,
    private _changeDetectorRef: ChangeDetectorRef,
    public localizationService: AppLocalizationService,
    private _elRef: ElementRef
  ) {}

  ngOnInit (): void {
    this.hideToolbar$ = this._appService.hideToolbar$;

    // if (this.theme?.style?.carousel) {
    // const grid = this.theme?.style?.carousel?.grid;
    // this.carouselOptions.grid = { xs: grid?.['xs'], sm: grid?.['sm'], md: grid?.['md'], lg: grid?.['lg'], all: grid?.['all'] || 0 };
    // this.carouselOptions.speed = this.theme?.style?.carousel.speed || 400;
    // }

    const fontsToLoad = {};
    if (this.theme.style?.item?.textStyle?.fontFamily) {
      fontsToLoad[this.theme.style.item.textStyle.fontFamily] = null;
    }

    if (this.theme.style?.item?.overlay?.textStyle?.fontFamily) {
      fontsToLoad[this.theme.style.item.overlay.textStyle.fontFamily] = null;
    }

    this._appService.loadGoogleFonts(fontsToLoad);

    if (this._appService.onScreenWidthChanged.value) {
      this.setCurrentWidth(this._appService.onScreenWidthChanged.value);
    }

    this._appService.onScreenWidthChanged
      .pipe(takeUntil(this._unsubscribeAll), debounceTime(300))
      .subscribe((width) => {
        this.setCurrentWidth(width);
      });

    if (this.theme.style?.carousel?.autoPlay?.enabled) {
      setTimeout(() => {
        this.autoPlayInterval = this.theme.style?.carousel?.autoPlay.interval;
        this.initializeWorker();

        this.startAutoPlay();
      });
    }
  }

  private initializeWorker (): void {
    if (typeof Worker !== 'undefined') {
      this.timerWorker = new Worker(new URL('./carousel-autoplay.worker', import.meta.url), { type: 'module' });

      this.timerWorker.onmessage = ({ data }) => {
        if (data === 'tick') {
          this.onChangeSlide('next', false);
          this.runTimer(this.autoPlayInterval);
        }
      };
    }
  }

  startAutoPlay (): void {
    this.runTimer(this.autoPlayInterval);
  }

  runTimer (duration: number): void {
    if (!duration || !this.timerWorker) return;

    this.timerWorker.postMessage({ duration, action: 'start' });
  }

  disableAutoPlay (): void {
    if (this.timerWorker) {
      this.timerWorker.postMessage({ action: 'stop' });
    }
  }

  enableAutoPlay (): void {
    this.runTimer(this.autoPlayInterval);
  }

  toggleAutoPlay (): void {
    this.isPaused = !this.isPaused;

    if (this.isPaused) {
      this.disableAutoPlay();
    } else {
      this.enableAutoPlay();
    }
  }

  setCurrentWidth (width): void {
    this.currentWidth = width.group;
    this.viewSize = this.theme?.style?.carousel?.grid?.[width.group] || 4;
    this.slideSize = this.theme?.style?.carousel?.slide?.[width.group] || 2;

    this.itemFraction =
      100 /
      (this.viewSize +
        (this.theme.style.carousel.startOffset?.[this.currentWidth] || 0) +
        (this.theme.style.carousel.endOffset?.[this.currentWidth] || 0));

    this.currentSlideTranslate = this.getSlideTranslate(this.currentSlide);
  }

  ngAfterViewInit (): void {
    this._changeDetectorRef.detectChanges();
  }

  setCurrentSlide (index: number): void {
    this.currentSlide = index;
    this.currentSlideTranslate = this.getSlideTranslate(this.currentSlide);
    this.resetTimer();
  }

  onChangeSlide (dir: 'next' | 'prev', resetTimer = true, slides = this.slideSize): void {
    let newSlide;
    if (dir === 'next') {
      newSlide = Math.round(this.currentSlide + slides);
    } else if (dir === 'prev') {
      newSlide = Math.round(this.currentSlide - slides);
    }

    if (Math.max(Math.round(newSlide), 0) <= this.theme.items.length - this.viewSize) {
      this.currentSlide = Math.min(Math.max(Math.round(newSlide), 0), this.theme.items.length - this.viewSize);
    } else {
      this.currentSlide = 0;
    }

    this.currentSlideTranslate = this.getSlideTranslate(this.currentSlide);

    if (resetTimer) {
      this.resetTimer();
    }
  }

  getSlideTranslate (slide: number): string {
    const translate =
      -1 * slide * this.itemFraction +
      this.itemFraction * (this.theme.style.carousel.startOffset?.[this.currentWidth] || 0);

    return `calc(${translate.toString()}%)`;
  }

  getGridColumnsTemplate (): string {
    return `repeat(${this.theme.items.length}, ${
      100 /
      (this.viewSize +
        (this.theme.style.carousel.startOffset?.[this.currentWidth] || 0) +
        (this.theme.style.carousel.endOffset?.[this.currentWidth] || 0))
    }%)`;
  }

  ngOnDestroy (): void {
    this.disableAutoPlay();
    if (this.timerWorker) {
      this.timerWorker.terminate();
      this.timerWorker = null;
    }

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onTouchStart (event: TouchEvent): void {
    this.touchStartX = event.touches[0].clientX;
    this.swipeDistance = 0;
  }

  onTouchMove (event: TouchEvent): void {
    this.swipeDistance = event.touches[0].clientX - this.touchStartX;
  }

  onTouchEnd (): void {
    const swipeThreshold = 50;

    if (this.swipeDistance < -swipeThreshold) {
      this.onChangeSlide('next');
    }

    if (this.swipeDistance > swipeThreshold) {
      this.onChangeSlide('prev');
    }

    this.swipeDistance = 0;
  }

  private resetTimer (): void {
    if (!this.isPaused) {
      this.disableAutoPlay();
      this.isResetTimerProcess = true;

      setTimeout(() => {
        this.runTimer(this.autoPlayInterval);
        this.isResetTimerProcess = false;
      }, 300);
    }
  }
}
