import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'deLowerDashCase',
})
export class DeLowerDashCase implements PipeTransform {
  transform (value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    value = value.split('_').join(' ');
    value = value[0].toUpperCase() + value.slice(1);
    return value;
  }
}
