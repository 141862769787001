import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { LocalStorage } from '../@fuse/services/storage/local-storage';
import { EnvService } from '../env.service';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import { ChillzAuthService } from './auth/chillz-auth.service';
import { TrackingService } from './shared/tracking.service';


@Injectable({
  providedIn: 'root',
})
export class WebReqInterceptor implements HttpInterceptor {
  refreshingAccessToken: boolean;

  accessTokenRefreshed = new Subject<any>();

  isBrowser: boolean;

  constructor (
    private authService: ChillzAuthService,
    private _translateService: TranslateService,
    private _appService: AppService,
    private _envService: EnvService,
    private _localStorage: LocalStorage,
    private _trackingService: TrackingService,
    @Inject(PLATFORM_ID) platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle the request

    request = this.addServerUrl(request);
    request = this.addHeaders(request);

    if (!this.isBrowser) {
      console.log(`=========\nREQUEST:\nurl: ${request.url}\nbody: ${JSON.stringify(request.body || {})}\n headers: ${JSON.stringify(request.headers)}\n=========`);
    }

    // call next() and handle the response
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if (error.status === 401 && this.isBrowser) {
        // 401 error so we are unauthorized
        // this._localStorage.removeItem('x-access-token');

        // refresh the access token
        return this.refreshAccessToken().pipe(
          switchMap(() => {
            request = this.addHeaders(request);
            return next.handle(request);
          }),
          catchError(() => {
            this.authService.logout();

            return EMPTY;
          })
        );
      }

      return throwError(error);
    }));
  }

  refreshAccessToken (): Observable<any> {
    if (this.refreshingAccessToken) {
      return new Observable((observer) => {
        this.accessTokenRefreshed.subscribe(() => {
          // this code will run when the access token has been refreshed
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshingAccessToken = true;
      // we want to call a method in the auth service to send a request to refresh the access token

      return this.authService.getNewAccessToken().pipe(
        tap(() => {
          this.refreshingAccessToken = false;
          this.accessTokenRefreshed.next();
        }),
        catchError((err) => {
          this.refreshingAccessToken = false;
          return throwError(err);
        })
      );
    }
  }

  addServerUrl (request: HttpRequest<any>): HttpRequest<any> {
    if (request.url.startsWith('assets') || request.url.startsWith('/assets')) {
      return request;
    }

    return request.clone({
      url: (this._envService.settings?.x_api || environment.serverUrl) + request.url,
    });
  }

  addHeaders (request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getAccessToken();
    const session = this._localStorage.getItem('session');
    const platform = this._appService.platform?._id || this._envService.settings?.x_platform || environment.platform;
    const hubspotutk = this._trackingService.getHubspotutk();

    return request.clone({
      setHeaders: {
        ...(token ? { 'x-access-token': token } : {}),
        ...(session ? { 'x-session-id': session } : {}),
        ...(platform ? { 'x-platform': platform } : {}),
        ...(hubspotutk ? { 'x-hubspotutk': hubspotutk } : {}),
        ...(this._translateService.currentLang ? { 'content-lang': this._translateService.currentLang } : {}),
      },
    });
  }
}
