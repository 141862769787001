<div
  class="ticket-thumb"
  [class.cancelled]="ticket.cancelled"
  (click)="viewTicket(ticket)"
  fxLayout="column"
  fxLayout.gt-xs="row"
  fxLayoutAlign="start center"
  fxLayoutAlign.gt-xs="start stretch"
>
  <!--  QR CODE-->
  <div class="qr-code-wrapper my-auto">
    <qrcode
      [qrdata]="ticket.id"
      [allowEmptyString]="true"
      [colorDark]="ticket.ticketType?.venue?.accentColor"
      [colorLight]="'#ffffffff'"
      [margin]="0"
      [elementType]="'img'"
      [errorCorrectionLevel]="'M'"
      [scale]="32"
      [width]="200"
    ></qrcode>

    <!--    <img src="/assets/images/qr-code.png" class="qr-icon" alt="" />-->
    <!--    <button mat-button *ngIf="!ticket.cancelled">-->
    <!--      {{ 'ORDER.DONE.TICKETS.VIEW' | translate }}-->
    <!--    </button>-->
  </div>
  <!--  /QR CODE-->

  <div class="stamp" *ngIf="ticket.cancelled" fxLayoutAlign="center center">
    <span class="warn-fg"> {{ 'VENUE_THUMB.CANCELED' | translate }} </span>
  </div>

  <div
    class="ticket-details gap-8"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.gt-xs="start center"
    [class.cancelled]="ticket.cancelled"
  >
    <div class="ticket-name">{{ ticket.fullName }}</div>
    <div class="ticket-event-details gap-4 text-center">
      <div *ngIf="ticket.ticketType?.venue?.titleEng" class="font-weight-800">
        {{ ticket.ticketType.venue.titleEng }}
      </div>
      <div *ngIf="ticket.ticketType?.venue?.startsAt?.formatted">
        {{ ticket.ticketType?.venue?.startsAt?.formatted['en'] }}
      </div>
    </div>
    <div class="ticket-type">{{ ticket.ticketType.title | titlecase }}</div>
    <div fxLayout="row" fxFlex="1 1 auto" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="center center">
      <div class="ticket-price before-discount" *ngIf="ticket?.payment?.originalPrice !== ticket?.payment?.sum">
        {{ ticket.payment.originalPrice | currency: ticket.payment.currency }}
      </div>
      <div class="ticket-price" *ngIf="ticket.payment?.sum">
        <span *ngIf="ticket.payment.sum > 0"> {{ ticket.payment.sum | currency: ticket.payment.currency }} </span>
        <span *ngIf="ticket.payment.sum === 0"> {{ 'TICKET.FREE' | translate }} </span>
      </div>
    </div>
    <!--    <div class="ticket-id">-->
    <!--      {{ ticket.id }}-->
    <!--    </div>-->

    <div class="ticket-actions">
      <chillz-button
        (clicked)="$event.stopPropagation(); getApplePass()"
        [icon]="'apple-logo'"
        [loadingIcon]="'apple-logo'"
        [isSvgIcon]="true"
        [isLoadingSvgIcon]="true"
        [outline]="true"
        [expandable]="true"
        [padding]="'16px'"
        [iconClass]="'s-24'"
        [loaderSize]="56"
        [height]="56"
        [maxHeight]="56"
        [maxWidth]="56"
        [fontSize]="'12px'"
        [loading]="gettingApplePass"
        [label]="{ en: 'Add to Wallet' }"
      ></chillz-button>
    </div>

    <div class="seating" *ngIf="ticket.seat" fxLayout="column" fxLayoutAlign="center end">
      <div class="seat-label" *ngIf="ticket.seat.label">{{ ticket.seat.label }}</div>
      <div class="seat-section" *ngIf="ticket.seat">{{ ticket.seat.section.title }}</div>
      <div class="seat-row" *ngIf="ticket.seat">{{ 'TICKET.ROW' | translate }} {{ ticket.seat.row }}</div>
      <div class="seat-seat" *ngIf="ticket.seat">{{ 'TICKET.SEAT' | translate }} {{ ticket.seat.seat }}</div>
    </div>

    <!--                <div class="h4">{{ticket.id}}</div>-->
  </div>

  <!--  <div class="background" [style.&#45;&#45;backgroundColor]="ticket.ticketType?.venue?.accentColor">-->
  <!--    <img *ngIf="ticket.ticketType?.venue?.coverPicture?.md || ticket.ticketType?.venue?.inlinePicture?.md"-->
  <!--         [src]="ticket.ticketType?.venue?.coverPicture?.md || ticket.ticketType?.venue?.inlinePicture?.md">-->
  <!--  </div>-->
</div>
<div class="outline" [ngStyle]="{ top: '-2px', bottom: '-2px', left: '-2px', right: '-2px' }"></div>
