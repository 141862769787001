import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InputNumberModule } from 'primeng/inputnumber';

import { ChillzButtonModule } from '../chillz-button/chillz-button.module';
import { ChillzCheckboxComponent } from './chillz-checkbox/chillz-checkbox.component';
import { ChillzNumberInputComponent } from './chillz-number-input/chillz-number-input.component';
import { ChillzPhoneNumberInputComponent } from './chillz-phone-number-input/chillz-phone-number-input.component';
import { ChillzRadioToggleComponent } from './chillz-radio-toggle/chillz-radio-toggle.component';


@NgModule({
  declarations: [
    ChillzNumberInputComponent,
    ChillzRadioToggleComponent,
    ChillzCheckboxComponent,
    ChillzPhoneNumberInputComponent,
  ],
  imports: [
    CommonModule,
    InputNumberModule,
    ReactiveFormsModule,
    ChillzButtonModule,
    FlexModule,
    MatIconModule,
    MatTooltipModule,
    NgxIntlTelInputModule,
    FormsModule,
    MatFormFieldModule,
    TranslateModule,
  ],
  exports: [
    ChillzNumberInputComponent,
    ChillzRadioToggleComponent,
    ChillzCheckboxComponent,
    ChillzPhoneNumberInputComponent,
  ],
})
export class ChillzFormElementsModule {}
