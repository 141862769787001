import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { animationFrameScheduler, BehaviorSubject, combineLatest, interval, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, endWith, map, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { MinusSignToParens } from '../pipes/minus-sign-to-parens.pipe';


@Injectable()
export class Destroy extends Observable<void> implements OnDestroy {
  private readonly destroySubject$ = new ReplaySubject<void>(1);

  constructor () {
    super((subscriber) => this.destroySubject$.subscribe(subscriber));
  }

  ngOnDestroy (): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}

/**
 * Quadratic Ease-Out Function: f(x) = x * (2 - x)
 */
const easeOutQuad = (x: number): number => x * (2 - x);

@Directive({
  selector: '[countUp]',
  providers: [ Destroy ],
})
export class CountUpDirective implements OnInit {
  private readonly count$ = new BehaviorSubject(0);
  private currentCount = 0;
  private readonly duration$ = new BehaviorSubject(2000);

  @Output('countUpCompleted') completed = new EventEmitter<any>();

  @Input('countUpDigitsInfo') digitsInfo = '1.0';
  @Input('countUpCurrency') currency: string;
  @Input('countUpType') type = 'number';
  @Input('countUpMinusSignToParens') minusSignToParens = true;

  private readonly currentCount$ = combineLatest([ this.count$, this.duration$ ]).pipe(switchMap(([ count, duration ]) => {
    // get the time when animation is triggered
    const startTime = animationFrameScheduler.now();

    return interval(0, animationFrameScheduler).pipe(
      // calculate elapsed time
      map(() => animationFrameScheduler.now() - startTime),
      // calculate progress
      map((elapsedTime) => elapsedTime / duration + (this.currentCount || 0) / (count || 1)),

      tap((x) => {
        if (x >= 1) {
          this.currentCount = count;
          this.completed.emit();
        }
      }),
      // complete when progress is greater than 1
      takeWhile((progress) => progress <= 1),
      // apply quadratic ease-out
      // for faster start and slower end of counting
      map(easeOutQuad),

      // calculate current count
      map((progress) => Math.round(progress * count)),
      // make sure that last emitted value is count
      endWith(count),
      distinctUntilChanged()
    );
  }));

  @Input('countUp')
  set count (count: number) {
    this.count$.next(count);
  }

  @Input()
  set duration (duration: number) {
    this.duration$.next(duration);
  }

  constructor (
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly destroy$: Destroy,
    private _currencyPipe: CurrencyPipe,
    private _decimalPipe: DecimalPipe,
    private _percentPipe: PercentPipe,
    private _minusSignToParensPipe: MinusSignToParens
  ) {}

  ngOnInit (): void {
    this.displayCurrentCount();
  }

  private displayCurrentCount (): void {
    this.currentCount$.pipe(takeUntil(this.destroy$)).subscribe((currentCount) => {
      let transformed;

      if ((this.type === 'number' || !this.type) && !this.currency) {
        transformed = this._decimalPipe.transform(currentCount, this.digitsInfo);
      } else if (this.currency) {
        transformed = this._currencyPipe.transform(currentCount, this.currency, 'symbol', this.digitsInfo);
      } else if (this.type === 'percents') {
        transformed = this._percentPipe.transform(currentCount, this.currency, this.digitsInfo);
      }

      if (this.minusSignToParens) {
        transformed = this._minusSignToParensPipe.transform(transformed);
      }

      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', transformed);
    });
  }
}
