import { Pipe, PipeTransform } from '@angular/core';
import invert from 'invert-color';


@Pipe({
  name: 'invertColor',
  pure: true,
})
export class InvertColorPipe implements PipeTransform {
  transform (baseColor: string, toBlackWhiteColor: boolean): string {
    return invert(baseColor, toBlackWhiteColor);
  }
}
