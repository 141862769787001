import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '../../../../@fuse/animations';
import { AppService } from '../../../app.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { Platform as ChillzPlatform } from '../../../shared/models/platform.model';


@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
  animations: fuseAnimations,
})
export class FooterComponent implements OnInit, OnDestroy {
  year = new Date().getFullYear();
  platform: ChillzPlatform;

  private _unsubscribeAll = new Subject();

  constructor (private _appService: AppService, public localizationService: AppLocalizationService) {}

  ngOnInit (): void {
    this._appService.onPlatformChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((platform) => {
      this.platform = platform;
    });
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
