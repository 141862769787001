import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreAnimationDirective } from './core-animation.directive';
import { FadeInAnimationDirective } from './fade-in-animation.directive';
import { HopInAnimationDirective } from './hop-in-animation.directive';
import { PopAnimationDirective } from './pop-animation.directive';


@NgModule({
  declarations: [ FadeInAnimationDirective, HopInAnimationDirective, CoreAnimationDirective, PopAnimationDirective ],
  exports: [ FadeInAnimationDirective, HopInAnimationDirective, PopAnimationDirective ],
  imports: [ CommonModule ],
  providers: [],
})
export class GSAPDirectivesModule {}
