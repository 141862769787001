<cdk-virtual-scroll-viewport
  itemSize="100"
  maxBufferPx="500"
  minBufferPx="500"
  [dynamicHeight]="searchList.length"
  [itemSize]="100"
  [maxLength]="6"
  class="search-results-container w-100-p chillz-virtual-scroll"
>
  <chillz-search-result-item
    [platform]="platform"
    [item]="item"
    *cdkVirtualFor="let item of searchList"
    (click)="onSelectItem(item.id)"
  ></chillz-search-result-item>
</cdk-virtual-scroll-viewport>
