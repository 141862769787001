import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { ChillzBaseButtonComponent } from './chillz-base-button.component';


@NgModule({
  declarations: [ ChillzBaseButtonComponent ],
  imports: [ CommonModule, MatIconModule, MatRippleModule, RouterModule ],
  exports: [ ChillzBaseButtonComponent ],
})
export class ChillzBaseButtonModule {}
