<div [formGroup]="formGroup" fxLayout="column" class="gap-12">
  <input *ngIf="username" hidden id="username" autocomplete="username" type="email" [value]="username" />

  <mat-form-field floatLabel="auto" *ngIf="current">
    <mat-label>Confirm current password</mat-label>
    <input matInput formControlName="currentPassword" type="password" autocomplete="current-password" />
    <mat-error *ngIf="formGroup.get('currentPassword').hasError('required')">
      Your current password is required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>New password</mat-label>
    <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
    <input
      #passwordWithValidation
      autocomplete="new-password"
      matInput
      [type]="toggle.type"
      [formControl]="passwordComponentWithValidation.passwordFormControl"
    />
  </mat-form-field>

  <mat-password-strength
    #passwordComponentWithValidation
    [enableDigitRule]="true"
    [enableLengthRule]="true"
    [enableSpecialCharRule]="false"
    [enableUpperCaseLetterRule]="true"
    [enableLowerCaseLetterRule]="true"
    [password]="passwordWithValidation.value"
  ></mat-password-strength>
  <!--Password's strength info-->
  <mat-password-strength-info
    [matIconDone]="'check_circle'"
    [matIconError]="'check_circle'"
    [lowerCaseCriteriaMsg]="'One lowercase character'"
    [upperCaseCriteriaMsg]="'One uppercase character'"
    [minCharsCriteriaMsg]="'At least 8 characters'"
    [digitsCriteriaMsg]="'One digit'"
    [passwordComponent]="passwordComponentWithValidation"
  ></mat-password-strength-info>

  <mat-form-field appearance="standard" class="mt-6" *ngIf="repeat">
    <mat-label>Repeat new password</mat-label>
    <input autocomplete="new-password" matInput type="password" formControlName="passwordConfirm" />
    <mat-error *ngIf="formGroup.get('passwordConfirm').hasError('required')">
      Password confirmation is required
    </mat-error>
    <mat-error
      *ngIf="
        !formGroup.get('passwordConfirm').hasError('required') &&
        formGroup.get('passwordConfirm').hasError('passwordsNotMatching')
      "
    >
      Passwords must match
    </mat-error>
  </mat-form-field>
</div>
