import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { Entity } from '../../../main/entity/entity.model';
import { EntityService } from '../../../main/entity/entity.service';
import { TicketTypesService } from '../../../main/entity/ticket-types.service';
import { Order } from '../../../main/orders/order/order.model';
import { ExploreEngagementAction } from '../../models/explore-engagement-action.model';
import { ExploreItem } from '../../models/explore-item.model';
import { OrderItem } from '../../models/order-item.model';
import { Platform } from '../../models/platform.model';
import { OrderPreparationService } from '../order-preparation/order-preparation.service';
import { getBackgroundPicture } from './getBackgroundPicture';


@Component({
  selector: 'app-item-card-new',
  templateUrl: './item-card-new.component.html',
  styleUrls: [ './item-card-new.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardNewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() item: ExploreItem;
  @Input() isBaseExploreItem = false;
  @Input() viewingHost: Entity;
  @Input() alwaysOpenInNewWindow = false;
  @Input() newWindowUrlParams = {};
  @Input() disabled = false;
  @Input() headlineFontFamily: string;
  @Input() width;

  @Output() widthChange: EventEmitter<number> = new EventEmitter<number>();

  fileLoaded: boolean;
  isInView: boolean;

  queryParams: any = {};
  selectedForQuickOrder = false;
  platform: Platform;
  currentOrder: Order;
  backgroundPicture: { src: string; isBlurred: boolean; isPlaceholder: boolean };
  @ViewChild('container', { static: true }) container: ElementRef;

  private _unsubscribeAll = new Subject<any>();

  constructor (
    private _entityService: EntityService,
    private _appService: AppService,
    private _ticketTypesService: TicketTypesService,
    private _orderPreparationService: OrderPreparationService,
    public localizationService: AppLocalizationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit (): void {
    if (this.item?.entity?.tags && this.item?.entity?.tags?.length > 3) {
      this.item.entity.tags = this.item.entity.tags.slice(0, 3);
    }

    this._appService.onPlatformChanged
      .pipe(
        filter((x) => !!x),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((platform) => (this.platform = platform));

    this._route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((params) => {
      this.queryParams = params;
    });

    this._orderPreparationService.onOrderChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((order) => {
      this.currentOrder = order;
    });

    this.localizationService.onCurrentLangChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // this._changeDetectorRef.detectChanges();
    });

    this._orderPreparationService.onSelectedItemsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((selectedItems) => {
        if (!this.item) {
          return;
        }

        if (this.item.quickOrder.singleSelect) {
          const item =
            this.item.quickOrder.singleSelect?.ticketType || this.item.quickOrder.singleSelect?.generalProduct;

          if (item) {
            this.selectedForQuickOrder = !!selectedItems.find((x) => x.item._id === item._id && x.quantity > 0);
          }

          return;
        }

        // this.selectedForQuickOrder = this.item.quickOrder.itemsObserver?.value?.filter(x => selectedItems.find(y => y.item._id === x._id && y.quantity > 0)).length > 0;
      });

    // this.getBackgroundPicture();
  }

  onIsIntersectingChanged (): void {
    // console.log(event);
    // this._changeDetectorRef.detectChanges();
  }

  onClick (action: ExploreEngagementAction): void {
    if (!action) {
      return;
    }

    if (action.target === 'quick-order') {
      if (this.selectedForQuickOrder && !this.item.quickOrder.itemsObserver) {
        this.removeFromOrder();
        return;
      }

      this.onAddToOrder();
      return;
    }

    const mergedParams = { ...this.queryParams, ...this.newWindowUrlParams };
    let params = '';

    if (Object.keys(mergedParams)?.length) {
      params = '?' + new URLSearchParams(mergedParams).toString();
    }

    if (action.target === 'venue' && action?.id) {
      if (this.alwaysOpenInNewWindow || this.item.quickOrder?.isAllowed || action.newWindow) {
        window.open(
          (this.platform.baseUrl || 'https://chillz.com') + '/' + (action?.id || this.item?.entity?.id) + params,
          '_blank'
        );
      } else {
        if (this.item.entity?.id === action?.id) {
          this._entityService.venue = this.item.entity;
        }

        this._router.navigate([ '/', action?.id || this.item?.entity?.id ], { queryParamsHandling: 'merge' });
      }
    }

    if (action.target === 'explore' && action?.id) {
      if (this.alwaysOpenInNewWindow || this.item.quickOrder?.isAllowed || this.item.action?.newWindow) {
        window.open((this.platform.baseUrl || 'https://chillz.com/explore/') + '/' + action?.id + params, '_blank');
      } else {
        this._router.navigate([ '/', 'explore', action?.id ], { queryParamsHandling: 'merge' });
      }
    }

    if (action?.target === 'url' && action?.url) {
      window.open(action?.url + params, '_blank');
    }
  }

  onAddToOrder (): void {
    if (this.disabled) {
      return;
    }

    if (
      this.item?.quickOrder?.singleSelect?.ticketType &&
      !this.item?.quickOrder?.singleSelect?.ticketType?.status?.status?.availableForPurchase
    ) {
      return;
    }

    this.selectedForQuickOrder = true;

    if (this.item.quickOrder.singleSelect) {
      this._orderPreparationService.updateSelectedItems(
        [
          {
            item: this.item.quickOrder.singleSelect?.ticketType || this.item.quickOrder.singleSelect?.generalProduct,
            quantity: this.item.quickOrder.singleSelect?.quantity || 1,
          },
        ],
        this.item.entity
      );
      return;
    }

    if (!this.item.quickOrder?.itemsObserver) {
      this.item.quickOrder.itemsObserver = new BehaviorSubject<OrderItem[]>(this.item.quickOrder.ticketTypes);
    }

    if (!this.item.quickOrder.ticketTypes?.length) {
      this._ticketTypesService.getTicketTypes(this.item.entity, undefined, false, this.viewingHost?.id).then((res) => {
        this.item.quickOrder.ticketTypes = res.ticketTypes;
        this.item.quickOrder.itemsObserver.next(res.ticketTypes);
        this._changeDetectorRef.detectChanges();
      });
    }
  }

  removeFromOrder (): void {
    if (this.disabled) {
      return;
    }

    if (this.item.quickOrder.singleSelect) {
      this._orderPreparationService.updateSelectedItems([
        {
          item: this.item.quickOrder.singleSelect?.ticketType || this.item.quickOrder.singleSelect?.generalProduct,
          quantity: 0,
        },
      ]);
      return;
    }

    this._orderPreparationService.updateSelectedItems(this.item.quickOrder.itemsObserver.value.map((x) => ({ item: x, quantity: 0 })));
    this.selectedForQuickOrder = false;
  }

  // async getBackgroundPicture(): Promise<any> {
  //   let picture;
  //   let isBlurred;
  //   let isPlaceholder = false;
  //   if (this?.item.style?.background?.mediaOverlay?.blurred) {
  //     picture = cleanDeep.default(this.item.backgroundPicture?.blurred);
  //     isBlurred = true;
  //     if (!picture || !Object.keys(picture || {}).length) {
  //       picture = this.item.backgroundPicture;
  //       isBlurred = false;
  //     }
  //   } else if (this.item.backgroundPicture) {
  //     picture = cleanDeep.default(this.item.backgroundPicture);
  //   }
  //
  //   if (!picture) {
  //     if (this?.item.style?.background?.mediaOverlay?.blurred) {
  //       picture = cleanDeep.default(this.item.style?.background?.mediaOverlay?.placeholder?.picture?.blurred);
  //       isBlurred = true;
  //
  //       if (!picture || !Object.keys(picture || {}).length) {
  //         picture = this.item.style?.background?.mediaOverlay?.placeholder?.picture;
  //         isBlurred = false;
  //       }
  //
  //     } else {
  //       picture = this.item.style?.background?.mediaOverlay?.placeholder?.picture;
  //     }
  //     if (picture) {
  //       isPlaceholder = true;
  //     }
  //   }
  //
  //   if (!picture) {
  //     return;
  //   }
  //
  //   if (picture && !this?.item.style?.background?.mediaOverlay?.blurred) {
  //     if (!Object.entries(picture)?.filter(x => !!x[1] && x[0] !== 'blurred' && x[0] !== '_id').length) {
  //       picture = picture.blurred;
  //     }
  //   }
  //   let src;
  //   const width = this.container.nativeElement.offsetWidth;
  //   if (width >= 1600) {
  //     src = picture['xl'];
  //   }
  //   if (width >= 1200 && !src) {
  //     src = picture['lg'];
  //   }
  //   if (width >= 992 && !src) {
  //     src = picture['md'];
  //   }
  //   if (!src) {
  //     src = picture['sm'];
  //   }
  //   if (!src) {
  //     src = picture['thumb'];
  //   }
  //
  //   if (src) {
  //     this.backgroundPicture = {
  //       src,
  //       isBlurred,
  //       isPlaceholder
  //     };
  //   }
  //
  //
  //
  //
  // }

  // private invokeNewWorker(): void {
  //   this.backgroundPictureWorker = new Worker(
  //     new URL('./item-picture-src.worker', import.meta.url)
  //   );
  //
  //   this.isCalculatingBackgroundPicture = true;
  //   this.backgroundPictureWorker.onmessage = ({ data }) => {
  //     this.backgroundPicture = data;
  //     this.isCalculatingBackgroundPicture = false;
  //     this._changeDetectorRef.detectChanges();
  //   };
  //   this.backgroundPictureWorker.postMessage({ itemBackgroundPicture: this.item.backgroundPicture, itemBackgroundStyle: this.item.style?.background, containerWidth: this.width });
  // }

  ngAfterViewInit (): void {
    if (!this.width && this.container) {
      this.width = this.container.nativeElement.offsetWidth;
      this.widthChange.emit(this.width);
    }

    this.backgroundPicture = getBackgroundPicture(
      this.item.backgroundPicture,
      this.item?.style?.background,
      this.width || 250
    );
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
