export const locale = {
  lang: 'he',
  data: {
    ADD_PAYMENT_METHOD: {
      TITLE: 'הוספת אמצעי תשלום',
      DISCLAIMER: 'אנחנו לא מקבלים כרטיסי חיוב מיידי',
    },
    VENUE_THUMB: {
      TICKETS: 'כרטיסים',
      CANCELED: 'מבוטל',
      ADD_TO_ORDER: 'הוספה להזמנה',
      MORE_INFO: 'מידע נוסף',
      ADDED_TO_ORDER: 'בהזמנה שלך',
      PURCHASE: 'לחצו לרכישה',
      REMOVE_FROM_ORDER: 'הסרה',
      SOLD_OUT: 'Sold Out',
    },
    COOKIES_CONSENT: {
      TITLE: 'אנחנו עושים שימוש בעוגיות',
      BODY: 'אנחנו עושים שימוש ב"עוגיות" (Cookies) על מנת לשפר את השירותים שאנחנו מעניקים לך וכדי להתאים את האתר להעדפותיך האישיות.',
      OK: 'הבנתי',
    },
    PAYMENT: {
      lbl_card_number: 'מספר כרטיס',
      lbl_expiry: 'תוקף',
      lbl_cvv: 'CVV',
      lbl_card_holder_id: 'מספר זהות',
      lbl_amount: 'סכום',
      ISRAELI_CARDS_ONLY: 'רק אם הכרטיס שלך ישראלי. אם לא - אפשר להזין 1-9.',
      VALIDATION_CARD_NUMBER: 'מספר כרטיס אשראי שגוי',
      VALIDATION_EXPIRY: 'תוקף שגוי',
      VALIDATION_CVV: 'CVV שגוי',
      VALIDATION_HOLDER_ID: 'תעודת זהות שגויה',
      placeholder_card_holder_id: 'תעודת זהות',
      placeholder_card_number: 'מספר אשראי',
      placeholder_expiry: 'MM/YYYY',
      placeholder_cvv: 'CVV',
    },
    TICKET: {
      CANCELED: 'מבוטל',
      ROW: 'שורה',
      SEAT: 'כיסא',
      VIEW: 'פתיחה',
      FREE: 'חינם',
      CUSTOM_FIELDS: {
        IS_REQUIRED: 'חובה',
        OPTIONAL: 'אופציונלי',
        ALWAYS_MUTABLE: 'תמיד אפשר לשנות',
        MUTABLE_UNTIL_CHECKED_IN: 'אפשר לשנות עד למימוש הכרטיס בכניסה לארוע',
        IMMUTABLE: 'לא ניתן לשינוי',
      },
    },
  },
};
