<form class="settings-section-body" [formGroup]="socialMediaFormGroup">
  <mat-form-field floatLabel="auto">
    <mat-label>Instagram</mat-label>
    <span matPrefix>@</span>
    <input matInput formControlName="instagram" />
    <mat-error *ngIf="socialMediaFormGroup.get('instagram').hasError('pattern')">
      Pattern is not valid. We only need a profile handle (starts with a "@"), not a full URL.
    </mat-error>
  </mat-form-field>

  <mat-form-field floatLabel="auto">
    <mat-label>Tiktok</mat-label>
    <span matPrefix>@</span>
    <input matInput formControlName="tiktok" />
    <mat-error *ngIf="socialMediaFormGroup.get('tiktok').hasError('pattern')">
      Pattern is not valid. We only need a profile handle (starts with a "@"), not a full URL.
    </mat-error>
  </mat-form-field>

  <mat-form-field floatLabel="auto">
    <mat-label>Facebook</mat-label>
    <input matInput formControlName="facebook" />
    <mat-error *ngIf="socialMediaFormGroup.get('facebook').hasError('pattern')">
      Pattern is not valid. We need a full URL.
    </mat-error>
  </mat-form-field>

  <div class="settings-section-actions">
    <chillz-button
      [label]="status !== 'saved' ? { en: 'Save', he: 'שמירה' } : undefined"
      [icon]="status === 'saved' ? 'checkmark' : undefined"
      [isSvgIcon]="true"
      [fontSize]="'14px'"
      [padding]="'6px 20px'"
      [height]="26"
      [loaderSize]="26"
      [hoverPush]="true"
      [expandable]="false"
      [outline]="true"
      [hoverOutlineShine]="true"
      [outlineShine]="true"
      [shadow]="true"
      [glow]="false"
      [isLoader]="true"
      [disabled]="socialMediaFormGroup.invalid || socialMediaFormGroup.pristine || status === 'saving'"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-button>
  </div>
</form>
