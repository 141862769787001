import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '../../../../../@fuse/shared.module';
import { ChillzLoaderModule } from '../../../../shared/components/chillz-loader/chillz-loader.module';
import { ChillzSkeletonBlockModule } from '../../../../shared/components/chillz-skeleton-block/chillz-skeleton-block.module';
import { ChillzThumbPlaceholderModule } from '../../../../shared/components/chillz-thumb-placeholder/chillz-thumb-placeholder.module';
import { ChillzDirectivesModule } from '../../../../shared/directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../../../shared/directives/gsap/gsap-directives.module';
import { ChillzPipesModule } from '../../../../shared/pipes/chillz-pipes.module';
import { ChillzSharedModule } from '../../../../shared/shared.module';
import { SearchService } from './search.service';
import { ChillzSearchBarComponent } from './search-bar.component';
import { SearchResultItemComponent } from './search-result-item/search-result-item.component';
import { SearchResultListComponent } from './search-result-list/search-result-list.component';


@NgModule({
  declarations: [ ChillzSearchBarComponent, SearchResultItemComponent, SearchResultListComponent ],
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,

    FuseSharedModule,
    ChillzLoaderModule,
    GSAPDirectivesModule,
    ChillzThumbPlaceholderModule,
    ChillzDirectivesModule,
    ChillzSharedModule,
    ScrollingModule,
    ChillzPipesModule,
    ChillzSkeletonBlockModule,
  ],
  exports: [ ChillzSearchBarComponent, SearchResultItemComponent, SearchResultListComponent ],
  providers: [ SearchService, DatePipe ],
})
export class ChillzSearchBarModule {}
