export interface IFracSettings {
  min?: number;
  max?: number;
  step?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  inputDisplayTransform?: number;
}

export class StyleProperty {
  value?: number | string;

  fracSettings?: IFracSettings;

  private readonly defaultUnit;

  private _unit?: string;
  set unit (val) {
    this._unit = val;
  }
  get unit (): string {
    return this._unit || this.defaultUnit;
  }

  constructor (value?: number | string, defaultUnit?: string, fracSettings?: IFracSettings) {
    this.defaultUnit = defaultUnit;
    this.fracSettings = fracSettings;

    this.setValue(value);
  }

  splitValueElements (s: string | number): any {
    if (s === undefined || s === '') {
      return null;
    }

    s = s.toString();
    let unit = s
      ?.trim()
      .split(/[\d\.]+/g)
      .filter((n) => n)
      .pop()
      ?.trim();

    const value = parseFloat(s
      ?.trim()
      .split(unit)
      .filter((n) => n)[0]
      ?.trim());

    if (value !== undefined && !unit) {
      unit = this.unit;
    }

    return {
      value,
      unit,
    };
  }

  joinValueElements (value: number, unit?: string): string {
    let res = this.splitValueElements(value?.toString())?.value?.toString();
    if (res !== undefined && unit) {
      res += unit;
    }

    return res;
  }

  toString (viewSize?: number): string {
    if (typeof this.value === 'string') {
      return this.value;
    }

    if (isNaN(this.value)) {
      return null;
    }

    if (viewSize) {
      if ([ 'vh', 'vw' ].includes(this.unit)) {
        return Math.round((this.value / 100) * viewSize).toFixed(0) + 'px';
      }

      if (this.unit === 'px') {
      }
    }

    return this.joinValueElements(this.value, this.unit);
  }

  get inputValue (): number | string {
    let val = this.value;
    if (typeof val === 'number') {
      val = val * (this.fracSettings?.inputDisplayTransform || 1);

      if (this.unit === 'frac') {
        val = val * 100;
      }
    }

    return val;
  }

  set inputValue (v: number | string) {
    if (typeof v === 'number') {
      v = v / (this.fracSettings?.inputDisplayTransform || 1);

      if (this.unit === 'frac') {
        v = v / 100;
      }
    }

    this.value = v;
  }

  get numberValue (): number {
    if (typeof this.value === 'number') {
      return this.value;
    }
  }

  setValue (value?: number | string): void {
    if (typeof value === 'string' && isNaN(parseFloat(value))) {
      this.value = value;
      return;
    }

    if (!value && !(this.unit && value === 0)) {
      delete this.value;
      delete this._unit;
      return;
    }

    const split = this.splitValueElements(value);
    this.value = split?.value;
    if (Number.isNaN(this.value)) {
      delete this.value;
      delete this._unit;
    } else {
      this.unit = split?.unit || this.unit;
    }
  }
}
