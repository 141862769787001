import { ColorStyle } from './color-style.model';
import { GradientStyle } from './gradient-style.model';
import { PicturePlaceholderStyle } from './picture-placeholder-style.model';


export class BackgroundStyle {
  mediaOverlay: {
    value?: 'video' | 'picture';
    blurred?: boolean;
    opacity?: number;
    placeholder?: PicturePlaceholderStyle;
  };
  enabled?: boolean;
  color?: ColorStyle;
  asString?: string;
  gradient: GradientStyle;

  constructor (style: any = {}, defaultColor?: 'related-accent' | 'host-accent' | string) {
    this.mediaOverlay = {
      placeholder: new PicturePlaceholderStyle(),
    };
    this.color = new ColorStyle(undefined, defaultColor);
    this.gradient = new GradientStyle();

    this.setValue(style);
  }

  setValue (value: any = {}): void {
    this.enabled = value.enabled;

    if (value.color !== undefined) {
      this.color.setValue(value.color);
    }

    if (value.gradient !== undefined) {
      this.gradient.setValue(value.gradient);
    }

    if (value.mediaOverlay) {
      this.mediaOverlay.value = value?.mediaOverlay?.value;
      this.mediaOverlay.blurred = value?.mediaOverlay?.blurred;

      this.mediaOverlay.opacity = value?.mediaOverlay?.opacity === null ? 0.75 : value?.mediaOverlay?.opacity;

      if (value.mediaOverlay?.placeholder) {
        this.mediaOverlay.placeholder.setValue(value.mediaOverlay?.placeholder);
      }
    }
  }

  toString (relatedAccent?: string, hostAccent?: string): string {
    if (this.gradient?.enabled) {
      return this.gradient.toString(this.color.toString(true, relatedAccent, hostAccent));
    }

    return this.color.toString(true, relatedAccent, hostAccent);
  }

  toJson (): any {
    return {
      mediaOverlay: {
        value: this.mediaOverlay?.value,
        blurred: this.mediaOverlay?.blurred,
        opacity: this.mediaOverlay?.opacity,
        placeholder: this.mediaOverlay?.placeholder?.toJson(),
      },
      enabled: this.enabled,
      color: this.color?.toString(),
      gradient: this.gradient?.toJson(),
    };
  }
}
