import { Entity } from '../../main/entity/entity.model';
import { Language } from './language.model';
import { HomePageLayout } from './style-components/home-page-layout.model';
import { ThumbPlaceholder } from './thumb-placeholder.model';


export class Platform {
  _id: string;
  id: string;
  title: { [lang: string]: string };
  brandName: { [lang: string]: string[] };
  description: { [lang: string]: string };
  keywords: { [lang: string]: string[] };
  apiKeys: {
    stripe: string;
  };
  ogImage: string;
  favIcon: string;
  orderSupportUrl: { [lang: string]: string[] };
  footer: [
    {
      id: string;
      title: { [lang: string]: string };
      url: string;
      align: 'start' | 'center' | 'end';
      items: [
        {
          id: string;
          title: { [lang: string]: string };
          url: string | { [lang: string]: string };
          image: {
            url: string | { [lang: string]: string };
            alt: { [lang: string]: string };
          };
        }
      ];
    }
  ];
  toolbar: {
    enabled: boolean;
    searchBar: boolean;
    authMenu: boolean;
    languagePicker: boolean;
    logo: boolean;
    color?: string;
    transparentBeforeScrolling?: boolean;
  };
  localization: {
    availableLanguages: Language[];
    availableCurrencies: string[];
    defaultCountry: string;
    defaultCurrency: string;
    defaultCountryCode: string;
    defaultLanguage: string;
    timeFormat: '24h' | 'AM:PM';
    distanceFormat: 'km' | 'mi';
    useMeridian: boolean;
    dateFormat: string;
    shortFormat: string;
  };
  facebookClientId: string;
  facebookPixels: string[];
  defaultRole: string;
  baseAdminUrl: string;
  baseUrl: string;
  defaultAccentColor: string;
  animatedBG: {
    intro: boolean;
    venue: boolean;
    order: boolean;
  };
  loyaltyProgram: boolean;
  exploreFilterMenu: boolean;
  cityPicker: boolean;
  checkoutDisclaimer: { [lang: string]: string };
  homePageLayout: HomePageLayout;
  termsOfService: { b2b: { [lang: string]: string }; b2c: { [lang: string]: string } };
  privacyPolicy: { b2b: { [lang: string]: string }; b2c: { [lang: string]: string } };
  homePageThemes: any[];
  geoLocation: boolean;
  cookiesPopup: boolean;
  integrations: {
    facebookClientId?: string;
    facebookAppId?: string;
    facebookPixels?: [string];
    googleAnalytics?: [string];
    gtag?: [string];
    gtm?: [string];
    snapchat?: [string];
    tiktok?: [string];
    nagich?: any;
    userWay?: string;
    googleAuthentication?: string;
  };
  completeSignupURL: {
    b2b: string;
    b2c: string;
  };
  logo: {
    main: string;
    white: string;
  };
  taxRates: [
    {
      tax: string;
      rate: number;
    }
  ];

  splashScreen: {
    logo: {
      type: 'lottie' | 'img';
      src: string;
      width: number;
      height: number;
    };
  };
  thumbPlaceholder: ThumbPlaceholder;

  whiteLabel: {
    entity: Entity;
  };

  optionalFonts: {
    font: string;
    weights: number[];
  }[];
  defaultFontFamily: string;
  uiDefaultFonts: {
    font: string;
    weights: number[];
    isGoogleFont: boolean;
    adobeWebProject: string;
  }[];

  /**
   * Constructor
   *
   */
  constructor (platform?) {
    platform = platform || {};

    this._id = platform._id;
    this.id = platform.id;
    this.title = platform.title;
    this.brandName = platform.brandName || {};
    this.localization = {
      availableLanguages: platform?.localization?.availableLanguages.map((x) => new Language(x)),
      availableCurrencies: platform?.localization?.availableCurrencies || [ 'USD', 'ILS', 'EUR' ],
      defaultLanguage: platform?.localization?.defaultLanguage || 'en',
      defaultCountry: platform?.localization?.defaultCountry || 'US',
      defaultCountryCode: platform?.localization?.defaultCountryCode || '1',
      defaultCurrency: platform?.localization?.defaultCurrency || 'USD',
      timeFormat: platform?.localization?.timeFormat || '24h',
      distanceFormat: platform?.localization?.distanceFormat || 'km',
      useMeridian: platform?.localization?.useMeridian,
      dateFormat: platform?.localization?.dateFormat,
      shortFormat: platform?.localization?.shortFormat,
    };
    this.defaultRole = platform.defaultRole;
    this.baseUrl = platform.baseUrl;
    this.baseAdminUrl = platform.baseAdminUrl;
    this.apiKeys = { stripe: platform.apiKeys?.stripe?.public };
    this.termsOfService = platform.termsOfService;
    this.privacyPolicy = platform.privacyPolicy;
    this.completeSignupURL = platform.completeSignupURL;
    this.logo = platform.logo;
    this.loyaltyProgram = platform.loyaltyProgram;
    this.exploreFilterMenu = platform.exploreFilterMenu;
    this.cityPicker = platform.cityPicker;
    this.orderSupportUrl = platform.orderSupportUrl;
    this.taxRates = platform.taxRates;
    this.footer = platform.footer?.b2c || [];
    this.toolbar = platform.toolbar?.b2c || {
      enabled: true,
      searchBar: true,
      authMenu: true,
      languagePicker: true,
      logo: true,
      transparentBeforeScrolling: true,
    };

    this.splashScreen = platform.splashScreen?.b2c || {};
    this.description = platform.description?.b2c || {};
    this.keywords = platform.keywords?.b2c || {};
    this.ogImage = platform.ogImage?.b2c;
    this.favIcon = platform.favIcon?.b2c;
    this.checkoutDisclaimer = platform.checkoutDisclaimer;
    this.facebookPixels = platform.facebookPixels?.b2c || [];
    this.facebookClientId = platform.facebookClientId?.b2c;

    this.animatedBG = platform.animatedBG;
    this.integrations = {
      facebookAppId: platform.integrations?.facebookAppId?.b2c,
      facebookPixels: platform.integrations?.facebookPixels?.b2c,
      facebookClientId: platform.integrations?.facebookClientId?.b2c,
      googleAnalytics: platform.integrations?.googleAnalytics?.b2c,
      googleAuthentication: platform.integrations?.googleAuthentication?.b2c,
      nagich: platform.integrations?.nagich,
      gtag: platform.integrations?.gtag?.b2c,
      snapchat: platform.integrations?.snapchat?.b2c,
      tiktok: platform.integrations?.tiktok?.b2c,
      gtm: platform.integrations?.gtm?.b2c,
      userWay: platform.integrations?.userWay?.b2c,
    };

    this.thumbPlaceholder = platform.thumbPlaceholder?.b2c ? new ThumbPlaceholder(platform.thumbPlaceholder.b2c) : null;

    if (platform.whiteLabel?.entity) {
      this.whiteLabel = {
        entity: new Entity(platform.whiteLabel.entity),
      };
    }

    this.defaultAccentColor = platform.defaultAccentColor;
    this.geoLocation = platform.geoLocation !== undefined ? platform.geoLocation : true;
    this.cookiesPopup = platform.cookiesPopup !== undefined ? platform.cookiesPopup : true;
    this.optionalFonts = platform.optionalFonts || [];
    this.uiDefaultFonts = platform.uiDefaultFonts?.b2c || [];
    this.defaultFontFamily = platform.defaultFontFamily;
    this.facebookPixels = this.integrations.facebookPixels || [];

    if (platform?.homePageLayout) {
      this.homePageLayout = new HomePageLayout(platform?.homePageLayout);
    }

    if (platform.homePageThemes) {
      this.homePageThemes = platform.homePageThemes;
    }
  }
}
