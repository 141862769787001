import { User } from '../../auth/user.model';
import { Entity } from '../../main/entity/entity.model';
import { Order } from '../../main/orders/order/order.model';
import { PromoCode } from './promo-code.model';
import { TicketType } from './ticket-type.model';


export class Ticket {
  _id: string;
  id: string;
  ticketType: TicketType;
  cluster: string;
  user: User;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  issuedAt: Date;
  issuedManually: boolean;
  venue: Entity;

  replacedBy: any;
  payment: {
    method: any;
    confirmationCode: string;
    currency: string;
    originalPrice: number;
    sum: number;
    manual: {
      method: string;
      reference: string;
      user: string;
      confirmationCode: string;
    };
  };
  checkedIn: Date[];
  cancelled: Date;
  cancelledBy: User;
  couponCode: Date;
  promotionConsent: boolean;
  seat: any;
  accessKey: string;
  order: Order;
  cancellationFee: number;
  age: number;
  gender: string;
  dateOfBirth: {
    year: number;
    month: number;
    day: number;
  };
  idNumber: string;
  customFields: any;
  healthDeclaration: boolean;
  facebook: string;
  replacedTicket: Ticket;
  voidTicket: {
    label: string;
    actualPrice: number;
    discount: number;
    appliedPromoCodes: PromoCode[];
  };
  appliedPromoCodes: PromoCode[];

  /**
   * Constructor
   *
   */
  constructor (ticket?) {
    {
      ticket = ticket || {};
      this._id = ticket._id;
      this.id = ticket.id;
      this.ticketType = ticket.ticketType || {};
      this.user = ticket.user;
      this.fullName = ticket.fullName;
      this.firstName = ticket.firstName;
      this.lastName = ticket.lastName;
      this.email = ticket.email;
      this.phoneNumber = ticket.phoneNumber;
      this.countryCode = ticket.countryCode;
      this.cancelled = ticket.cancelled;

      if (ticket.cancelledBy) {
        this.cancelledBy = new User(ticket.cancelledBy);
      }

      this.checkedIn = ticket.checkedIn || [];
      this.couponCode = ticket.couponCode;

      if (ticket.order) {
        this.order = new Order(ticket.order);
      }

      this.accessKey = ticket.accessKey;

      ticket.payment = ticket.payment || {};

      this.payment = ticket.payment;

      this.payment.manual = ticket.payment.manual || {};
      this.payment.method = ticket.payment.method || {};

      this.promotionConsent = ticket.promotionConsent;
      this.cluster = ticket.cluster;
      this.issuedAt = ticket.issuedAt;
      this.issuedManually = ticket.issuedManually;
      this.seat = ticket.seat || {};

      this.age = ticket.age;
      this.gender = ticket.gender;
      this.dateOfBirth = ticket.dateOfBirth || {};
      this.idNumber = ticket.idNumber;
      this.customFields = ticket.customFields || {};
      this.healthDeclaration = ticket.healthDeclaration;
      this.facebook = ticket.facebook;

      if (ticket.replacedTicket) {
        this.replacedTicket = new Ticket(ticket.replacedTicket);
      }

      this.replacedBy = ticket.replacedBy;

      if (ticket.venue) {
        this.venue = new Entity(ticket.venue);
      }

      this.voidTicket = ticket.voidTicket;

      this.appliedPromoCodes = ticket.appliedPromoCodes?.map((x) => new PromoCode(x));
      if (!this.appliedPromoCodes?.length) {
        this.appliedPromoCodes = this.voidTicket?.appliedPromoCodes?.map((x) => new PromoCode(x));
      }

      this.cancellationFee =
        ticket.venue && ticket.venue.management ? ticket.venue.management.cancellationFee || 0.05 : 0.05;
    }
  }
}
