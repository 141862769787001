import { PaddingStyleProperty } from './padding-style-property';


export class HomePageLayout {
  _id: string;
  padding: PaddingStyleProperty;

  constructor (style: any = {}) {
    this._id = style._id;
    this.padding = new PaddingStyleProperty();
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    if (value.padding) {
      this.padding.setValue(value.padding);
    }
  }
}
