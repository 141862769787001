import { ExploreItem } from './explore-item.model';


export class ExploreEngagementAction {
  isActive: boolean;
  title?: { [lang: string]: string };
  target?: 'explore' | 'url' | 'venue' | 'quick-order';
  id?: string;
  url?: string;
  newWindow?: boolean;
  theme?: string;
  invalid?: boolean;
  label?: { [lang: string]: string };

  constructor (value: any = {}) {
    this.setValue(value);
  }

  setValue (value: any = {}): void {
    if (value.isActive !== undefined) {
      this.isActive = value.isActive;
    }

    if (value.title !== undefined) {
      this.title = value.title;
    }

    if (value.target !== undefined) {
      this.target = value.target;
    }

    if (value.id !== undefined) {
      this.id = value.id;
    }

    if (value.url !== undefined) {
      this.url = value.url;
    }

    if (value.newWindow !== undefined) {
      this.newWindow = value.newWindow;
    }

    if (value.theme !== undefined) {
      this.theme = value.theme;
    }

    if (value.label !== undefined) {
      this.label = value.label;
    }
  }

  getForItem (item: ExploreItem): ExploreEngagementAction {
    const res = new ExploreEngagementAction();
    res.target = this.target;
    res.id = this.id;
    res.newWindow = this.newWindow;
    res.label = this.label;
    if (item.entity) {
      if (res.target === 'quick-order' && (item?.quickOrder?.isAllowed === false || !item.entity?.sellingTickets)) {
        res.target = 'venue';
      }

      if (res.target === 'venue' && !res.id) {
        res.id = item.entity.id;
      }
    }

    res.url = res.target === 'url' ? this.url : undefined;
    res.invalid =
      !this.target ||
      (res.target === 'url' && !this.url) ||
      (res.target === 'venue' && !item.entity?.id && !this.id) ||
      (res.target === 'explore' && !this.id);

    return res;
  }

  toJson (): any {
    return {
      isActive: this.isActive,
      title: this.title,
      target: this.target,
      id: this.id,
      url: this.url,
      newWindow: this.newWindow,
      theme: this.theme,
    };
  }
}
