import { Component, Input } from '@angular/core';

import { ThumbPlaceholder } from '../../models/thumb-placeholder.model';


@Component({
  selector: 'chillz-thumb-placeholder',
  templateUrl: './chillz-thumb-placeholder.component.html',
  styleUrls: [ './chillz-thumb-placeholder.component.scss' ],
})
export class ChillzThumbPlaceholderComponent {
  @Input() thumbPlaceholder: ThumbPlaceholder;
  @Input() borderRadius = '16px';

  public thumbPlaceholderSymbolLoaded: boolean;
  public thumbPlaceholderPictureLoaded: boolean;

  public thumbPlaceholderSymbolLoad (): void {
    this.thumbPlaceholderSymbolLoaded = true;
  }

  public thumbPlaceholderPictureLoad (): void {
    this.thumbPlaceholderPictureLoaded = true;
  }
}
