import { CommonModule, Location } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';

import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '../@fuse/components';
import { FuseModule } from '../@fuse/fuse.module';
import { SeoService } from '../@fuse/services/seo.service';
import { FuseSharedModule } from '../@fuse/shared.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppLanguageGuard } from './app-language.guard';
import { AppLocalizationService } from './app-localization.service';
import { ChillzAuthService } from './auth/chillz-auth.service';
import { fuseConfig } from './fuse-config';
import { LayoutModule } from './layout/layout.module';
import { ChillzLoaderModule } from './shared/components/chillz-loader/chillz-loader.module';
import { ChillzDialogsModule } from './shared/components/dialogs/chillz-dialogs.module';
import { OrderPreparationModule } from './shared/components/order-preparation/order-preparation.module';
import { ChillzDirectivesModule } from './shared/directives/chillz-directives.module';
import { GSAPDirectivesModule } from './shared/directives/gsap/gsap-directives.module';
import { ChillzSharedModule } from './shared/shared.module';
import { TrackingService } from './shared/tracking.service';
import { WebReqInterceptor } from './web-req.interceptor';

export function playerFactory (): Promise<any> {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

const innerRouter: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./main/intro/intro.module').then((m) => m.IntroModule),
  },
  { path: 'auth', loadChildren: () => import('./auth/auth-with-routes.module').then((m) => m.AuthWithRoutesModule) },
  {
    path: 'explore',
    loadChildren: () => import('./main/explore/explore.module').then((m) => m.ExploreModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./main/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./main/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'errors',
    loadChildren: () => import('./shared/components/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 's',
    loadChildren: () => import('./main/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
  },
  {
    path: '',
    loadChildren: () => import('./main/entity/entity.module').then((m) => m.EntityModule),
    pathMatch: 'prefix',
  },
];

const appRoutes: Routes = [
  {
    path: '',
    resolve: {
      session: ChillzAuthService,
    },
    children: [
      {
        path: ':lang',
        // component: ContentComponent,
        canActivate: [ AppLanguageGuard ],
        canActivateChild: [ AppLanguageGuard ],
        runGuardsAndResolvers: 'pathParamsChange',
        resolve: {
          seo: SeoService,
          // localization: AppLanguageResolverService,
          localization: AppLocalizationService,
          platform: AppService,
          tracking: TrackingService,
        },
        children: innerRouter,
      },
      {
        path: '',
        pathMatch: 'full',
        canActivate: [ AppLanguageGuard ],
        // canActivateChild: [AppLanguageGuard],
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      // preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledNonBlocking',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
      scrollPositionRestoration: 'top',
    }),

    Angulartics2Module.forRoot(),

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    CommonModule,
    ChillzDialogsModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return '';
        },
      },
    }),
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    ChillzSharedModule,
    LayoutModule,
    MatSelectModule,
    OrderPreparationModule,
    RecaptchaV3Module,
    ChillzDirectivesModule,
    GSAPDirectivesModule,
    LayoutModule,
    ChillzLoaderModule,
  ],
  bootstrap: [ AppComponent ],
  providers: [
    Location,
    JwtHelperService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: WebReqInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
  ],
})
export class AppModule {}
