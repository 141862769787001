<div mat-dialog-title>
  <div class="dialog-title" *ngIf="title" [innerText]="title[localizationService.currentLang] || title['en']"></div>
  <div
    class="dialog-sub-title"
    *ngIf="subtitle"
    [innerText]="subtitle[localizationService.currentLang] || subtitle['en']"
  ></div>
</div>
<p *ngIf="description" [innerText]="description[localizationService.currentLang] || description['en']"></p>
<div mat-dialog-actions [class.singular]="hideConfirmButton || hideCancelButton">
  <chillz-base-button
    *ngIf="!hideCancelButton && cancel"
    [mat-dialog-close]="false"
    [label]="cancel"
    [variant]="'secondary'"
    [size]="'large'"
  ></chillz-base-button>
  <chillz-base-button
    *ngIf="!hideConfirmButton && confirm"
    [mat-dialog-close]="true"
    [variant]="'primary'"
    [size]="'large'"
    [label]="confirm"
  ></chillz-base-button>
</div>
<mat-icon class="dialog-close-icon" [matDialogClose]="false" svgIcon="chillz-cross"></mat-icon>
