import { Entity } from '../../main/entity/entity.model';
import { OrderItem } from './order-item.model';


export class TicketType extends OrderItem {
  isCluster: boolean;
  cluster: TicketType;

  venue: Entity;
  quantity: number;
  quantityStrictness: 'strict' | 'loose';
  seatRequired: boolean;
  isAddon = false;
  facebookRequired: boolean;
  instagramRequired: boolean;
  healthDeclarationRequired = false;
  idNumberRequired = false;
  subjectToApproval = false;
  greenPassRequired = false;
  couponRequired = false;
  reuseAllowed = false;
  checkOutAllowed = false;
  bgColor: string;
  ageRequired = false;
  dateOfBirthRequired = false;
  genderRequired = false;
  mergedBuyerDetails = false;

  forceMinAge: number;
  buyerProcessingFee: number;

  coinzWorth: number;
  sold: number;
  income: number;
  soldOut: boolean;
  couponCodes: string[];
  warning: string;
  isHidden: boolean;
  fee: any;
  feeUnit: string;
  isRefundable: boolean;
  idPrefix: string;
  status: {
    status: {
      _id: string;
      id: string;
      name: { [lang: string]: string };
      availableForPurchase: boolean;
    };
    custom?: string;
  };
  attachedFiles: string[];
  whiteListMembershipRequired: boolean;
  whiteLists: any[];
  attachments: [{ url: string; bucket: string; key: string; fileName: string }];
  logo: any;
  bottomLine: string;
  emailNotifications: string[];

  ticketTypes: TicketType[];

  ticketsAllocated: number;

  sortVal: number;

  customFields: {
    key: string;
    required: boolean;
    fieldType: 'input' | 'textArea' | 'checkbox' | 'date';
    mutable: 'always' | 'untilCheckedIn';
    title: {
      he: string;
      en: string;
    };
  }[];

  updating = false;

  /**
   * Constructor
   *
   */
  constructor (ticketType?) {
    super(ticketType);

    ticketType = ticketType || {};
    this.isCluster = ticketType.isCluster;

    if (ticketType.cluster) {
      this.cluster = new TicketType(ticketType.cluster);
    }

    this.quantity = ticketType.quantity || undefined;
    this.quantityStrictness = ticketType.quantityStrictness;
    this.seatRequired = ticketType.seatRequired;
    this.facebookRequired = ticketType.facebookRequired;
    this.instagramRequired = ticketType.instagramRequired;
    this.healthDeclarationRequired = ticketType.healthDeclarationRequired;
    this.greenPassRequired = ticketType.greenPassRequired;
    this.ageRequired = ticketType.ageRequired;
    this.genderRequired = ticketType.genderRequired;
    this.subjectToApproval = ticketType.subjectToApproval;
    this.reuseAllowed = ticketType.reuseAllowed;
    this.checkOutAllowed = ticketType.checkOutAllowed;
    this.sortVal = ticketType.sortVal;
    this.mergedBuyerDetails = ticketType.mergedBuyerDetails;

    this.coinzWorth = ticketType.coinzWorth;
    this.couponCodes = ticketType.couponCodes || [];
    this.warning = ticketType.warning;
    this.sold = ticketType.sold;
    this.income = ticketType.income;
    this.soldOut = ticketType.soldOut;
    this.isHidden = ticketType.isHidden;
    this.attachedFiles = ticketType.attachedFiles || [];
    this.emailNotifications = ticketType.emailNotifications || [];

    this.idNumberRequired = ticketType.idNumberRequired;
    this.customFields = ticketType.customFields || [];

    if (ticketType.ticketTypes) {
      this.ticketTypes = ticketType.ticketTypes.map((x) => new TicketType(x));
    }

    this.buyerProcessingFee = ticketType.buyerProcessingFee;
    this.forceMinAge = ticketType.forceMinAge;
    this.dateOfBirthRequired = ticketType.dateOfBirthRequired;

    this.bgColor = ticketType.bgColor;
    this.logo = ticketType.logo;
    this.bottomLine = ticketType.bottomLine;

    this.status = ticketType.status || {};

    if (ticketType.venue) {
      this.venue = ticketType.venue instanceof Entity ? ticketType.venue : new Entity(ticketType.venue);
    }

    this.attachments = ticketType.attachments || [];

    this.idPrefix = ticketType.idPrefix;
    this.isAddon = ticketType.isAddon;
    this.couponRequired = ticketType.couponRequired;
    this.userRequired = ticketType.userRequired;
    this.whiteLists = ticketType.whiteLists || [];
    this.whiteListMembershipRequired = ticketType.whiteListMembershipRequired;
  }
}
