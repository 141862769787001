import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { AppLocalizationService } from '../../../../app-localization.service';


@Component({
  selector: 'chillz-radio-toggle',
  templateUrl: './chillz-radio-toggle.component.html',
  styleUrls: [ './chillz-radio-toggle.component.scss' ],
})
export class ChillzRadioToggleComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: AbstractControl;
  @Input() label: { [lang: string]: string };
  @Input() id: string;
  @Input() icon: string;
  @Input() isIconSymbol: boolean;

  @Input() options: {
    id?: string;
    value: any | boolean;
    label?: { [lang: string]: string };
    icon?: string;
    isSvgIcon?: boolean;
    ctaIcon?: string;
    isCtaSvgIcon?: boolean;
    disabled?: boolean;
    iconClass?: string;
  }[];

  @Input() cta: boolean;
  // @Input() outline: boolean;
  @Input() outlineShine = true;
  @Input() glow = true;

  @Input() glowIntensity = 0;
  @Input() ctaGlowIntensity = 0;

  @Input() shadow = true;

  @Input() height: number;
  @Input() width: number;
  @Input() maxWidth: number;
  @Input() maxHeight: number;

  @Input() padding = '8px 16px';
  @Input() letterSpacing = '0';
  @Input() fontSize = '12px';

  @Input() borderRadius = '300px';
  @Input() strokeWidth;

  @Input() background = true;
  @Input() backgroundColor;

  @Input() ctaColors = [ '#ffb369', '#ff4272' ];

  // Private
  private _unsubscribeAll = new Subject<any>();

  constructor (public localizationService: AppLocalizationService) {}

  ngOnInit (): void {}

  ngAfterViewInit (): void {}

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
