import { NgModule } from '@angular/core';

import { ChillzSkeletonBlockComponent } from './chillz-skeleton-block.component';


@NgModule({
  declarations: [ ChillzSkeletonBlockComponent ],
  exports: [ ChillzSkeletonBlockComponent ],
})
export class ChillzSkeletonBlockModule {}
