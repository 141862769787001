import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CreditCardValidators } from 'angular-cc-library';
import { BehaviorSubject, merge, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { locale as english } from '../i18n/en';
import { locale as hebrew } from '../i18n/he';


declare const TzlaHostedFields: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: [ './payment.component.scss' ],
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  paymentForm: FormGroup;
  fields: any;

  @Input() amount: string;
  @Input() currencyCode: any = 'ILS';
  @Input() extraFields: any = {};
  @Input() charge: Observable<void>;
  @Input() installments: BehaviorSubject<number>;
  @Input() actualAmount: BehaviorSubject<number>;
  @Input() verificationOnly = false;

  @Input() showChargeButton = true;
  @Input() showAmount = true;
  @Input() placeholders = false;
  @Input() terminalName;
  @Output() complete = new EventEmitter<any>();
  @Output() formValidityChanged = new EventEmitter<boolean>();

  private chargeEventsSubscription: Subscription;
  private installmentsChangeEventsSubscription: Subscription;
  textAttr: { direction: any; align: any };

  installmentsFields = {};
  creditCardNumber: string;
  expiry: string;
  cvv: string;
  imageSrc: string;

  cardNumValid;
  cvvValid;
  cardHolderValid;
  expiryValid;

  successHidden = true;
  failedHidden = true;
  errorMessage: string;
  private _unsubscribeAll: Subject<any>;

  constructor (
    private _fb: FormBuilder,
    public _translateService: TranslateService,
    private _localizationService: AppLocalizationService,
    private translationLoader: FuseTranslationLoaderService,
    private elementRef: ElementRef
  ) {
    if ([ 'he' ].includes(_translateService.currentLang)) {
      this.textAttr = { align: 'right', direction: 'rtl' };
    } else {
      this.textAttr = { align: 'left', direction: 'ltr' };
    }

    this.translationLoader.loadTranslations(english, hebrew);
    this._unsubscribeAll = new Subject();
    this.creditCardNumber = '';
    this.expiry = '';
    this.cvv = '';
    this.imageSrc = 'assets/icons/customized/credit-card.svg';
  }

  ngOnInit (): void {
    this.paymentForm = this._fb.group({
      amount: [ { value: '', disabled: true } ],
      creditCardNumber: [ '', [ CreditCardValidators.validateCCNumber ] ],
      expiry: [ '', [ CreditCardValidators.validateExpDate ] ],
      cvv: [ '', [ Validators.required, Validators.minLength(3), Validators.maxLength(4) ] ],
      creditCardHolderId: [ '', [ Validators.required ] ],
    });

    if (this.charge) {
      this.charge.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        this.onSubmit();
      });
    }

    if (this.installments && this.actualAmount) {
      merge(this.installments, this.actualAmount)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          if (this.installments.value > 1) {
            console.log(this.actualAmount.value);
            const installmentsAmount = (this.actualAmount.value / this.installments.value).toFixed(2);
            const firstInstallmentAmount = (
              this.actualAmount.value -
              parseFloat(installmentsAmount) * (this.installments.value - 1)
            ).toFixed(2);

            this.installmentsFields = {
              payment_plan: '8',
              first_installment_amount: firstInstallmentAmount,
              other_installments_amount: installmentsAmount,
              total_installments_number: this.installments.value.toString(),
            };
            console.log(this.installmentsFields);
          } else {
            this.installmentsFields = {
              payment_plan: '1',
            };
          }
        });
    }
  }

  ngAfterViewInit (): void {
    let holderIdPlaceholder = '';
    let cardNumPlaceholder = '';
    let cvvPlaceholder = '';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let expiryPlaceholder = '';
    this._translateService
      .get('PAYMENT.placeholder_card_holder_id')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        holderIdPlaceholder = res;
        this._translateService
          .get('PAYMENT.placeholder_card_number')
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res1) => {
            cardNumPlaceholder = res1;
            this._translateService
              .get('PAYMENT.placeholder_expiry')
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((res2) => {
                expiryPlaceholder = res2;
                this._translateService
                  .get('PAYMENT.placeholder_cvv')
                  .pipe(takeUntil(this._unsubscribeAll))
                  .subscribe((res3) => {
                    cvvPlaceholder = res3;
                    this.fields = TzlaHostedFields.create({
                      sandbox: false,
                      styles: {
                        input: {
                          'color': '#F3F3F3',
                          'padding-top': '15px',
                          'font-size': '16px',
                        },
                      },
                      fields: {
                        credit_card_number: {
                          selector: '#credit_card_num',
                          placeholder: this.placeholders ? cardNumPlaceholder : '',
                          tabindex: 1,
                        },
                        cvv: {
                          selector: '#cvv',
                          placeholder: this.placeholders ? cvvPlaceholder : '',
                          tabindex: 4,
                        },
                        card_holder_id_number: {
                          selector: '#card_holder_id_number',
                          placeholder: this.placeholders ? holderIdPlaceholder : '',
                          tabindex: 2,
                        },
                        expiry: {
                          selector: '#expiry',
                          placeholder: 'MM/YYYY',
                          version: '1',
                          tabindex: 3,
                        },
                      },
                    });
                    this.fields.onEvent('cardTypeChange', this.onCardNumberChanged);
                    this.fields.onEvent('blur', this.validityChange);
                  });
              });
          });
      });
    this.paymentForm.controls.amount.setValue(this.amount);
  }

  validityChange = (result: any): void => {
    console.log(result);
    if (result.field === 'expiry') {
      this.expiryValid = true;
    } else if (result.field === 'credit_card_number') {
      this.cardNumValid = true;
    } else if (result.field === 'card_holder_id_number') {
      this.cardHolderValid = true;
    } else if (result.field === 'cvv') {
      this.cvvValid = true;
    }

    this.formValidityChanged.emit(this.expiryValid === true && this.cardNumValid === true && this.cardHolderValid === true && this.cvvValid === true);
  };

  onCardNumberChanged = (result: any): void => {
    console.log(result);
    if (result.cardType !== 'unknown') {
      this.imageSrc = '';
      this.imageSrc = 'assets/landing-page/credit-card/' + result.cardType + '.png';
    } else {
      this.imageSrc = 'assets/icons/customized/credit-card.svg';
    }
  };

  onSubmit (): void {
    console.log({
      expiry_month: this.paymentForm.controls.expiry.value.substring(0, 2),
      expiry_year: this.paymentForm.controls.expiry.value.substring(3, 7),
      terminal_name: this.terminalName,
      amount: this.amount,
      tokenize: true,
      tran_mode: this.verificationOnly ? 'V' : 'A',
      currency_code: this.currencyCode,
      response_language: this._localizationService.currentLang === 'he' ? 'hebrew' : 'english',
      ...this.extraFields,
      ...this.installmentsFields,
    });
    this.fields.charge(
      {
        expiry_month: this.paymentForm.controls.expiry.value.substring(0, 2),
        expiry_year: this.paymentForm.controls.expiry.value.substring(3, 7),
        terminal_name: this.terminalName,
        amount: this.amount,
        tokenize: true,
        tran_mode: this.verificationOnly ? 'V' : 'A',
        currency_code: this.currencyCode,
        response_language: this._localizationService.currentLang === 'he' ? 'hebrew' : 'english',
        ...this.extraFields,
        ...this.installmentsFields,
      },
      (error, response) => {
        console.log(response);
        console.log(error);
        if (error) {
          if (error.error === 'invalid_resource') {
            if (error.messages.length !== 0) {
              error.messages.forEach((message) => {
                if (message.param === 'credit_card_number') {
                  this.cardNumValid = false;
                } else if (message.param === 'cvv') {
                  this.cvvValid = false;
                } else if (message.param === 'card_holder_id_number') {
                  this.cardHolderValid = false;
                } else if (message.param === 'expiry') {
                  this.expiryValid = false;
                }

                this.complete.emit({ error: 'Invalid Payment Details' });
                this.formValidityChanged.emit(this.expiryValid === true &&
                    this.cardNumValid === true &&
                    this.cardHolderValid === true &&
                    this.cvvValid === true);
              });
            }

            return;
          } else {
            // this.failedHidden = false;
            this.errorMessage = error.error_description;
            this.complete.emit({ error: error.error_description });
          }
        } else if (response.transaction_response?.error) {
          this.errorMessage = response.transaction_response?.error;
          this.complete.emit({ error: response.transaction_response?.error });
        } else if (!response.transaction_response?.success) {
          this.complete.emit({ error: 'Unsuccessful Transaction' });
        } else {
          this.complete.emit({ success: response['transaction_response'] });
        }
      }
    );
  }

  setLanguage (lang): void {
    if ([ 'he' ].includes(lang)) {
      this.textAttr = { align: 'right', direction: 'rtl' };
    } else {
      this.textAttr = { align: 'left', direction: 'ltr' };
    }

    // Use the selected language for translations
    this._translateService.use(lang);
  }

  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this.fields.clearEvents();
    console.log('DESTROYED');
  }
}
