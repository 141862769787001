<div *ngIf="email || phone">
  <h1>Check your {{ email ? 'inbox' : 'phone' }} for a code</h1>
  <h3>
    We’ve sent a {{ length }}-character code to {{ email || phone }}. The code expires shortly, so please enter it soon.
  </h3>
</div>

<ng-otp-input
  [class.sending]="sending"
  [formCtrl]="formControl"
  [config]="{
  length,
  allowNumbersOnly: true,
  letterCase: 'Upper',
  disableAutoFocus: false,
  containerClass: 'verification-code-container',
  inputClass: 'verification-code-input'
  }"
></ng-otp-input>

<div *ngIf="error" fxLayoutAlign="center center">{{ error }}</div>
<div fxFlex="0 0 30px">
  <chillz-button popAnimation *ngIf="sending" [isLoader]="true" [loaderSize]="30" [loading]="true"></chillz-button>
</div>
