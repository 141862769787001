import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';
import { CarouselModule } from 'primeng/carousel';
import { ListboxModule } from 'primeng/listbox';

import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { ProfileModule } from '../../../main/profile-settings/profile.module';
import { GSAPDirectivesModule } from '../../directives/gsap/gsap-directives.module';
import { ChillzPipesModule } from '../../pipes/chillz-pipes.module';
import { ChillzBaseButtonModule } from '../chillz-base-button/chillz-base-button.module';
import { ChillzButtonModule } from '../chillz-button/chillz-button.module';
import { ChillzFormElementsModule } from '../chillz-form-elements/chillz-form-elements.module';
import { ChillzStepIndicatorModule } from '../chillz-step-indicator/chillz-step-indicator.module';
import { ChillzConfirmationDialogComponent } from './chillz-confirmation-dialog/chillz-confirmation-dialog.component';
import { SettingsDialogNewComponent } from './settings-dialog-new/settings-dialog-new.component';


@NgModule({
  declarations: [ SettingsDialogNewComponent, ChillzConfirmationDialogComponent ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FuseSharedModule,
    TranslateModule,
    FuseDirectivesModule,
    ListboxModule,
    CarouselModule,
    ChillzPipesModule,
    ChillzFormElementsModule,
    LottieModule,
    ProfileModule,
    ChillzStepIndicatorModule,
    GSAPDirectivesModule,
    ProfileModule,
    MatDialogModule,
    ChillzButtonModule,
    ChillzBaseButtonModule,
    MatIconModule,
  ],
  exports: [ SettingsDialogNewComponent, ChillzConfirmationDialogComponent ],
})
export class ChillzDialogsModule {}
