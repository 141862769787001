import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { Platform } from '../../models/platform.model';
import { TicketService } from './ticket.service';


@Component({
  selector: 'ticket-view-dialog',
  templateUrl: './ticket-view-dialog.component.html',
  styleUrls: [ './ticket-view-dialog.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class TicketViewDialogComponent implements OnInit, OnDestroy {
  loading: boolean;
  initializing: boolean;
  error: string;
  textAttr = { align: 'left', direction: 'ltr' };

  ticket: any;
  id: string;

  requireAccessKey: boolean;
  requireEmail: boolean;
  requireHealthDeclaration: boolean;
  accessKey = [ null, null, null, null ];
  accessCode = '';

  saving = false;

  modificationsFormGroup: FormGroup;

  healthDeclarationFormControl: FormControl;

  emailFormControl: FormControl;
  platform: Platform;

  private _unsubscribeAll: Subject<any>;

  constructor (
    public matDialogRef: MatDialogRef<TicketViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private _sanitizer: DomSanitizer,
    private _ticketService: TicketService,
    private _translateService: TranslateService,
    private _appService: AppService
  ) {
    this.ticket = _data.ticket;
    if (this.ticket) {
      this.initFormGroup();
    }

    this.id = _data.id;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  onLastCharKeyup (): void {
    if (!this.requireHealthDeclaration) {
      this.getTicket();
    }
  }

  updateTicket (): void {
    this.saving = true;
    this._ticketService
      .updateTicket(this.ticket.id, this.modificationsFormGroup.getRawValue())
      .then(() => {
        this._matSnackBar.open('Ticket details updated', 'OK', {
          verticalPosition: 'top',
          duration: 10000,
        });
      })
      .catch((err) => {
        // this.error = err.error;
        this._matSnackBar.open(err.error?.error, 'OK', {
          verticalPosition: 'top',
          duration: 10000,
        });
      })
      .finally(() => {
        this.saving = false;
        // this.modificationsFormGroup.markAsPristine();
      });
  }

  getTicket (): void {
    if (this.requireAccessKey && this.accessCode === '') {
      return;
    }

    this.loading = true;

    this._ticketService
      .getTicket(this.id, this.accessCode, this.emailFormControl.value, this.healthDeclarationFormControl.value)
      .then((ticket) => {
        this.ticket = ticket;
        this.initFormGroup();
        this.loading = false;
      })
      .catch((err) => {
        this.error = err.error;
        this.loading = false;
      });
  }

  initFormGroup (): void {
    this.modificationsFormGroup = this._formBuilder.group({
      customFields: this._formBuilder.group({}),
    });

    if (this.ticket.ticketType.customFields) {
      for (const field of this.ticket.ticketType.customFields) {
        const formControl = this._formBuilder.control(this.ticket.customFields ? this.ticket.customFields[field.key] : null);

        if (field.required) {
          formControl.setValidators([ Validators.required ]);
        }

        if (
          !field.mutable ||
          field.mutable === '' ||
          (field.mutable === 'untilCheckedIn' && this.ticket.checkedIn?.length)
        ) {
          formControl.disable({ onlySelf: true, emitEvent: false });
        }

        (this.modificationsFormGroup.get('customFields') as FormGroup).addControl(field.key, formControl);
      }
    }
  }

  ngOnInit (): void {
    this._appService.onPlatformChanged
      .pipe(
        filter((x) => !!x),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((platform) => (this.platform = platform));

    if ([ 'he' ].includes(this._translateService.currentLang)) {
      this.textAttr = { align: 'right', direction: 'rtl' };
    } else {
      this.textAttr = { align: 'left', direction: 'ltr' };
    }

    if (this.id) {
      this.initializing = true;
      this.emailFormControl = this._formBuilder.control('', [ Validators.email, Validators.required ]);
      this.healthDeclarationFormControl = this._formBuilder.control(false);

      this._ticketService.requestTicket(this.id).then((x) => {
        if (x.ticket) {
          this.ticket = x.ticket;
        }

        this.requireAccessKey = x.requireAccessKey;
        this.requireHealthDeclaration = x.requireHealthDeclaration;
        this.requireEmail = x.requireEmail;

        this.initializing = false;

        if (!this.requireAccessKey) {
          this.getTicket();
        }

        console.log(this.loading);
        console.log(this.requireAccessKey);
        console.log(this.requireEmail);
        console.log(this.requireHealthDeclaration);
      });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
