import { StyleProperty } from './style-property.model';


export class PaddingStyleProperty {
  right: StyleProperty;
  left: StyleProperty;
  top: StyleProperty;
  bottom: StyleProperty;

  constructor (padding: any = {}, defaultUnit = 'px') {
    this.top = new StyleProperty(undefined, defaultUnit);
    this.right = new StyleProperty(undefined, defaultUnit);
    this.left = new StyleProperty(undefined, defaultUnit);
    this.bottom = new StyleProperty(undefined, defaultUnit);
    this.setValue(padding);
  }

  setValue (value: any | string | number = {}): any {
    if (typeof value === 'string' || typeof value === 'number') {
      value = {
        top: value,
        right: value,
        bottom: value,
        left: value,
      };
    }

    this.top.setValue(value.top);
    this.right.setValue(value.right);
    this.left.setValue(value.left);
    this.bottom.setValue(value.bottom);
  }

  toJson (): any {
    return {
      top: this.top?.toString(),
      right: this.right?.toString(),
      bottom: this.bottom?.toString(),
      left: this.left?.toString(),
    };
  }

  toString (): string {
    const values = [ this.top, this.right, this.bottom, this.left ];

    if (values.filter((x) => x.value !== undefined).length === 0) {
      return undefined;
    }

    return values.map((x) => x?.toString() || '0').join(' ');
  }
}
