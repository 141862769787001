<ng-container *ngIf="fuseConfig">
  <!-- SIDE PANEL -->
  <ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'">
    <!-- PANEL CONTENT -->
  </ng-container>
  <!-- / SIDE PANEL -->

  <div id="main">
    <!--  &lt;!&ndash; TOOLBAR: Above &ndash;&gt;-->
    <!--  <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above' && isBrowser">-->
    <!--    <ng-container *ngTemplateOutlet="toolbar"></ng-container>-->
    <!--  </ng-container>-->
    <!--  &lt;!&ndash; / TOOLBAR: Above &ndash;&gt;-->

    <div id="container-1" class="container">
      <!-- NAVBAR: Left -->
      <ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
        <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
      </ng-container>
      <!-- / NAVBAR: Left -->

      <div id="container-2" class="container">
        <div id="container-3" class="container">
          <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed' && isBrowser">
            <ng-container *ngTemplateOutlet="toolbar"></ng-container>
          </ng-container>

          <div class="animated-background" [class.active]="showAnimatedBackground$ | async">
            <app-video-player
              [absolute]="true"
              [controls]="false"
              [sources]="[{ src: '/assets/videos/gradient-light.mp4', type: 'video/mp4' }]"
            ></app-video-player>
          </div>

          <!-- CONTENT -->
          <content [class.toolbar-active]="!hideToolbar"></content>
          <!-- / CONTENT -->

          <!-- FOOTER: Below static -->
          <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-static' && isBrowser">
            <ng-container *ngTemplateOutlet="footer"></ng-container>
          </ng-container>
          <!-- / FOOTER: Below static -->
        </div>

        <!-- FOOTER: Below fixed -->
        <ng-container *ngIf="fuseConfig.layout.footer.position === 'below-fixed'">
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
        <!-- / FOOTER: Below fixed -->
      </div>

      <!-- NAVBAR: Right -->
      <ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
        <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
      </ng-container>
      <!-- / NAVBAR: Right -->
    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above'">
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above -->
  </div>

  <!-- ----------------------------------------------------------------------------------------------------- -->
  <!-- @ PARTIALS                                                                                            -->
  <!-- ----------------------------------------------------------------------------------------------------- -->

  <!-- TOOLBAR -->
  <ng-template #toolbar>
    <toolbar
      *ngIf="!hideToolbar"
      class="sticky"
      [hideAuth]="embedded"
      [hideSearch]="embedded"
      [hideBranding]="hideBranding"
      [ngClass]="
      fuseConfig.layout.toolbar.customBackgroundColor === true
        ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background
        : fuseConfig.layout.toolbar.position
    "
    ></toolbar>
  </ng-template>
  <!-- / TOOLBAR -->

  <!-- FOOTER -->
  <ng-template #footer>
    <footer *ngIf="!fuseConfig.layout.footer.hidden && !embedded && isBrowser"></footer>
  </ng-template>
  <!-- / FOOTER -->
</ng-container>
