<!--&lt;!&ndash; Display a payment form &ndash;&gt;-->
<!--<form id="payment-form">-->
<!--  <div id="payment-element">-->
<!--    &lt;!&ndash;Stripe.js injects the Payment Element&ndash;&gt;-->
<!--  </div>-->
<!--  <button id="submit">-->
<!--    <div class="spinner hidden" id="spinner"></div>-->
<!--    <span id="button-text">Pay now</span>-->
<!--  </button>-->
<!--  <div id="payment-message" class="hidden"></div>-->
<!--</form>-->

<!-- stripe-payment.component.html -->
<form (ngSubmit)="confirmStripePayment()" class="checkout" id="payment-form">
  <!--  <label for="card-info">Card Info</label>-->
  <div id="form-field">
    <div id="card-info" #cardInfo></div>
    <button id="submit" type="submit" *ngIf="showChargeButton">
      Pay
      <span *ngIf="showAmount">${{ amount }}</span>
    </button>
    <mat-error class="error-with-icon mt-16" id="card-errors" role="alert" *ngIf="cardError">
      <mat-icon style="color: #f44336">cancel</mat-icon>
      {{ cardError }}
    </mat-error>
  </div>
</form>
