import 'zone.js';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();

  window.console.log =
    window.console.warn =
    window.console.info =
      function () {
        // Don't log anything.
      };
}

const bootstrap = (settings?: any): Promise<any> =>
  platformBrowserDynamic(settings ? [ { provide: 'envSettings', useValue: settings } ] : undefined).bootstrapModule(
    AppModule,
    settings ? { preserveWhitespaces: true } : undefined
  );

document.addEventListener('DOMContentLoaded', () => {
  let _env: any;
  // In production mode
  if (window && window['_env'] !== undefined && !!environment.ssr) {
    _env = {};

    const browserWindowEnv = window['_env'] || {};

    for (const key in browserWindowEnv) {
      if (browserWindowEnv.hasOwnProperty(key)) {
        _env[key] = window['_env'][key];
      }
    }
  }

  bootstrap(_env).catch((err) => console.error(err));
});
