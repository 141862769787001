import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { User } from '../../../auth/user.model';
import { Platform } from '../../../shared/models/platform.model';
import { ProfileService } from '../profile.service';


@Component({
  selector: 'app-profile-name',
  templateUrl: './profile-name.component.html',
  styleUrls: [ './profile-name.component.scss', '../profile.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileNameComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;

  nameFormGroup: FormGroup;

  status: 'saving' | 'saved';
  @Output() status$: EventEmitter<'saving' | 'saved'> = new EventEmitter<'saving' | 'saved'>();

  private _unsubscribeAll = new Subject<any>();

  constructor (
    private _profileService: ProfileService,
    private _authService: ChillzAuthService,
    private _appService: AppService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit (): void {
    this.nameFormGroup = this._formBuilder.group({
      firstName: [ undefined, Validators.required ],
      lastName: [ undefined, Validators.required ],
    });

    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
        this.nameFormGroup.patchValue(this.profile, { emitEvent: false });
        this.nameFormGroup.markAsPristine();
      });

    this.nameFormGroup.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((value) => {
        for (const x of [ 'firstName', 'lastName' ]) {
          if (value[x]) {
            this.nameFormGroup.get(x).setValue(
              value[x]
                .replace(new RegExp(/\s+/, 'g'), ' ')
                .split(' ')
                .map((w) => `${w.slice(0, 1)?.toUpperCase() || ''}${w.slice(1) || ''}`)
                .join(' '),
              { emitEvent: false }
            );
          }
        }
      });

    this.status$
      .pipe(
        tap((status) => {
          this.status = status;
        }),
        debounceTime(5000),
        tap((status) => {
          if (status === 'saved') {
            this.status = null;
          }
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  onSave (): void {
    this.status$.emit('saving');
    this._profileService
      .updateProfile(this.nameFormGroup.getRawValue())
      .then(() => {
        this.status$.emit('saved');
      })
      .catch((err) => {
        this.status$.emit(null);
        console.log(err);
      })
      .finally(() => {
        this.nameFormGroup.markAsPristine();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
