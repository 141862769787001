import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { ChillzAuthService } from '../../auth/chillz-auth.service';
import { User } from '../../auth/user.model';
import { SettingsDialogNewComponent } from '../../shared/components/dialogs/settings-dialog-new/settings-dialog-new.component';


@Injectable({ providedIn: 'root' })
export class ProfileService implements Resolve<any> {
  routeParams: any;
  missingProfileDetailsDialogOpen: boolean;

  /**
   * Constructor
   *
   */
  constructor (private _httpClient: HttpClient, private _authService: ChillzAuthService, private _matDialog: MatDialog) {
    // Set the defaults
  }

  /**
   * Resolver
   *
   */
  resolve (route: ActivatedRouteSnapshot): Promise<void> {
    this.routeParams = { ...route.params, ...route.parent.params };
    return new Promise<void>(async (resolve, reject) => {
      this.init();

      Promise.all([]).then(([]) => {
        resolve();
      }, reject);
    });
  }

  init (): void {}

  updateProfile (value): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put('/users/self', value).subscribe(
        (response: any) => {
          this._authService.user = new User(response.user);
          this._authService.onUserChanged.next(this._authService.user);
          resolve(this._authService.user);
        },
        () => {
          reject();
        }
      );
    });
  }

  async missingProfileDetails (): Promise<void> {
    return new Promise((resolve) => {
      if (
        !this.missingProfileDetailsDialogOpen &&
        (!this._authService.user?.firstName ||
          !this._authService.user?.lastName ||
          !this._authService.user?.hasPassword)
      ) {
        const dialog = this._matDialog.open(SettingsDialogNewComponent, {
          panelClass: [ 'chillz-dialog', 'full-screen' ],
          data: {
            title: { en: 'A few moments for final set-up' },
            subtitle: { en: 'Just because you\'re new here.\nWe promise not to ask for this information everytime.' },
            steps: [
              ...(!this._authService.user?.firstName || !this._authService.user?.lastName
                ? [
                    {
                      type: 'profile',
                      subType: 'name',
                      title: { en: 'What is your Name?' },
                      stepIndicatorLabel: { en: 'Your Name' },
                      skipAllowed: false,
                      closeAllowed: false,
                    },
                  ]
                : []),
              ...(!this._authService.user?.hasPassword
                ? [
                    {
                      type: 'profile',
                      subType: 'password',
                      title: { en: 'Set a Password' },
                      skipAllowed: false,
                      closeAllowed: false,
                    },
                  ]
                : []),
            ],
          },
        });

        this.missingProfileDetailsDialogOpen = true;

        dialog.afterClosed().subscribe(() => {
          this.missingProfileDetailsDialogOpen = false;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
}
