import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DeCamelCase } from './de-camel-case.pipe';
import { DeDashCase } from './de-dash-case.pipe';
import { DeLowerDashCase } from './de-lower-dash-case.pipe';
import { FormattedDatePipe } from './formatted-date.pipe';
import { InvertColorPipe } from './invert-color.pipe';
import { MinusSignToParens } from './minus-sign-to-parens.pipe';
import { SafePipe } from './safe.pipe';
import { SearchableValuePipe } from './searchable-value.pipe';
import { ShowHistoryResentValuePipe } from './show-history-resent-value.pipe';


@NgModule({
  declarations: [
    DeCamelCase,
    DeLowerDashCase,
    DeDashCase,
    SafePipe,
    MinusSignToParens,
    FormattedDatePipe,
    ShowHistoryResentValuePipe,
    SearchableValuePipe,
    InvertColorPipe,
  ],
  imports: [ CommonModule ],
  exports: [
    DeCamelCase,
    DeLowerDashCase,
    DeDashCase,
    SafePipe,
    MinusSignToParens,
    FormattedDatePipe,
    ShowHistoryResentValuePipe,
    SearchableValuePipe,
    InvertColorPipe,
  ],
})
export class ChillzPipesModule {}
