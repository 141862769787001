<form class="settings-section-body">
  <mat-form-field floatLabel="auto">
    <mat-label>Email</mat-label>
    <input matInput [formControl]="emailFormControl" />
  </mat-form-field>

  <app-verification-code
    popAnimation
    *ngIfAnimated="emailVerificationToken"
    (tokenChange)="!$event ? status$.next('saved') : null"
    [(token)]="emailVerificationToken"
  ></app-verification-code>

  <mat-error *ngIf="emailError" fxFlex="1 1 auto">{{ emailError }}</mat-error>

  <div class="settings-section-actions">
    <chillz-button
      popAnimation
      *ngIf="!emailVerificationToken"
      [label]="status !== 'saved' ? { en: 'Save', he: 'שמירה' } : undefined"
      [icon]="status === 'saved' ? 'checkmark' : undefined"
      [isSvgIcon]="true"
      [fontSize]="'14px'"
      [padding]="'6px 20px'"
      [height]="26"
      [loaderSize]="26"
      [hoverPush]="true"
      [expandable]="false"
      [outline]="true"
      [hoverOutlineShine]="true"
      [outlineShine]="true"
      [shadow]="true"
      [glow]="false"
      [isLoader]="true"
      [disabled]="
        emailFormControl.invalid ||
        emailFormControl.pristine ||
        profile.email === emailFormControl.value ||
        status === 'saving'
      "
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-button>
  </div>
</form>
