<div class="result" [routerLink]="['/', item.id]">
  <div class="thumb">
    <div class="image-wrapper">
      <ng-container *ngIf="item?.pictures?.length; else thumbPlaceholder">
        <chillz-skeleton-block
          *ngIf="!fileLoaded"
          [height]="'100%'"
          [width]="'100%'"
          [style.border-radius]="'6px'"
        ></chillz-skeleton-block>
        <img
          loading="lazy"
          [chillzOpacityAnimation]="fileLoaded"
          (load)="onFileLoaded()"
          [src]="item.pictures[0].thumb"
          alt=""
        />
      </ng-container>

      <ng-template #thumbPlaceholder>
        <chillz-thumb-placeholder
          *ngIf="item.pictures.length === 0 || !item.pictures[0]"
          [thumbPlaceholder]="platform?.thumbPlaceholder"
          [borderRadius]="'6px'"
        ></chillz-thumb-placeholder>
      </ng-template>
    </div>
  </div>
  <div class="details">
    <p class="search-result-title B7-Regular m-0">
      {{ item.entityType?.title[localizationService.currentLang] || item.entityType?.title['en'] }}
    </p>
    <div class="result-details">
      <p class="H6-Regular black-50 m-0">{{ item.titleEng }}</p>
      <p class="B4-Light black-250 m-0" *ngIf="item.formattedTime">
        {{ item.formattedTime[localizationService.currentLang] || item.formattedTime['en'] }}
        <ng-container *ngIf="item.locationDetails?.formatted"> • {{ item.locationDetails?.formatted }} </ng-container>
      </p>
    </div>
  </div>
</div>
