import { CDN_URLS_REPLACE } from '../CDN_URLS_REPLACE';


export class ChillzFile {
  static cdnUrl (url): string {
    if (!url || typeof url !== 'string') {
      return;
    }

    for (const r of CDN_URLS_REPLACE) {
      url = url?.replace(r.replace, r.with);
    }
    return url;
  }
}
