import { StyleProperty } from './style-property.model';


export class FlexStyleProperty {
  basis: StyleProperty;
  grow = 0;
  shrink = 0;

  constructor (flex: any = {}, defaultUnit = 'px') {
    this.basis = new StyleProperty(undefined, defaultUnit);
    this.setValue(flex);
  }

  setValue (value: any = {}): void {
    if (value.basis !== undefined && value.basis !== null) {
      this.basis.setValue(value.basis);
    } else {
      this.basis.setValue('auto');
    }

    if (value.grow !== undefined) {
      this.grow = value.grow;
    }

    if (value.shrink !== undefined) {
      this.shrink = value.shrink;
    }
  }

  toJson (): any {
    return {
      basis: this.basis?.toString(),
      grow: this.grow,
      shrink: this.shrink,
    };
  }
}

export class FlexAlignmentStyleProperty {
  alignItems: string;
  justifyContent: string;

  constructor (alignment: any = {}) {
    this.setValue(alignment);
  }

  setValue (value: any = {}): void {
    this.alignItems = value.alignItems;
    this.justifyContent = value.justifyContent;
  }

  toJson (): any {
    return {
      alignItems: this.alignItems,
      justifyContent: this.justifyContent,
    };
  }
}
