import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { skip } from 'rxjs/operators';

import { LocalStorage } from '../../../../@fuse/services/storage/local-storage';
import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { locale as english } from '../i18n/en';
import { locale as hebrew } from '../i18n/he';


@Component({
  selector: 'app-payment-method-dialog',
  templateUrl: './add-payment-method-dialog.component.html',
  styleUrls: [ './add-payment-method-dialog.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class AddPaymentMethodDialogComponent implements OnInit, AfterViewInit {
  saved: string;
  iframe: SafeResourceUrl;
  textAttr: { direction: string; align: string };

  @ViewChild('tranzilaIframe', { static: true }) tranzilaIframe: ElementRef;

  constructor (
    public matDialogRef: MatDialogRef<AddPaymentMethodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private _localStorage: LocalStorage,
    private _translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService
  ) {
    this.saved = _data.saved;
    this.translationLoader.loadTranslations(english, hebrew);
  }

  /**
   * Set the language
   *
   */
  setLanguage (lang): void {
    if ([ 'he' ].includes(lang)) {
      this.textAttr = { align: 'right', direction: 'rtl' };
    } else {
      this.textAttr = { align: 'left', direction: 'ltr' };
    }
  }

  ngAfterViewInit (): void {
    fromEvent(this.tranzilaIframe.nativeElement, 'load')
      // Skip the initial load event and only capture subsequent events.
      .pipe(skip(1))
      .subscribe((event: Event) => {
        console.log(event.target);
        this.matDialogRef.close();
      });
  }

  ngOnInit (): void {
    // this.socketService.setupSocketConnection();
    // this._translateService.onLangChange.subscribe(lang => {
    //   this.setLanguage(lang.lang);
    // });

    let token = '';
    if (this._localStorage.getItem('x-access-token')) {
      token = '&token=' + this._localStorage.getItem('x-access-token');
    } else if (this._localStorage.getItem('gtoken')) {
      token = '&token=' + this._localStorage.getItem('gtoken');
    }

    // console.log(this.tranzilaIframe);
    //
    console.log(this._translateService.currentLang);
    const sum = '&sum=1';
    const saved = '&saved=' + this.saved;
    const lang = '&lang=' + (this._translateService.currentLang === 'he' ? 'il' : 'us');

    const url =
      'https://direct.tranzila.com/chillz/iframenew.php?nologo=1&buttonLabel=Save&tranmode=VK&currency=1&cred_type=1&hidesum=1' +
      sum +
      saved +
      lang +
      token;

    this.iframe = this._sanitizer.bypassSecurityTrustResourceUrl(encodeURI(url));
  }
}
