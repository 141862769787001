import { ChillzPicture } from './picture.model';


export class SocialMediaProfile {
  username: string;
  id: string;
  url: string;
  fullName: string;
  verified: boolean;
  biography: string;
  private: boolean;
  followersCount: number;
  followsCount: number;
  postsCount: number;
  profilePicture: ChillzPicture;
  coverPicture: ChillzPicture;
  platform: 'facebook' | 'instagram' | 'snapchat' | 'tiktok';
  createdAt: Date;
  updatedAt: Date;

  constructor (profile: any = {}) {
    this.username = profile.username;
    this.id = profile.id;
    this.url = profile.url;
    this.fullName = profile.fullName;
    this.verified = profile.verified;
    this.biography = profile.biography;
    this.private = profile.private;
    this.followersCount = profile.followersCount;
    this.followsCount = profile.followsCount;
    this.postsCount = profile.postsCount;
    this.platform = profile.platform;

    if (profile.profilePicture) {
      this.profilePicture = new ChillzPicture(profile.profilePicture);
    }

    if (profile.coverPicture) {
      this.coverPicture = new ChillzPicture(profile.coverPicture);
    }

    if (profile.createdAt) {
      this.createdAt = new Date(profile.createdAt);
    }

    if (profile.updatedAt) {
      this.updatedAt = new Date(profile.updatedAt);
    }
  }
}
