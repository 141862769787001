import { ChillzPicture } from '../picture.model';
import { ColorStyle } from './color-style.model';


export class PicturePlaceholderStyle {
  picture?: ChillzPicture;
  background?: ColorStyle;
  isSymbol?: boolean;

  constructor (style: any = {}, defaultColor?: 'related-accent' | 'host-accent' | string) {
    this.background = new ColorStyle(undefined, defaultColor);
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    if (value.picture !== undefined) {
      this.picture = new ChillzPicture(value.picture);
    }

    if (value.background !== undefined) {
      this.background.setValue(value.background);
    }

    this.isSymbol = value.isSymbol;
  }

  toJson (): any {
    return {
      picture: this.picture,
      background: this.background?.toString(),
      isSymbol: this.isSymbol,
    };
  }
}
