export class ThumbPlaceholder {
  backgroundColor?: string;
  picture?: string;
  symbol?: string;

  constructor (thumbPlaceholder: ThumbPlaceholder) {
    this.backgroundColor = thumbPlaceholder.backgroundColor || '#141414';
    this.picture = thumbPlaceholder.picture;
    this.symbol = thumbPlaceholder.symbol;
  }
}
