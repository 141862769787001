import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ChillzAuthService } from '../chillz-auth.service';


@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  constructor (
    private jwtHelper: JwtHelperService,
    private _httpClient: HttpClient,
    private _authService: ChillzAuthService,
    private _deviceService: DeviceDetectorService
  ) {}

  /**
   * init verification
   */
  initVerification (to: { email?: string; countryCode?: string; phoneNumber?: string }, source?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post('/users/initVerification', {
          ...to,
          source,
          metaData: {
            device: this._deviceService.getDeviceInfo(),
          },
        })
        .subscribe((response: any) => {
          resolve(response.token);
        }, reject);
    });
  }

  /**
   * check verification
   */
  checkVerification (token?: string, code?: string, googleToken?: string, source?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(
          '/users/checkVerification',
          {
            token,
            googleToken,
            source,
            code,
            metaData: {
              device: this._deviceService.getDeviceInfo(),
            },
          },
          { observe: 'response' }
        )
        .subscribe((response: any) => {
          if (response.error) {
            return reject(response.error);
          }

          if (response.headers.get('x-refresh-token') && response.headers.get('x-access-token')) {
            this._authService.setSession(
              response.body['_id'],
              response.headers.get('x-access-token'),
              response.headers.get('x-refresh-token')
            );
            this._authService.loggedIn = true;
            this._authService.onLoggedInChanged.next(this._authService.loggedIn);
            this._authService.getMyProfile();
          }

          resolve(response);
        }, reject);
    });
  }
}
