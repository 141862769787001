import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { User } from '../../../auth/user.model';
import { Platform } from '../../../shared/models/platform.model';
import { facebookValidator, instagramValidator, tiktokValidator } from '../../../shared/validators/validators';
import { ProfileService } from '../profile.service';


@Component({
  selector: 'app-profile-social-media',
  templateUrl: './profile-social-media.component.html',
  styleUrls: [ './profile-social-media.component.scss', '../profile.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileSocialMediaComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;

  socialMediaFormGroup: FormGroup;

  status: 'saving' | 'saved';
  @Output() status$: EventEmitter<'saving' | 'saved'> = new EventEmitter<'saving' | 'saved'>();

  private _unsubscribeAll = new Subject<any>();

  constructor (
    private _profileService: ProfileService,
    private _authService: ChillzAuthService,
    private _appService: AppService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit (): void {
    this.socialMediaFormGroup = this._formBuilder.group({
      instagram: [ undefined, instagramValidator ],
      facebook: [ undefined, facebookValidator ],
      tiktok: [ undefined, tiktokValidator ],
    });

    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
        this.socialMediaFormGroup.patchValue(this.profile, { emitEvent: false });

        this.socialMediaFormGroup.markAsPristine();
      });

    this.socialMediaFormGroup.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((socialMedia) => {
      if (socialMedia?.facebook) {
        this.socialMediaFormGroup
          .get('facebook')
          .setValue(socialMedia?.facebook.replaceAll(new RegExp(/((\?|&)+(?!id)[\d\w_\-.]*=?[^?&]*|[\s]*)/, 'g'), ''), {
            emitEvent: false,
          });
      }

      if (socialMedia?.instagram) {
        this.socialMediaFormGroup
          .get('instagram')
          .setValue(socialMedia?.instagram.replaceAll(new RegExp(/[\s@]*/, 'g'), ''), { emitEvent: false });
      }

      if (socialMedia?.tiktok) {
        this.socialMediaFormGroup
          .get('tiktok')
          .setValue(socialMedia?.tiktok.replaceAll(new RegExp(/[\s@]*/, 'g'), ''), { emitEvent: false });
      }
    });

    this.status$
      .pipe(
        tap((status) => {
          this.status = status;
        }),
        debounceTime(5000),
        tap((status) => {
          if (status === 'saved') {
            this.status = null;
          }
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  onSave (): void {
    this.status$.emit('saving');
    this._profileService
      .updateProfile(this.socialMediaFormGroup.getRawValue())
      .then(() => {
        this.status$.emit('saved');
      })
      .catch((err) => {
        this.status$.emit(null);
        console.log(err);
      })
      .finally(() => {
        this.socialMediaFormGroup.markAsPristine();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
