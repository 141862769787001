<div mat-dialog-title class="m-0">
  <app-chillz-step-indicator
    *ngIf="stepIndicatorSteps?.length > 1"
    [(currentStep)]="currentStep"
    [steps]="stepIndicatorSteps"
  ></app-chillz-step-indicator>
  <h1 class="dialog-title" *ngIf="title" [innerText]="title[localizationService.currentLang] || title['en']"></h1>
  <h2
    class="dialog-sub-title"
    *ngIf="subtitle"
    [innerText]="subtitle[localizationService.currentLang] || subtitle['en']"
  ></h2>
</div>

<div mat-dialog-content class="pb-50">
  <h1
    class="dialog-title"
    *ngIf="steps?.[currentStep]?.title"
    [innerText]="steps?.[currentStep].title[localizationService.currentLang] || steps?.[currentStep].title['en']"
  ></h1>
  <h2
    class="dialog-sub-title"
    *ngIf="steps?.[currentStep]?.subtitle"
    [innerText]="steps?.[currentStep].subtitle[localizationService.currentLang] || steps?.[currentStep].subtitle['en']"
  ></h2>

  <div fxLayoutAlign="center center">
    <div fxFlex="0 1 400px" fxLayout="column" fxLayoutAlign="start stretch">
      <p
        *ngIf="steps?.[currentStep]?.description"
        [innerText]="steps?.[currentStep].description[localizationService.currentLang] || steps?.[currentStep].description['en']"
      ></p>

      <ng-container *ngIf="steps?.[currentStep]?.type === 'profile'">
        <app-profile-name
          (status$)="onStepStatusChanged(steps?.[currentStep], $event)"
          *ngIf="steps?.[currentStep]?.subType === 'name'"
        ></app-profile-name>
        <app-profile-password
          (status$)="onStepStatusChanged(steps?.[currentStep], $event)"
          *ngIf="steps?.[currentStep]?.subType === 'password'"
        ></app-profile-password>
        <app-profile-phone-number
          (status$)="onStepStatusChanged(steps?.[currentStep], $event)"
          *ngIf="steps?.[currentStep]?.subType === 'phone-number'"
        ></app-profile-phone-number>
        <app-profile-general-details
          (status$)="onStepStatusChanged(steps?.[currentStep], $event)"
          *ngIf="steps?.[currentStep]?.subType === 'general-details'"
        ></app-profile-general-details>
        <app-profile-social-media
          (status$)="onStepStatusChanged(steps?.[currentStep], $event)"
          *ngIf="steps?.[currentStep]?.subType === 'social-media'"
        ></app-profile-social-media>
        <app-profile-email
          (status$)="onStepStatusChanged(steps?.[currentStep], $event)"
          *ngIf="steps?.[currentStep]?.subType === 'email'"
        ></app-profile-email>
      </ng-container>
    </div>
  </div>

  <ng-lottie
    [containerClass]="'mt-32'"
    *ngIf="steps?.[currentStep]?.lottie?.src"
    [height]="steps?.[currentStep]?.lottie?.height"
    [width]="steps?.[currentStep]?.lottie?.width"
    [options]="{path: steps?.[currentStep]?.lottie?.src}"
  ></ng-lottie>
</div>

<div
  mat-dialog-actions
  fxLayoutAlign="center center"
  class="gap-8 mt-24"
  [dir]="localizationService.textAttr?.direction"
>
  <chillz-button
    *ngIf="cancel && steps?.[currentStep]?.closeAllowed"
    [mat-dialog-close]="false"
    [label]="cancel"
    [fontSize]="'12px'"
    [letterSpacing]="'0px'"
    [padding]="'10px 20px'"
    [cta]="false"
    [glow]="false"
    [outline]="false"
    [isLoader]="false"
    [expandable]="false"
  ></chillz-button>
  <div fxFlex="1 1 auto"></div>
  <chillz-button
    *ngIf="skip && steps?.[currentStep]?.skipAllowed && steps?.length > currentStep + 1"
    (clicked)="setStep(currentStep + 1)"
    [label]="skip"
    [fontSize]="'12px'"
    [letterSpacing]="'0px'"
    [padding]="'10px 20px'"
    [cta]="false"
    [glow]="false"
    [outline]="false"
    [isLoader]="false"
    [expandable]="false"
  ></chillz-button>
  <chillz-button
    *ngIf="confirm"
    [mat-dialog-close]="true"
    [label]="confirm"
    [fontSize]="'14px'"
    [letterSpacing]="'1px'"
    [padding]="'10px 20px'"
    [cta]="true"
    [glow]="false"
    [outline]="true"
    [strokeWidth]="'1px'"
    [isLoader]="false"
    [expandable]="false"
  ></chillz-button>
</div>
