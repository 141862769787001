<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="m-0">
    <mat-toolbar-row class="pt-16" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"></span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>

    <mat-toolbar-row class="toolbar-bottom pb-32" fxLayout="column" fxLayoutAlign="center center">
      <div class="title dialog-title">
        <!--        <mat-icon class="mr-4">lock</mat-icon>-->
        {{ 'ADD_PAYMENT_METHOD.TITLE' | translate }}
      </div>
      <span class="subtitle">{{ 'ADD_PAYMENT_METHOD.DISCLAIMER' | translate }}</span>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="pb-0 m-0 dialog-content" fxLayout="column" fxLayoutAlign="center center">
    <iframe [src]="iframe" fxFlex="100" #tranzilaIframe></iframe>
    <mat-spinner diameter="50"></mat-spinner>
    <!--        <button mat-flat-button color="primary" class="my-16" (click)="matDialogRef.close()">OK</button>-->
  </div>
</div>
