import { Platform } from '@angular/cdk/platform';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class TransferStateHelper {
  constructor (private _transferState: TransferState, private _platform: Platform, private _httpClient: HttpClient) {}

  wrap<T>(key: string, source: Observable<T>): Observable<T> {
    let res: Observable<T>;
    const tsKey = makeStateKey(key);
    if (this._platform.isBrowser) {
      if (this._transferState.hasKey(tsKey)) {
        const data = this._transferState.get(tsKey, null as any);
        this._transferState.remove(tsKey);
        res = of(data);
      } else {
        res = source;
      }
    } else {
      res = source.pipe(tap((data) => {
        this._transferState.set(tsKey, data as any);
      }));
    }

    return res;
  }

  wrapHttpGet<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
          [header: string]: string | string[];
        };
      observe?: 'body';
      params?:
        | HttpParams
        | {
          [param: string]: string | string[];
        };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    const key = 'HttpGet-' + url + '-' + JSON.stringify(options);
    return this.wrap(key, this._httpClient.get<T>(url, options));
  }
}
