import { ExploreEngagementAction } from '../explore-engagement-action.model';
import { AbsolutePositionStyleProperty } from './absolute-position-style-property';
import { BackgroundStyle } from './background-style.model';
import { BorderStyle } from './border-style.model';
import { ButtonStyle } from './button-style.model';
import { ButtonsGroupStyle } from './collection-style.model';
import { FlexAlignmentStyleProperty } from './flex-style-property';
import { GradientFadeStyle } from './gradient-fade-style.model';
import { PaddingStyleProperty } from './padding-style-property';
import { PicturePlaceholderStyle } from './picture-placeholder-style.model';
import { StyleProperty } from './style-property.model';
import { TextStyle } from './text-style.model';


export class ItemStyle {
  textStyle?: TextStyle;
  aspectRatio?: number;

  flexAlignment?: FlexAlignmentStyleProperty;
  height?: StyleProperty;
  width?: StyleProperty;
  border?: BorderStyle;
  padding?: PaddingStyleProperty;

  background?: BackgroundStyle;
  overlay?: ItemOverlayStyle;
  underneath?: ItemUnderneathStyle;
  picture?: PictureStyle;
  badge?: BadgeStyle;
  isBaseExploreItem: boolean;

  gradientFade?: GradientFadeStyle;

  action?: ExploreEngagementAction;

  constructor (_style: any = {}) {
    this.border = new BorderStyle();
    this.textStyle = new TextStyle();
    this.height = new StyleProperty();
    this.width = new StyleProperty();
    this.padding = new PaddingStyleProperty();
    this.action = new ExploreEngagementAction();

    this.background = new BackgroundStyle();
    this.overlay = new ItemOverlayStyle();
    this.underneath = new ItemUnderneathStyle();
    this.picture = new PictureStyle();
    this.flexAlignment = new FlexAlignmentStyleProperty();
    this.isBaseExploreItem = false;
    this.gradientFade = new GradientFadeStyle();
    this.badge = new BadgeStyle({ zIndex: 50 });
  }

  setValue (value: any = {}): void {
    this.aspectRatio = value.aspectRatio;

    if (value.action !== undefined) {
      this.action.setValue(value.action);
    }

    if (value.height !== undefined) {
      this.height.setValue(value.height);
    }

    if (value.width !== undefined) {
      this.width.setValue(value.width);
    }

    if (value.padding !== undefined) {
      this.padding.setValue(value.padding);
    }

    if (value.gradientFade !== undefined) {
      this.gradientFade.setValue(value.gradientFade);
    }

    if (value.badge) {
      this.badge.setValue(value.badge);
    }

    if (value.textStyle) {
      this.textStyle.setValue(value.textStyle);
    }

    if (value.border) {
      this.border.setValue(value.border);
    }

    if (value.flexAlignment) {
      this.flexAlignment.setValue(value.flexAlignment);
    }

    if (value.background) {
      this.background.setValue(value.background);
    }

    if (value.isBaseExploreItem) {
      this.isBaseExploreItem = value.isBaseExploreItem;
    }

    this.overlay.setValue(value.overlay);
    this.underneath.setValue(value.underneath);
    this.picture.setValue(value.picture);
  }

  toJson (): any {
    return {
      textStyle: this.textStyle?.toJson(),
      aspectRatio: this.aspectRatio,
      flexAlignment: this.flexAlignment?.toJson(),
      height: this.height?.toString(),
      width: this.width?.toString(),
      border: this.border?.toJson(),
      padding: this.padding?.toJson(),
      badge: this.badge?.toJson(),
      background: this.background?.toJson(),
      overlay: this.overlay?.toJson(),
      underneath: this.underneath?.toJson(),
      picture: this.picture?.toJson(),
      gradientFade: this.gradientFade?.toJson(),
      action: this.action?.toJson(),
    };
  }
}

export class ItemCTAButton {
  style?: ButtonStyle;
  label?: { [lang: string]: string };
  icon?: {
    icon?: string;
    svgIcon?: string;
  };
  action?: ExploreEngagementAction;

  constructor (button: any = {}) {
    this.style = new ButtonStyle();
    this.action = new ExploreEngagementAction();
    this.icon = {};
    this.label = {};
    this.setValue(button);
  }

  setValue (value: any = {}): void {
    if (value.style) {
      this.style.setValue(value.style);
    }

    if (value.icon?.icon) {
      this.icon.icon = value.icon?.icon;
    }

    if (value.icon?.svgIcon) {
      this.icon.svgIcon = value.icon?.svgIcon;
    }

    if (value.label) {
      this.label = value.label;
    }

    if (value.action) {
      this.action.setValue(value.action);
    }
  }

  toJson (): any {
    return {
      style: this.style?.toJson(),
      icon: this.icon,
      action: this.action,
      label: this.label,
    };
  }
}

export class ItemOverlayStyle {
  enabled?: boolean;
  animated?: boolean;
  quickInfo?: boolean;
  minAge?: boolean;
  time?: boolean;
  location?: boolean;
  customFields?: boolean;
  entityType?: boolean;
  mainContentGrow?: boolean;
  zIndex?: number;
  urls?: boolean;
  flexAlignment?: FlexAlignmentStyleProperty;
  textStyle?: TextStyle;
  background?: BackgroundStyle;
  CTA?: {
    style?: ButtonsGroupStyle;
    buttons?: ItemCTAButton[];
  };

  constructor (style: any = {}) {
    this.textStyle = new TextStyle();
    this.background = new BackgroundStyle();
    this.CTA = {
      style: new ButtonsGroupStyle(),
      buttons: [],
    };
    this.flexAlignment = new FlexAlignmentStyleProperty();
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    this.enabled = value.enabled;
    this.animated = value.animated;
    this.quickInfo = value.quickInfo;
    this.minAge = value.minAge;
    this.time = value.time;
    this.customFields = value.customFields;
    this.location = value.location;
    this.entityType = value.entityType;
    this.mainContentGrow = value.mainContentGrow;
    this.zIndex = value.zIndex;
    this.urls = value.urls;

    if (value.textStyle) {
      this.textStyle.setValue(value.textStyle);
    }

    if (value.flexAlignment) {
      this.flexAlignment.setValue(value.flexAlignment);
    }

    if (value.background) {
      this.background.setValue(value.background);
    }

    if (value.CTA?.style) {
      this.CTA.style.setValue(value.CTA.style);
    }

    if (value.CTA?.buttons) {
      this.CTA.buttons = value.CTA?.buttons.map((x) => new ItemCTAButton(x));
    }
  }

  toJson (): any {
    return {
      enabled: this.enabled,
      animated: this.animated,
      quickInfo: this.quickInfo,
      minAge: this.minAge,
      time: this.time,
      location: this.location,
      customFields: this.customFields,
      entityType: this.entityType,
      mainContentGrow: this.mainContentGrow,
      zIndex: this.zIndex,
      urls: this.urls,
      flexAlignment: this.flexAlignment?.toJson(),
      textStyle: this.textStyle?.toJson(),
      background: this.background?.toJson(),
      CTA: {
        style: this.CTA?.style?.toJson(),
        buttons: this.CTA?.buttons?.map((x) => x.toJson()),
      },
    };
  }
}

export class ItemUnderneathStyle {
  enabled?: boolean;
  time?: boolean;
  location?: boolean;
  customFields?: boolean;
  entityType?: boolean;
  mainContentGrow?: boolean;
  zIndex?: number;
  flexAlignment?: FlexAlignmentStyleProperty;
  padding?: PaddingStyleProperty;
  height?: StyleProperty;
  gap?: StyleProperty;

  textStyle?: TextStyle;
  background?: BackgroundStyle;

  constructor (style: any = {}) {
    this.textStyle = new TextStyle();
    this.background = new BackgroundStyle();
    this.flexAlignment = new FlexAlignmentStyleProperty();
    this.padding = new PaddingStyleProperty();
    this.height = new StyleProperty();
    this.gap = new StyleProperty();

    this.setValue(style);
  }

  setValue (value: any = {}): void {
    this.enabled = value.enabled;
    this.time = value.time;
    this.customFields = value.customFields;
    this.location = value.location;
    this.entityType = value.entityType;
    this.mainContentGrow = value.mainContentGrow;
    this.zIndex = value.zIndex;

    if (value.textStyle) {
      this.textStyle.setValue(value.textStyle);
    }

    if (value.flexAlignment) {
      this.flexAlignment.setValue(value.flexAlignment);
    }

    if (value.background) {
      this.background.setValue(value.background);
    }

    if (value.padding !== undefined) {
      this.padding.setValue(value.padding);
    }

    if (value.height !== undefined) {
      this.height.setValue(value.height);
    }

    if (value.gap !== undefined) {
      this.gap.setValue(value.gap);
    }
  }

  toJson (): any {
    return {
      enabled: this.enabled,
      time: this.time,
      location: this.location,
      customFields: this.customFields,
      entityType: this.entityType,
      mainContentGrow: this.mainContentGrow,
      flexAlignment: this.flexAlignment?.toJson(),
      textStyle: this.textStyle?.toJson(),
      background: this.background?.toJson(),
      padding: this.padding?.toJson(),
      height: this.height?.toString(),
      gap: this.gap?.toString(),
    };
  }
}

export class PictureStyle {
  enabled?: boolean;
  border?: BorderStyle;
  aspectRatio?: number;
  height?: StyleProperty;
  width?: StyleProperty;
  zIndex?: number;
  placeholder: PicturePlaceholderStyle;

  constructor (style: any = {}) {
    this.border = new BorderStyle();
    this.height = new StyleProperty('100%');
    this.width = new StyleProperty();
    this.placeholder = new PicturePlaceholderStyle();
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    this.enabled = value.enabled;
    this.zIndex = value.zIndex;
    this.aspectRatio = value.aspectRatio;

    if (value.placeholder) {
      this.placeholder.setValue(value.placeholder);
    }

    if (value.width !== null) {
      this.width.setValue(value.width);
    }

    if (value.height !== null) {
      this.height.setValue(value.height);
    }

    if (value.border) {
      this.border.setValue(value.border);
    }
  }

  toJson (): any {
    return {
      enabled: this.enabled,
      border: this.border?.toJson(),
      aspectRatio: this.aspectRatio,
      height: this.height?.toString(),
      width: this.width?.toString(),
      zIndex: this.zIndex,
      placeholder: this.placeholder?.toJson(),
    };
  }
}

export class BadgeStyle {
  enabled?: boolean;
  border?: BorderStyle;
  aspectRatio?: number;
  height?: StyleProperty;
  width?: StyleProperty;
  zIndex?: number;
  flexAlignment?: FlexAlignmentStyleProperty;
  textStyle?: TextStyle;
  padding?: PaddingStyleProperty;
  background?: BackgroundStyle;
  isAbsolute?: boolean;
  absolutePosition?: AbsolutePositionStyleProperty;

  constructor (style: any = {}) {
    this.border = new BorderStyle();
    this.height = new StyleProperty(undefined);
    this.width = new StyleProperty();
    this.flexAlignment = new FlexAlignmentStyleProperty();
    this.textStyle = new TextStyle();
    this.background = new BackgroundStyle();
    this.padding = new PaddingStyleProperty();
    this.absolutePosition = new AbsolutePositionStyleProperty();

    this.setValue(style);
  }

  setValue (value: any = {}): void {
    this.enabled = value.enabled;
    this.zIndex = value.zIndex;
    this.aspectRatio = value.aspectRatio;
    this.isAbsolute = value.isAbsolute;

    if (value.width) {
      this.width.setValue(value.width);
    }

    if (value.height) {
      this.height.setValue(value.height);
    }

    if (value.border) {
      this.border.setValue(value.border);
    }

    if (value.padding) {
      this.padding.setValue(value.padding);
    }

    if (value.flexAlignment) {
      this.flexAlignment.setValue(value.flexAlignment);
    }

    if (value.textStyle) {
      this.textStyle.setValue(value.textStyle);
    }

    if (value.background) {
      this.background.setValue(value.background);
    }

    if (value.absolutePosition) {
      this.absolutePosition.setValue(value.absolutePosition);
    }
  }

  toJson (): any {
    return {
      enabled: this.enabled,
      border: this.border?.toJson(),
      aspectRatio: this.aspectRatio,
      height: this.height?.toString(),
      width: this.width?.toString(),
      zIndex: this.zIndex,
      isAbsolute: this.isAbsolute,
      flexAlignment: this.flexAlignment?.toJson(),
      textStyle: this.textStyle?.toJson(),
      padding: this.padding?.toJson(),
      background: this.background?.toJson(),
      absolutePosition: this.absolutePosition?.toJson(),
    };
  }
}
