import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { NgOtpInputModule } from 'ng-otp-input';

import { ChillzButtonModule } from '../../shared/components/chillz-button/chillz-button.module';
import { ChillzDirectivesModule } from '../../shared/directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../shared/directives/gsap/gsap-directives.module';
import { VerificationCodeComponent } from './verification-code/verification-code.component';


@NgModule({
  declarations: [ VerificationCodeComponent ],
  imports: [
    CommonModule,
    NgOtpInputModule,
    ChillzButtonModule,
    GSAPDirectivesModule,
    ChillzDirectivesModule,
    FlexModule,
  ],
  exports: [ VerificationCodeComponent ],
})
export class VerificationModule {}
