import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuPanel } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';

import { AppLocalizationService } from '../../../app-localization.service';


@Component({
  selector: 'chillz-button',
  templateUrl: './chillz-button.component.html',
  styleUrls: [ './chillz-button.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChillzButtonComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() cta: boolean;
  @Input() outline: boolean;
  @Input() outlineShine = true;
  @Input() glow = true;

  @Input() glowIntensity = 0;
  @Input() ctaGlowIntensity = 0;

  @Input() shadow = true;
  @Input() shadowIntensity = 12;
  @Input() ctaShadowIntensity = 12;
  @Input() expandable: boolean;

  @Input() height: number;
  @Input() width: number;
  @Input() maxWidth;
  @Input() maxHeight;

  @Input() padding: string;
  @Input() letterSpacing: string;
  @Input() fontSize: string;
  @Input() fontColor: string;
  @Input() uppercase = true;
  @Input() titlecase: boolean;

  @Input() classes: string;
  @Input() link: string | string[];
  @Input() relativeTo: ActivatedRoute | null;
  @Input() queryParams: { [param: string]: string };

  @Input() icon: string;
  @Input() isSvgIcon: boolean;

  @Input() ctaIcon: string;
  @Input() isCtaSvgIcon: boolean;

  @Input() loadingIcon: string;
  @Input() keepIconOnLoading: boolean;
  @Input() isLoadingSvgIcon: boolean;

  @Input() loadingSpinDisabled: boolean;

  @Input() backdropBlur = 0;
  @Input() iconPopDuration = 3;
  @Input() loadingIconPopDuration = 3;

  @Input() iconClass: string;
  @Input() label: { [lang: string]: string };
  @Input() borderRadius = '300px';
  @Input() strokeWidth: string;

  @Input() background = true;
  @Input() backgroundColor;

  @Input() ctaColors = [ '#ffb369', '#ff4272' ];

  @Input() blinkIn;

  @Input() hoverPush = true;
  @Input() hoverOutlineShine = true;
  @Input() hoverBackgroundPosition = true;
  @Input() hoverBackgroundColor: string;

  @Input() loading: boolean;

  @Input() isLoader;

  @Input() loaderSize: number;

  @Input() disabled: boolean;

  @Input() matMenuTriggerFor: MatMenuPanel;

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  @Input() contentInjected = false;

  wrapperStyle = {};
  wrapperLoadingStyle = {};

  ctaBackground;

  ctaChanged;

  viewLoaded = true;

  // @ViewChild('contentInjection') contentInjection: ElementRef;

  constructor (
    private _changeDetectorRef: ChangeDetectorRef,
    public localizationService: AppLocalizationService,
    public elementRef: ElementRef,
    private _ngZone: NgZone
  ) {}

  ngOnInit (): void {
    // if (this.height) {
    //   this.wrapperStyle['height'] = this.height + 'px';
    // }
    //
    // if (this.width) {
    //   this.wrapperStyle['width'] = this.width + 'px';
    // }
    //
    // this.height = this.height || this.maxHeight;
    // this.width = this.width || this.maxWidth;
    //
    // if (this.icon && (this.expandable || (!this.label && !this.contentInjected))) {
    //   if (!this.maxWidth) {
    //     this.maxWidth = 40;
    //   }
    //   if (!this.maxHeight) {
    //     this.maxHeight = 40;
    //   }
    // }
    //
    // this.wrapperStyle['max-width'] = (this.maxWidth || this.width) ? ((this.maxWidth || this.width) + 'px') : '100%';
    // this.wrapperStyle['max-height'] = (this.maxHeight || this.height) ? ((this.maxHeight || this.height) + 'px') : '100%';
    //
    // // if (this.maxHeight || this.height) {
    // //   this.wrapperStyle['max-height'] = (this.maxHeight || this.height) + 'px';
    // // }
    //
    // this.ctaBackground = `linear-gradient(to right, ${[...this.ctaColors, ...this.ctaColors, ...this.ctaColors].join(', ')})`;
    //
    // this.makePostInitAdjustments();
  }

  ngAfterViewInit (): void {
    //
    // if (this.icon) {
    //
    //   this.wrapperStyle['max-height'] = this.wrapperStyle['max-height'] || this.wrapperStyle['max-width'] || '40px';
    //
    //   if (this.expandable || (!this.label && !this.contentInjected)) {
    //     if (!this.wrapperStyle['max-width']) {
    //       this.wrapperStyle['max-width'] = this.wrapperStyle['max-height'] || '40px';
    //     }
    //   }
    // }
    //
    //
    // if (this.isLoader) {
    //
    //   this.wrapperStyle['max-height'] = this.wrapperStyle['max-height'] || '40px';
    //   this.wrapperStyle['max-width'] = this.wrapperStyle['max-width'] || '180px';
    //
    //   this.wrapperStyle = {
    //     ...this.wrapperStyle,
    //     'min-width': (this.width || this.height) ? ((this.width || this.height) + 'px') : this.wrapperStyle['max-width'],
    //     'min-height': (this.width || this.height) ? ((this.width || this.height) + 'px') : this.wrapperStyle['max-width'],
    //   };
    //
    //   const loaderSize = (this.loaderSize || this.height || this.width || 40) + 'px';
    //
    //   this.wrapperLoadingStyle = {
    //     'min-width': loaderSize,
    //     'max-width': loaderSize,
    //     'min-height': loaderSize,
    //     'max-height': loaderSize
    //   };
    // }
    // this.viewLoaded = true;
    //
    // this._changeDetectorRef.detectChanges();
    // setTimeout(() => {
    //   this.viewLoaded = true;
    //   this._changeDetectorRef.detectChanges();
    // }, 10);
  }

  makePostInitAdjustments (): void {
    if (this.icon) {
      this.wrapperStyle['max-height'] = this.wrapperStyle['max-height'] || this.wrapperStyle['max-width'] || '40px';
    }

    if (this.icon && (this.expandable || (!this.label && !this.contentInjected))) {
      if (!this.wrapperStyle['max-width']) {
        this.wrapperStyle['max-width'] = this.wrapperStyle['max-height'] || '40px';
      }
    }

    if (this.expandable === undefined && (this.label || this.contentInjected)) {
      this.expandable = true;
    }

    if (this.isLoader) {
      this.wrapperStyle['max-height'] = this.wrapperStyle['max-height'] || '40px';
      this.wrapperStyle['max-width'] = this.wrapperStyle['max-width'] || '180px';

      this.wrapperStyle = {
        ...this.wrapperStyle,
        'min-width': this.width || this.height ? (this.width || this.height) + 'px' : this.wrapperStyle['max-width'],
        'min-height': this.width || this.height ? (this.width || this.height) + 'px' : this.wrapperStyle['max-width'],
      };

      const loaderSize = (this.loaderSize || this.height || this.width || 40) + 'px';

      this.wrapperLoadingStyle = {
        'min-width': loaderSize,
        'max-width': loaderSize,
        'min-height': loaderSize,
        'max-height': loaderSize,
      };
    }

    this._changeDetectorRef.detectChanges();
    //
    // this._ngZone.runOutsideAngular(() => {
    //   setTimeout(() => {
    //     this.viewLoaded = true;
    //     this._changeDetectorRef.detectChanges();
    //   }, 0);
    // });
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.cta && !changes.cta.isFirstChange()) {
      this.ctaChanged = true;
    }

    if (this.height) {
      this.wrapperStyle['height'] = this.height + 'px';
    }

    if (this.width) {
      this.wrapperStyle['width'] = this.width + 'px';
    }

    this.height = this.height || this.maxHeight;
    this.width = this.width || this.maxWidth;

    if (this.icon && (this.expandable || (!this.label && !this.contentInjected))) {
      if (!this.maxWidth) {
        this.maxWidth = 40;
      }

      if (!this.maxHeight) {
        this.maxHeight = 40;
      }
    }

    this.wrapperStyle['max-width'] = this.maxWidth || this.width ? (this.maxWidth || this.width) + 'px' : '100%';
    this.wrapperStyle['max-height'] = this.maxHeight || this.height ? (this.maxHeight || this.height) + 'px' : '100%';

    // if (this.maxHeight || this.height) {
    //   this.wrapperStyle['max-height'] = (this.maxHeight || this.height) + 'px';
    // }

    this.ctaBackground = `linear-gradient(to right, ${[ ...this.ctaColors, ...this.ctaColors, ...this.ctaColors ].join(', ')})`;

    this.makePostInitAdjustments();
  }
}
