export const locale = {
  lang: 'en',
  data: {
    ORDER_PREPARATION: {
      ITEMS_ADDED: 'Items',
      ONE_ITEM_ADDED: 'One Item',
      SUB_TOTAL: 'Subtotal',
      TOTAL: 'Total',
      SAVED: 'Saved',
      CONTINUE: 'Checkout',
      INCLUDING_FEES: 'Incl. fees',
      JUST_A_MOMENT: 'Initializing your order, just a few seconds please',
      CALCULATING: 'Calculating',
      CALCULATING_TOTAL: 'Calculating Total...',
      NO_CHARGE: 'You won\'t be charged yet',
      SUBTOTAL_DISCLAIMER: 'service fee excluded',
      BACK_TO_START: 'Edit',
    },
    TICKET_TYPE_STATUS: {
      'SOLD-OUT': 'SOLD OUT',
      'SALE-ENDED': 'Sale Ended',
      'SOON': 'SOON',
      'UNAVAILABLE': 'UNAVAILABLE',
    },
  },
};
