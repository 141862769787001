import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';

import { fuseAnimations } from '../../../../../@fuse/animations';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { AppService } from '../../../../app.service';
import { Entity } from '../../../../main/entity/entity.model';
import { Platform } from '../../../../shared/models/platform.model';
import { SearchService } from './search.service';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: [ './search-bar.component.scss' ],
  animations: [ ...fuseAnimations ],
})
export class ChillzSearchBarComponent implements OnInit, OnDestroy {
  collapsed: boolean;
  fuseConfig: any;
  platform: Platform;
  recentlyViewed;
  searchHistory;
  searchResults: Entity[];
  searchControl = new FormControl('');
  searchInProgress = new Subject();
  isEmptySearch = false;
  @ViewChild('searchInput') searchInput: ElementRef;

  private _unsubscribeAll = new Subject();

  constructor (
    public searchService: SearchService,
    private _fuseConfigService: FuseConfigService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _appService: AppService
  ) {
    this.collapsed = true;
  }

  ngOnInit (): void {
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;
    });

    this._appService.onPlatformChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((platform) => {
      this.platform = platform;
    });

    this.searchValue();
  }

  openSearchBar (): void {
    this.collapsed = false;
    this._appService.openModal('search');
    this._changeDetectorRef.detectChanges();
    this.searchInput.nativeElement.focus();

    this.searchService.getRecentlyViewed().then((result) => {
      this.recentlyViewed = result;
    });

    this.searchService.getSearchHistory().then((result) => {
      this.searchHistory = result;
    });
  }

  collapse (): void {
    this.collapsed = true;
    this.clearSearch();
    this._appService.closeModal('search');
  }

  selectRecentSearch (item): void {
    this.searchService.loading = true;
    this.searchControl.setValue(item);
    this.searchInput.nativeElement.focus();
  }

  clearSearch (): void {
    this.searchControl.setValue('');
    this.searchResults = [];
    this.searchService.loading = false;
    this.searchInProgress.next(false);
    this.isEmptySearch = false;
  }

  selectItem (_itemId: string): void {
    this.collapse();
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private searchValue (): void {
    this.searchInProgress.next(true);
    this.searchControl.valueChanges
      .pipe(
        tap((value: string) => {
          this.isEmptySearch = false;
          this.updateLoadingState(value);
        }),
        debounceTime(600),
        distinctUntilChanged((prev: string, curr: string) => {
          if (prev === curr) {
            this.searchService.loading = false;
          }

          return prev === curr;
        }),

        takeUntil(this._unsubscribeAll),
        switchMap((value: string) => this.searchService.search(value).pipe(takeUntil(this.searchInProgress)))
      )
      .subscribe((results: Entity[]) => {
        this.searchResults = results;
        this.handleEmptySearch(results);
      });
  }

  private updateLoadingState (value: string): void {
    this.searchService.loading = value && value.length > 2;
  }

  private handleEmptySearch (results: Entity[]): void {
    this.isEmptySearch = !results || results.length === 0;
  }
}
