import { BackgroundStyle } from './background-style.model';
import { BorderStyle } from './border-style.model';
import { FlexAlignmentStyleProperty, FlexStyleProperty } from './flex-style-property';
import { PaddingStyleProperty } from './padding-style-property';
import { SelectableStyle } from './selectable-style.model';
import { StyleProperty } from './style-property.model';
import { TextStyle } from './text-style.model';


export class ButtonStyle extends SelectableStyle {
  textStyle?: TextStyle;
  aspectRatio?: number;
  flexAlignment?: FlexAlignmentStyleProperty;
  flex?: FlexStyleProperty;

  height?: StyleProperty;

  border?: BorderStyle;

  padding?: PaddingStyleProperty;

  background?: BackgroundStyle;

  constructor (style: any = {}) {
    super(style);
    this.border = new BorderStyle();
    this.textStyle = new TextStyle();
    this.flex = new FlexStyleProperty();
    this.height = new StyleProperty();
    this.padding = new PaddingStyleProperty();
    this.flexAlignment = new FlexAlignmentStyleProperty();

    this.background = new BackgroundStyle();
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    this.aspectRatio = value.aspectRatio;

    if (value.height) {
      this.height.setValue(value.height);
    }

    if (value.flexAlignment) {
      this.flexAlignment.setValue(value.flexAlignment);
    }

    if (value.flex) {
      this.flex.setValue(value.flex);
    }

    if (value.padding) {
      this.padding.setValue(value.padding);
    }

    if (value.textStyle) {
      this.textStyle.setValue(value.textStyle);
    }

    if (value.border) {
      this.border.setValue(value.border);
    }

    if (value.background) {
      this.background.setValue(value.background);
    }
  }

  toJson (excludeAttributions = false): any {
    return {
      ...super.toJson(excludeAttributions),
      border: this.border?.toJson(),
      textStyle: this.textStyle?.toJson(),
      height: this.height?.toString(),
      padding: this.padding?.toJson(),
      background: this.background?.toJson(),
      flexAlignment: this.flexAlignment?.toJson(),
      flex: this.flex?.toJson(),
      aspectRatio: this.aspectRatio,
    };
  }
}

export class EntityMenuItemStyle extends ButtonStyle {
  constructor (style: any = {}) {
    super(style);
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    super.setValue(value);
  }

  toJson (): any {
    return {
      ...super.toJson(),
    };
  }
}
