import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { locale as english } from '../i18n/en';
import { locale as hebrew } from '../i18n/he';


@Component({
  selector: 'app-cookies-consent',
  templateUrl: './cookies-consent.component.html',
  styleUrls: [ './cookies-consent.component.scss' ],
})
export class CookiesConsentComponent implements OnInit {
  @Output() approved: EventEmitter<void> = new EventEmitter<void>();
  textAttr: { direction: any; align: any };
  show = true;

  constructor (public _translateService: TranslateService, private translationLoader: FuseTranslationLoaderService) {
    if ([ 'he' ].includes(_translateService.currentLang)) {
      this.textAttr = { align: 'right', direction: 'rtl' };
    } else {
      this.textAttr = { align: 'left', direction: 'ltr' };
    }

    this.translationLoader.loadTranslations(english, hebrew);

    // Use the selected language for translations
    this.setLanguage(this._translateService.currentLang);
  }

  ngOnInit (): void {
    this._translateService.onLangChange.subscribe((lang) => {
      this.setLanguage(lang.lang);
    });
  }

  setLanguage (lang): void {
    if ([ 'he' ].includes(lang)) {
      this.textAttr = { align: 'right', direction: 'rtl' };
    } else {
      this.textAttr = { align: 'left', direction: 'ltr' };
    }

    // Use the selected language for translations
    this._translateService.use(lang);
  }
}
