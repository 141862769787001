import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { Nl2BrPipeModule } from 'nl2br-pipe';

import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { ChillzDirectivesModule } from '../../directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../directives/gsap/gsap-directives.module';
import { ChillzSkeletonBlockModule } from '../chillz-skeleton-block/chillz-skeleton-block.module';
import { ChillzThumbPlaceholderModule } from '../chillz-thumb-placeholder/chillz-thumb-placeholder.module';
import { OrderPreparationModule } from '../order-preparation/order-preparation.module';
import { VideoPlayerModule } from '../video-player/video-player.module';
import { ItemCardNewComponent } from './item-card-new.component';


@NgModule({
  declarations: [ ItemCardNewComponent ],
  imports: [
    CommonModule,
    OrderPreparationModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FuseDirectivesModule,
    FusePipesModule,
    Nl2BrPipeModule,
    VideoPlayerModule,
    ChillzDirectivesModule,
    GSAPDirectivesModule,
    ChillzThumbPlaceholderModule,
    ChillzSkeletonBlockModule,
  ],
  exports: [ ItemCardNewComponent ],
})
export class ItemCardModule {}
