import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ChillzButtonModule } from '../chillz-button/chillz-button.module';
import { ChillzStepIndicatorComponent } from './chillz-step-indicator.component';


@NgModule({
  declarations: [ ChillzStepIndicatorComponent ],
  imports: [ CommonModule, ChillzButtonModule, FlexLayoutModule ],
  exports: [ ChillzStepIndicatorComponent ],
})
export class ChillzStepIndicatorModule {}
