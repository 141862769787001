import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { CountUpDirective } from './count-up.directive';
import { DynamicHeightDirective } from './dynamic-height.directive';
import { NgIfAnimatedDirective } from './ngIf-animated.directive';
import { ObserveVisibilityDirective } from './observe-visibility.directive';
import { OpacityAnimationDirective } from './opacity-animation.directive';


@NgModule({
  declarations: [
    CountUpDirective,
    ObserveVisibilityDirective,
    NgIfAnimatedDirective,
    DynamicHeightDirective,
    OpacityAnimationDirective,
  ],
  exports: [
    CountUpDirective,
    ObserveVisibilityDirective,
    NgIfAnimatedDirective,
    DynamicHeightDirective,
    OpacityAnimationDirective,
  ],
  imports: [ CommonModule ],
  providers: [ CurrencyPipe, DecimalPipe, PercentPipe ],
})
export class ChillzDirectivesModule {}
