import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Directive({
  selector: '[observeVisibility]',
  exportAs: 'intersection',
})
export class ObserveVisibilityDirective implements OnDestroy, OnInit {
  @Input() root: HTMLElement | null = null;
  @Input() rootMargin = '0px 0px 0px 0px';
  @Input() threshold = 0;
  @Input() debounceTime = 500;
  @Input() isContinuous = false;

  @Output() isIntersecting = new EventEmitter<boolean>();

  _isIntersecting = false;
  subscription: Subscription;

  constructor (private element: ElementRef) {}

  ngOnInit (): void {
    this.subscription = this.createAndObserve();
  }

  ngOnDestroy (): void {
    this.subscription.unsubscribe();
  }

  createAndObserve (): Subscription {
    const options: IntersectionObserverInit = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };

    return new Observable<boolean>((subscriber) => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0];
        subscriber.next(isIntersecting);

        if (isIntersecting && !this.isContinuous) {
          intersectionObserver.disconnect();
        }
      }, options);

      intersectionObserver.observe(this.element.nativeElement);

      return {
        unsubscribe () {
          intersectionObserver.disconnect();
        },
      };
    })
      .pipe(debounceTime(this.debounceTime))
      .subscribe((status) => {
        this.isIntersecting.emit(status);
        this._isIntersecting = status;
      });
  }
}
