import { ColorStyle } from './color-style.model';
import { StyleProperty } from './style-property.model';


export class GradientFadeStyle {
  enabled?: boolean;
  color?: ColorStyle;
  height?: StyleProperty;
  asString?: string;

  constructor (style: any = {}) {
    this.height = new StyleProperty();
    this.color = new ColorStyle();
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    if (value.height !== undefined) {
      this.height.setValue(value.height);
    }

    this.enabled = value.enabled;
    if (value.color !== undefined) {
      this.color.setValue(value.color);
    }
  }

  toJson (): any {
    return {
      enabled: this.enabled,
      color: this.color?.toString(),
      height: this.height?.toString(),
    };
  }
}
