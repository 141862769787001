import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { CoreAnimationDirective } from './core-animation.directive';


@Directive({
  selector: '[popAnimation]',
})
export class PopAnimationDirective extends CoreAnimationDirective implements OnInit {
  @Input() elasticMax = 1.2;
  @Input() elasticMin = 0.3;

  constructor (protected element: ElementRef) {
    super(element);
  }

  ngOnInit (): void {
    // perform animation
    this.animateIn();
  }

  protected animateIn (): void {
    this.timeline.from(
      this.element.nativeElement,
      { duration: this.duration, scale: 0, ease: `elastic.out(${this.elasticMax}, ${this.elasticMin})` },
      this.delay
    );
    super.animateIn();
  }
}
