<div class="gap-8" fxLayout="column" fxLayoutAlign="start center">
  <div *ngIf="label || icon" fxLayout="row" fxLayoutAlign="center center" class="gap-8">
    <div *ngIf="icon" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon *ngIf="!isIconSymbol">{{ icon }}</mat-icon>
      <span class="material-symbols-outlined" *ngIf="isIconSymbol">{{ icon }}</span>
    </div>

    <span *ngIf="label" class="chillz-input-label">{{ label[localizationService.currentLang] || label['en'] }}</span>
  </div>

  <p-inputNumber
    class="chillz-number-input"
    dir="ltr"
    #input
    [mode]="mode"
    [class.pulse]="pulsing"
    [placeholder]="placeholder"
    [showClear]="showClear"
    [suffix]="suffix"
    [prefix]="prefix"
    [currency]="currency"
    [formControl]="control"
    [id]="id"
    [inputId]="id"
    [showButtons]="showButtons"
    [min]="min"
    [max]="max"
    [minFractionDigits]="minFractionDigits"
    [step]="step"
    [allowEmpty]="allowEmpty"
    [maxFractionDigits]="maxFractionDigits"
    (ngModelChange)="valueChanged($event)"
    incrementButtonIcon="pi pi-plus"
    decrementButtonIcon="pi pi-minus"
    buttonLayout="horizontal"
    locale="en-US"
  ></p-inputNumber>
</div>
