import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ChillzDirectivesModule } from '../../../app/shared/directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../../app/shared/directives/gsap/gsap-directives.module';
import { FuseCountdownComponent } from './countdown.component';


@NgModule({
  declarations: [ FuseCountdownComponent ],
  exports: [ FuseCountdownComponent ],
  imports: [ MatProgressSpinnerModule, CommonModule, ChillzDirectivesModule, GSAPDirectivesModule ],
})
export class FuseCountdownModule {}
