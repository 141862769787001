import { NgModule } from '@angular/core';

import { ChillzLoaderComponent } from './chillz-loader.component';


@NgModule({
  declarations: [ ChillzLoaderComponent ],
  exports: [ ChillzLoaderComponent ],
})
export class ChillzLoaderModule {}
