import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Entity } from '../../../../main/entity/entity.model';


@Injectable({ providedIn: 'root' })
export class SearchService implements Resolve<any> {
  routeParams: any;
  recentlyViewed: any;
  onRecentlyViewedChanged: BehaviorSubject<any>;

  searchHistory: any;
  onSearchHistoryChanged: BehaviorSubject<any>;

  searchResults: Entity[];

  weekdays = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ];
  today = this.weekdays[new Date().getDay()];

  loading: boolean;

  constructor (private _httpClient: HttpClient) {
    this.onRecentlyViewedChanged = new BehaviorSubject({});
    this.onSearchHistoryChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   */
  resolve (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Promise.all([ this.getRecentlyViewed() ]).then(() => {
        resolve(false);
      }, reject);
    });
  }

  getRecentlyViewed (): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('/users/self/recentlyViewed').subscribe(() => {
        this.onRecentlyViewedChanged.next(this.recentlyViewed);
        resolve(this.recentlyViewed);
      }, reject);
    });
  }

  getSearchHistory (): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('/search/history').subscribe((response: any) => {
        this.searchHistory = response.searchHistory;
        this.onSearchHistoryChanged.next(this.searchHistory);
        resolve(this.searchHistory);
      }, reject);
    });
  }

  search (value: string): Observable<Entity[]> {
    if (!value || value.length < 3) {
      this.loading = false;
      this.searchResults = [];
      return of(this.searchResults);
    }

    return this._httpClient.get<{ result: any[] }>(`/search?q=${value}`).pipe(switchMap((response) => {
      this.loading = false;
      this.searchResults = response.result.map((x) => new Entity(x));
      return of(this.searchResults);
    }));
  }
}
