import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { VerificationService } from '../verification.service';


@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: [ './verification-code.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class VerificationCodeComponent implements OnInit, OnDestroy {
  @Input() token: string;
  @Output() tokenChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() success: EventEmitter<void> = new EventEmitter<void>();
  @Output() fail: EventEmitter<string> = new EventEmitter<string>();

  @Input() length = 6;

  @Input() email: string;
  @Input() phone: string;

  formControl: FormControl;

  sending: boolean;
  error: string;

  private _unsubscribeAll = new Subject<any>();

  constructor (private _formBuilder: FormBuilder, private _verificationService: VerificationService) {}

  ngOnInit (): void {
    this.formControl = this._formBuilder.control(undefined);

    this.formControl.valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(200)).subscribe((value) => {
      if (value.length === this.length) {
        this.error = undefined;
        this.formControl.disable({ emitEvent: false });
        this.sending = true;
        this._verificationService
          .checkVerification(this.token, value)
          .then(() => {
            this.token = undefined;
            this.tokenChange.emit(this.token);
            this.success.emit();
          })
          .catch((err) => {
            console.log(err);
            this.error = err?.error?.error;
            this.fail.emit(err?.error?.error);
          })
          .finally(() => (this.sending = false));
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
