import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-picture-viewer',
  templateUrl: './picture-viewer.component.html',
  styleUrls: [ './picture-viewer.component.scss' ],
})
export class PictureViewerComponent implements OnInit {
  @Input() album;
  @Input() position;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor () {}

  ngOnInit (): void {}

  onNext (): void {
    this.position = (this.position + 1) % this.album.length;
  }

  onPrev (): void {
    if (this.position === 0) {
      this.position = this.album.length - 1;
      return;
    }

    this.position = this.position - 1;
  }

  onClose (): void {
    this.close.emit();
  }
}
