import { Direction } from '@angular/cdk/bidi';


export class Language {
  _id: string;
  en: string;
  title: string;
  id: string;
  code: string;
  flag: string;
  local: string;
  direction: Direction;
  align: string;

  /**
   * Constructor
   *
   */
  constructor (language) {
    {
      this._id = language._id;
      this.en = language.en;
      this.title = language.title;
      this.local = language.local;
      this.direction = language.direction;
      this.id = language.id;
      this.code = language.code;
      this.flag = language.flag;
      this.align = language.align;
    }
  }
}
