<form class="settings-section-body" [formGroup]="passwordFormGroup">
  <app-password-creation
    [username]="profile?.email"
    [current]="profile.hasPassword"
    [repeat]="false"
    [formGroup]="passwordFormGroup"
  ></app-password-creation>

  <mat-error *ngIf="passwordError" fxFlex="1 1 auto">{{ passwordError }}</mat-error>
  <chillz-button
    [label]="status !== 'saved' ? { en: 'Save', he: 'שמירה' } : undefined"
    [icon]="status === 'saved' ? 'checkmark' : undefined"
    [isSvgIcon]="true"
    [fontSize]="'14px'"
    [padding]="'6px 20px'"
    [height]="26"
    [loaderSize]="26"
    [hoverPush]="true"
    [expandable]="false"
    [outline]="true"
    [hoverOutlineShine]="true"
    [outlineShine]="true"
    [shadow]="true"
    [glow]="false"
    [isLoader]="true"
    [disabled]="passwordFormGroup.invalid || status === 'saving'"
    [loading]="status === 'saving'"
    (clicked)="onSave()"
  ></chillz-button>
</form>
