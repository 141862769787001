import { ChillzFile } from './chillz-file.model';
import { ChillzMediaItem } from './chillz-media-item.model';


export class ChillzPicture extends ChillzMediaItem {
  _id?: string;
  url?: string;
  optimized?: string;
  vertical?: string;

  ticketBg: string;
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  thumb: string;
  blurred: {
    optimized?: string;
    vertical?: string;
    ticketBg?: string;
    xs?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
    thumb?: string;
  };

  constructor (picture: any = {}) {
    super(picture);
    this._id = picture._id;
    this.url = ChillzFile.cdnUrl(picture.url);
    this.optimized = ChillzFile.cdnUrl(picture.optimized);
    this.vertical = ChillzFile.cdnUrl(picture.vertical);
    this.xs = ChillzFile.cdnUrl(picture.xs || picture.small);
    this.sm = ChillzFile.cdnUrl(picture.sm || picture.small);
    this.md = ChillzFile.cdnUrl(picture.md || picture.medium);
    this.lg = ChillzFile.cdnUrl(picture.lg || picture.medium);
    this.xl = ChillzFile.cdnUrl(picture.xl || picture.url);
    this.thumb = ChillzFile.cdnUrl(picture.thumb || picture.small);
    this.blurred = {
      optimized: ChillzFile.cdnUrl(picture.blurred?.optimized),
      vertical: ChillzFile.cdnUrl(picture.blurred?.vertical),
      ticketBg: ChillzFile.cdnUrl(picture.blurred?.vertical),
      thumb: ChillzFile.cdnUrl(picture.blurred?.thumb || picture.blurred?.small),
      xs: ChillzFile.cdnUrl(picture.blurred?.xs || picture.blurred?.small),
      sm: ChillzFile.cdnUrl(picture.blurred?.sm || picture.blurred?.small),
      md: ChillzFile.cdnUrl(picture.blurred?.md || picture.blurred?.medium),
      lg: ChillzFile.cdnUrl(picture.blurred?.lg || picture.blurred?.medium),
      xl: ChillzFile.cdnUrl(picture.blurred?.xl || picture.blurred?.original),
    };
  }

  getUrl (idealVersion: 'url' | 'optimized' | 'small' | 'medium' | 'original' | 'vertical'): string {
    let result;
    if (idealVersion === 'small') {
      result = this.sm;
    }

    if (idealVersion === 'medium' || (idealVersion === 'small' && !result)) {
      result = this.md;
    }

    if (idealVersion === 'url' || ([ 'small', 'medium' ].includes(idealVersion) && !result)) {
      result = this.url;
    }

    if (idealVersion === 'vertical' || ([ 'small', 'medium', 'url' ].includes(idealVersion) && !result)) {
      result = this.vertical;
    }

    return result;
  }
}
