<div
  class="button-wrapper"
  [class.icon-button]="icon"
  [class.expandable]="expandable || (expandable === undefined && !!label)"
  [class.loading]="loading"
  [class.keep-icon]="keepIconOnLoading"
  [class.cta]="cta"
  [class.disabled]="disabled"
  [class.has-label]="!!label || contentInjected"
  [class.animate-push]="hoverPush"
  [class.animate-outline]="hoverOutlineShine"
  [class.animate-background-position]="hoverBackgroundPosition"
  [class.spin-disabled]="loadingSpinDisabled"
  [ngStyle]="!loading ? wrapperStyle : wrapperLoadingStyle"
>
  <button
    class="chillz-button"
    [ngClass]="classes"
    [style.border-radius]="borderRadius"
    [style.padding]="padding"
    [style.color]="fontColor || '#fff'"
    [disabled]="disabled"
    (click)="!disabled ? clicked.emit($event) : undefined"
    [routerLink]="link"
    [relativeTo]="relativeTo"
    [queryParams]="queryParams"
    [queryParamsHandling]="'merge'"
  >
    <mat-icon
      popAnimation
      [duration]="iconPopDuration"
      *ngIf="viewLoaded && (cta && ctaIcon ? isCtaSvgIcon : icon && isSvgIcon)"
      [svgIcon]="cta && ctaIcon ? ctaIcon : icon"
      [class]="iconClass || 's-20'"
    ></mat-icon>
    <mat-icon
      popAnimation
      [duration]="iconPopDuration"
      *ngIf="viewLoaded && (cta && ctaIcon ? !isCtaSvgIcon : icon && !isSvgIcon)"
      [class]="iconClass || 's-20'"
    >
      {{ cta && ctaIcon ? ctaIcon : icon }}
    </mat-icon>

    <span
      class="label"
      [dir]="localizationService.textAttr?.direction"
      [style.letter-spacing]="letterSpacing"
      [style.font-size]="fontSize"
      [style.max-width]="wrapperStyle['max-width']"
      *ngIf="label"
    >
      <ng-container *ngIf="uppercase && !titlecase">
        {{ label[localizationService.currentLang] || label['en'] | uppercase }}
      </ng-container>
      <ng-container *ngIf="titlecase">
        {{ label[localizationService.currentLang] || label['en'] | titlecase }}
      </ng-container>
      <ng-container *ngIf="!titlecase && !uppercase">
        {{ label[localizationService.currentLang] || label['en'] }}
      </ng-container>
    </span>

    <div
      *ngIf="contentInjected"
      [dir]="localizationService.textAttr?.direction"
      class="injected-content"
      [style.max-width]="wrapperStyle['max-width']"
    >
      <ng-content></ng-content>
    </div>
  </button>

  <div class="loading-icon-container" *ngIf="loading && loadingIcon && !keepIconOnLoading">
    <mat-icon
      class="loading-icon"
      *ngIf="isLoadingSvgIcon"
      [svgIcon]="loadingIcon"
      [ngClass]="iconClass || 's-20'"
    ></mat-icon>
    <mat-icon class="loading-icon" *ngIf="!isLoadingSvgIcon" [ngClass]="iconClass || 's-20'">
      {{ loadingIcon }}
    </mat-icon>
  </div>

  <div
    class="cta-background"
    [class.view-loaded]="viewLoaded"
    [style.background-image]="ctaBackground"
    [class.blink-in]="blinkIn && cta && ctaChanged"
    [class.disabled]="disabled"
    [style.opacity]="cta ? undefined : 0"
    [style.border-radius]="borderRadius"
  ></div>
  <div
    class="background"
    [class.view-loaded]="viewLoaded"
    [style.backdrop-filter]="backdropBlur ? 'blur(' + backdropBlur + 'px)' : null"
    [style.--backgroundColor]="backgroundColor"
    [style.--hoverBackgroundColor]="hoverBackgroundColor"
    [style.border-radius]="borderRadius"
    [style.opacity]="background ? undefined : 0"
  ></div>

  <div
    class="outline"
    [class.view-loaded]="viewLoaded"
    [style.opacity]="outline || loading ? undefined : 0"
    [class.no-shine]="!outlineShine"
    [style.border-radius]="borderRadius"
    [ngStyle]="
      strokeWidth
        ? { top: '-' + strokeWidth, bottom: '-' + strokeWidth, left: '-' + strokeWidth, right: '-' + strokeWidth }
        : {}
    "
  ></div>
  <div
    class="shadow"
    [class.view-loaded]="viewLoaded"
    [style.opacity]="shadow ? undefined : 0"
    [style.--shadow-intensity]="cta ? ctaShadowIntensity : shadowIntensity"
    [style.border-radius]="borderRadius"
    [ngStyle]="!(outline || loading) ? { top: 0, bottom: 0, left: 0, right: 0 } : {}"
  ></div>
  <div
    class="glow"
    [class.view-loaded]="viewLoaded"
    [class.blink-in]="blinkIn && cta && ctaChanged"
    [style.opacity]="glow ? undefined : 0"
    [style.border-radius]="borderRadius"
    [style.--glow-intensity]="(cta ? ctaGlowIntensity : glowIntensity) + 'px'"
    [style.--glow-color]="ctaColors[1] || ctaColors[0]"
    [ngStyle]="!outline ? { top: 0, bottom: 0, left: 0, right: 0 } : {}"
  ></div>
</div>
