import { Inject, Injectable, Optional } from '@angular/core';


export interface IEnvService {
  x_platform: string;
  x_api: string;
  base_url: string;
}

@Injectable({ providedIn: 'root' })
export class EnvService {
  settings: IEnvService = {} as null;
  constructor (@Inject('envSettings') @Optional() private envSettings?: any) {
    if (this.envSettings) {
      this.settings = this.envSettings;
    }
  }
}
