import { IPrivacySettings } from '../../main/entity/entity.model';
import { ICustomField } from '../custom-field.interface';


export interface IPrivacyDefaults extends IPrivacySettings {
  memberFields?: ICustomField[];
}

export class EntityType {
  _id: string;
  id: string;
  icon: string;
  isRoot: boolean;
  title: { [lang: string]: string };
  badge: { [lang: string]: string };
  privacyDefaults: IPrivacyDefaults;

  /**
   * Constructor
   *
   */
  constructor (entityType?) {
    entityType = entityType || {};

    this.id = entityType.id;
    this._id = entityType._id;
    this.title = entityType.title || {};
    this.isRoot = entityType.isRoot;

    if (this.isRoot === undefined) {
      this.isRoot = this.id !== 'party';
    }

    this.icon = entityType.icon;
    this.badge = entityType.badge;

    this.privacyDefaults = entityType.privacyDefaults;
    if (entityType.privacyDefaults?.customFields) {
      this.privacyDefaults.memberFields = entityType.privacyDefaults?.customFields;
    }
  }
}
