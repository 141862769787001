import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { AppLocalizationService } from '../../../app-localization.service';
import { BundleOffer } from '../../models/bundle-offer.model';


@Component({
  selector: 'app-bundle-offer-summary',
  templateUrl: './bundle-offer-summary.component.html',
  styleUrls: [ './bundle-offer-summary.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class BundleOfferSummaryComponent implements OnInit {
  @Input() bundleOffer: BundleOffer;
  @Input() currentTier: number;

  @Input() hideTitle: boolean;
  @Input() hideDescription: boolean;

  @Input() hideTierTitle: boolean;
  @Input() hideTierSummary: boolean;

  @Input() opacityStatusIndication: boolean;

  @Input() tiersHeight = '40px';

  constructor (public localizationService: AppLocalizationService) {}

  ngOnInit (): void {
    console.log(this.bundleOffer);
    console.log(this.localizationService.currentLang);
    this.localizationService.onCurrentLangChanged.subscribe(() => console.log(this.localizationService.currentLang));
  }
}
