import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NguCarouselModule } from '@ngu/carousel';
import { TranslateModule } from '@ngx-translate/core';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { QRCodeModule } from 'angularx-qrcode';

import { FuseDirectivesModule } from '../../@fuse/directives/directives';
import { FusePipesModule } from '../../@fuse/pipes/pipes.module';
import { AddPaymentMethodDialogComponent } from './components/add-payment-method-dialog/add-payment-method-dialog.component';
import { BundleOfferSummaryModule } from './components/bundle-offer-summary/bundle-offer-summary.module';
import { ChillzCarouselPosterItemModule } from './components/carousel-poster-item/carousel-poster-item.module';
import { ChillzBaseButtonModule } from './components/chillz-base-button/chillz-base-button.module';
import { ChillzButtonModule } from './components/chillz-button/chillz-button.module';
import { CookiesConsentComponent } from './components/cookies-consent/cookies-consent.component';
import { ExploreCarouselComponent } from './components/explore-carousel/explore-carousel.component';
import { InitialLoaderComponent } from './components/initial-loader/initial-loader.component';
import { ItemCardModule } from './components/item-card/item-card.module';
import { PaymentComponent } from './components/payment/payment.component';
import { PictureViewerComponent } from './components/picture-viewer/picture-viewer.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { StripePaymentComponent } from './components/stripe-payment/stripe-payment.component';
import { TicketThumbComponent } from './components/ticket-thumb/ticket-thumb.component';
import { TicketService } from './components/ticket-view-dialog/ticket.service';
import { VenueThumbComponent } from './components/venue-thumb/venue-thumb.component';
import { VenueThumbService } from './components/venue-thumb/venue-thumb.service';
import { ChillzDirectivesModule } from './directives/chillz-directives.module';
import { GSAPDirectivesModule } from './directives/gsap/gsap-directives.module';
import { SafePipe } from './pipes/safe.pipe';


@NgModule({
  declarations: [
    CookiesConsentComponent,
    VenueThumbComponent,
    ExploreCarouselComponent,
    AddPaymentMethodDialogComponent,
    PictureViewerComponent,
    TicketThumbComponent,
    CookiesConsentComponent,
    PaymentComponent,
    StripePaymentComponent,
    SplashScreenComponent,
    InitialLoaderComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatDialogModule,

    CreditCardDirectivesModule,
    FlexLayoutModule,
    TranslateModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatTooltipModule,
    NguCarouselModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ItemCardModule,
    BundleOfferSummaryModule,
    QRCodeModule,
    ChillzButtonModule,
    ChillzDirectivesModule,
    GSAPDirectivesModule,
    ChillzCarouselPosterItemModule,
    ChillzButtonModule,
    ChillzBaseButtonModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    FuseDirectivesModule,
    FusePipesModule,
    PaymentComponent,
    VenueThumbComponent,
    CookiesConsentComponent,
    AddPaymentMethodDialogComponent,
    PictureViewerComponent,
    ExploreCarouselComponent,
    TicketThumbComponent,
    StripePaymentComponent,
    SplashScreenComponent,
    ItemCardModule,
    BundleOfferSummaryModule,
    InitialLoaderComponent,
  ],
  providers: [ DatePipe, VenueThumbService, TicketService, SafePipe ],
})
export class ChillzSharedModule {}
