import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { AppLocalizationService } from '../../../app-localization.service';


@Component({
  selector: 'app-chillz-step-indicator',
  templateUrl: './chillz-step-indicator.component.html',
  styleUrls: [ './chillz-step-indicator.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ChillzStepIndicatorComponent implements OnInit {
  @Input() steps: {
    label: { [lang: string]: string };
  }[] = [];

  @Input() currentStep = 0;
  @Output() currentStepChange: EventEmitter<number> = new EventEmitter<number>();

  constructor (public localizationService: AppLocalizationService) {}

  ngOnInit (): void {
    console.log(this.steps);
  }
}
