import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { LocalStorage } from '../@fuse/services/storage/local-storage';
import { AppService } from './app.service';
import { AppLocalizationService } from './app-localization.service';


// import * as url from 'url';

@Injectable({
  providedIn: 'root',
})
export class AppLanguageGuard implements CanActivate, CanActivateChild {
  constructor (
    private _localizationService: AppLocalizationService,
    private _appService: AppService,
    private router: Router,
    private _localStorage: LocalStorage
  ) {}

  canActivate (
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const languages = this._localizationService.languages.map((x) => x.id);

    if (languages.includes(childRoute.params.lang)) {
      return true;
    }

    let lang = this._localStorage.getItem('selected-lang');

    if (!lang || !languages.includes(lang)) {
      lang = 'en';
      this._localStorage.removeItem('selected-lang');
    }

    return this.router.parseUrl(lang + (state.url !== '/' ? state.url.replace('/?', '?') : ''));
  }

  canActivateChild (): boolean {
    return true;
  }
}
