import * as _ from 'lodash';
import randomString from 'randomstring';

import { Entity } from '../../../main/entity/entity.model';
import { ExploreItem } from '../explore-item.model';
import { ExploreTheme } from '../explore-theme.model';
import { ButtonStyle } from './button-style.model';
import { ItemsCollectionStyle } from './collection-style.model';
import { ColorStyle } from './color-style.model';
import { FlexAlignmentStyleProperty, FlexStyleProperty } from './flex-style-property';
import { GradientStyle } from './gradient-style.model';
import { MediaSource, MediaSourcesList } from './media-source.model';
import { PaddingStyleProperty } from './padding-style-property';
import { SelectableStyle } from './selectable-style.model';
import { StyleProperty } from './style-property.model';
import { TextStyle } from './text-style.model';


export class EntityPageLayoutStyle extends SelectableStyle {
  _id: string;
  name?: string;
  entity: Entity;
  hero: HeroStyle;
  textStyles?: {
    default?: TextStyle;
    secondaryHeadline?: TextStyle;
  };
  sectionsOrder?: EntityPageSectionsLayout;
  gridLayout?: { [size: string]: EntityPageSectionsGridLayout };
  background?: {
    gradient?: GradientStyle;
    color?: ColorStyle;
    animation?: {
      enabled?: boolean;
      color?: ColorStyle;
      intensity?: StyleProperty;
    };
  };

  sectionsLayout?: { [size: string]: EntityPageSectionsGridLayout };

  constructor (style: any = {}) {
    super(style);
    this._id = style._id;
    if (style.entity) {
      this.entity = new Entity(style.entity);
    }

    this.hero = new HeroStyle();
    this.textStyles = {
      secondaryHeadline: new TextStyle(undefined, undefined),
      default: new TextStyle(undefined, undefined),
    };

    this.background = {
      gradient: new GradientStyle(),
      color: new ColorStyle('#000000'),
      animation: {
        enabled: true,
        color: new ColorStyle('host-accent'),
        intensity: new StyleProperty(1, 'frac'),
      },
    };

    this.sectionsOrder = new EntityPageSectionsLayout(undefined);
    this.gridLayout = {};

    this.setValue(style);
  }

  getSectionsOrder (size = 'default'): EntityPageSectionInstance[] {
    return this.getSectionsLayout(size)?.sections || [];
  }

  getSectionsLayout (size = 'default'): EntityPageSectionsGridLayout {
    const sizes = [ 'default', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl' ];

    let i = sizes.indexOf(size);

    do {
      size = sizes[i];

      if (this.gridLayout[size]) {
        return this.gridLayout[size];
      }

      sizes.splice(i, 1);

      if (i >= sizes.length) {
        i--;
      }
    } while (sizes.length);
  }

  setValue (value: any = {}): void {
    super.setValue(value);

    this.hero.setValue(value.hero);

    if (value.textStyles?.default) {
      this.textStyles.default.setValue(value.textStyles.default);
    }

    if (value.textStyles?.secondaryHeadline) {
      this.textStyles.secondaryHeadline.setValue(value.textStyles.secondaryHeadline);
    }

    if (value.background?.gradient) {
      this.background.gradient.setValue(value.background.gradient);
    }

    if (value.background?.animation?.color) {
      this.background.animation.color.setValue(value.background.animation.color);
    }

    if (value.background?.color) {
      this.background.color.setValue(value.background.color);
    }

    if (value.background?.animation?.intensity !== undefined) {
      this.background.animation.intensity.setValue(value.background.animation.intensity);
    }

    if (value.background?.animation?.enabled !== undefined) {
      this.background.animation.enabled = value.background?.animation?.enabled;
    }

    if (value.sectionsOrder) {
      this.sectionsOrder.setValue(value.sectionsOrder);
      // this.gridLayout.setValue(value.sectionsOrder);
    }

    for (const size of [ 'default', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl' ]) {
      if (value.gridLayout?.[size] || this.sectionsOrder?.[size]) {
        if (this.gridLayout[size]) {
          this.gridLayout[size].setValue(value.gridLayout?.[size] || { sections: this.sectionsOrder?.[size].map((x) => x.toJson()) });
        } else {
          this.gridLayout[size] = new EntityPageSectionsGridLayout(value.gridLayout?.[size] || { sections: this.sectionsOrder?.[size].map((x) => x.toJson()) });
        }
      }
    }
  }

  toJson (excludeAttributions = false): any {
    const gridLayout = {};
    for (const size of [
      'default',
      'gt-xs',
      'gt-sm',
      'gt-md',
      'gt-lg',
      'gt-xl',
      'gt-xs',
      'gt-sm',
      'gt-md',
      'gt-lg',
      'gt-xl',
    ]) {
      if (this.gridLayout?.[size]) {
        gridLayout[size] = this.gridLayout[size].toJson();
      }
    }

    return {
      ...super.toJson(excludeAttributions),
      hero: this.hero?.toJson(),
      // hero: this.hero?.map(x => x.toJson()),
      textStyles: {
        default: this.textStyles?.default?.toJson(),
        secondaryHeadline: this.textStyles?.secondaryHeadline?.toJson(),
      },
      background: {
        gradient: this.background?.gradient?.toJson(),
        color: this.background?.color?.toString(),
        animation: {
          enabled: this.background?.animation?.enabled,
          color: this.background?.animation?.color?.toString(),
          intensity: this.background?.animation?.intensity?.value,
        },
      },
      sectionsOrder: this.sectionsOrder?.toJson(),
      gridLayout,
    };
  }

  getFonts (): { [font: string]: number[] } {
    const fontsToLoad = {};

    if (this?.textStyles) {
      if (this.textStyles.default?.fontFamily && this.textStyles.default?.fontFamily !== 'inherit') {
        fontsToLoad[this.textStyles.default.fontFamily] = null;
      }

      if (
        this.textStyles.secondaryHeadline?.fontFamily &&
        this.textStyles?.secondaryHeadline?.fontFamily !== 'inherit'
      ) {
        fontsToLoad[this.textStyles.secondaryHeadline.fontFamily] = null;
      }
    }

    for (const size of [ 'default', 'gt-xs', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl' ]) {
      if (this?.getSectionsOrder(size)) {
        for (const section of this.getSectionsOrder(size)) {
          if (section.textStyle?.fontFamily && section.textStyle?.fontFamily !== 'inherit') {
            fontsToLoad[section.textStyle?.fontFamily] = null;
          }
        }
      }
    }

    return fontsToLoad;
  }
}

export class HeroStyle {
  sources: MediaSourcesList;

  constructor (style: any = {}) {
    this.sources = new MediaSourcesList();
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    if (value.sources) {
      if (value instanceof HeroStyle) {
        value = value.toJson();
      }

      this.sources.setValue(value?.sources);
    }
  }

  updateEntityMediaValues (entity: Entity): void {
    this.sources?.updateEntityMediaValues(entity);
  }

  get activeSource (): MediaSource {
    return this.sources.activeSource;
  }

  get enabledSources (): MediaSource[] {
    return this.sources?.enabledSources;
  }

  get enabledSourcesWithValue (): MediaSource[] {
    return this.sources?.enabledSourcesWithValue;
  }

  toJson (): any {
    return {
      sources: this.sources.toJson(),
    };
  }
}

export class EntityPageSectionsLayout {
  default: EntityPageSectionInstance[];
  'gt-xs': EntityPageSectionInstance[];
  'gt-sm': EntityPageSectionInstance[];
  'gt-md': EntityPageSectionInstance[];
  'gt-lg': EntityPageSectionInstance[];
  'gt-xl': EntityPageSectionInstance[];

  constructor (layout: any = {}) {
    this.setValue(layout);
  }

  setValue (value: any = {}): void {
    for (const size of [ 'default', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl' ]) {
      if (value[size]) {
        value[size].forEach((instance, index) => {
          const existing: EntityPageSectionInstance = this[size]?.find((p) => p.id === instance.id && instance.id);
          if (existing) {
            existing.setValue(instance);
          }

          value[size][index] = existing || new EntityPageSectionInstance(instance);
        });
        if (!this[size]) {
          this[size] = [];
        }

        _.remove(this[size], () => true);
        this[size].push(...value[size]);
      } else {
        this[size] = undefined;
      }
    }
  }

  toJson (): any {
    const res = {};
    for (const size of [ 'default', 'gt-xs', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl' ]) {
      if (this[size]) {
        res[size] = (this[size] as EntityPageSectionInstance[]).map((section) => section.toJson());
      }
    }
    return res;
  }
}

export class LayoutGap {
  constructor (
    public id: string,
    public row: number,
    public isNewRow: boolean,
    public startCol: number,
    public span: number,
    public isAvailable = true,
    public isAfterSingleArea = false
  ) {}
}

export class EntityPageSectionsGridLayout {
  sections: EntityPageSectionInstance[];
  templateAreas: string[][];
  columns: string[] = [ 'minmax(0, 50%)', 'minmax(0, 50%)' ];

  extendedLayout: {
    gaps: LayoutGap[];
    templateAreas: string[][];
    templateAreasString: string;
    templateColumns: string;
  };

  constructor (layout: any = {}) {
    this.setValue(layout);
  }

  setValue (value: any = {}): void {
    if (value.columns) {
      this.columns = value.columns;
    }

    if (value.sections) {
      value.sections.forEach((instance, index) => {
        const existing: EntityPageSectionInstance = this.sections?.find((p) => p.id === instance.id && instance.id);
        if (existing) {
          existing.setValue(instance);
        }

        value.sections[index] = existing || new EntityPageSectionInstance(instance);
      });
      if (!this.sections) {
        this.sections = [];
      }

      _.remove(this.sections, () => true);
      this.sections.push(...value.sections);
    }

    // this.sections = value.sections?.map(x => new EntityPageSectionInstance(x)) || [];
    this.templateAreas = value.templateAreas || [];

    this.assignAreasToSections();

    this.updateExtendedLayout();
  }

  assignAreasToSections (): void {
    const flat = _.flatten(this.templateAreas);

    const unusedSections = this.sections.filter((s) => !flat.includes(s.id));

    this.templateAreas.push(...unusedSections.map((s) => _.fill(Array(this.columns.length), s.id)));
  }

  updateExtendedLayout (): void {
    let gap = new LayoutGap('gap-1', 0, true, 0, this.columns.length);

    const totalAreaColumns = this.columns.length * 2 + 1;
    const templateAreas = [ _.fill(Array(totalAreaColumns), gap.id) ];
    const templateColumns = _.flatten(_.zip(_.fill(Array(this.columns.length + 1), 'minmax(50px, auto)'), this.columns));

    const gaps: LayoutGap[] = [ gap ];
    this.templateAreas.forEach((row, i) => {
      const hasAvailableCol = _.uniq(row).length < this.columns.length;

      const updatedRow = [];
      let j = 0;

      let existingAreaCurrentSpan = row.filter((x) => row[0] === x).length;
      let existingAreaNewSpan = Math.floor(existingAreaCurrentSpan / 2) || 1;

      gap = new LayoutGap(
        'gap-' + (i + 1) + '-' + j,
        i,
        false,
        0,
        existingAreaNewSpan,
        hasAvailableCol,
        existingAreaCurrentSpan === 1
      );
      updatedRow.push(gap.id);
      gaps.push(gap);
      j++;

      row.forEach((area, index) => {
        if (row.length > index && row[index + 1] === area) {
          updatedRow.push(area, area);
        } else {
          existingAreaCurrentSpan = row.slice(row.indexOf(area)).filter((x) => x === area).length;
          existingAreaNewSpan = Math.floor(existingAreaCurrentSpan / 2) || 1;

          gap = new LayoutGap(
            'gap-' + (i + 1) + '-' + j,
            i,
            false,
            row.indexOf(area) + existingAreaNewSpan,
            existingAreaCurrentSpan - existingAreaNewSpan || 1,
            hasAvailableCol,
            existingAreaCurrentSpan === 1
          );
          updatedRow.push(area, gap.id);
          gaps.push(gap);
          j++;
        }
      });

      templateAreas.push(updatedRow);

      gap = new LayoutGap('gap-' + (i + 2), i + 1, true, 0, this.columns.length);
      templateAreas.push(_.fill(Array(totalAreaColumns), gap.id));
      gaps.push(gap);
    });

    let templateAreasString = '';
    templateAreas?.forEach((row) => {
      templateAreasString += `\"${row.join(' ')}\"\n`;
    });

    this.extendedLayout = {
      templateAreas,
      templateAreasString,
      gaps,
      templateColumns: templateColumns.join(' '),
    };
  }

  get templateAreasString (): string {
    let template = '';
    this.templateAreas?.forEach((row) => {
      template += `\"${row.join(' ')}\"\n`;
    });

    return template;
  }

  get templateColumnsString (): string {
    return this.columns.join(' ');
  }

  addSection (section: EntityPageSectionInstance, gap?: LayoutGap): void {
    const existing = this.sections.findIndex((x) => x.id === section.id);
    if (existing > -1) {
      this.sections.splice(existing, 1);
    }

    this.sections.push(section);

    if (!gap) {
      this.updateExtendedLayout();
      return;
    }

    if (gap.isNewRow) {
      this.templateAreas.splice(gap.row, 0, _.fill(Array(this.columns.length), section.id));
    } else {
      this.templateAreas[gap.row].splice(
        gap.startCol,
        !gap.isAfterSingleArea ? gap.span : 0,
        ..._.fill(Array(gap.span), section.id)
      );
      if (gap.isAfterSingleArea) {
        const spliceIndex = this.templateAreas[gap.row].findIndex((area, index) => area === this.templateAreas[gap.row][index + 1]);

        this.templateAreas[gap.row].splice(spliceIndex, gap.span);
      }
    }

    this.updateExtendedLayout();
  }

  removeSection (section: EntityPageSectionInstance): void {
    this.templateAreas.forEach((row) => {
      const existingArea = row.indexOf(section.id);
      if (existingArea > -1) {
        let existingAreaSpan = row.filter((x) => row[existingArea] === x).length;
        if (existingAreaSpan === row.length) {
          row.splice(0, row.length);
          return;
        }

        let nextArea;
        let prevArea;
        let areasBefore = 0;

        if (existingArea > 0) {
          areasBefore = Math.ceil(existingAreaSpan / 2);
          prevArea = row[existingArea - 1];

          row.splice(existingArea, areasBefore, ..._.fill(Array(areasBefore), prevArea));
          existingAreaSpan -= areasBefore;
        }

        if (existingArea < row.length - 1) {
          nextArea = row[existingArea + existingAreaSpan];

          row.splice(existingArea + areasBefore, existingAreaSpan, ..._.fill(Array(existingAreaSpan), nextArea));
        }
      }
    });

    this.templateAreas = this.templateAreas.filter((x) => !!x.length);

    const existing = this.sections.findIndex((x) => x.id === section.id);
    if (existing > -1) {
      this.sections.splice(existing, 1);
    }

    this.updateExtendedLayout();
  }

  toJson (): any {
    return {
      sections: this.sections?.map((s) => s.toJson()),
      templateAreas: this.templateAreas,
    };
  }
}

export class EntityPageSection {
  _id?: string;
  id?: string;
  title?: { [lang: string]: string };
  relatedGroup?: {
    id?: string;
    timeFilter?: string;
    itemsLimit?: number;
    mockItems?: number;
    timeFilterOptions?: string[];
    style?: ItemsCollectionStyle;
    optionalStyles?: ItemsCollectionStyle[];
  };
  isVisiblePublicly?: boolean;
  isSectionTitleVisible?: boolean;
  defaultLayout: any;

  constructor (section: any = {}) {
    this._id = section._id;
    this.id = section.id;
    if (section.title) {
      this.title = section.title;
    }

    this.isVisiblePublicly = section.isVisiblePublicly;
    this.isSectionTitleVisible = section.isSectionTitleVisible;

    if (section.relatedGroup?.id) {
      this.relatedGroup = {
        id: section?.relatedGroup?.id,
        timeFilter: section?.relatedGroup?.timeFilter,
        itemsLimit: section?.relatedGroup?.itemsLimit,
        timeFilterOptions: section?.relatedGroup?.timeFilterOptions,
        mockItems: section?.relatedGroup?.mockItems,
        style: section?.relatedGroup?.style ? new ItemsCollectionStyle(section?.relatedGroup?.style) : undefined,
      };
    }

    this.defaultLayout = section.defaultLayout;
  }
}

export class EntityPageSectionInstance {
  id: string;
  section?: EntityPageSection;
  title?: { [lang: string]: string };
  flex?: FlexStyleProperty;
  height?: StyleProperty;
  padding?: PaddingStyleProperty;
  maxHeight?: StyleProperty;
  minHeight?: StyleProperty;
  flexAlignment?: FlexAlignmentStyleProperty;

  flexDirection: string;
  gap?: StyleProperty;
  minColumnWidth?: StyleProperty;
  maxColumns?: number;
  gridTemplateCols: 'auto-fill' | 'auto-fit' = 'auto-fill';

  code?: string;
  richText?: string;
  album?: string;

  buttons?: {
    style?: ButtonStyle;
    label?: { [lang: string]: string };
    icon?: string;
    svgIcon?: string;
    url?: string;
    page?: string;
  }[];

  exploreTheme?: ExploreTheme;

  textStyle?: TextStyle;
  isSectionTitleVisible?: boolean;
  isVisiblePublicly?: boolean;
  relatedGroup?: {
    timeFilter?: string;
    itemsLimit?: number;
    style?: ItemsCollectionStyle;
    items?: ExploreItem[];
    total?: number;
    censoredDisclaimer?: {
      title: { [lang: string]: string };
      text: { [lang: string]: string };
    };
    showIfEmpty?: boolean;
    emptyMessage?: { [lang: string]: string };
  };

  constructor (section: any = {}) {
    this.id = section.id || randomString.generate({ length: 4, charset: 'alphabetic' });
    this.section = new EntityPageSection(section.section);
    this.height = new StyleProperty();
    this.padding = new PaddingStyleProperty();
    this.flex = new FlexStyleProperty({ grow: 0, basis: '45%' }, '%');
    this.maxHeight = new StyleProperty();
    this.minHeight = new StyleProperty();
    this.gap = new StyleProperty();
    this.minColumnWidth = new StyleProperty();
    this.flexAlignment = new FlexAlignmentStyleProperty();
    this.textStyle = new TextStyle(
      { fontSize: '1em', textTransform: 'uppercase', lineHeight: 1.1, fontFamily: 'inherit' },
      undefined,
      'host-accent'
    );
    this.setValue(section);
  }

  setValue (value: any = {}): void {
    this.title = value.title;
    this.isSectionTitleVisible = value.isSectionTitleVisible;
    this.isVisiblePublicly = value.isVisiblePublicly;
    this.album = value.album;

    if (value.gridTemplateCols) {
      this.gridTemplateCols = value.gridTemplateCols;
    }

    this.maxColumns = value.maxColumns;
    this.flexDirection = value.flexDirection;

    if (!this.title || !Object.keys(this.title)?.length) {
      this.title = undefined;
    }

    this.buttons = value.buttons;

    this.buttons?.forEach((button) => {
      if (button.style) {
        button.style = new ButtonStyle(button.style);
      }
    });

    if (value.exploreTheme !== undefined) {
      this.exploreTheme = new ExploreTheme(value.exploreTheme);
    }

    if (value.code !== undefined) {
      this.code = value.code;
    }

    if (value.richText !== undefined) {
      this.richText = value.richText;
    }

    if (value.flexAlignment !== undefined) {
      this.flexAlignment.setValue(value.flexAlignment);
    }

    if (value.textStyle !== undefined) {
      this.textStyle.setValue(value.textStyle);
    }

    if (value.flex !== undefined) {
      this.flex.setValue(value.flex);
    }

    if (value.height !== undefined) {
      this.height.setValue(value.height);
    }

    if (value.padding !== undefined) {
      this.padding.setValue(value.padding);
    }

    if (value.maxHeight !== undefined) {
      this.maxHeight.setValue(value.maxHeight);
    }

    if (value.minHeight !== undefined) {
      this.minHeight.setValue(value.minHeight);
    }

    if (value.gap !== undefined) {
      this.gap.setValue(value.gap);
    }

    if (value.minColumnWidth !== undefined) {
      this.minColumnWidth.setValue(value.minColumnWidth);
    }

    if (value.relatedGroup !== undefined) {
      this.relatedGroup = {
        timeFilter: value.relatedGroup.timeFilter,
        itemsLimit: value.relatedGroup.itemsLimit,
        style: value.relatedGroup.style ? new ItemsCollectionStyle(value.relatedGroup.style) : undefined,
        showIfEmpty: value.relatedGroup?.showIfEmpty,
        censoredDisclaimer: value.relatedGroup?.censoredDisclaimer,
        emptyMessage: value.relatedGroup?.emptyMessage,
      };
    }
  }

  toJson (): any {
    return {
      id: this.id,
      section: this.section,
      title: this.title,
      flex: this.flex?.toJson(),
      height: this.height?.toString(),
      padding: this.padding?.toJson(),
      maxHeight: this.maxHeight?.toString(),
      minHeight: this.minHeight?.toString(),
      flexAlignment: this.flexAlignment?.toJson(),
      textStyle: this.textStyle?.toJson(),
      code: this.code,
      richText: this.richText,
      // buttons: this.buttons?.map(button => ({...button, style: null})),
      maxColumns: this.maxColumns,
      gridTemplateCols: this.gridTemplateCols,
      gap: this.gap?.toString(),
      minColumnWidth: this.minColumnWidth?.toString(),
      flexDirection: this.flexDirection,
      isVisiblePublicly: this.isVisiblePublicly,
      isSectionTitleVisible: this.isSectionTitleVisible,
      ...(this.relatedGroup
        ? {
            relatedGroup: {
              timeFilter: this.relatedGroup?.timeFilter,
              itemsLimit: this.relatedGroup?.itemsLimit,
              style: this.relatedGroup?.style?.toJson(),
            },
          }
        : {}),
    };
  }

  gridTemplateColumns (gridTemplateCols?: string, ignoreGap?: boolean): string {
    if (typeof this.gap.value !== 'number') {
      return;
    }

    return `repeat(${
      gridTemplateCols || this.gridTemplateCols || 'auto-fill'
    }, minmax(max(min(100%, ${this.minColumnWidth.toString()}), calc(${Math.floor(100 / this.maxColumns - 1).toFixed(0)}% - ${ignoreGap ? '0' : Math.ceil(this.gap.value / this.maxColumns).toFixed(0)}${this.gap.unit})), 1fr))`;
  }

  clone (): EntityPageSectionInstance {
    const clone = _.clone(this);
    clone.id = randomString.generate({ length: 4, charset: 'alphabetic' });
    return clone;
  }
}
