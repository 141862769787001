import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Entity } from '../../../../../main/entity/entity.model';
import { Platform } from '../../../../../shared/models/platform.model';


@Component({
  selector: 'app-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: [ './search-result-list.component.scss' ],
})
export class SearchResultListComponent {
  @Input() searchList: Entity[] = [];
  @Input() platform: Platform;

  @Output() selectItem = new EventEmitter<string>();

  constructor () {}

  onSelectItem (itemId: string): void {
    this.selectItem.emit(itemId);
  }
}
