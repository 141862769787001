import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';


@Directive({
  selector: '[dynamicHeight]',
})
export class DynamicHeightDirective {
  @Input() itemSize = 100;
  @Input() maxLength = 6;

  @Input('dynamicHeight') set listLength (length: number) {
    const height = (length > this.maxLength ? this.maxLength : length) * this.itemSize;
    this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
  }

  constructor (private el: ElementRef, private renderer: Renderer2) {}
}
