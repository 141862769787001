import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPasswordStrengthComponent } from '@angular-material-extensions/password-strength';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { confirmPasswordValidator } from '../../shared/validators/validators';


@Component({
  selector: 'app-password-creation',
  templateUrl: './password-creation.component.html',
  styleUrls: [ './password-creation.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordCreationComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() formGroup: FormGroup;
  @Input() current: boolean;
  @Input() repeat = true;

  @Input() username: string;

  @ViewChild('passwordComponentWithValidation') passwordComponentWithValidation: MatPasswordStrengthComponent;

  // Private
  private _unsubscribeAll = new Subject<any>();

  constructor (private _formBuilder: FormBuilder) {}

  ngOnInit (): void {}

  ngOnChanges (): void {
    if (!this.formGroup) {
      this.formGroup = this._formBuilder.group({});
    }

    if (!this.formGroup.get('currentPassword') && this.current) {
      this.formGroup.setControl('currentPassword', this._formBuilder.control(undefined, [ Validators.required ]));
    }

    if (!this.formGroup.get('passwordConfirm') && this.repeat) {
      this.formGroup.setControl(
        'passwordConfirm',
        this._formBuilder.control(undefined, [ Validators.required, confirmPasswordValidator ])
      );
    }
  }

  ngAfterViewInit (): void {
    const initialPasswordVal = this.formGroup?.get('password')?.value;

    this.formGroup.setControl('password', this.passwordComponentWithValidation.passwordFormControl);

    if (initialPasswordVal) {
      this.formGroup.get('password').setValue(initialPasswordVal);
    }

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    if (this.repeat) {
      this.formGroup
        .get('password')
        .valueChanges.pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this.formGroup.get('passwordConfirm').updateValueAndValidity();
        });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
