import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { InputNumber } from 'primeng/inputnumber';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppLocalizationService } from '../../../../app-localization.service';


@Component({
  selector: 'chillz-number-input',
  templateUrl: './chillz-number-input.component.html',
  styleUrls: [ './chillz-number-input.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ChillzNumberInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: AbstractControl;
  @Input() label: { [lang: string]: string };
  @Input() id: string;
  @Input() mode: string;
  @Input() currency: string;
  @Input() placeholder: string;
  @Input() suffix: string;
  @Input() prefix: string;
  @Input() showButtons: boolean;
  @Input() showClear: boolean;
  @Input() allowEmpty: boolean;
  @Input() min: number;
  @Input() max: number;
  @Input() maxFractionDigits: number;
  @Input() minFractionDigits: number;
  @Input() step: number;
  @Input() incrementButtonIcon: number;
  @Input() isZeroEmpty = true;

  @Input() icon: string;
  @Input() isIconSymbol: boolean;

  pulsing;
  prevVal;

  // Private
  private _unsubscribeAll = new Subject<any>();
  @ViewChild('input') input: InputNumber;

  constructor (public localizationService: AppLocalizationService) {}

  ngOnInit (): void {}

  valueChanged (value): void {
    if (value === null) {
      this.prevVal = null;
      return;
    }

    if (value === 0 && this.prevVal !== 0 && this.isZeroEmpty && this.allowEmpty) {
      this.prevVal = null;

      // this.input.value = null;
      // this.input.clear();
      // this.input.input.nativeElement
      if (this.control) {
        this.control.reset(null, { emitEvent: false });
      }

      this.pulsing = false;
    } else {
      this.prevVal = value;
    }
  }

  ngAfterViewInit (): void {
    this.input.onInput.pipe(takeUntil(this._unsubscribeAll)).subscribe((event) => {
      console.log(event);
      if (!event.value) {
        this.pulsing = false;
        return;
      }

      if (event.originalEvent instanceof MouseEvent) {
        this.input.input.nativeElement.blur();
      }

      this.pulsing = true;
      setTimeout(() => {
        if (event.value === this.input.value || event.originalEvent instanceof KeyboardEvent) {
          this.pulsing = false;
        }
      }, 300);
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
