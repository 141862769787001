import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NguCarouselModule } from '@ngu/carousel';
import { TranslateModule } from '@ngx-translate/core';

import { FuseCountdownModule } from '../../../../@fuse/components';
import { FuseDirectivesModule } from '../../../../@fuse/directives/directives';
import { FusePipesModule } from '../../../../@fuse/pipes/pipes.module';
import { ChillzDirectivesModule } from '../../directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../directives/gsap/gsap-directives.module';
import { BundleOfferSummaryModule } from '../bundle-offer-summary/bundle-offer-summary.module';
import { ChillzBaseButtonModule } from '../chillz-base-button/chillz-base-button.module';
import { ChillzButtonModule } from '../chillz-button/chillz-button.module';
import { ChillzFormElementsModule } from '../chillz-form-elements/chillz-form-elements.module';
import { ChillzDialogsModule } from '../dialogs/chillz-dialogs.module';
import { OrderPreparationComponent } from './order-preparation.component';
import { SelectItemsComponent } from './select-items/select-items.component';


@NgModule({
  declarations: [ OrderPreparationComponent, SelectItemsComponent ],
  imports: [
    CommonModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChillzDialogsModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FlexLayoutModule,
    TranslateModule,
    FuseDirectivesModule,
    FusePipesModule,
    MatTooltipModule,
    NguCarouselModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FuseCountdownModule,
    BundleOfferSummaryModule,
    ChillzFormElementsModule,
    ChillzButtonModule,
    ChillzDirectivesModule,
    GSAPDirectivesModule,
    ChillzBaseButtonModule,
  ],
  exports: [ OrderPreparationComponent, SelectItemsComponent ],
})
export class OrderPreparationModule {}
