import { Platform } from '../platform.model';
import { ColorStyle } from './color-style.model';
import { StyleProperty } from './style-property.model';


export class TextStyle {
  fontSize?: StyleProperty;
  fontFamily?: string;
  fontWeight?: StyleProperty;
  color?: ColorStyle;
  textTransform?: string;
  textAlign?: string;
  lineHeight?: StyleProperty;
  letterSpacing?: StyleProperty;

  constructor (style: any = {}, defaultUnit = 'em', defaultColor?: 'related-accent' | 'host-accent' | string) {
    this.fontSize = new StyleProperty(undefined, defaultUnit);
    this.color = new ColorStyle(undefined, defaultColor);
    this.fontWeight = new StyleProperty(undefined, undefined, {
      min: 1,
      max: 9,
      step: 1,
      maxFractionDigits: 0,
      inputDisplayTransform: 0.01,
    });
    this.lineHeight = new StyleProperty(1, undefined, { min: 0, max: 5, step: 0.1, maxFractionDigits: 1 });
    this.letterSpacing = new StyleProperty(undefined, defaultUnit, { min: 0, max: 5, step: 0.1, maxFractionDigits: 1 });
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    if (value.fontSize !== undefined) {
      this.fontSize.setValue(value.fontSize || 'inherit');
    }

    if (value.fontFamily !== undefined) {
      this.fontFamily = value.fontFamily;
    }

    if (value.textTransform !== undefined) {
      this.textTransform = value.textTransform;
    }

    if (value.textAlign !== undefined) {
      this.textAlign = value.textAlign;
    }

    this.fontFamily = value.fontFamily;

    if (value.fontWeight !== undefined) {
      this.fontWeight.setValue(value.fontWeight);
    }

    if (value.lineHeight !== undefined) {
      this.lineHeight.setValue(value.lineHeight);
    }

    if (value.letterSpacing !== undefined) {
      this.letterSpacing.setValue(value.letterSpacing);
    }

    if (value.color !== undefined) {
      this.color.setValue(value.color);
    }
  }

  getFontFamily (platform?: Platform): string {
    // if (!this.fontFamily) {
    //   return;
    // }
    const fonts = [ this.fontFamily, ...platform?.uiDefaultFonts?.map((x) => x.font) ].filter((x) => !!x);
    if (!fonts?.length) {
      return;
    }

    return fonts.map((x) => x.trim()).join(', ');
  }

  toJson (): any {
    return {
      fontSize: this.fontSize?.toString(),
      fontFamily: this.fontFamily,
      fontWeight: this.fontWeight?.toString(),
      letterSpacing: this.letterSpacing?.toString(),
      color: this.color?.toString(),
      textAlign: this.textAlign,
      textTransform: this.textTransform,
      lineHeight: this.lineHeight?.toString(),
    };
  }
}
