import { Entity } from '../../main/entity/entity.model';
import { GeneralProduct } from './general-product.model';
import { PromoCode } from './promo-code.model';
import { TicketType } from './ticket-type.model';


const DEFAULT_GRADIENTS = [
  [ '#fab10f', '#dea023' ],
  [ '#a2a2a2', '#c0bebe' ],
  [ '#a1844c', '#88621a' ],
];

export class BundleOffer {
  _id: string;
  title: {
    en: string;
    he: string;
  };

  description: {
    en: string;
    he: string;
  };
  picture: any;
  applicableTo: {
    venues?: Entity[];
    ticketTypes?: TicketType[];
    generalProducts?: GeneralProduct[];
  };
  tiers: {
    title: {
      en: string;
      he: string;
    };

    summary: {
      en: string;
      he: string;
    };
    gradient: string[];
    conditions: {
      orderTotal: number;
      itemsCount: number;
    };
    benefits: {
      autoAppliedPromoCodes: PromoCode[];
      attachedProducts: { product: GeneralProduct; quantity: number }[];
      attachedTickets: { ticketType: TicketType; quantity: number }[];
    };
  }[];

  mergedBuyerDetails: boolean;

  constructor (offer: any = {}) {
    this._id = offer._id;
    this.title = offer.title || {};
    this.description = offer.description;
    this.tiers = offer.tiers;

    this.applicableTo = offer.applicableTo;

    this.picture = offer.picture;

    this.mergedBuyerDetails = offer.mergedBuyerDetails;

    if (this.mergedBuyerDetails === undefined) {
      this.mergedBuyerDetails = true;
    }

    if (offer.tiers?.length) {
      this.tiers = offer.tiers.map((x, index) => ({
        title: x.title,
        summary: x.summary,
        gradient: x.gradient || DEFAULT_GRADIENTS[index],
        conditions: x.conditions,
        benefits: {
          autoAppliedPromoCodes: x.benefits.autoAppliedPromoCodes?.map((p) => new PromoCode(p)),
          attachedProducts: x.benefits.attachedProducts?.map((p) => ({
            quantity: p.quantity,
            product: new GeneralProduct(p.product),
          })),
          attachedTickets: x.benefits.attachedTickets?.map((p) => ({
            quantity: p.quantity,
            ticketType: new TicketType(p.ticketType),
          })),
        },
      }));
    }
  }
}
