import { IEnvironment, ILocalization } from './environment.interface';


export const environment: IEnvironment = {
  production: false,
  staging: false,
  ssr: process.env.SSR !== undefined ? process.env.SSR === 'true' : false,
  serverUrl: 'https://b2c-api.dev.chillz.com',
  localization: process.env.LOCALIZATION as unknown as ILocalization,
  buildVersion: process.env.BUILD_VERSION,
  recaptchaSiteKey: null,
  api: {
    properties: 'https://props.api.dev.chillz.io',
    files: 'https://api.dev.chillz.io/files',
    cities: 'https://api.dev.chillz.io/cities',
    idp: 'https://api.dev.chillz.io/idp',
    cms: 'https://api.dev.chillz.io/cms',
  },
  platform: '61c1a74f2a951a7fa655b86d',
};
