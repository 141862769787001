import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { CoreAnimationDirective } from './core-animation.directive';


@Directive({
  selector: '[hopInAnimation]',
})
export class HopInAnimationDirective extends CoreAnimationDirective implements OnInit {
  constructor (protected element: ElementRef) {
    super(element);
  }

  @Input('hopInDirection') direction: 'up' | 'down' | 'right' | 'left' = 'up';
  @Input() elasticMax = 1.2;
  @Input() elasticMin = 0.3;

  ngOnInit (): void {
    // perform animation
    this.animateIn();
  }

  protected animateIn (): void {
    let transform;
    if (this.direction === 'up') {
      transform = { y: '200%' };
    }

    if (this.direction === 'down') {
      transform = { y: '-200%' };
    }

    if (this.direction === 'left') {
      transform = { x: '200%' };
    }

    if (this.direction === 'right') {
      transform = { x: '-200%' };
    }

    this.timeline.from(
      this.element.nativeElement,
      {
        duration: this.duration,
        ...transform,
        opacity: 0,
        ease: `elastic.out(${this.elasticMax}, ${this.elasticMin})`,
      },
      this.delay
    );
    super.animateIn();
  }
}
