import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'formattedDate',
})
export class FormattedDatePipe implements PipeTransform {
  transform (value: { year: number; month: number; day: number }, locale = 'en'): string {
    if (!value || !value.year || !value.month || !value.day) {
      return '';
    }

    const date = new Date(value.year, value.month - 1, value.day);

    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);

    return `${formattedDate}`;
  }
}
