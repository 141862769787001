import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TicketType } from '../../shared/models/ticket-type.model';
import { Entity } from './entity.model';


@Injectable({
  providedIn: 'root',
})
export class TicketTypesService {
  currentEntity: Entity;

  ticketTypes: TicketType[] = [];
  onTicketTypesChanged = new BehaviorSubject<TicketType[]>([]);

  onPromoCodeAllowedChanged = new BehaviorSubject<any>(false);
  onSellerDetailsChanged = new BehaviorSubject<any>(undefined);
  onContactInfoChanged = new BehaviorSubject<any>(undefined);

  constructor (private _httpClient: HttpClient) {}

  /**
   * Get ticketTypes
   */
  getTicketTypes (venue?: Entity, coupon?: string, emit = true, viewAs?): Promise<any> {
    return new Promise((resolve, reject) => {
      venue = venue || this.currentEntity;

      const params = {};
      if (coupon) {
        params['couponCode'] = coupon;
      }

      if (viewAs) {
        params['viewAs'] = viewAs;
      }

      this._httpClient.get('/venues/' + venue.id + '/ticketTypes', { params }).subscribe((response: any) => {
        const ticketTypes = response.ticketTypes
          .map((x) => new TicketType({ ...x, venue }))
          .filter((v, i, a) => a.findIndex((x) => x._id === v._id) === i);

        if (!emit) {
          return resolve({
            ticketTypes,
            promoCodeAllowed: response.promoCodeAllowed,
            contactInfo: response.contactInfo,
            sellerDetails: response.sellerDetails,
          });
        }

        this.ticketTypes = [ ...ticketTypes ];

        this.onTicketTypesChanged.next(this.ticketTypes);
        this.onPromoCodeAllowedChanged.next(response.promoCodeAllowed);
        this.onSellerDetailsChanged.next(response.sellerDetails);
        this.onContactInfoChanged.next(response.contactInfo);

        resolve(ticketTypes);
      }, reject);
    });
  }
}
