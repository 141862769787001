import { BehaviorSubject } from 'rxjs';

import { Entity } from '../../main/entity/entity.model';
import { ExploreEngagementAction } from './explore-engagement-action.model';
import { GeneralProduct } from './general-product.model';
import { OrderItem } from './order-item.model';
import { ChillzPicture } from './picture.model';
import { ItemCTAButton, ItemStyle } from './style-components/collection-item-style.model';
import { ItemsCollectionStyle } from './style-components/collection-style.model';
import { TicketType } from './ticket-type.model';
import { ChillzVideo } from './video.model';


export class ExploreItem {
  _id: string;
  template: string;
  title: { [lang: string]: string };
  subTitle: { [lang: string]: string };

  additionalInfo: {
    key?: { [lang: string]: string };
    value: { [lang: string]: string };
  }[];

  quickInfo: {
    text?: { [lang: string]: string };
    direction?: 'ltr' | 'rtl';
    icon?: string;
    iconClass?: string;
    class?: string;
    svgIcon?: string;
    onClick?: () => void;
  }[];

  badge: { [lang: string]: string };

  entity: Entity;
  backgroundPicture: ChillzPicture;
  foregroundPicture: ChillzPicture;

  video?: ChillzVideo;

  private _action?: ExploreEngagementAction;

  // action?: ExploreEngagementAction;
  isLocked: boolean;

  quickOrder: {
    isAllowed: boolean;
    ticketTypes?: any[];
    itemsObserver?: BehaviorSubject<OrderItem[]>;
    singleSelect?: {
      ticketType?: TicketType;
      generalProduct?: GeneralProduct;
      quantity?: number;
    };
  };

  CTAButtons: ItemCTAButton[];

  style?: ItemStyle;
  hostAccent: string;

  constructor (item: any = {}) {
    this.template = item['template'];
    this.title = item['title'];
    this.subTitle = item['subTitle'];
    this.badge = item['badge'];
    if (item['entity'] || item['venue']) {
      this.entity = new Entity(item['entity'] || item['venue']);
    }

    this._id = item['_id'] || this.entity?._id;

    this.isLocked = item['isLocked'] || false;

    if (item.foregroundPicture) {
      this.foregroundPicture = new ChillzPicture(item.foregroundPicture);
    }

    if (!this.foregroundPicture && item.picture) {
      this.foregroundPicture = new ChillzPicture(item.picture);
    }

    if (!this.foregroundPicture) {
      this.foregroundPicture =
        this.entity?.getPreviewPicture() || this.entity?.getHeroPicture() || this.entity?.inlinePicture;
    }

    if (item.backgroundPicture) {
      this.backgroundPicture = new ChillzPicture(item.backgroundPicture);
    }

    if (!this.backgroundPicture) {
      this.backgroundPicture = this.foregroundPicture || this.entity?.coverPicture;
    }

    this.video = item.video;

    this.quickOrder = {
      isAllowed: item.quickOrder?.isAllowed,
      ticketTypes: item.quickOrder?.ticketTypes?.map((x) => new TicketType(x)),
      // singleSelect: {
      //   generalProduct: item.quickOrder?.singleSelect?.generalProduct,
      //   ticketType: item.quickOrder?.singleSelect?.ticketType
      // }
    };

    // if (this.quickOrder.isAllowed === undefined) {
    //   this.quickOrder.isAllowed = this.entity?.sellingTickets && (
    //     item.action?.target === 'quick-order'
    //     // || !!this.CTAs?.find(x => x.action?.target === 'quick-order')
    //   );
    // }

    this._action = new ExploreEngagementAction(item.action || item.link);

    this.hostAccent = item.hostAccent;

    if (
      item.quickOrder?.singleSelect &&
      (item.quickOrder.singleSelect.ticketType || item.quickOrder.singleSelect.generalProduct)
    ) {
      this.quickOrder.singleSelect = {
        quantity: item.quickOrder?.singleSelect.quantity,
      };

      if (item.quickOrder.singleSelect.ticketType) {
        this.quickOrder.singleSelect.ticketType = new TicketType(item.quickOrder.singleSelect.ticketType);
      }

      if (item.quickOrder.singleSelect.generalProduct) {
        this.quickOrder.singleSelect.generalProduct = new GeneralProduct(item.quickOrder.singleSelect.generalProduct);
      }
    }

    this.additionalInfo = item.additionalInfo;

    this.quickInfo = item.quickInfo;
  }

  get picture (): ChillzPicture {
    return this.foregroundPicture || this.backgroundPicture;
  }

  get action (): ExploreEngagementAction {
    let ac = this._action.getForItem(this);
    if (ac.invalid) {
      ac = this.style.action.getForItem(this);
    }

    if (ac.invalid) {
      return null;
    }

    return ac;
  }

  set action (action: any) {
    this._action = new ExploreEngagementAction(action);
  }

  setStyle (style: ItemStyle): void {
    this.style = style;

    this.CTAButtons =
      this.style?.overlay?.CTA?.buttons
        ?.map((button) => {
          const resButton = new ItemCTAButton(button.toJson());
          const action = resButton.action.getForItem(this);
          if (action.invalid) {
            return null;
          }

          resButton.action = new ExploreEngagementAction(action);
          return resButton;
        })
        .filter((x) => !!x) || [];
  }
}

export class Relationship extends ExploreItem {
  customFields: {
    id: string;
    value: { [lang: string]: string } | string;
    fieldType: 'input' | 'textArea' | 'dropdown' | 'date' | 'time' | 'checkbox';
    dropdownOptions: [
      {
        id: string;
        name: { [lang: string]: string };
      }
    ];
    title: { [lang: string]: string };
  }[];

  constructor (relationship: any = {}) {
    super(relationship);

    this.customFields = relationship.customFields;
    this.title = this.entity?.title;

    if (relationship.style) {
      this.setStyle(relationship.style);
    } else {
      this.setQuickInfo();
      this.setInfo();
    }
  }

  setInfo (): void {
    // if (this.entity?.startsAt?.formatted && this.style?.overlay?.time) {
    //   this.subTitle = this.entity?.startsAt?.formatted;
    // }

    this.additionalInfo = [];
    // if (this.entity?.locationDetails?.formatted && this.style?.overlay?.location) {
    //   this.additionalInfo.push({value: {en: this.entity?.locationDetails?.formatted}});
    // }

    if (this.customFields) {
      this.additionalInfo.push(...this.customFields.map((cf) => ({ key: cf.title, value: cf.value as { [lang: string]: string } })));
    }
  }

  setQuickInfo (): void {
    this.quickInfo = [];
    if (this.style?.overlay?.minAge && this.entity?.minAge) {
      this.quickInfo.push({
        text: {
          en: `${this.entity?.minAge}+`,
        },
        direction: 'ltr',
      });
    }

    if (this.style?.overlay?.urls) {
      if (this.entity.urls?.instagram) {
        this.quickInfo.push({
          svgIcon: 'instagram',
          iconClass: 's-18',
          onClick: () => {
            window.open(this.entity.urls?.instagram, '_blank');
          },
        });
      }

      if (this.entity.urls?.facebook) {
        this.quickInfo.push({
          svgIcon: 'facebook',
          onClick: () => {
            window.open(this.entity.urls?.facebook, '_blank');
          },
        });
      }
    }
  }

  setStyle (style: ItemStyle): void {
    super.setStyle(style);
    this.setQuickInfo();
    this.setInfo();

    this.video = undefined;

    if (this.style?.background?.mediaOverlay.value === 'video' && this.entity?.video) {
      this.video = this.entity?.video;
    }
  }
}

export class EntitiesRelationshipType {
  style: ItemsCollectionStyle;
  title: { [lang: string]: string };
  customFields: {
    id: string;
    dropdownOptions: [
      {
        id: string;
        name: { [lang: string]: string };
      }
    ];
  }[];

  constructor (type: any = {}) {
    if (type.style) {
      this.style = new ItemsCollectionStyle(type.style);
    }

    this.title = type.title;
    this.customFields = type.customFields;
  }
}

export class EventGuest extends ExploreItem {
  _id: string;
  platform: 'instagram' | 'facebook';
  verified: boolean;
  username: string;
  id: string;
  url: string;
  fullName: string;
  biography: string;
  private: number;
  followersCount: number;
  followsCount: number;
  postsCount: number;
  profilePicture: ChillzPicture;
  coverPicture: ChillzPicture;

  constructor (profile: any = {}) {
    super(profile);

    this._id = this._id || profile._id;
    this.platform = profile.platform;
    this.verified = profile.verified;
    this.username = profile.username;
    this.id = profile.id;
    this.url = profile.url;
    this.fullName = profile.fullName;
    this.biography = profile.biography;
    this.private = profile.private;
    this.followersCount = profile.followersCount;
    this.followsCount = profile.followsCount;
    this.postsCount = profile.postsCount;

    if (profile.profilePicture) {
      this.profilePicture = new ChillzPicture(profile.profilePicture);
    }

    if (profile.coverPicture) {
      this.coverPicture = new ChillzPicture(profile.coverPicture);
    }

    if (this.fullName) {
      this.title = {
        en: this.fullName,
      };
    }

    if (this.username) {
      this.subTitle = {
        en: this.username,
      };
    }

    this.backgroundPicture = this.profilePicture || this.coverPicture;

    if (this.url) {
      this.action = {
        target: 'url',
        newWindow: true,
        url: this.url,
      };
    }
  }

  setQuickInfo (): void {
    this.quickInfo = [];

    if (this.url) {
      this.quickInfo.push({
        svgIcon: this.platform,
        iconClass: 's-18',
        onClick: () => {
          window.open(this.url, '_blank');
        },
      });
    }
  }

  setStyle (style: ItemStyle): void {
    super.setStyle(style);
    this.setQuickInfo();
  }
}
