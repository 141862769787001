import * as _ from 'lodash';

import { ExploreItemsGroup } from '../../main/explore/explore.service';
import { BundleOffer } from './bundle-offer.model';
import { ExploreEngagementAction } from './explore-engagement-action.model';
import { ExploreItem } from './explore-item.model';
import { ExplorePageSettings } from './explore-page-settings.model';
import { ItemsCollectionStyle } from './style-components/collection-style.model';


export class ExploreTheme {
  _id: string;
  id: string;
  title: { [lang: string]: string };
  description: { [lang: string]: string };

  style: ItemsCollectionStyle;
  inlineSectionSettings: {
    title: { enabled: boolean };
    CTA: ExploreEngagementAction;
    shuffleItems: boolean;
    itemsLimit: number;
    style: 'carousel' | 'grid';
    headerBackground: string;
  };
  explorePageSettings: ExplorePageSettings;

  items: ExploreItem[];
  itemsCount: number;
  isPlaceholder: boolean;
  bundleOffer: BundleOffer;
  quickOrder: {
    isAllowed: boolean;
  };
  grouped: ExploreItemsGroup[];

  constructor (theme, isPlaceholder = false) {
    this._id = theme['_id'];
    this.id = theme['id'];
    this.title = _.omitBy(theme['title'], (x) => !x || x?.toString()?.trim() === '');
    this.description = _.omitBy(theme['description'], (x) => !x || x?.toString()?.trim() === '');

    if (theme.style) {
      this.style = new ItemsCollectionStyle(theme.style);
    }

    this.items = theme.items?.map((item) => new ExploreItem(item));
    if (this.style) {
      this.items.forEach((x) => x.setStyle(this.style.item));
    }

    this.itemsCount = theme['itemsCount'] || this.items?.length || 0;

    this.inlineSectionSettings = {
      title: { enabled: theme.inlineSectionSettings?.title?.enabled },
      CTA: new ExploreEngagementAction(theme.inlineSectionSettings?.CTA),
      style: theme.inlineSectionSettings?.style || 'carousel',
      shuffleItems: theme.inlineSectionSettings?.shuffleItems,
      itemsLimit: theme.inlineSectionSettings?.itemsLimit,
      headerBackground: theme.inlineSectionSettings?.headerBackground,
    };

    this.explorePageSettings = theme.explorePageSettings
      ? new ExplorePageSettings(theme.explorePageSettings)
      : undefined;

    this.quickOrder = theme.quickOrder || { isAllowed: false };

    this.isPlaceholder = isPlaceholder;

    if (theme.bundleOffer) {
      this.bundleOffer = new BundleOffer(theme.bundleOffer);
    }

    this.grouped = theme.grouped;
    if (this.grouped) {
      this.grouped.forEach((group) => {
        group.items = group.items.map((x) => new ExploreItem(x));
        if (this.style) {
          group.items.forEach((x) => x.setStyle(this.style.item));
        }
      });
    }

    this.isPlaceholder = isPlaceholder;
  }
}
