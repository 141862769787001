import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { v4 as uuidv4 } from 'uuid';

import { TransferStateHelper } from '../../@fuse/services/transfer-state-helper';
import { OrderItem } from './models/order-item.model';
import { UserInteractionEventType } from './models/user-interaction-event-type.model';
import { SafePipe } from './pipes/safe.pipe';


@Injectable({ providedIn: 'root' })
export class TrackingService {
  private renderer2: Renderer2;
  private isBrowser: boolean;

  addedIntegrations: {
    facebookPixel?: { id: string; headElements: any[]; removed?: boolean }[];
    GA?: { id: string; headElements: any[]; removed?: boolean }[];
    GTM?: { id: string; headElements: any[]; bodyElements: any[]; removed?: boolean }[];
    Gtag?: { id: string; headElements: any[]; removed?: boolean }[];
    snapchat?: { id: string; headElements: any[]; removed?: boolean }[];
    tiktok?: { id: string; headElements: any[]; removed?: boolean }[];
  } = {
      facebookPixel: [],
      GA: [],
      GTM: [],
      Gtag: [],
      snapchat: [],
      tiktok: [],
    };

  eventTypes: UserInteractionEventType[];

  constructor (
    private _httpClient: HttpClient,
    private _route: ActivatedRoute,
    private _platform: Platform,
    private _safePipe: SafePipe,
    @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) platformId,
    rendererFactory: RendererFactory2,
    private _transferStateHelper: TransferStateHelper,
    private _cookieService: CookieService
  ) {
    // Set the defaults
    this.renderer2 = rendererFactory.createRenderer(null, null);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /**
   * Resolver
   *
   */
  resolve (): Promise<boolean> {
    return new Promise((resolve) => {
      Promise.all([ this.getUserInteractionEventTypes() ])
        .then(() => {
          resolve(false);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  init (): void {}

  /**
   * Get User Interaction Types
   *
   */
  getUserInteractionEventTypes (): Promise<UserInteractionEventType[]> {
    return new Promise((resolve, reject) => {
      this._transferStateHelper.wrapHttpGet('/options/userInteractionEventTypes').subscribe((response: any) => {
        this.eventTypes = response.userInteractionEventTypes?.map((x) => new UserInteractionEventType(x));

        resolve(this.eventTypes);
      }, reject);
    });
  }

  addPixel (id: string): void {
    if (this.addedIntegrations.facebookPixel.find((x) => x.id === id && !x.removed)) {
      return;
    }

    const chillzPixel = this.renderer2.createElement('script');
    chillzPixel.text = `fbq('init', '${id}');`;

    this.renderer2.appendChild(this._document.head, chillzPixel);

    this.addedIntegrations.facebookPixel.push({
      id,
      headElements: [ chillzPixel ],
    });
  }

  addGA (id: string): void {
    const ga = this.renderer2.createElement('script');
    ga.text = `ga('create', '${id}', 'auto');`;
    this.renderer2.appendChild(this._document.head, ga);

    this.addedIntegrations.GA.push({
      id,
      headElements: [ ga ],
    });
  }

  addGtag (id: string): void {
    const gtag1 = this.renderer2.createElement('script');
    gtag1.async = true;
    gtag1.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

    this.renderer2.appendChild(this._document.head, gtag1);

    const gtag2 = this.renderer2.createElement('script');
    gtag2.text = `
            window.dataLayer = window.dataLayer || [];

            function gtag() {
              dataLayer.push(arguments);
            }

            gtag('js', new Date());
            gtag('config', '${id}');`;

    this.renderer2.appendChild(this._document.head, gtag2);

    this.addedIntegrations.Gtag.push({
      id,
      headElements: [ gtag1, gtag2 ],
    });
  }

  addGTM (id: string): void {
    const gtmScript = this.renderer2.createElement('script');
    gtmScript.text = `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${id}');
                  `;

    this.renderer2.appendChild(this._document.head, gtmScript);

    const gtmNoScript = this.renderer2.createElement('noscript');
    const iframe = this.renderer2.createElement('iframe');
    this.renderer2.setAttribute(iframe, 'src', `https://www.googletagmanager.com/ns.html?id=${id}`);
    this.renderer2.setAttribute(iframe, 'height', '0');
    this.renderer2.setAttribute(iframe, 'width', '0');
    this.renderer2.setStyle(iframe, 'display', 'none');
    this.renderer2.setStyle(iframe, 'visibility', 'hidden');

    this.renderer2.appendChild(gtmNoScript, iframe);

    this.renderer2.appendChild(this._document.body, gtmNoScript);

    this.addedIntegrations.GTM.push({
      id,
      headElements: [ gtmScript ],
      bodyElements: [ gtmNoScript ],
    });
  }

  addSnapchat (id: string): void {
    const snapchatScript = this.renderer2.createElement('script');
    snapchatScript.type = 'text/javascript';
    snapchatScript.text = `
              (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                  {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                  a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                  r.src=n;var u=t.getElementsByTagName(s)[0];
                  u.parentNode.insertBefore(r,u);})(window,document,
                  'https://sc-static.net/scevent.min.js');

                  snaptr('init', '${id}');

                  snaptr('track', 'PAGE_VIEW');
                  `;

    this.renderer2.appendChild(this._document.head, snapchatScript);

    this.addedIntegrations.snapchat.push({
      id,
      headElements: [ snapchatScript ],
    });
  }

  addTiktok (id: string): void {
    const tiktokScript = this.renderer2.createElement('script');
    tiktokScript.type = 'text/javascript';
    tiktokScript.text = `
                    !function (w, d, t) {
                      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

                      ttq.load('${id}');
                      ttq.page();
                    }(window, document, 'ttq');
                  `;

    this.renderer2.appendChild(this._document.head, tiktokScript);

    this.addedIntegrations.tiktok.push({
      id,
      headElements: [ tiktokScript ],
    });
  }

  removeIntegrations (type: string, ids: string[]): void {
    if (this.addedIntegrations[type]) {
      for (const integration of this.addedIntegrations[type].filter((x) => ids.includes(x.id))) {
        for (const el of integration.headElements || []) {
          this.renderer2.removeChild(this._document.head, el);
        }
        for (const el of integration.bodyElements || []) {
          this.renderer2.removeChild(this._document.body, el);
        }
        integration.removed = true;
      }
    }
  }

  triggerEvent (
    event: string,
    properties: {
      value?: number;
      currency?: string;
      transaction_id?: string;
      items?: { item?: OrderItem; quantity?: number; discount?: number }[];
    } = {},
    eventId?: string
  ): void {
    const eventType = this.eventTypes.find((x) => x.id === event);
    if (!eventType) {
      return;
    }

    if (!eventId) {
      eventId = uuidv4();
    }

    if (eventType.facebookPixel) {
      const pixelProperties: any = {};
      if (properties) {
        if (properties.value) {
          pixelProperties.value = properties.value;
        }

        if (properties.currency) {
          pixelProperties.currency = properties.currency;
        }

        if (properties.items) {
          pixelProperties.contents = properties.items.map((x) => ({
            id: x.item?._id,
            quantity: x.quantity,
          }));
        }
      }

      for (const p of this.addedIntegrations.facebookPixel?.filter((x) => !x.removed)) {
        const pixelElement = this.renderer2.createElement('script');
        pixelElement.text = `
           fbq('trackSingle', '${p.id}', '${eventType.facebookPixel}', ${JSON.stringify(pixelProperties)}, {eventID: '${eventId}'});
        `;
        this.renderer2.appendChild(this._document.head, pixelElement);
      }
    }

    if (eventType.GTM) {
      const ecommerceProperties: any = {};
      if (properties?.items) {
        ecommerceProperties.items = properties.items.map((x) => ({
          item_id: x.item?._id,
          item_name: `${x.item.venue?.id}_${x.item.venue?.titleEng
            ?.replace(new RegExp('\\s+', 'g'), '-')
            .replace(new RegExp('[^\\w\\d-]+', 'g'), '')}_${(typeof x.item?.title === 'string'
            ? x.item?.title
            : x.item?.title?.en
          )
            ?.replace(new RegExp('\\s+', 'g'), '-')
            .replace(new RegExp('[^\\w\\d-]+', 'g'), '')}`,
          quantity: x.quantity,
          currency: x.item?.currency,
          discount: x.discount,
          price: x.item?.price,
        }));
      }

      if (properties.transaction_id) {
        ecommerceProperties.transaction_id = properties.transaction_id;
      }

      if (properties.value) {
        ecommerceProperties.value = properties.value;
      }

      if (properties.currency) {
        ecommerceProperties.currency = properties.currency;
      }

      if (this.addedIntegrations.GTM?.filter((x) => !x.removed)?.length) {
        const dataLayerEvent = {
          event: eventType.GTM,
          ecommerce: ecommerceProperties,
        };

        const gtmElement = this.renderer2.createElement('script');
        gtmElement.text = `
        dataLayer.push({ ecommerce: null });
        dataLayer.push(${JSON.stringify(dataLayerEvent)})
        `;

        this.renderer2.appendChild(this._document.head, gtmElement);
      }
    }

    if (eventType.snapchat) {
      const pixelProperties: any = {};
      if (properties) {
        if (properties.transaction_id) {
          pixelProperties.transaction_id = properties.transaction_id;
        }

        if (properties.value) {
          pixelProperties.price = properties.value;
        }

        if (properties.currency) {
          pixelProperties.currency = properties.currency;
        }
      }

      this.addedIntegrations.snapchat
        ?.filter((x) => !x.removed)
        .forEach(() => {
          const pixelElement = this.renderer2.createElement('script');
          pixelElement.text = `snaptr('track', '${eventType.snapchat}', ${JSON.stringify(pixelProperties)});`;
          this.renderer2.appendChild(this._document.head, pixelElement);
        });
    }

    if (eventType.tiktok) {
      const ecommerceProperties: any = {};
      if (properties?.items) {
        ecommerceProperties.contents = properties.items.map((x) => ({
          content_id: x.item?._id,
          content_name: `${x.item.venue?.id}_${x.item.venue?.titleEng
            ?.replace(new RegExp('\\s+', 'g'), '-')
            .replace(new RegExp('[^\\w\\d-]+', 'g'), '')}_${(typeof x.item?.title === 'string'
            ? x.item?.title
            : x.item?.title?.en
          )
            ?.replace(new RegExp('\\s+', 'g'), '-')
            .replace(new RegExp('[^\\w\\d-]+', 'g'), '')}`,
          quantity: x.quantity,
          currency: x.item?.currency,
          discount: x.discount,
          price: x.item?.price,
        }));
      }

      if (properties.value) {
        ecommerceProperties.value = properties.value;
      }

      if (properties.currency) {
        ecommerceProperties.currency = properties.currency;
      }

      this.addedIntegrations.tiktok
        ?.filter((x) => !x.removed)
        .forEach(() => {
          const pixelElement = this.renderer2.createElement('script');
          pixelElement.text = `ttq.track('${eventType.tiktok}', ${JSON.stringify(ecommerceProperties)});`;
          this.renderer2.appendChild(this._document.head, pixelElement);
        });
    }
  }

  getHubspotutk (): string {
    return this._cookieService.get('hubspotutk');
  }
}
