import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser, Location } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { LocalStorage } from '../../@fuse/services/storage/local-storage';
import { SessionStorage } from '../../@fuse/services/storage/session-storage';
import { SessionInfoService } from '../shared/session-info.service';
import { User } from './user.model';


@Injectable({ providedIn: 'root' })
export class ChillzAuthService implements Resolve<any> {
  queryParams: any;

  sessionInfo: any;

  loggedIn: boolean;

  onIsModeratorChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  onLoggedInChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

  user: User;

  onUserChanged: BehaviorSubject<User>;

  isBrowser: boolean;

  isModerator: boolean;

  /**
   * Constructor
   *
   */
  constructor (
    private _httpClient: HttpClient,
    private _sessionInfoService: SessionInfoService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _location: Location,
    private _platform: Platform,
    private _localStorage: LocalStorage,
    private _sessionStorage: SessionStorage,
    private jwtHelper: JwtHelperService,
    @Inject(PLATFORM_ID) platformId,
    private _deviceService: DeviceDetectorService
  ) {
    // Set the defaults
    this.onUserChanged = new BehaviorSubject(undefined);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   */
  resolve (route: ActivatedRouteSnapshot): Promise<boolean> {
    this.queryParams = route.queryParams;

    return new Promise((resolve, reject) => {
      Promise.all([ this.startSession() ]).then(() => {
        resolve(false);
      }, reject);
    });
  }

  /**
   * Log In
   *
   */
  logIn (email: string, password: string, source?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(
          '/users/logIn',
          {
            email,
            password,
            source,
            businessUser: false,
            metaData: this._sessionInfoService.getSessionInfo(),
          },
          { observe: 'response' }
        )
        .subscribe(
          (response: any) => {
            // localStorage.setItem('token', response.body.accessToken);
            this.setSession(
              response.body['_id'],
              response.headers.get('x-access-token'),
              response.headers.get('x-refresh-token')
            );
            this.loggedIn = true;
            this.onLoggedInChanged.next(this.loggedIn);
            resolve(this.getMyProfile());
            // resolve();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  isLoggedIn (thorough = false): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (!this._localStorage.getItem('x-access-token')) {
          this.loggedIn = false;
          this.onLoggedInChanged.next(this.loggedIn);
          return resolve(false);
        }

        if (!thorough) {
          if (!this.jwtHelper.isTokenExpired(this._localStorage.getItem('x-access-token'))) {
            this.loggedIn = true;
            this.onLoggedInChanged.next(this.loggedIn);
            return resolve(this.loggedIn);
          }
          // this.loggedIn = !this.jwtHelper.isTokenExpired(this.getAccessToken());
          // this.onLoggedInChanged.next(this.loggedIn);
          // return resolve(this.loggedIn);
        }

        this._httpClient
          .post('/users/self/verifyToken', {
            metaData: this._sessionInfoService.getSessionInfo(),
          })
          .subscribe(
            (response: any) => {
              if (!response['valid']) {
                this.loggedIn = false;
                this.onLoggedInChanged.next(this.loggedIn);
                if (this.getRefreshToken()) {
                  this.getNewAccessToken().subscribe(
                    () => {
                      this.loggedIn = true;
                      this.onLoggedInChanged.next(this.loggedIn);
                      this.getMyProfile();
                      resolve(true);
                    },
                    () => {
                      resolve(false);
                    }
                  );
                } else {
                  this.removeSession();
                  resolve(false);
                }
              } else {
                this.loggedIn = true;
                this.onLoggedInChanged.next(this.loggedIn);
                this.getMyProfile()
                  .then(() => {
                    this._sessionInfoService.updateSessionId(response.session);
                    resolve(true);
                  })
                  .catch(() => {
                    this.loggedIn = false;
                    resolve(false);
                  });
                resolve(true);
              }
            },
            (error) => {
              reject(error);
            }
          );
      } catch (e) {
        reject(e);
      }
    });
  }

  getMyProfile (): Promise<User> {
    return new Promise((resolve, reject) => {
      if (!this._platform.isBrowser) {
        return reject('You can not get profile on Server');
      }

      if (!this.loggedIn) {
        return reject('You can not get profile: User is not logged in');
      }

      this._httpClient.get('/users/self/profile').subscribe((response: any) => {
        this.user = new User(response.profile);
        this.user.isModerator = response.isModerator;
        this.isModerator = response.isModerator;
        this.onIsModeratorChanged.next(this.isModerator);

        this._sessionStorage.setItem('profilePicture', JSON.stringify(this.user.profilePicture));
        this.onUserChanged.next(this.user);

        resolve(this.user);
      }, reject);
    });
  }

  forgotPassword (email): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put('/users/self/password/forgot', {
          email,
          metaData: this._sessionInfoService.getSessionInfo(),
        })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updatePassword (password: string, token: string, currentPassword?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put(
          '/users/self/password',
          {
            ...(currentPassword ? { currentPassword } : {}),
            password,
            metaData: {
              device: this._deviceService.getDeviceInfo(),
            },
          },
          { headers: { 'x-access-token': token }, params: { token }, observe: 'response' }
        )
        .subscribe(
          (response: any) => {
            if (response.error) {
              return reject(response.error);
            }

            if (response.headers.get('x-refresh-token')) {
              localStorage.setItem('x-refresh-token', response.headers.get('x-refresh-token'));
            }

            if (response.body.user && this.user) {
              _.extend(this.user, response.body.user);
              this.onUserChanged.next(this.user);
            }

            resolve(false);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  logout (): void {
    this.loggedIn = false;
    this.onLoggedInChanged.next(this.loggedIn);

    this.user = undefined;
    this.removeSession();
    this.onUserChanged.next(this.user);

    if (this._location.path().includes('profile')) {
      this._router.navigate([ '/' ], { queryParamsHandling: 'merge' });
    }
  }

  getAccessToken (): string {
    return this._localStorage.getItem('x-access-token') || this._localStorage.getItem('gtoken');
  }

  setSession (userId: string, accessToken: string, refreshToken: string): void {
    this.loggedIn = true;
    this._localStorage.setItem('user-id', userId);
    this._localStorage.setItem('x-access-token', accessToken);
    this._localStorage.setItem('x-refresh-token', refreshToken);
  }

  getNewAccessToken (): Observable<any> {
    return this._httpClient
      .get('/users/self/accessToken', {
        headers: {
          'x-refresh-token': this.getRefreshToken(),
          '_id': this.getUserId(),
          'x-user-id': this.getUserId(),
        },
        observe: 'response',
      })
      .pipe(
        tap((res: HttpResponse<any>) => {
          this.setAccessToken(res.headers.get('x-access-token'));
          this.loggedIn = true;
          this.onLoggedInChanged.next(this.loggedIn);
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  private startSession (): Promise<any> {
    return new Promise((resolve) => {
      if (this.queryParams['redirect_status']) {
        if (this.queryParams['atoken']) {
          this.setAccessToken(this.queryParams['atoken']);
        } else if (this.queryParams['gtoken']) {
          this._localStorage.setItem('gtoken', this.queryParams['gtoken']);
        }
      }

      this.isLoggedIn(true).then((isLoggedIn) => {
        if (!isLoggedIn) {
          this.guestLogIn().then(resolve);
          return;
        }

        resolve(false);
      });
    });
  }

  /**
   * Guest Log In
   *
   */
  private guestLogIn (): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post('/users/guestLogin', {
          metaData: this._sessionInfoService.getSessionInfo(),
        })
        .subscribe(
          (response: any) => {
            this._localStorage.setItem('gtoken', response.accessToken);
            this._sessionInfoService.updateSessionId(response.session);
            resolve(false);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  private getRefreshToken (): string {
    return this._localStorage.getItem('x-refresh-token');
  }

  private getUserId (): string {
    return this._localStorage.getItem('user-id');
  }

  private setAccessToken (accessToken: string): void {
    this._localStorage.setItem('x-access-token', accessToken);
  }

  private removeSession (): void {
    this._sessionStorage.removeItem('profilePicture');
    this._localStorage.removeItem('user-id');
    this._localStorage.removeItem('x-access-token');
    this._localStorage.removeItem('x-refresh-token');
  }
}
