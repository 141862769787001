import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChillzDirectivesModule } from '../../directives/chillz-directives.module';
import { ChillzSkeletonBlockModule } from '../chillz-skeleton-block/chillz-skeleton-block.module';
import { ChillzThumbPlaceholderComponent } from './chillz-thumb-placeholder.component';


@NgModule({
  declarations: [ ChillzThumbPlaceholderComponent ],
  imports: [ CommonModule, ChillzSkeletonBlockModule, ChillzDirectivesModule ],
  exports: [ ChillzThumbPlaceholderComponent ],
})
export class ChillzThumbPlaceholderModule {}
