import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VideoPlayerComponent } from './video-player.component';


@NgModule({
  declarations: [ VideoPlayerComponent ],
  imports: [ CommonModule ],
  entryComponents: [],
  exports: [ VideoPlayerComponent ],
  providers: [],
})
export class VideoPlayerModule {}
