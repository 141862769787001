import * as _ from 'lodash';

import { Entity } from '../../../main/entity/entity.model';
import { EntityMenuItemStyle } from './button-style.model';
import { EntityMenuStyle } from './collection-style.model';
import { EntityPageLayoutStyle } from './entity-page-layout-style.model';
import { SelectableStyle } from './selectable-style.model';
import { TextStyle } from './text-style.model';


export class EntityPage {
  _id?: string;
  id?: string;
  title?: { [lang: string]: string };
  layout: EntityPageLayoutStyle;

  constructor (page: any = {}) {
    this._id = page._id;
    this.setValue(page);
  }

  setValue (value: any = {}): void {
    this.id = value.id;
    this.title = value.title;
    if (value.layout && typeof value.layout === 'object') {
      if (value.layout?._id === this.layout?._id) {
        this.layout.setValue(value.layout);
      } else {
        this.layout = new EntityPageLayoutStyle(value.layout);
      }
    }
  }

  toJson (): any {
    return {
      _id: this._id,
      id: this.id,
      title: this.title,
      layout: this.layout?.toJson(),
    };
  }
}

export class EntityLayout extends SelectableStyle {
  _id: string;
  entity: Entity;
  textStyles: {
    default: TextStyle;
    secondaryHeadline: TextStyle;
  };
  menu: EntityMenuLayout;
  expandableMenu: EntityMenuLayout;
  pages: EntityPage[];

  constructor (style: any = {}) {
    super(style);
    this._id = style._id;
    if (style.entity) {
      this.entity = new Entity(style.entity);
    }

    this.textStyles = {
      secondaryHeadline: new TextStyle(undefined, undefined),
      default: new TextStyle(undefined, undefined),
    };

    this.pages = [];

    this.menu = new EntityMenuLayout();
    this.expandableMenu = new EntityMenuLayout();

    this.setValue(style);
  }

  setValue (value: any = {}): void {
    if (value.textStyles?.default) {
      this.textStyles.default.setValue(value.textStyles.default);
    }

    if (value.textStyles?.secondaryHeadline) {
      this.textStyles.secondaryHeadline.setValue(value.textStyles.secondaryHeadline);
    }

    if (value.menu) {
      this.menu.setValue(value.menu);
    }

    if (value.expandableMenu) {
      this.expandableMenu.setValue(value.expandableMenu);
    }

    if (value.pages) {
      value.pages.forEach((page, index) => {
        const existing = this.pages?.find((p) => p._id === page._id && page._id);
        if (existing) {
          existing.setValue(page);
        }

        value.pages[index] = existing || new EntityPage(page);
      });
      if (!this.pages) {
        this.pages = [];
      }

      _.remove(this.pages, () => true);
      this.pages.push(...value.pages);
      // this.pages = value.pages.map(page => new EntityPage(page));
    }
  }

  toJson (excludeAttributions): any {
    return {
      ...super.toJson(excludeAttributions),
      _id: this._id,
      entity: this.entity?._id,
      textStyles: {
        default: this.textStyles?.default?.toJson(),
        secondaryHeadline: this.textStyles?.secondaryHeadline?.toJson(),
      },
      menu: this.menu?.toJson(),
      pages: this.pages?.map((p) => p.toJson()),
    };
  }

  getMenu (size = 'default'): EntityMenuLayoutInstance {
    return this.menu.forWidth(size);
  }

  getExpandableMenu (size = 'default'): EntityMenuLayoutInstance {
    return this.expandableMenu.forWidth(size);
  }
}

export class EntityMenuLayout {
  default: EntityMenuLayoutInstance;
  'gt-xs': EntityMenuLayoutInstance;
  'gt-sm': EntityMenuLayoutInstance;
  'gt-md': EntityMenuLayoutInstance;
  'gt-lg': EntityMenuLayoutInstance;
  'gt-xl': EntityMenuLayoutInstance;

  constructor (layout: any = {}) {
    this.setValue(layout);
  }

  forWidth (size = 'default'): EntityMenuLayoutInstance {
    let result;
    let fallback;
    if (size === 'gt-xl') {
      result = this['gt-xl'];
      fallback = !result;
    }

    if (fallback || size === 'gt-lg') {
      result = this['gt-lg'];
      fallback = !result;
    }

    if (fallback || size === 'gt-md') {
      result = this['gt-md'];
      fallback = !result;
    }

    if (fallback || size === 'gt-sm') {
      result = this['gt-sm'];
      fallback = !result;
    }

    if (fallback || size === 'gt-xs') {
      result = this['gt-xs'];
      fallback = !result;
    }

    if (fallback || size === 'default') {
      result = this['default'];
    }

    return result;
  }

  setValue (value: any = {}): void {
    for (const size of [ 'default', 'gt-xs', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl' ]) {
      if (value[size]) {
        if (!this[size]) {
          this[size] = new EntityMenuLayoutInstance();
        }

        (this[size] as EntityMenuLayoutInstance).setValue(value[size]);
      }
    }
  }

  toJson (): any {
    const res = {};
    for (const size of [ 'default', 'gt-xs', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg', 'gt-xl' ]) {
      if (this[size]) {
        res[size] = (this[size] as EntityMenuLayoutInstance).toJson();
      }
    }
    return res;
  }
}

export class EntityMenuItem {
  icon?: string;
  svgIcon?: string;
  url?: string;
  page?: string;
  picture?: {
    url: string;
    alt?: string;
    height?: string;
    width?: string;
  };
  label?: { [lang: string]: string };
  children: EntityMenuItem[];
  style: { default?: EntityMenuItemStyle; hover?: EntityMenuItemStyle };

  constructor (item: any = {}) {
    this.style = {};
    this.setValue(item);
  }

  setValue (value: any = {}): void {
    if (value.label) {
      this.label = value.label;
    }

    if (value.svgIcon) {
      this.svgIcon = value.svgIcon;
    }

    if (value.icon) {
      this.icon = value.icon;
    }

    if (value.url) {
      this.url = value.url;
    }

    if (value.picture) {
      this.picture = value.picture;
    }

    if (value.page || value.page === '') {
      this.page = value.page;
    }

    if (value.style?.default) {
      this.style.default = new EntityMenuItemStyle(value.style.default);
    }

    if (value.style?.hover) {
      this.style.hover = new EntityMenuItemStyle(value.style.hover);
    }

    if (value.children) {
      this.children = value.children.map((x) => new EntityMenuItem({ ...x, style: this.style }));
    }
  }

  toJson (): any {
    return {
      icon: this.icon,
      svgIcon: this.svgIcon,
      picture: this.picture,
      url: this.url,
      label: this.label,
      style: {
        hover: this.style?.hover?.toJson(),
        default: this?.style?.default?.toJson(),
      },
      children: this.children?.map((c) => c.toJson()),
    };
  }
}

export class EntityMenuLayoutInstance {
  style: EntityMenuStyle;
  items: EntityMenuItem[];

  constructor (value: any = {}) {
    this.style = new EntityMenuStyle();
    this.setValue(value);
  }

  setValue (value: any = {}): void {
    if (value.style) {
      this.style.setValue(value.style);
    }

    if (value.items) {
      this.items = value.items.map((x) => new EntityMenuItem({ ...x, style: this.style?.item }));
    }
  }

  toJson (): any {
    return {
      items: this.items?.map((x) => x.toJson()),
      style: this.style?.toJson(),
    };
  }
}
