import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SessionInfoService } from '../../session-info.service';


@Injectable()
export class VenueThumbService {
  constructor (
    private _httpClient: HttpClient,
    private _sessionInfoService: SessionInfoService,
    private _router: Router
  ) {}

  invokeActivity (id, activity, value?): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const val = value ? '?value=' + value : '';

      this._httpClient.post('/venues/' + id + '/' + activity + val, null).subscribe((response: any) => {
        resolve(response.status);
      }, reject);
    });
  }
}
