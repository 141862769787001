import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';


@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: [ './video-player.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() playsinline = true;
  @Input() muted = true;
  @Input() loop = true;
  @Input() fluid = false;
  @Input() controls = false;
  @Input() absolute = true;
  @Input() autoplay = true;
  @Input() sources: { src: string; type: string }[];

  @ViewChild('videoTarget', { static: false }) videoTarget: ElementRef;
  player: videojs.Player;

  constructor () {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        console.log(document.hidden);
        // tab is now inactive
      } else {
        this.start();
        // tab is active again
      }
    });
  }

  ngOnInit (): void {}

  ngAfterViewInit (): void {
    this.init();
  }

  init (): void {
    console.log('Init', this.videoTarget);

    if (this.videoTarget) {
      this.player = videojs(
        this.videoTarget.nativeElement,
        {
          playsinline: this.playsinline,
          muted: this.muted,
          fluid: this.fluid,
          controls: this.controls,
          loop: this.loop,
          sources: this.sources,
        },
        function onPlayerReady () {
          console.log('onPlayerReady', this);
        }
      );

      if (this.autoplay) {
        this.start();
      }
    }
  }

  start (): void {
    if (this.player) {
      this.player.ready(() => {
        this.player.currentTime(0);
        const promise = this.player.play();
        console.log('Player Ready');
        if (promise !== undefined) {
          promise
            .then(() => {
              console.log('Autoplay started!');
            })
            .catch((error) => {
              console.error('Autoplay was prevented');
              console.error(error);
            });
        }
      });
    }
  }

  pause (): void {
    if (this.player) {
      this.player.ready(() => {
        const promise = this.player.pause();
        if (promise !== undefined) {
          promise
            .then(() => {
              console.log('Video paused');
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    }
  }

  ngOnChanges (): void {
    this.init();
  }

  ngOnDestroy (): void {
    if (this.player) {
      this.player.dispose();
    }
  }
}
