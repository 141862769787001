import { Entity } from '../../main/entity/entity.model';


export class OrderItem {
  _id: string;
  price: number;
  currency: 'ILS' | 'USD';
  title:
    | {
      he: string;
      en: string;
    }
    | string;
  description: {
    he: string;
    en: string;
    isCollapsed: boolean;
  };
  descriptionExpanded: boolean;
  venue: Entity;
  picture: any;
  quantityOptions = [ 0 ];

  quantityLeft: number;
  attachedProducts: {
    product: OrderItem;
    productType: 'TicketType' | 'GeneralProduct';
    quantity: number;
    quantityMode: 'one-for-all' | 'one-per-instance';
    detachable: boolean;
  }[];
  userRequired = false;
  maxPerUser: number;
  maxPerPurchase: number;
  minPerPurchase: number;
  nondetachableProducts: { [lang: string]: string[] };

  maxQuantity;

  constructor (item: any = {}) {
    this._id = item._id;
    this.price = item.price;
    this.currency = item.currency;
    this.title = item.title || {};
    this.description = item.description || {};
    this.descriptionExpanded = this.description.isCollapsed === false;

    this.picture = item.picture;
    this.attachedProducts = item.attachedProducts?.filter((x) => !!x.product) || [];

    this.userRequired = item.userRequired;
    this.maxPerUser = item.maxPerUser;
    this.maxPerPurchase = item.maxPerPurchase || 9;
    this.minPerPurchase = item.minPerPurchase || 1;

    if (item.venue) {
      this.venue = item.venue instanceof Entity ? item.venue : new Entity(item.venue);
    }

    this.quantityLeft = item.quantityLeft;

    for (
      let i = this.minPerPurchase;
      i <= Math.min(this.maxPerPurchase, this.quantityLeft !== undefined ? this.quantityLeft : this.maxPerPurchase);
      i++
    ) {
      this.quantityOptions.push(i);
    }

    this.maxQuantity = Math.min(
      this.maxPerPurchase,
      this.quantityLeft !== undefined ? this.quantityLeft : this.maxPerPurchase
    );

    if (this.attachedProducts) {
      this.nondetachableProducts = {
        en: [],
        he: [],
      };
      this.attachedProducts.forEach((attachedProduct) => {
        if (!attachedProduct.detachable) {
          if (attachedProduct.productType === 'GeneralProduct') {
            this.price += attachedProduct.product.price * attachedProduct.quantity;
            this.nondetachableProducts.he.push(typeof attachedProduct.product.title !== 'string'
              ? attachedProduct.product.title.he
              : attachedProduct.product.title);
            this.nondetachableProducts.en.push(typeof attachedProduct.product.title !== 'string'
              ? attachedProduct.product.title.en
              : attachedProduct.product.title);
          } else if (attachedProduct.productType === 'TicketType') {
            this.price += attachedProduct.product.price * attachedProduct.quantity;
            this.nondetachableProducts.en.push(`${attachedProduct.quantity > 1 ? attachedProduct.quantity + ' x ' : ''} ${attachedProduct.product.title}`);
            this.nondetachableProducts.he.push(`${attachedProduct.quantity > 1 ? ' x ' + attachedProduct.quantity : ''} ${attachedProduct.product.title}`);
          }
        }
      });
    }
  }
}
