import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { ChillzDirectivesModule } from '../../directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../directives/gsap/gsap-directives.module';
import { ChillzPipesModule } from '../../pipes/chillz-pipes.module';
import { ChillzButtonComponent } from './chillz-button.component';


@NgModule({
  declarations: [ ChillzButtonComponent ],
  exports: [ ChillzButtonComponent ],
  imports: [
    CommonModule,
    MatMenuModule,
    FlexModule,
    RouterModule,
    MatIconModule,
    ChillzPipesModule,
    ChillzDirectivesModule,
    GSAPDirectivesModule,
  ],
})
export class ChillzButtonModule {}
