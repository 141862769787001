<div class="splash-screen-container" fxLayoutAlign="center center">
  <button mat-icon-button class="close-button" (click)="onDismiss(); $event.stopPropagation()">
    <mat-icon class="s-32">close</mat-icon>
  </button>

  <img
    [src]="splashItem?.picture?.url"
    [alt]="splashItem?.title?.en"
    *ngIf="splashItem?.picture?.url"
    (mouseup)="onClick()"
  />
</div>
