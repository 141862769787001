<form
  *ngIf="successHidden && failedHidden"
  #paytForm="ngForm"
  (ngSubmit)="onSubmit()"
  [formGroup]="paymentForm"
  novalidate
  class="chillz-content-section"
  fxLayoutAlign="start stretch"
  fxLayout="column"
>
  <!-- AMOUNT-->
  <mat-form-field floatLabel="always" *ngIf="showAmount">
    <mat-label for="payment_amount">{{ 'PAYMENT.lbl_amount' | translate }}</mat-label>
    <input matInput type="text" class="form-control" formControlName="amount" id="payment_amount" />
    <span matSuffix>{{ currencyCode }}</span>
  </mat-form-field>
  <!-- / AMOUNT-->
  <!-- CARD NUMBER-->

  <div fxLayout="row" class="hosted-form-field" fxLayoutAlign="center center">
    <div fxFlex="grow">
      <label for="credit_card_num">{{ 'PAYMENT.lbl_card_number' | translate }}</label>
      <div id="credit_card_num"></div>
      <div id="errors_for_number" class="error_message"></div>
      <mat-error *ngIf="cardNumValid === false">{{ 'PAYMENT.VALIDATION_CARD_NUMBER' | translate }}</mat-error>
    </div>

    <ng-container *ngIf="imageSrc && imageSrc !== ''">
      <div fxFlex="0 0 8px"></div>
      <img fxFlex="0 0 70px" class="credit-card-firm" alt="Credit card firm" src="{{ imageSrc }}" />
    </ng-container>
  </div>

  <!-- / CARD NUMBER-->
  <!-- HOLDER ID-->
  <div class="hosted-form-field">
    <label for="card_holder_id_number" style="height: auto">{{ 'PAYMENT.lbl_card_holder_id' | translate }}</label>
    <div class="form-control" id="card_holder_id_number"></div>
    <div id="errors_for_id" class="error_message"></div>
    <mat-hint>{{ 'PAYMENT.ISRAELI_CARDS_ONLY' | translate }}</mat-hint>
    <mat-error *ngIf="cardHolderValid === false && !paymentForm.controls.creditCardHolderId.valid">
      {{ 'PAYMENT.VALIDATION_HOLDER_ID' | translate }}
    </mat-error>
  </div>
  <!-- / HOLDER ID-->

  <div fxLayout="row" fxLayoutAlign="space-between">
    <!-- EXPIRY-->
    <div class="hosted-form-field" fxFlex="49">
      <label for="expiry">{{ 'PAYMENT.lbl_expiry' | translate }}</label>
      <div class="form-control" id="expiry"></div>
      <div id="errors_for_expiry" class="error_message"></div>
      <mat-error *ngIf="expiryValid === false">{{ 'PAYMENT.VALIDATION_EXPIRY' | translate }}</mat-error>
    </div>
    <!-- / EXPIRY-->

    <!-- CVV-->
    <div class="hosted-form-field" fxFlex="49">
      <label for="cvv">{{ 'PAYMENT.lbl_cvv' | translate }}</label>
      <div class="form-control" id="cvv"></div>
      <div id="errors_for_cvv" class="error_message"></div>
      <mat-error *ngIf="cvvValid === false">{{ 'PAYMENT.VALIDATION_CVV' | translate }}</mat-error>
    </div>
    <!-- CVV-->
  </div>

  <div class="row pt-4" *ngIf="showChargeButton">
    <div class="col-md-12">
      <button type="submit" class="btn btn-success" style="width: 100%">PAY</button>
    </div>
  </div>
</form>

<div *ngIf="!successHidden">
  <div class="container">
    <div class="row justify-content-center align-self-center pt-5">
      <div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex">
        <div class="swal2-success-circular-line-left"></div>
        <span class="swal2-success-line-tip"></span>
        <span class="swal2-success-line-long"></span>
        <div class="swal2-success-ring"></div>
        <div class="swal2-success-fix"></div>
        <div class="swal2-success-circular-line-right"></div>
      </div>
    </div>
    <!--    <div class="row justify-content-center align-self-center pt-5">-->
    <!--      <h2 style="color:#0fad00">Payment completed successfully</h2>-->
    <!--    </div>-->
  </div>
</div>

<div *ngIf="!failedHidden">
  <div class="container">
    <div class="row justify-content-center align-self-center pt-5">
      <div class="swal2-icon swal2-error swal2-animate-error-icon" style="display: flex">
        <span class="swal2-x-mark">
          <span class="swal2-x-mark-line-left"></span>
          <span class="swal2-x-mark-line-right"></span>
        </span>
      </div>
    </div>
    <div class="row justify-content-center align-self-center pt-5">
      <h2 style="color: #ff0000">Payment failed!</h2>
    </div>
    <div class="row justify-content-center align-self-center pt-5">
      <h5>{{ errorMessage }}</h5>
    </div>
  </div>
</div>
