import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { User } from '../../../auth/user.model';
import { VerificationService } from '../../../auth/verification/verification.service';
import { Platform } from '../../../shared/models/platform.model';
import { emailValidator } from '../../../shared/validators/validators';
import { ProfileService } from '../profile.service';


@Component({
  selector: 'app-profile-email',
  templateUrl: './profile-email.component.html',
  styleUrls: [ './profile-email.component.scss', '../profile.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileEmailComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;

  emailError: string;
  emailVerificationToken: string;
  emailFormControl: FormControl;

  status: 'saving' | 'saved';
  @Output() status$: EventEmitter<'saving' | 'saved'> = new EventEmitter<'saving' | 'saved'>();

  private _unsubscribeAll = new Subject<any>();

  constructor (
    private _profileService: ProfileService,
    private _authService: ChillzAuthService,
    private _appService: AppService,
    private _formBuilder: FormBuilder,
    private _verificationService: VerificationService
  ) {}

  ngOnInit (): void {
    this.emailFormControl = this._formBuilder.control(null, [ emailValidator ]);

    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
        this.emailFormControl.setValue(this.profile.email);
        this.emailFormControl.markAsPristine();
      });

    this.status$
      .pipe(
        tap((status) => {
          this.status = status;
        }),
        debounceTime(5000),
        tap((status) => {
          if (status === 'saved') {
            this.status = null;
          }
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  onSave (): void {
    this.status$.emit('saving');

    this._verificationService
      .initVerification({ email: this.emailFormControl.value })
      .then((x) => {
        this.emailVerificationToken = x;
        this.emailError = undefined;
      })
      .catch((err) => {
        this.status$.emit(null);
        console.log(err);
        this.emailError = err?.error?.error || err?.error;
      })
      .finally(() => {
        this.emailFormControl.markAsPristine();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
