<div class="footer-row">
  <div
    class="footer-column"
    [ngStyle.gt-xs]="{
      'align-items': column.align || 'center',
      'align-content': column.align || 'center'
    }"
    *ngFor="let column of platform?.footer"
  >
    <div class="footer-column-info-block" *ngIf="column.title">
      <a [href]="column.url" *ngIf="column.url">
        <div class="footer-column-title B4-Regular">
          {{ column.title[localizationService.currentLang] || column.title['en'] }}
        </div>
      </a>
      <div class="footer-column-title B4-Regular" *ngIf="!column.url">
        {{ column.title[localizationService.currentLang] || column.title['en'] }}
      </div>
    </div>

    <ng-container *ngFor="let item of column.items">
      <ng-container *ngIf="item.title || item.image">
        <a
          class="black-250"
          [href]="item.url[localizationService.currentLang] || item.url['en'] || item.url"
          *ngIf="item.url"
          target="_blank"
        >
          <div class="footer-item" *ngIf="item.title">
            {{ item.title[localizationService.currentLang] || item.title['en'] }}
          </div>
          <div class="footer-item" *ngIf="item.image">
            <img
              [src]="item.image.url[localizationService.currentLang] || item.image.url['en'] || item.image.url"
              [alt]="item.image.alt"
            />
          </div>
        </a>
        <ng-container *ngIf="!item.url">
          <div class="footer-item B4-Regular black-400" *ngIf="!item.url && item.title">
            {{ item.title[localizationService.currentLang] || item.title['en'] }}
          </div>
          <div class="footer-item" *ngIf="item.image">
            <img
              [src]="item.image.url[localizationService.currentLang] || item.image.url['en'] || item.image.url"
              [alt]="item.image.alt"
              *ngIf="item.image"
            />
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
