<div class="gap-8" fxLayout="column" fxLayoutAlign="start center">
  <div *ngIf="label || icon" fxLayout="row" fxLayoutAlign="center center" class="gap-8">
    <div *ngIf="icon" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon *ngIf="!isIconSymbol">{{ icon }}</mat-icon>
      <span class="material-symbols-outlined" *ngIf="isIconSymbol">{{ icon }}</span>
    </div>

    <span *ngIf="label" class="chillz-input-label">{{ label[localizationService.currentLang] || label['en'] }}</span>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="center center" class="gap-8">
    <chillz-button
      *ngFor="let option of options"
      (clicked)="control?.setValue(option.value)"
      [label]="option.label"
      [icon]="option.icon"
      [isSvgIcon]="option.isSvgIcon"
      [ctaIcon]="option.ctaIcon"
      [isCtaSvgIcon]="option.isCtaSvgIcon"
      [iconClass]="option.iconClass"
      [expandable]="false"
      [fontSize]="fontSize"
      [height]="height"
      [width]="width"
      [maxWidth]="maxWidth"
      [maxHeight]="maxHeight"
      [padding]="padding"
      [letterSpacing]="letterSpacing || '0'"
      [borderRadius]="borderRadius"
      [strokeWidth]="strokeWidth"
      [outline]="control?.value !== undefined && control?.value === option.value"
      [cta]="control?.value !== undefined && control?.value === option.value"
      [outlineShine]="outlineShine"
      [glow]="glow"
      [glowIntensity]="glowIntensity"
      [ctaGlowIntensity]="ctaGlowIntensity"
      [shadow]="shadow"
      [backgroundColor]="backgroundColor"
      [background]="background"
      [ctaColors]="ctaColors"
      [disabled]="option.disabled"
      [hoverPush]="!(control?.value !== undefined && control?.value === option.value)"
    ></chillz-button>
  </div>
</div>
