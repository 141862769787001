export const locale = {
  lang: 'en',
  data: {
    ADD_PAYMENT_METHOD: {
      TITLE: 'Add a Credit Card',
      DISCLAIMER: 'Notice: we do not accept debit cards',
    },
    VENUE_THUMB: {
      TICKETS: 'Get your Tickets',
      CANCELED: 'Canceled',
      ADD_TO_ORDER: 'Add to Order',
      ADDED_TO_ORDER: 'Added 1to Order',
      PURCHASE: 'Click to Purchase',
      REMOVE_FROM_ORDER: 'Remove',
      MORE_INFO: 'More Info',
      SOLD_OUT: 'Sold Out',
    },
    COOKIES_CONSENT: {
      TITLE: 'We use cookies',
      BODY: 'We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, analyze our website traffic, and to understand our visitors are coming from.',
      OK: 'Got it',
    },
    PAYMENT: {
      title: 'yo yo welcome',
      header: 'Payment by Credit Card',
      lbl_card_number: 'Card Number',
      lbl_expiry: 'Expiry date',
      lbl_cvv: 'CVV',
      lbl_card_holder_id: 'Card holder ID',
      ISRAELI_CARDS_ONLY: 'Only if your card is Israeli. Otherwise - type 1-9.',
      lbl_amount: 'Amount',
      VALIDATION_CARD_NUMBER: 'Incorrect credit card number',
      VALIDATION_EXPIRY: 'Invalid card expiration date',
      VALIDATION_CVV: 'Invalid CVV',
      VALIDATION_HOLDER_ID: 'Invalid holder ID number',
      placeholder_card_holder_id: 'ID',
      placeholder_card_number: 'Credit card number',
      placeholder_expiry: 'MM/YYYY',
      placeholder_cvv: 'CVV',
    },
    TICKET: {
      CANCELED: 'CANCELED',
      ROW: 'Row',
      SEAT: 'Seat',
      VIEW: 'View',
      FREE: 'Free',
      CUSTOM_FIELDS: {
        IS_REQUIRED: 'Required',
        OPTIONAL: 'Optional',
        ALWAYS_MUTABLE: 'Can always be changed',
        MUTABLE_UNTIL_CHECKED_IN: 'Can be changed until the ticket checks in to the venue',
        IMMUTABLE: 'Can\'t be updated',
      },
    },
    TICKET_TYPE_STATUS: {
      'SOLD-OUT': 'SOLD OUT',
      'SALE-ENDED': 'Sale Ended',
      'SOON': 'SOON',
      'UNAVAILABLE': 'UNAVAILABLE',
    },
  },
};
