export class ExploreFilter {
  cities: string[];
  tags: string[];
  venueTypes: string[];
  parents: string[];
  sellingTickets: boolean;
  includeRepeating: boolean;
  time: {
    type: 'this-weekend' | 'anytime-in-the-future' | 'timeframe' | 'today' | 'between-dates';
    weekendCity?: string;
    hoursAgo?: number;
    hoursAhead?: number;
    afterDate?: Date;
    beforeDate?: Date;
  };
  group: boolean;

  constructor (query?) {
    this.cities = [];
    this.tags = [];
    this.venueTypes = [];
    this.parents = [];

    if (query) {
      [ 'cities', 'venueTypes', 'tags' ].forEach((key) => {
        if (query[key]) {
          this[key] = query[key].split(',');
        }
      });

      if (query['group']) {
        this['group'] = query.group === 'true';
      }

      if (query['sellingTickets']) {
        this['sellingTickets'] = query.sellingTickets === 'true';
      }

      const timeKeys = Object.keys(query).filter((x) => x.startsWith('time.'));
      if (timeKeys.length) {
        this['time'] = {
          type: 'anytime-in-the-future',
        };
        timeKeys.forEach((key) => {
          this['time'][key.replace('time.', '')] = query[key];
        });
      }
    }
  }

  today (): void {
    this.time = {
      type: 'today',
    };
  }

  thisWeekend (): void {
    this.time = {
      type: 'this-weekend',
    };
  }
  anytime (): void {
    this.time = {
      type: 'anytime-in-the-future',
    };
  }

  betweenDates (afterDate, beforeDate?): void {
    this.time = {
      type: 'between-dates',
      afterDate,
      beforeDate,
    };
  }

  timeFrame (hoursAgo, hoursAhead): void {
    this.time = {
      type: 'timeframe',
      hoursAgo,
      hoursAhead,
    };
  }

  getQueryParams (): any {
    const params = {};
    if (this.cities?.length) {
      params['cities'] = this.cities.join(',');
    }

    if (this.tags?.length) {
      params['tags'] = this.tags.join(',');
    }

    if (this.venueTypes?.length) {
      params['venueTypes'] = this.venueTypes.join(',');
    }

    if (this.parents?.length) {
      params['parents'] = this.parents.join(',');
    }

    if (this.sellingTickets !== undefined) {
      params['sellingTickets'] = this.sellingTickets.toString();
    }

    if (this.includeRepeating !== undefined) {
      params['includeRepeating'] = this.includeRepeating.toString();
    }

    if (this.group !== undefined) {
      params['group'] = this.group.toString();
    }

    if (this.time) {
      for (const key of Object.keys(this.time)) {
        params['time.' + key] = this.time[key].toString();
      }
    }

    return params;
  }
}
