import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppLocalizationService } from '../../../../app-localization.service';


@Component({
  selector: 'chillz-checkbox',
  templateUrl: './chillz-checkbox.component.html',
  styleUrls: [ './chillz-checkbox.component.scss' ],
})
export class ChillzCheckboxComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() control: AbstractControl;
  @Input() label: { [lang: string]: string };
  @Input() tooltip: { [lang: string]: string };
  @Input() labelPosition: 'before' | 'after' | 'above' | 'below' = 'after';
  @Input() icon: string;
  @Input() isSvgIcon: boolean;

  @Input() disabled = false;
  @Input() outline = true;
  @Input() outlineShine = false;
  @Input() glow = false;

  @Input() glowIntensity = 0;
  @Input() ctaGlowIntensity = 0;

  @Input() shadow = true;

  @Input() height = 28;
  @Input() width = 28;

  @Input() strokeWidth = '1px';

  @Input() background = true;
  @Input() backgroundColor;

  @Input() ctaColors = [ '#ffb369', '#ff4272' ];

  @Input() checked: boolean;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() hoverPush = false;
  @Input() hoverOutlineShine = false;
  @Input() hoverBackgroundPosition = false;
  @Input() hoverBackgroundColor = false;

  controlSub: Subscription;

  layout: { direction: string; align: string } = { direction: 'row', align: 'start center' };

  // Private
  private _unsubscribeAll = new Subject<any>();

  constructor (public localizationService: AppLocalizationService) {}

  ngOnInit (): void {}

  ngAfterViewInit (): void {}

  onCheck (): void {
    if (this.disabled || this.control?.disabled) {
      return;
    }

    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
    if (this.control) {
      this.control.setValue(this.checked);
    }
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (this.control) {
      this.checked = this.control.value;
    }

    if (this.control && changes.control) {
      if (changes.control.isFirstChange() && this.checked !== undefined) {
        this.control.setValue(this.checked);
      }

      if (this.controlSub) {
        this.controlSub.unsubscribe();
      }

      this.controlSub = this.control.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((val) => {
        this.checked = val;
        this.checkedChange.emit(this.checked);
      });
    }

    if (this.labelPosition) {
      if (this.labelPosition === 'after') {
        this.layout = { direction: 'row', align: 'start center' };
      }

      if (this.labelPosition === 'before') {
        this.layout = { direction: 'row-reverse', align: 'start center' };
      }

      if (this.labelPosition === 'above') {
        this.layout = { direction: 'column-reverse', align: 'start center' };
      }

      if (this.labelPosition === 'below') {
        this.layout = { direction: 'column', align: 'start center' };
      }
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
