export const locale = {
  lang: 'en',
  data: {
    NAV: {
      APPLICATIONS: 'Applications',
      SAMPLE: {
        TITLE: 'Sample',
        BADGE: '25',
      },
    },
  },
};
