import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';


@Component({
  selector: 'chillz-skeleton-block',
  template: '',
  styleUrls: [ 'chillz-skeleton-block.component.scss' ],
})
export class ChillzSkeletonBlockComponent implements OnInit {
  @Input() width = '100%';
  @Input() height = '100%';

  constructor (private host: ElementRef<HTMLElement>, private _renderer: Renderer2) {}

  public ngOnInit (): void {
    this.setStyles();
  }

  private setStyles (): void {
    const host: HTMLElement = this.host.nativeElement;

    this._renderer.setStyle(host, 'width', this.width);
    this._renderer.setStyle(host, 'height', this.height);
  }
}
