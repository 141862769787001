import { ExploreTheme } from './explore-theme.model';
import { ChillzPicture } from './picture.model';


export class City {
  _id: string;
  en: string;
  titles: { [lang: string]: string };
  variations: string[];
  thumbUrl: string;
  thumb: ChillzPicture;
  timeZone: string;
  themes: ExploreTheme[];
  weekendStart: string;

  constructor (city: any = {}) {
    this._id = city._id;
    this.titles = city.titles;
    this.en = city.en;
    this.variations = city.variations;
    this.thumbUrl = city.thumbUrl;

    this.weekendStart = city.weekendStart;

    this.themes = city.themes?.map((x) => new ExploreTheme(x)) || [];
  }
}
