<div id="cookies-popup" [dir]="textAttr.direction">
  <p class="cookies-disclaimer">{{ 'COOKIES_CONSENT.BODY' | translate }}</p>
  <chillz-base-button
    class="button"
    [label]="{
        en: 'COOKIES_CONSENT.OK' | translate,
        he: 'COOKIES_CONSENT.OK' | translate
      }"
    [variant]="'primary'"
    [size]="'large'"
    (clicked)="approved.emit()"
  ></chillz-base-button>
</div>
