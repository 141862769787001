export class SelectableStyle {
  _id?: string;
  name?: string;

  __t: 'ItemsCollectionStyle' | 'EntityPageLayout' | 'EntityLayout' | 'TicketStyle';

  constructor (style: any = {}) {
    this.name = style.name;
    this.__t = style.__t;
  }

  setValue (value: any = {}): void {
    if (value._id) {
      this._id = value._id;
    }

    if (value.name) {
      this.name = value.name;
    }
  }

  toJson (_excludeAttributions = false, _nullEmpty = false): any {
    return {
      _id: this._id,
      name: this.name,
    };
  }
}
