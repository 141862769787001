import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { Platform } from '../../models/platform.model';


@Component({
  selector: 'app-initial-loader',
  templateUrl: './initial-loader.component.html',
  styleUrls: [ './initial-loader.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class InitialLoaderComponent implements OnInit {
  private renderer2: Renderer2;
  platform: Platform;

  visible = true;
  constructor (
    private _router: Router,
    private _appService: AppService,
    @Inject(DOCUMENT) private _document: any,
    rendererFactory: RendererFactory2
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  ngOnInit (): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this.visible = false;
      });

    this._appService.onPlatformChanged
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((platform) => {
        this.platform = platform;
        this._init();
      });
  }

  /**
   * Initialize
   *
   */
  private _init (): void {
    // Get the splash screen element

    // console.log(this.platform);
    const logoContainer = this._document.body.querySelector('#splash-logo');

    let logoElement;

    if (this.platform.splashScreen?.logo?.type === 'lottie') {
      if (!customElements.get('lottie-player')) {
        const lottieScript = this.renderer2.createElement('script');
        lottieScript.src = 'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js';

        this.renderer2.appendChild(this._document.head, lottieScript);
      }

      logoElement = this.renderer2.createElement('lottie-player');
      this.renderer2.setAttribute(logoElement, 'autoplay', null);
      this.renderer2.setAttribute(logoElement, 'speed', '0.75');
    }

    if (this.platform.splashScreen?.logo?.type === 'img') {
      logoElement = this.renderer2.createElement('img');
    }

    if (logoElement) {
      this.renderer2.setAttribute(logoElement, 'src', this.platform.splashScreen?.logo.src);
      if (this.platform.splashScreen?.logo.width) {
        this.renderer2.setStyle(logoElement, 'width', this.platform.splashScreen?.logo.width + 'px');
      }

      if (this.platform.splashScreen?.logo.height) {
        this.renderer2.setStyle(logoElement, 'height', this.platform.splashScreen?.logo.height + 'px');
      }

      this.renderer2.appendChild(logoContainer, logoElement);
    }

    // <img width="128" id="splash-logo" src="" />

    //   <lottie-player
    // src="/assets/animations/logos/6EbM27Ld2z.json"
    // background="transparent" speed="0.5" style="width: 150px; height: 150px;" autoplay></lottie-player>

    // if (this.splashLogoEl) {
    //   this.splashLogoEl.src = environment.logo;
    // }
    // // If the splash screen element exists...
    // if (this.splashScreenEl) {
    //
    //   // Hide it on the first NavigationEnd event
    //   this._router.events
    //     .pipe(
    //       filter(event => event instanceof NavigationEnd),
    //       take(1),
    //     )
    //     .subscribe(() => {
    //       setTimeout(() => {
    //         this.hide();
    //       });
    //     });
    // }
  }

  initializeLottiePlayer (): void {
    const logoElement = this.renderer2.createElement('lottie-player');
    this.renderer2.setAttribute(logoElement, 'autoplay', 'true');
    this.renderer2.setAttribute(logoElement, 'speed', '0.75');
  }
}
