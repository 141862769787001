import { Component, Input, OnInit } from '@angular/core';

import { AppLocalizationService } from '../../../../../app-localization.service';
import { Entity } from '../../../../../main/entity/entity.model';
import { Platform } from '../../../../../shared/models/platform.model';


@Component({
  selector: 'chillz-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: [ './search-result-item.component.scss' ],
})
export class SearchResultItemComponent implements OnInit {
  @Input() item: Entity;
  @Input() platform: Platform;
  fileLoaded: boolean;

  constructor (public localizationService: AppLocalizationService) {}

  ngOnInit (): void {}

  public onFileLoaded (): void {
    this.fileLoaded = true;
  }
}
