export class UserInteractionEventType {
  id: string;
  title: { [lang: string]: string };
  snapchat: string;
  tiktok: string;
  facebookPixel: string;
  GA: string;
  GTM: string;
  Gtag: string;

  constructor (type: any = {}) {
    this.id = type.id;
    this.title = type.title;
    this.snapchat = type.snapchat;
    this.facebookPixel = type.facebookPixel;
    this.GA = type.GA;
    this.GTM = type.GTM;
    this.Gtag = type.Gtag;
    this.tiktok = type.tiktok;
  }
}
