<div *ngIf="loading" class="loader-overlay">
  <chillz-loader [size]="144"></chillz-loader>
</div>

<ng-container *ngIf="isPreview && isBrowser">
  <div class="warning-spacer"></div>
  <div class="preview-mode-warning">
    <div class="preview-mode-warning-title">{{ 'ENTITY.PREVIEW.TITLE' | translate }}</div>
    <div class="preview-mode-warning-subtitle" [innerText]="'ENTITY.PREVIEW.SUB_TITLE' | translate"></div>
  </div>
</ng-container>
<!-- PROGRESS BAR -->
<!--<fuse-progress-bar></fuse-progress-bar>-->
<!-- / PROGRESS BAR -->
<app-splash-screen *ngIf="splashItem && isBrowser" [splashItem]="splashItem"></app-splash-screen>

<app-initial-loader></app-initial-loader>

<app-cookies-consent
  [@animate]="{ value: '*', params: { y: '100%', opacity: 0, duration: '1s' } }"
  (approved)="dismissCookiesConsent()"
  *ngIf="showCookiesConsent && !embedded && isBrowser"
></app-cookies-consent>
<app-order-preparation *ngIfAnimated="showOrderPreparation && isBrowser" fadeInAnimation></app-order-preparation>
<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
  <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->
