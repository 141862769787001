import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { SessionStorage } from '../../@fuse/services/storage/session-storage';
import { TransferStateHelper } from '../../@fuse/services/transfer-state-helper';
import { AppService } from '../app.service';
import { City } from './models/city.model';
import { SessionInfoService } from './session-info.service';


@Injectable({ providedIn: 'root' })
export class CitiesService implements Resolve<any> {
  routeParams: any;
  cities: City[];
  onCitiesChanged: BehaviorSubject<City[]>;
  currentCity: City;
  onCurrentCityChanged: BehaviorSubject<City>;

  DEFAULT_CITY = 0;

  isBrowser;

  /**
   * Constructor
   *
   */
  constructor (
    private _httpClient: HttpClient,
    private _router: Router,
    @Inject(PLATFORM_ID) platformId,
    private _platform: Platform,
    private _appService: AppService,
    private _sessionStorage: SessionStorage,
    private _sessionService: SessionInfoService,
    private _transferStateHelper: TransferStateHelper
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    // Set the defaults
    this.onCurrentCityChanged = new BehaviorSubject(undefined);
    this.onCitiesChanged = new BehaviorSubject([]);
  }

  /**
   * Resolver
   *
   */
  resolve (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getCities();
      Promise.all([])
        .then(() => {
          resolve(false);
        })
        .catch(() => {
          this._router.navigate([ '/', 'errors', '500' ]);
          reject();
        });
    });
  }

  /**
   * Get cities
   *
   */
  getCities (): Promise<City[]> {
    return new Promise((resolve, reject) => {
      if (this.cities) {
        this.onCitiesChanged.next(this.cities);
        return resolve(this.cities);
      }

      this._httpClient.get('/cities/active').subscribe((response: any) => {
        this.cities = response.cities.map((x) => new City(x));
        this.onCitiesChanged.next(this.cities);

        this.setCurrentCity();
        resolve(this.cities);
      }, reject);
    });
  }

  /**
   * Get nearest city
   *
   */
  getNearestCity (lat, lng): Promise<City> {
    const location = '?lat=' + lat + '&lng=' + lng;

    return new Promise((resolve, reject) => {
      this._httpClient.get('/cities/active/nearest' + location).subscribe((response: any) => {
        if (response.nearest) {
          this.currentCity = new City(response.nearest);
          this.onCurrentCityChanged.next(this.currentCity);

          this._sessionStorage.setItem('selected_city', this.currentCity._id);
          this._sessionStorage.setItem('currentCity', this.currentCity.en);
        }

        resolve(this.currentCity);
      }, reject);
    });
  }

  /**
   * Set current city
   *
   */
  setCurrentCity (_id?): void {
    if (_id) {
      this.currentCity = this.cities.find((city) => {
        return city._id === _id;
      });
      this.onCurrentCityChanged.next(this.currentCity);

      if (this._platform.isBrowser) {
        this._sessionStorage.setItem('selected_city', this.currentCity._id);
        this._sessionStorage.setItem('currentCity', this.currentCity.en);
      }
    } else {
      if (this._sessionStorage.getItem('selected_city')) {
        this.currentCity = this.cities.find((city) => {
          return city._id === this._sessionStorage.getItem('selected_city');
        });

        this.onCurrentCityChanged.next(this.currentCity);

        if (this.currentCity && this.isBrowser) {
          this._sessionStorage.setItem('selected_city', this.currentCity._id);
          this._sessionStorage.setItem('currentCity', this.currentCity.en);
        }
      }

      if (!this.currentCity) {
        this.currentCity = this.cities[this.DEFAULT_CITY];
        this.onCurrentCityChanged.next(this.currentCity);
      }

      if (
        this.isBrowser &&
        navigator?.geolocation &&
        (!this._appService.platform || this._appService.platform?.geoLocation)
      ) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.getNearestCity(position.coords.latitude, position.coords.longitude);

            this._sessionService.sessionInfo['position'] = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            this._sessionService.onSessionInfoChanged.next(this._sessionService.sessionInfo);
            this._sessionService.updateSession();
          },
          (err) => console.log(err)
        );
      }
    }
  }
}
