<div
  class="toolbar-container"
  [class.scrolled]="scrolled || !platform?.toolbar?.transparentBeforeScrolling"
  [style.--toolbarColor]="platform?.toolbar?.color"
>
  <mat-toolbar class="py-0">
    <div [ngClass.lt-sm]="['hide']">
      <ng-container *ngTemplateOutlet="cityPicker"></ng-container>
    </div>
    <div class="cities-menu" [class.expanded]="cityPickerExpanded">
      <button mat-icon-button (click)="closeCityPickerModal()" class="close-button">
        <mat-icon svgIcon="chillz-cross" class="s-32 stroke-black-50"></mat-icon>
      </button>
      <div #citiesPickerOptions class="cities-options-block">
        <div class="cities-options">
          <div class="city-option" *ngFor="let city of cities" (click)="onLocationSelect(city._id)">
            {{ city.titles[localizationService.currentLang] || city.titles['en'] }}
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!hideBranding">
      <div class="toolbar-main">
        <a [routerLink]="['/']" [queryParamsHandling]="'preserve'" class="logo mr-auto" *ngIf="platform?.toolbar?.logo">
          <img
            class="logo-icon"
            [src]="platform?.logo?.white"
            *ngIf="showFull"
            [@animate]="{ value: '*', params: { duration: '1s', opacity: 0 } }"
            alt=""
          />
        </a>

        <div class="toolbar-actions">
          <app-search-bar *ngIf="!hideSearch && showFull && platform?.toolbar?.searchBar"></app-search-bar>
          <ng-container *ngTemplateOutlet="languageSelector"></ng-container>
          <div *ngIf="platform" class="create-action" fadeInAnimation>
            <chillz-base-button
              (clicked)="openDashboard()"
              [label]="{ en: 'Create event' | translate, he: 'Create event' | translate }"
              [variant]="'primary'"
              [size]="'medium'"
            ></chillz-base-button>
          </div>
          <!--          <ng-container *ngIf="platform?.loyaltyProgram && user && coinz !== null && !hideAuth && showFull">-->
          <!--            <button-->
          <!--              mat-button-->
          <!--              class="coinz-button"-->
          <!--              [routerLink]="['/', 'profile']"-->
          <!--              [@animate]="{ value: '*', params: { duration: '1s', opacity: 0 } }"-->
          <!--              fxHide-->
          <!--              fxShow.gt-xs-->
          <!--              fxLayout="column"-->
          <!--              fxLayoutAlign="center"-->
          <!--            >-->
          <!--              <div fxLayout="row" fxLayoutAlign="center center">-->
          <!--                <mat-icon svgIcon="coinz" class="s-20"></mat-icon>-->
          <!--                <div fxFlex="0 0 4px"></div>-->
          <!--                <span>{{ coinz }}</span>-->
          <!--              </div>-->
          <!--              <div class="chillz-secondary-text coinz-text">Coinz</div>-->
          <!--            </button>-->
          <!--          </ng-container>-->
          <ng-container *ngIf="userLoaded && !hideAuth && platform && platform?.toolbar?.authMenu">
            <ng-container *ngIf="!user">
              <button
                fadeInAnimation
                mat-ripple
                class="menu-button desktop-element"
                #noUserMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="noUserMenu"
                [class.active]="noUserMenuTrigger.menuOpen"
              >
                <ng-container
                  *ngTemplateOutlet="sidebarSwitcher;
                  context: {
                    openMenu: noUserMenuTrigger.menuOpen
                  }"
                ></ng-container>
                <span class="B4-Regular black-50 menu-text">Menu</span>
              </button>

              <button (click)="collapseMenu()" fadeInAnimation mat-ripple class="mobile-element menu-button">
                <ng-container
                  *ngTemplateOutlet="sidebarSwitcher;
                  context: {
                    openMenu: userMenuExpanded
                  }"
                ></ng-container>
              </button>
              <mat-menu class="chillz-menu lower-position" #noUserMenu="matMenu" [overlapTrigger]="false">
                <ng-container *ngFor="let item of noUserMenuItems">
                  <button mat-menu-item class="B4-Regular" (click)="item.action()">{{ item.label }}</button>
                </ng-container>

                <div *ngIf="buildVersion" class="version-label">{{ 'V' + buildVersion }}</div>
              </mat-menu>
            </ng-container>
            <ng-container *ngIf="user">
              <div class="vertical-divider"></div>
              <button
                fadeInAnimation
                mat-button
                #userMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="userMenu"
                class="user-button desktop-element"
              >
                <div class="user-button-content">
                  <ng-container *ngTemplateOutlet="userAvatar"></ng-container>
                  <div class="user-text">
                    <p class="B4-Regular black-50 m-0">
                      <ng-container *ngIf="user.displayName; else userElement">
                        {{ user.displayName | titlecase }}
                      </ng-container>
                      <ng-template #userElement>
                        {{ user.firstName | titlecase }} {{ user.lastName | titlecase }}
                      </ng-template>
                    </p>
                    <p class="B6-Regular black-50 m-0" *ngIf="user.email">{{ user.email }}</p>
                  </div>
                </div>
              </button>
              <mat-menu class="chillz-menu lower-position" #userMenu="matMenu" [overlapTrigger]="false">
                <ng-container *ngFor="let item of menuItems">
                  <mat-divider class="light-divider" *ngIf="item.divide"></mat-divider>
                  <button mat-menu-item (click)="item.action()">
                    <div class="menu-item-content">
                      <mat-icon class="stroke-black-200" *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
                      <span> {{ item.label }}</span>
                    </div>
                  </button>
                </ng-container>
              </mat-menu>
              <ng-container *ngIf="!userMenuExpanded; else cross">
                <button (click)="collapseMenu()" fadeInAnimation mat-ripple class="mobile-element user-button">
                  <div class="user-button-content">
                    <ng-container *ngTemplateOutlet="userAvatar"></ng-container>
                  </div>
                </button>
              </ng-container>
              <ng-template #cross>
                <button (click)="collapseMenu()" fadeInAnimation mat-icon-button class="close-button">
                  <mat-icon svgIcon="chillz-cross" class="s-32 stroke-black-50"></mat-icon>
                </button>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </mat-toolbar>
</div>

<div [class.opened]="userMenuExpanded && isMobile" class="mobile-menu mobile-element">
  <div class="main-content">
    <ng-container *ngFor="let item of userMenuItems">
      <mat-divider class="light-divider" *ngIf="item.divide"></mat-divider>
      <button class="H5-Regular black-200" mat-button (click)="item.action(); closeUserMenu()">
        <div class="menu-item-content">
          <mat-icon class="stroke-black-200" *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.label }}</span>
        </div>
      </button>
    </ng-container>
  </div>
  <div *ngIf="userMenuExpanded" class="menu-footer">
    <ng-container *ngTemplateOutlet="cityPicker"></ng-container>
    <div class="language-switcher-wrapper">
      <ng-container *ngTemplateOutlet="languageSelector"></ng-container>
    </div>
  </div>
</div>

<ng-template #cityPicker>
  <div fadeInAnimation class="city-picker" *ngIf="isBrowser && platform?.cityPicker && cities?.length > 1 && isIntro">
    <button class="selected-city black-50 px-300" (click)="openCityPickerModal()">
      <mat-icon svgIcon="location-marker" class="s-24"></mat-icon>
      <div *ngIf="currentCity" class="B3-Regular">
        {{ currentCity.titles[localizationService.currentLang] || currentCity.en }}
      </div>
    </button>
  </div>
</ng-template>

<ng-template #languageSelector>
  <ng-container *ngIf="!hideLangPicker && languages?.length > 1 && platform?.toolbar?.languagePicker">
    <button
      fadeInAnimation
      mat-button
      class="language-button"
      #languageMenuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="languageMenu"
    >
      <div class="language-button-content">
        <p class="B3-Regular black-50 m-0">{{ selectedLanguage?.id | uppercase }}</p>
        <mat-icon class="stroke-black-50" svgIcon="chillz-arrow-short"></mat-icon>
      </div>
    </button>

    <mat-menu fadeInAnimation class="chillz-menu lower-position" #languageMenu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
        <img [alt]="lang.title" class="flag" [src]="'assets/icons/flags/' + lang.flag + '.png'" />
        <span class="iso">{{ lang.title }}</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-template>

<ng-template #userAvatar>
  <ng-container *ngIf="user.profilePicture; else defaultPicture">
    <img class="avatar" alt="" [src]="user.profilePicture.url" />
  </ng-container>
  <ng-template #defaultPicture>
    <img class="avatar" alt="" src="assets/images/default-avatar.png" />
  </ng-template>
</ng-template>

<ng-template #sidebarSwitcher let-openMenu="openMenu">
  <div class="sidebar-switcher" [class.active]="openMenu">
    <div class="menu-bar" data-position="top"></div>
    <div class="menu-bar" data-position="bottom"></div>
  </div>
</ng-template>
