import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { getRegionCodeForCountryCode, ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '../../../../../@fuse/animations';
import { AppService } from '../../../../app.service';
import { AppLocalizationService } from '../../../../app-localization.service';


@Component({
  selector: 'chillz-phone-number-input',
  templateUrl: './chillz-phone-number-input.component.html',
  styleUrls: [ './chillz-phone-number-input.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ChillzPhoneNumberInputComponent implements OnInit, OnDestroy {
  SearchCountryField = SearchCountryField;

  @Input() initialValue: {
    countryCode?: number | string;
    phoneNumber?: string;
  };
  @Input() containerClass: string;
  @Input() label: { [lang: string]: string };
  @Input() id: string;
  @Input() icon: string;
  @Input() isIconSymbol: boolean;
  @Input() disabled = false;
  @Input() invalid: boolean;
  @Input() required: boolean;
  @Input() errorMessage: string;
  @Input() preferredCountries: CountryISO[] = [ CountryISO.UnitedStates, CountryISO.UnitedKingdom ];

  @Output() valueChanged: EventEmitter<ParsedPhoneNumber> = new EventEmitter<ParsedPhoneNumber>();

  selectedCountryISO: CountryISO;
  phone = new BehaviorSubject<any>(undefined);
  phoneValue: string;
  parsedPhoneNumber: ParsedPhoneNumber;
  saving: boolean;

  private _unsubscribeAll = new Subject<void>();

  constructor (public localizationService: AppLocalizationService, private _appService: AppService) {}

  ngOnInit (): void {
    this.handleInputChanges();
    this.patchInitialValue();
  }

  patchInitialValue (): void {
    const v = this.initialValue;
    if (!v.countryCode) {
      v.countryCode = this._appService.platform?.localization?.defaultCountryCode;
    }

    if (!v.phoneNumber) {
      v.phoneNumber = '';
    }

    this.parsedPhoneNumber = parsePhoneNumber(
      v.phoneNumber || '',
      v.countryCode ? { regionCode: getRegionCodeForCountryCode(parseInt(v.countryCode?.toString(), 10)) } : {}
    );
    this.phoneValue = this.parsedPhoneNumber?.number?.e164 || this.parsedPhoneNumber?.number?.significant;

    this.selectedCountryISO = this.parsedPhoneNumber.regionCode.toLowerCase() as CountryISO;
  }

  handleInputChanges (): void {
    this.phone.pipe(takeUntil(this._unsubscribeAll)).subscribe((phone) => {
      if (phone) {
        this.phoneValue = phone?.nationalNumber || phone?.number;
        this.parsedPhoneNumber = parsePhoneNumber(phone?.e164Number);
        this.valueChanged.emit(this.parsedPhoneNumber);
      }
    });
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
