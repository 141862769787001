import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AppLocalizationService } from '../../../../app-localization.service';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './chillz-confirmation-dialog.component.html',
  styleUrls: [ './chillz-confirmation-dialog.component.scss' ],
})
export class ChillzConfirmationDialogComponent {
  title: { [lang: string]: string };
  subtitle: { [lang: string]: string };
  description: { [lang: string]: string };

  confirm: { [lang: string]: string };
  cancel: { [lang: string]: string };

  hideCancelButton: boolean;
  hideConfirmButton: boolean;

  constructor (
    public dialogRef: MatDialogRef<ChillzConfirmationDialogComponent>,
    private _formBuilder: FormBuilder,
    public localizationService: AppLocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _translateService: TranslateService
  ) {
    this.title = data.title || {
      en: this._translateService.instant('CONFIRMATION_DIALOG.TITLE'),
      he: this._translateService.instant('CONFIRMATION_DIALOG.TITLE'),
    };
    this.subtitle = data.subtitle;
    this.description = data.description;

    this.confirm = data.confirm || {
      en: this._translateService.instant('CONFIRMATION_DIALOG.CONFIRM'),
      he: this._translateService.instant('CONFIRMATION_DIALOG.CONFIRM'),
    };
    this.cancel = data.cancel || {
      en: this._translateService.instant('CONFIRMATION_DIALOG.CANCEL'),
      he: this._translateService.instant('CONFIRMATION_DIALOG.CANCEL'),
    };

    this.hideCancelButton = data.hideCancelButton;
    this.hideConfirmButton = data.hideConfirmButton;
  }
}
