import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '../../../../@fuse/animations';
import { AppService } from '../../../app.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { EntityService } from '../../../main/entity/entity.service';
import { ExploreEngagementAction } from '../../models/explore-engagement-action.model';
import { ExploreItem } from '../../models/explore-item.model';
import { Platform } from '../../models/platform.model';
import { getBackgroundPicture, getResponsiveSrc } from '../item-card/getBackgroundPicture';


@Component({
  selector: 'app-carousel-poster-item',
  templateUrl: './carousel-poster-item.component.html',
  styleUrls: [ './carousel-poster-item.component.scss' ],
  animations: fuseAnimations,
})
export class CarouselPosterItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() item: ExploreItem;
  @ViewChild('posterContainer', { static: false }) posterContainer: ElementRef;

  fileLoaded: boolean;
  foregroundLoaded: boolean;
  isInView: boolean;
  platform: Platform;
  queryParams: any = {};
  backgroundPicture: { src: string; isBlurred: boolean; isPlaceholder: boolean };
  width: number;
  backgroundBlurPicture: string;
  private _unsubscribeAll = new Subject<any>();

  constructor (
    public localizationService: AppLocalizationService,
    private _router: Router,
    private _appService: AppService,
    private _entityService: EntityService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit (): void {
    this._appService.onPlatformChanged
      .pipe(
        filter((x) => !!x),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((platform) => (this.platform = platform));

    this._route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((params) => {
      this.queryParams = params;
    });
  }

  ngAfterViewInit (): void {
    this.backgroundPicture = getBackgroundPicture(this.item.backgroundPicture, this.item?.style?.background, 500);

    if (this.posterContainer) {
      this.setContainerWidth();
    }

    if (this.item.backgroundPicture.blurred) {
      this.setBackgroundBlurPicture();
    }
  }

  onClick (action: ExploreEngagementAction): void {
    if (!action) {
      return;
    }

    const mergedParams = { ...this.queryParams };
    let params = '';

    if (Object.keys(mergedParams)?.length) {
      params = '?' + new URLSearchParams(mergedParams).toString();
    }

    if (action.target === 'venue' && action?.id) {
      if (this.item.quickOrder?.isAllowed || action.newWindow) {
        window.open(
          (this.platform.baseUrl || 'https://chillz.com') + '/' + (action?.id || this.item?.entity?.id) + params,
          '_blank'
        );
      } else {
        if (this.item.entity?.id === action?.id) {
          this._entityService.venue = this.item.entity;
        }

        this._router.navigate([ '/', action?.id || this.item?.entity?.id ], { queryParamsHandling: 'merge' });
      }
    }

    if (action.target === 'explore' && action?.id) {
      if (this.item.quickOrder?.isAllowed || this.item.action?.newWindow) {
        window.open((this.platform.baseUrl || 'https://chillz.com/explore/') + '/' + action?.id + params, '_blank');
      } else {
        this._router.navigate([ '/', 'explore', action?.id ], { queryParamsHandling: 'merge' });
      }
    }

    if (action?.target === 'url' && action?.url) {
      window.open(action?.url + params, '_blank');
    }
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private setContainerWidth (): void {
    if (this.posterContainer) {
      this.width = this.posterContainer.nativeElement.offsetWidth;
    }
  }

  private setBackgroundBlurPicture (): void {
    this.backgroundBlurPicture = getResponsiveSrc(this.item.backgroundPicture.blurred, this.width || 250);
  }
}
