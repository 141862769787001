import { Entity } from '../../../main/entity/entity.model';
import { ChillzPicture } from '../picture.model';
import { ChillzVideo } from '../video.model';
import { EntityEditingComponent } from './entity-editing-component.model';
import { MediaSelectionValue } from './media-selection-value';
import { StyleProperty } from './style-property.model';


export class MediaSourcesList {
  sources?: MediaSource[];
  allowedMediaTypes?: ('Video' | 'Picture')[];

  constructor (
    sources: any[] = [],
    allowedMediaTypes: ('Video' | 'Picture')[] = [ 'Video', 'Picture' ],
    private _isEntityMutable = true,
    private addDefaultIfMissing = true
  ) {
    this.allowedMediaTypes = allowedMediaTypes;
    this.setValue(sources);
  }

  setValue (sources: any[] = []): void {
    this.sources = sources?.map((x) => new MediaSource({ isEntityMutable: this._isEntityMutable, ...x }));

    if (!this.sources.find((x) => !x.entityValue?.component) && this.addDefaultIfMissing) {
      this.sources.push(new MediaSource());
    }
  }

  updateEntityMediaValues (entity: Entity): void {
    this.sources?.forEach((s) => s.updateEntityMediaValue(entity));
  }

  get activeSource (): MediaSource {
    return this.sources?.find((x) => !x.disabled && (x.entityMediaValue?.mediaItem || x.selectionValue?.mediaItem));
  }

  get enabledSources (): MediaSource[] {
    return this.sources?.filter((x) => !x.disabled);
  }

  get enabledSourcesWithValue (): MediaSource[] {
    return this.sources?.filter((x) => !x.disabled && (x.entityMediaValue?.mediaItem || x.selectionValue?.mediaItem));
  }

  toJson (): any[] {
    return this.sources?.map((x) => x.toJson());
  }
}

export class MediaSource {
  name?: { [lang: string]: string };
  tooltip?: { [lang: string]: string };
  disabled?: boolean;
  opacity?: StyleProperty;
  blurred = false;

  entityValue?: {
    component: EntityEditingComponent;
  };

  selectionValue?: MediaSelectionValue;

  entityMediaValue?: MediaSelectionValue;

  constructor (style: any = {}) {
    this.opacity = new StyleProperty(1, 'frac');
    this.selectionValue = new MediaSelectionValue();

    this.setValue(style);
  }

  setValue (value: any = {}): void {
    if (value.selectionValue !== undefined) {
      this.selectionValue.setValue(value.selectionValue);
    }

    if (value?.entityValue?.component) {
      this.entityValue = {
        component: new EntityEditingComponent(value?.entityValue?.component),
      };
    }

    if (value?.name) {
      this.name = value.name;
    }

    if (value?.tooltip) {
      this.tooltip = value.tooltip;
    }

    if (value?.opacity) {
      this.opacity.setValue(value.opacity);
    }

    if (value?.blurred !== undefined) {
      this.blurred = value?.blurred;
    }

    if (value?.disabled !== undefined) {
      this.disabled = value?.disabled;
    }
  }

  updateEntityMediaValue (entity: Entity): void {
    if (!this.entityValue?.component || !entity) {
      return (this.entityMediaValue = null);
    } else {
      this.entityMediaValue = new MediaSelectionValue({
        mediaType: this.entityValue?.component?.mediaProperties?.mediaType,
        mediaItem: entity[this.entityValue.component.id],
      });
    }
  }

  getPictureSrc (blurredAllowed = true): { url: string; isBlurred: boolean } {
    let value: MediaSelectionValue;

    if (this.entityValue?.component?.id) {
      value = this.entityMediaValue;
    } else {
      value = this.selectionValue;
    }

    if (value?.mediaType === 'Picture') {
      if (value?.mediaItem) {
        if (blurredAllowed && this?.blurred && (value?.mediaItem as ChillzPicture)?.blurred?.md) {
          return { url: (value?.mediaItem as ChillzPicture)?.blurred?.md, isBlurred: true };
        }

        return { url: (value?.mediaItem as ChillzPicture)?.md, isBlurred: false };
      }
    }
  }

  get video (): ChillzVideo {
    let value: MediaSelectionValue;

    if (this.entityValue?.component?.id) {
      value = this.entityMediaValue;
    } else {
      value = this.selectionValue;
    }

    if (value?.mediaType === 'Video') {
      return value?.mediaItem;
    }
  }

  toJson (nullEmpty = false): any {
    return {
      opacity: this.opacity?.value || (nullEmpty ? null : undefined),
      name: this.name || (nullEmpty ? null : undefined),
      tooltip: this.tooltip || (nullEmpty ? null : undefined),
      disabled: this.disabled,
      selectionValue: this.selectionValue.toJson(nullEmpty),
      blurred: this.blurred || (nullEmpty ? null : undefined),
      entityValue: this.entityValue,
    };
  }
}
