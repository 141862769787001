import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { Ticket } from '../../models/ticket.model';
import { locale as english } from '../i18n/en';
import { locale as hebrew } from '../i18n/he';
import { TicketService } from '../ticket-view-dialog/ticket.service';
import { TicketViewDialogComponent } from '../ticket-view-dialog/ticket-view-dialog.component';


@Component({
  selector: 'app-ticket-thumb',
  templateUrl: './ticket-thumb.component.html',
  styleUrls: [ './ticket-thumb.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class TicketThumbComponent implements OnInit {
  @Input() ticket: Ticket;
  dialogRef: any;

  gettingApplePass: boolean;

  constructor (
    public _matDialog: MatDialog,
    public _translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private _ticketService: TicketService
  ) {
    this.translationLoader.loadTranslations(english, hebrew);
  }

  ngOnInit (): void {}

  viewTicket (ticket): void {
    if (ticket.cancelled) {
      return;
    }

    this.dialogRef = this._matDialog.open(TicketViewDialogComponent, {
      panelClass: 'ticket-view-dialog',
      data: {
        ticket,
      },
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  getApplePass (): void {
    this.gettingApplePass = true;
    this._ticketService
      .getTicketApplePass(this.ticket)
      .then((x) => console.log(x))
      .catch((err) => console.log(err))
      .finally(() => (this.gettingApplePass = false));
  }
}
