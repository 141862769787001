<form class="settings-section-body" [formGroup]="detailsFormGroup">
  <mat-form-field formGroupName="birthday" floatLabel="auto">
    <mat-label>Date of Birth</mat-label>
    <input
      matInput
      [matDatepicker]="dp"
      formControlName="dateObj"
      [max]="birthdayValidRange.max.toDate()"
      [min]="birthdayValidRange.min.toDate()"
    />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      [touchUi]="true"
      [startView]="detailsFormGroup.get('birthday').get('dateObj').value ? 'month' : 'multi-year'"
      [startAt]="detailsFormGroup.get('birthday').get('dateObj').value || '2000-01-01'"
      #dp
    ></mat-datepicker>
  </mat-form-field>

  <mat-form-field floatLabel="auto">
    <mat-label>Gender</mat-label>
    <mat-select formControlName="gender">
      <mat-option></mat-option>
      <mat-option value="male">Male</mat-option>
      <mat-option value="female">Female</mat-option>
      <mat-option value="other">Other</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container formGroupName="hometown">
    <mat-form-field appearance="standard">
      <mat-label>Country</mat-label>
      <mat-select formControlName="country">
        <mat-option></mat-option>
        <mat-option [value]="country.code" *ngFor="let country of countries">{{ country.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="standard" *ngIf="detailsFormGroup.get('hometown').get('country').value === 'US'">
      <mat-label>State</mat-label>
      <mat-select formControlName="state">
        <mat-option></mat-option>
        <mat-option [value]="state.abbreviation" *ngFor="let state of states">{{ state.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>City</mat-label>
      <input matInput name="city" type="text" formControlName="city" />
    </mat-form-field>
  </ng-container>

  <div class="settings-section-actions">
    <chillz-button
      [label]="status !== 'saved' ? { en: 'Save', he: 'שמירה' } : undefined"
      [icon]="status === 'saved' ? 'checkmark' : undefined"
      [isSvgIcon]="true"
      [fontSize]="'14px'"
      [padding]="'6px 20px'"
      [height]="26"
      [loaderSize]="26"
      [hoverPush]="true"
      [expandable]="false"
      [outline]="true"
      [hoverOutlineShine]="true"
      [outlineShine]="true"
      [shadow]="true"
      [glow]="false"
      [isLoader]="true"
      [disabled]="detailsFormGroup.invalid || detailsFormGroup.pristine || status === 'saving'"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-button>
  </div>
</form>
