import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AppLocalizationService } from './app-localization.service';
import { ExploreItem } from './shared/models/explore-item.model';


@Injectable({
  providedIn: 'root',
})
export class SplashService implements Resolve<any> {
  routeParams: any;

  lastDisplayed: string;

  splashItem: ExploreItem;
  onSplashItemChanged = new BehaviorSubject<ExploreItem>(undefined);

  constructor (
    private _httpClient: HttpClient,
    private _localizationService: AppLocalizationService,
    private _router: Router,
    @Inject(DOCUMENT) private _document: any
  ) {}

  /**
   * Resolver
   *
   */
  resolve (route: ActivatedRouteSnapshot): Promise<boolean> {
    this.routeParams = route.params;

    return new Promise((resolve) => {
      Promise.all([ this.getExploreThemeSplashScreen(this.routeParams.theme) ]).then(() => {
        resolve(false);
      }, resolve);
    });
  }

  /**
   * Get Splash Screen
   *
   */
  getSplashScreen (theme): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!theme) {
        reject('Neither theme or filter was provided');
      }

      this._httpClient.get('/platform').subscribe((response: any) => {
        this.splashItem = new ExploreItem(response.platform);

        this.onSplashItemChanged.next(this.splashItem);
        resolve(this.splashItem);
      }, reject);
    });
  }

  /**
   * Get Splash Screen
   *
   */
  getExploreThemeSplashScreen (theme): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(theme, this.lastDisplayed);
      if (!theme || theme === this.lastDisplayed) {
        return reject('Theme was not provided');
      }

      this.lastDisplayed = theme;

      this._httpClient.get('/explore/' + theme + '/splashItem').subscribe((response: any) => {
        this.splashItem = response.splashItem ? new ExploreItem(response.splashItem) : null;
        this.onSplashItemChanged.next(this.splashItem);
        resolve(this.splashItem);
      }, reject);
    });
  }

  dismissedSplashItem (): void {
    this.splashItem = null;
    this.onSplashItemChanged.next(this.splashItem);
  }
}
