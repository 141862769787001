import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'platform',
})
export class ServerService {
  port = 4000;

  get url (): string {
    return 'http://localhost:' + this.port;
  }
}
