export const locale = {
  lang: 'he',
  data: {
    ORDER_PREPARATION: {
      ITEMS_ADDED: 'פריטים',
      ONE_ITEM_ADDED: 'פריט אחד',
      SUB_TOTAL: 'מחיר',
      TOTAL: 'מחיר סופי',
      SAVED: 'חסכת',
      CONTINUE: 'המשך',
      CALCULATING: 'בודקים כמה זה יוצא...',
      CALCULATING_TOTAL: 'בודקים כמה זה יוצא...',
      NO_CHARGE: 'לא לדאוג, זה עדיין לא מחייב',
      INCLUDING_FEES: 'כולל דמי שירות',
      JUST_A_MOMENT: 'מתחילים את ההזמנה שלך, זה ייקח רק כמה שניות',
      NEXT_REWARD: 'הטבה תופעל עבור הזמנה',
      MORE_THAN: 'עם',
      ITEMS: 'פריטים ומעלה',
      OR: 'או',
      AMOUNT: 'בסכום גבוה מ-',
      SUBTOTAL_DISCLAIMER: 'לא כולל דמי שירות',
      BACK_TO_START: 'עריכה',
    },
    TICKET_TYPE_STATUS: {
      'SOLD-OUT': 'SOLD OUT',
      'SALE-ENDED': 'המכירה הסתיימה',
      'SOON': 'בקרוב',
      'UNAVAILABLE': 'לא זמין',
    },
  },
};
