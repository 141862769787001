import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { countries } from '../../../auth/countries';
import { usStates } from '../../../auth/us-states';
import { User } from '../../../auth/user.model';
import { Platform } from '../../../shared/models/platform.model';
import { ProfileService } from '../profile.service';


const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: environment.localization.shortFormat,
  },
  display: {
    dateInput: environment.localization.shortFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-profile-general-details',
  templateUrl: './profile-general-details.component.html',
  styleUrls: [ './profile-general-details.component.scss', '../profile.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export class ProfileGeneralDetailsComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;

  countries = countries;
  states = usStates;
  detailsFormGroup: FormGroup;

  birthdayValidRange = {
    max: moment().subtract(15, 'years'),
    min: moment().subtract(90, 'years'),
  };

  status: 'saving' | 'saved';
  @Output() status$: EventEmitter<'saving' | 'saved'> = new EventEmitter<'saving' | 'saved'>();

  private _unsubscribeAll = new Subject<any>();

  constructor (
    private _profileService: ProfileService,
    private _authService: ChillzAuthService,
    private _appService: AppService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit (): void {
    this.detailsFormGroup = this._formBuilder.group({
      about: [],
      hometown: this._formBuilder.group({
        country: [],
        state: [],
        city: [],
      }),
      gender: [],
      birthday: this._formBuilder.group({
        year: [],
        month: [],
        day: [],
        dateObj: [],
      }),
      profilePicture: [],
    });

    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
        this.detailsFormGroup.patchValue(this.profile, { emitEvent: false });
        this.detailsFormGroup.markAsPristine();

        const birthday = this.profile.birthday;
        this.detailsFormGroup.get('birthday').get('year').setValue(birthday?.year, { emitEvent: false });
        this.detailsFormGroup.get('birthday').get('month').setValue(birthday?.month, { emitEvent: false });
        this.detailsFormGroup.get('birthday').get('day').setValue(birthday?.day, { emitEvent: false });

        if (birthday?.year && birthday?.month && birthday?.day) {
          const m = moment([ birthday?.year, birthday?.month - 1, birthday?.day ]);
          this.detailsFormGroup.get('birthday').get('dateObj').setValue(m, { emitEvent: false });
        }
      });

    this.detailsFormGroup
      .get('birthday')
      .get('dateObj')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value: moment.Moment) => {
        this.detailsFormGroup.get('birthday').get('year').setValue(value?.year(), { emitEvent: false });
        this.detailsFormGroup
          .get('birthday')
          .get('month')
          .setValue(value ? value?.month() + 1 : null, { emitEvent: false });
        this.detailsFormGroup.get('birthday').get('day').setValue(value?.date(), { emitEvent: false });
      });

    this.detailsFormGroup
      .get('hometown')
      .get('country')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        if (value !== 'US') {
          this.detailsFormGroup.get('hometown').get('state').setValue(null, { emitEvent: false });
        }
      });

    this.status$
      .pipe(
        tap((status) => {
          this.status = status;
        }),
        debounceTime(5000),
        tap((status) => {
          if (status === 'saved') {
            this.status = null;
          }
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  onSave (): void {
    this.status$.emit('saving');
    this._profileService
      .updateProfile(this.detailsFormGroup.getRawValue())
      .then(() => {
        this.status$.emit('saved');
      })
      .catch((err) => {
        this.status$.emit(null);
        console.log(err);
      })
      .finally(() => {
        this.detailsFormGroup.markAsPristine();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
