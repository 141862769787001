<button mat-icon-button class="search-button pop-animation" (click)="openSearchBar()">
  <mat-icon svgIcon="search" class="s-24"></mat-icon>
</button>

<div @fadeInOut class="chillz-search-bar" *ngIf="!collapsed">
  <div class="chillz-search-blur" (click)="collapse()"></div>
  <div class="chillz-search-bar-content mx-auto">
    <mat-form-field
      floatLabel="never"
      class="chillz-form-field-rounded appearance-transparent-gray-300 w-100-p"
      id="chillz-search-bar-input"
    >
      <mat-icon matPrefix svgIcon="search" class="s-24"></mat-icon>
      <input type="text" #searchInput [formControl]="searchControl" matInput />
      <button
        mat-button
        *ngIf="searchControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="results" *ngIf="searchService.loading; else displayContent">
      <div class="search-loader">
        <chillz-loader [size]="48"></chillz-loader>
      </div>
    </div>

    <ng-template #displayContent>
      <div class="results">
        <div
          class="recent-content"
          *ngIf="searchResults | showSearchRecentValue: searchControl.value; else searchResult"
        >
          <div class="recently-viewed w-100-p" *ngIf="recentlyViewed && recentlyViewed.length > 0">
            <p class="w-100-p H7-Regular mt-0 me-200">Recently Viewed</p>
            <app-search-result-list
              class="search-results recent-results"
              [searchList]="recentlyViewed"
              [platform]="platform"
              (selectItem)="selectItem($event)"
            ></app-search-result-list>
          </div>
          <div class="search-history" *ngIf="searchHistory &&  searchHistory.length > 0">
            <p class="w-100-p H7-Regular mt-0 me-200">Recent searches</p>
            <div class="result-content">
              <div *ngFor="let item of searchHistory" class="result" (click)="selectRecentSearch(item)">
                <mat-icon svgIcon="chillz-past"></mat-icon>
                <span class="H7-Regular Black-100 history-text">{{ item }}</span>
              </div>
            </div>
          </div>
        </div>

        <ng-template #searchResult>
          <ng-container *ngIf="searchResults | searchableValue: searchControl.value">
            <ng-container *ngIf="isEmptySearch">
              <div class="search-results">
                <div class="no-info-wrapper fade-animation">
                  <mat-icon [class]="'s-48'" svgIcon="chillz-no-data"></mat-icon>
                  <p class="H7-Regular black-100 m-0">No results found</p>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!!searchResults.length">
              <app-search-result-list
                class="search-results"
                [searchList]="searchResults"
                [platform]="platform"
                (selectItem)="selectItem($event)"
              ></app-search-result-list>
            </ng-container>
          </ng-container>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
