<form class="settings-section-body">
  <chillz-phone-number-input
    [containerClass]="'legacy-phone-number-input'"
    [disabled]="!!phoneVerificationToken"
    [initialValue]="{ countryCode: profile.countryCode, phoneNumber: profile.phoneNumber }"
    (valueChanged)="onPhoneChanged($event)"
  ></chillz-phone-number-input>

  <app-verification-code
    popAnimation
    [phone]="parsedPhoneNumber?.number?.national"
    *ngIfAnimated="phoneVerificationToken"
    (tokenChange)="!$event ? status$.next('saved') : null"
    [(token)]="phoneVerificationToken"
  ></app-verification-code>

  <mat-error *ngIf="phoneError" fxFlex="1 1 auto">{{ phoneError }}</mat-error>

  <div class="settings-section-actions">
    <chillz-button
      *ngIf="!phoneVerificationToken"
      popAnimation
      [disabled]="!phoneNumberFormGroup?.valid || phoneNumberFormGroup.pristine || status === 'saving'"
      [label]="status !== 'saved' ? { en: 'Save', he: 'שמירה' } : undefined"
      [icon]="status === 'saved' ? 'checkmark' : undefined"
      [isSvgIcon]="true"
      [fontSize]="'14px'"
      [padding]="'6px 20px'"
      [height]="26"
      [loaderSize]="26"
      [hoverPush]="true"
      [expandable]="false"
      [outline]="true"
      [hoverOutlineShine]="true"
      [outlineShine]="true"
      [shadow]="true"
      [glow]="false"
      [isLoader]="true"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-button>
  </div>
</form>
