import { ChillzFile } from './chillz-file.model';


export class ChillzMediaItem {
  _id?: string;
  url?: string;
  type?: string;

  constructor (item: any = {}) {
    this._id = item._id;
    this.url = ChillzFile.cdnUrl(item.url);
    this.type = item.type;
  }
}
