<div fxLayout="column">
  <div class="bundle-title" *ngIf="!hideTitle">
    {{bundleOffer.title[localizationService.currentLang] || bundleOffer.title['en'] }}
  </div>
  <div class="bundle-description" *ngIf="!hideDescription">
    {{bundleOffer.description[localizationService.currentLang] || bundleOffer.description['en'] }}
  </div>
  <div class="tiers-container" fxLayout="row" [style.min-height]="tiersHeight" dir="ltr">
    <div
      class="tier"
      fxLayout="column"
      fxLayoutAlign="center center"
      [class.is-current]="true"
      [style.box-shadow]="'4px 0px 8px 3px #0000ffa8'"
      [ngStyle]="{ background: 'linear-gradient(83.87deg, #0000ff, #ff0000)' }"
      *ngFor="let tier of bundleOffer.tiers; let i = index"
      [fxFlex]="100 / bundleOffer.tiers.length"
    >
      <div class="arrow-head" *ngIf="i < bundleOffer.tiers.length - 1"></div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="tier-title">{{tier.title[localizationService.currentLang] || tier.title['en'] }}</div>
        <div class="tier-summary">{{tier.summary[localizationService.currentLang] || tier.summary['en'] }}</div>
      </div>
    </div>
  </div>
</div>
