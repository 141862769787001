export class ColorStyle {
  value: 'related-accent' | 'host-accent' | string;

  constructor (
    value?: 'related-accent' | 'host-accent' | string,
    private _default?: 'related-accent' | 'host-accent' | string
  ) {
    this.setValue(value);
  }

  setValue (value?: 'related-accent' | 'host-accent' | string, _gradient?: any): void {
    this.value = value;
  }

  clear (): void {
    this.value = this._default;
  }

  isColor (): boolean {
    return ![ 'related-accent', 'host-accent' ].includes(this.value);
  }

  toString (
    colorValueOnly = false,
    relatedAccent?: string,
    hostAccent?: string
  ): 'related-accent' | 'host-accent' | string {
    if (colorValueOnly) {
      let res;
      if ([ 'related-accent', 'host-accent' ].includes(this.value)) {
        if (this.value === 'related-accent' && relatedAccent) {
          res = relatedAccent;
        }

        if (this.value === 'host-accent' && hostAccent) {
          res = hostAccent;
        }

        if (!res) {
          return null;
        }
      } else {
        res = this.value;
      }

      // if (this.gradient?.enabled && res && bgString) {
      //   res = this.gradient.toString(res);
      // }
      return res;
    }

    return this.value;
  }
}
