import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

import { AppLocalizationService } from './app-localization.service';
import { ChillzConfirmationDialogComponent } from './shared/components/dialogs/chillz-confirmation-dialog/chillz-confirmation-dialog.component';
import { Platform } from './shared/models/platform.model';
import { TrackingService } from './shared/tracking.service';


@Injectable({
  providedIn: 'root',
})
export class AppService implements Resolve<any> {
  private renderer2: Renderer2;
  loadedFonts: { [font: string]: number[] } = {};
  loadedAdobeWebProjects: string[] = [];

  platform: Platform;
  onPlatformChanged = new BehaviorSubject<Platform>(undefined);
  openedModals: string[] = [];
  screenHeight: number;
  screenWidth: number;
  onScreenWidthChanged = new BehaviorSubject<{
    width: number;
    group?: string;
  }>(null);

  isBrowser: boolean;
  dialogRef: any;
  isIntro$ = new BehaviorSubject<boolean>(null);
  isPreview$ = new BehaviorSubject<boolean>(false);
  hubspotPageView$ = new Subject<string>();
  hideToolbar$ = new BehaviorSubject<boolean>(false);
  showAnimatedBackground$ = new BehaviorSubject<boolean>(false);

  scroll$ = new BehaviorSubject<Event>(null);

  private readonly hiddenModalContentClass = 'hidden-modal-content';

  constructor (
    private _httpClient: HttpClient,
    private _localizationService: AppLocalizationService,
    private _trackingService: TrackingService,
    private _matDialog: MatDialog,
    @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) platformId,
    rendererFactory: RendererFactory2
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);

    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.getScreenSize();
    }
  }

  getWindow (): Window | null {
    return this._document.defaultView;
  }

  /**
   * Resolver
   *
   */
  resolve (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Promise.all([ this.getPlatform() ]).then(() => {
        resolve(false);
      }, reject);
    });
  }

  confirmationDialog (
    title?,
    subtitle?,
    description?,
    confirm?,
    cancel?,
    hideCancelButton?,
    hideConfirmButton?
  ): Promise<boolean> {
    return new Promise((resolve) => {
      this.dialogRef = this._matDialog.open(ChillzConfirmationDialogComponent, {
        panelClass: [ 'chillz-dialog' ],
        data: {
          title,
          subtitle,
          description,
          confirm,
          cancel,
          hideCancelButton,
          hideConfirmButton,
        },
      });

      this.dialogRef.afterClosed().subscribe((response) => {
        resolve(response);
      });
    });
  }

  getScreenSize (): void {
    this.screenHeight = this.getWindow()?.innerHeight;
    this.screenWidth = this.getWindow()?.innerWidth;

    let sizeGroup;

    if (this.screenWidth >= 1600) {
      sizeGroup = 'xl';
    } else if (this.screenWidth >= 1200) {
      sizeGroup = 'lg';
    } else if (this.screenWidth >= 992) {
      sizeGroup = 'md';
    } else if (this.screenWidth >= 768) {
      sizeGroup = 'sm';
    } else {
      sizeGroup = 'xs';
    }

    if (
      this.getWindow()?.innerWidth !== this.onScreenWidthChanged?.value?.width ||
      sizeGroup !== this.onScreenWidthChanged?.value?.group
    ) {
      this.onScreenWidthChanged.next({ width: this.screenWidth, group: sizeGroup });
    }
  }

  /**
   * Get platform
   *
   */
  getPlatform (): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.platform) {
        this.onPlatformChanged.next(this.platform);
        return resolve(this.platform);
      }

      this._httpClient.get('/platform').subscribe((response: any) => {
        this.platform = new Platform(response.platform);

        const favIcon = this._document.head.querySelector('#fav-icon');
        if (favIcon) {
          favIcon.href = this.platform.favIcon;
        }

        this._localizationService.setLanguages(
          this.platform.localization.availableLanguages,
          this.platform.localization.defaultLanguage
        );

        if (this.platform?.uiDefaultFonts) {
          const fontsToLoad = {};
          for (const font of this.platform.uiDefaultFonts) {
            if (font.isGoogleFont) {
              fontsToLoad[font.font] = font.weights?.length ? font.weights : null;
            }

            if (font.adobeWebProject) {
              this.loadAdobeWebProject(font.adobeWebProject);
            }
          }
          this.loadGoogleFonts(fontsToLoad);

          this.renderer2.setStyle(
            this._document.body,
            'font-family',
            this.platform.uiDefaultFonts?.map((x) => x.font).join(', ')
          );
        }

        if (this.isBrowser) {
          for (const pixel of this.platform.integrations.facebookPixels || []) {
            this._trackingService.addPixel(pixel);
          }

          for (const analytics of this.platform.integrations.googleAnalytics || []) {
            this._trackingService.addGA(analytics);
          }
          for (const tag of this.platform.integrations.gtag || []) {
            this._trackingService.addGtag(tag);
          }

          for (const gtm of this.platform.integrations.gtm || []) {
            this._trackingService.addGTM(gtm);
          }

          for (const sc of this.platform.integrations.snapchat || []) {
            this._trackingService.addSnapchat(sc);
          }

          for (const tiktok of this.platform.integrations.tiktok || []) {
            this._trackingService.addTiktok(tiktok);
          }

          if (this.platform?.integrations?.nagich) {
            const nagich = this.renderer2.createElement('script');
            this.renderer2.setAttribute(nagich, 'data-cfasync', 'false');

            nagich.text = `window.interdeal = ${JSON.stringify(this.platform?.integrations?.nagich)}`;
            this.renderer2.appendChild(this._document.head, nagich);

            const nagich2 = this.renderer2.createElement('script');
            nagich2.text = `(function(doc, head, body){
        var coreCall             = doc.createElement('script');
        coreCall.src             = 'https://js.nagich.co.il/core/2.1.10/accessibility.js';
        coreCall.defer           = true;
        coreCall.integrity       = 'sha512-PWvjZ/e9EGeHEPppEOqscViKxQIW1/5rJeFedXLJiv0IrltJPvuhrYOiUsOkQ49FaZ5HSDp51/O/6V9uL2nNIA==';
        coreCall.crossOrigin     = 'anonymous';
        coreCall.setAttribute('data-cfasync', true );
        body? body.appendChild(coreCall) : head.appendChild(coreCall);
      })(document, document.head, document.body);`;
            this.renderer2.appendChild(this._document.head, nagich2);
          }

          if (this.platform?.integrations?.userWay) {
            const userWay = this.renderer2.createElement('script');

            userWay.text = `
                  (function(){
                  var s = document.createElement("script");s.setAttribute("data-account","${this.platform?.integrations?.userWay}");
                  s.setAttribute("src","https://cdn.userway.org/widget.js");
                  document.body.appendChild(s);})();
              `;
            this.renderer2.appendChild(this._document.body, userWay);
          }
        }

        this.onPlatformChanged.next(this.platform);
        resolve(this.platform);
      }, reject);
    });
  }

  loadGoogleFonts (fonts: { [font: string]: number[] }): void {
    const fontsElement = this.renderer2.createElement('link');
    const fontFamilies = [];

    for (const font of Object.keys(fonts)) {
      if (!fonts[font]?.length) {
        fonts[font] = this.platform?.optionalFonts?.find((x) => x.font === font)?.weights || [ 100, 200, 300, 400, 500, 600, 700, 800, 900, ];
      }

      if (this.loadedFonts[font]) {
        fonts[font] = fonts[font].filter((w) => !this.loadedFonts[font].includes(w));
      }

      if (!fonts[font]?.length) {
        continue;
      }

      fontFamilies.push(`family=${font}:wght@${fonts[font].map((x) => x.toString()).join(';')}`);
      this.loadedFonts[font] = this.loadedFonts[font] || [];
      this.loadedFonts[font].push(...fonts[font]);
    }

    if (fontFamilies?.length) {
      fontsElement.setAttribute('href', `https://fonts.googleapis.com/css2?${fontFamilies.join('&')}&display=swap`);
      fontsElement.setAttribute('rel', 'stylesheet');
      this.renderer2.appendChild(this._document.head, fontsElement);
    }
  }

  loadAdobeWebProject (project: string): void {
    if (this.loadedAdobeWebProjects.includes(project)) {
      return;
    }

    const fontsElement = this.renderer2.createElement('link');

    fontsElement.setAttribute('href', `https://use.typekit.net/${project}.css`);
    fontsElement.setAttribute('rel', 'stylesheet');
    this.renderer2.appendChild(this._document.head, fontsElement);

    this.loadedAdobeWebProjects.push(project);
  }

  openModal (modalName: string): void {
    if (!this.openedModals.length) {
      this.renderer2.addClass(this._document.body, this.hiddenModalContentClass);
    }

    const modalExist = this.openedModals.some((openModalName) => openModalName === modalName);
    if (!modalExist) {
      this.openedModals.push(modalName);
    }
  }

  closeModal (modalName: string): void {
    const modalIndex = this.openedModals.findIndex((openModalName) => openModalName === modalName);
    if (modalIndex >= 0) {
      this.openedModals.splice(modalIndex, 1);
    }

    if (!this.openedModals.length) {
      this.renderer2.removeClass(this._document.body, this.hiddenModalContentClass);
    }
  }

  showAnimatedBackground (): void {
    this.showAnimatedBackground$.next(true);
  }

  hideAnimatedBackground (): void {
    this.showAnimatedBackground$.next(false);
  }
}
