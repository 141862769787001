<div
  class="carousel-container"
  [class.carousel-with-base-explore-item]="theme?.style?.item?.isBaseExploreItem"
  fxFlex="100"
  fxLayout="column"
>
  <div
    fxLayoutAlign="space-between center"
    class="intro-section-header"
    [style.padding-right]="(itemFraction * (theme.style.carousel.startOffset?.[currentWidth] || 0)) + '%'"
    [style.padding-left]="(itemFraction * (theme.style.carousel.startOffset?.[currentWidth] || 0)) + '%'"
    [dir]="localizationService?.textAttr.direction"
    *ngIf="theme?.title?.['en'] || theme?.inlineSectionSettings?.CTA?.title?.['en']"
    [ngStyle]="{ 'text-align': localizationService?.textAttr.align, background: theme.inlineSectionSettings?.headerBackground }"
  >
    <h2
      class="intro-section-title"
      [class.cursor-pointer]="theme?.inlineSectionSettings?.CTA?.title"
      (mouseup)="goToTheme.emit()"
      *ngIf="theme?.title"
    >
      {{ theme.title[localizationService.currentLang] || theme.title['en'] }}
    </h2>
    <div class="link" *ngIf="theme?.inlineSectionSettings?.CTA?.title" (mouseup)="goToTheme.emit()">
      <button mat-ripple>
        {{ theme?.inlineSectionSettings?.CTA?.title[localizationService.currentLang] ||
        theme?.inlineSectionSettings?.CTA?.title['en'] }}
      </button>
    </div>
  </div>
  <div
    class="chillz-carousel"
    [class.carousel-poster]="theme?.style?.carousel?.type === 'carousel-poster'"
    [class.has-title]="theme?.title?.['en'] || theme?.inlineSectionSettings?.CTA?.title?.['en']"
    [style.aspect-ratio]="theme?.style?.carousel?.aspectRatio"
    [style.min-height]="theme?.style?.carousel?.minHeight?.toString() || theme?.style?.item?.height?.toString()"
    [style.max-height]="theme?.style?.carousel?.maxHeight?.toString()"
    [style.height]="theme?.style?.carousel?.height?.toString()"
  >
    <div class="chillz-carousel-items-scroll-container">
      <div
        class="chillz-carousel-items"
        [style.--speed]="theme?.style?.carousel?.speed"
        [style.grid-template-columns]="getGridColumnsTemplate()"
        [style.transform]="'translateX(' + currentSlideTranslate + ')'"
        [style.margin-top]="isFirstSection && theme.style.carousel.type === 'carousel-poster' && (hideToolbar$ | async) === false ? '1.5em' : 0"
        [@animateStagger]="{ value: '50' }"
        (touchstart)="onTouchStart($event)"
        (touchmove)="onTouchMove($event)"
        (touchend)="onTouchEnd()"
      >
        <ng-container *ngIf="theme.style.carousel.type === 'carousel-poster' else default;">
          <app-carousel-poster-item *ngFor="let item of theme.items" [item]="item"> </app-carousel-poster-item>
        </ng-container>

        <ng-template #default>
          <app-item-card-new
            *ngFor="let item of theme.items"
            [item]="item"
            [isBaseExploreItem]="theme?.style?.item?.isBaseExploreItem"
            [style.margin-right]="(viewSize > 1 || (theme.items.length > 1 && theme.style.carousel.endOffset?.[currentWidth])) ? theme.style?.gap?.toString() : '0'"
            [@animate]="{ value: '*', params: { opacity: '0', duration: '3s' } }"
            [style.height]="theme?.style?.carousel?.aspectRatio ? '100%' : theme?.style?.item?.height?.toString()"
          >
          </app-item-card-new>
        </ng-template>
      </div>
    </div>

    <ng-container *ngIf="theme?.items?.length > 1">
      <div
        class="carousel-progress"
        *ngIf="theme?.style?.carousel?.pagination as pagination"
        [class.custom-position]="true"
        [class.position-bottom]="pagination?.positionConfig?.position === 'bottom'"
        [class.position-top]="pagination?.positionConfig?.position === 'top'"
        [class.placement-start]="pagination?.positionConfig?.placement === 'start'"
        [class.placement-center]="pagination?.positionConfig?.placement === 'center'"
        [class.placement-end]="pagination?.positionConfig?.placement === 'end'"
        [style.--pos-top]="pagination?.positionConfig?.customPosition?.top"
        [style.--pos-bottom]="pagination?.positionConfig?.customPosition?.bottom"
        [style.--pos-left]="pagination?.positionConfig?.customPosition?.left"
        [style.--pos-right]="pagination?.positionConfig?.customPosition?.right"
        [style.--edge-offset]="pagination?.positionConfig?.customPosition?.edgeOffset"
      >
        <div class="carousel-progress-wrapper">
          <div class="carousel-progress-content">
            <div
              class="autoplay-control"
              *ngIf="(theme?.style?.carousel?.autoPlay?.enabled && theme?.style?.carousel?.pagination?.autoPlayControl)"
              [class.reset]="isPaused || isResetTimerProcess"
              [style.--autoPlayInterval]="autoPlayInterval + 'ms'"
              (click)="toggleAutoPlay()"
            >
              <div class="action">
                <mat-icon *ngIf="!isPaused; else playIcon" svgIcon="chillz-pause"></mat-icon>

                <ng-template #playIcon>
                  <mat-icon class="s-16" svgIcon="chillz-play"></mat-icon>
                </ng-template>
              </div>

              <div class="progress">
                <svg version="1.1" width="48px" height="48px">
                  <defs>
                    <linearGradient id="GradientColor">
                      <stop offset="08" stop-color="#DA22FF" />
                      <stop offset="100%" stop-color="#9733EE" />
                    </linearGradient>
                  </defs>
                  <circle cx="24" cy="24" r="22" stroke-linecap="round" />
                </svg>
              </div>
            </div>

            <div class="pagination" *ngIf="theme?.style?.carousel?.pagination?.bullets && theme.items?.length > 1">
              <div
                *ngFor="let item of theme.items; index as i"
                class="slide-dot"
                (click)="setCurrentSlide(i)"
                [class.active]="currentSlide === i"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="theme?.style?.carousel?.navigationActionsVariant === 'round'; else defaultNavigationArrows">
      <div
        dir="ltr"
        (click)="onChangeSlide('prev')"
        [class.disabled]="currentSlide <= 0"
        class="round-carousel-arrow-container round-carousel-arrow-container-left"
      >
        <button class="carousel-arrow">
          <mat-icon class="s-28" svgIcon="chillz-arrow-short"></mat-icon>
        </button>
      </div>

      <div
        dir="rtl"
        (click)="onChangeSlide('next')"
        [class.disabled]="currentSlide >= theme.items.length - viewSize"
        class="round-carousel-arrow-container round-carousel-arrow-container-right"
      >
        <button class="carousel-arrow">
          <mat-icon class="s-28" svgIcon="chillz-arrow-short"></mat-icon>
        </button>
      </div>
    </ng-container>

    <ng-template #defaultNavigationArrows>
      <div
        dir="ltr"
        (click)="onChangeSlide('prev')"
        [class.transparent]="currentSlide <= 0"
        class="carousel-arrow-container carousel-arrow-container-left"
      >
        <button class="carousel-arrow">
          <mat-icon class="s-40">arrow_back_ios</mat-icon>
        </button>
      </div>

      <div
        dir="rtl"
        (click)="onChangeSlide('next')"
        [class.transparent]="currentSlide >= theme.items.length - viewSize"
        class="carousel-arrow-container carousel-arrow-container-right"
      >
        <button class="carousel-arrow">
          <mat-icon class="s-40">arrow_forward_ios</mat-icon>
        </button>
      </div>
    </ng-template>
  </div>
</div>
