import { ColorStyle } from './color-style.model';
import { StyleProperty } from './style-property.model';


export class BorderStyle {
  color: ColorStyle;
  width: StyleProperty;
  radius?: StyleProperty;

  constructor (style: any = {}, defaultColor?: 'related-accent' | 'host-accent' | string) {
    this.width = new StyleProperty(null, 'em', { step: 0.1, minFractionDigits: 1, maxFractionDigits: 1 });
    this.radius = new StyleProperty(null, 'em', { step: 0.1, minFractionDigits: 1, maxFractionDigits: 1 });
    this.color = new ColorStyle(undefined, defaultColor);

    this.setValue(style);
  }

  setValue (value: any = {}): void {
    if (value?.color !== undefined) {
      this.color.setValue(value.color);
    }

    if (value?.radius !== undefined) {
      this.radius.setValue(value.radius);
    }

    if (value?.width !== undefined) {
      this.width.setValue(value.width);
    }
  }

  toJson (): any {
    return {
      color: this.color?.toString(),
      radius: this.radius?.toString(),
      width: this.width?.toString(),
    };
  }
}
