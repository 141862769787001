import * as cleanDeep from 'clean-deep';

import { BundleOffer } from '../../../shared/models/bundle-offer.model';
import { GeneralProduct } from '../../../shared/models/general-product.model';
import { PromoCode } from '../../../shared/models/promo-code.model';
import { TicketType } from '../../../shared/models/ticket-type.model';
import { Entity } from '../../entity/entity.model';


export class Order {
  _id: string;
  createdAt: Date;
  expired: boolean;
  completed: boolean;
  id: string;
  discount: number;
  venue: Entity;
  seating: boolean;
  seatsSavedUntil: Date;
  ticketsSavedUntil: Date;
  installments: number;
  items: {
    _id: string;
    ticketType: TicketType;
    product: GeneralProduct;
    quantity: number;
    seats: any;
    subtotal: number;
    discount: number;
    appliedPromoCodes: any[];
    subtotalBeforeVAT: number;
    ticketDetails: any;
  }[];
  details: {
    fullName: string;
    countryCode: string;
    phoneNumber: string;
    email: string;
  }[];
  total: number;
  subtotal: number;
  tip: number;
  buyerProcessingFee: number;
  accumulatedFees: [
    {
      name: { [lang: string]: string };
      value: number;
    }
  ];
  serviceFee: number;
  additionalFee: {
    amount: number;
    description: { [lang: string]: string };
  }[];
  taxes: [
    {
      tax: string;
      value: number;
    }
  ];
  coinz: number;
  currency: string;
  VAT: number;
  tickets: any;
  paymentMethod: any;
  error: string;
  pendingApproval: boolean;
  rejected: boolean;
  comment: string;
  stripe: {
    isStripe: boolean;
    clientSecret: string;
    publicKey: string;
    setupIntent: any;
    paymentIntent: any;
    stripeAccount: string;
  };
  token: string;
  paymentService: {
    primaryTerminal?: {
      name?: string;
      provider?: string;
    };
    _id: string;
  };
  termsAndConditions: {
    ticketing: {
      generalTerms: { [lang: string]: string };
      refundPolicy: { [lang: string]: string };
      ageRestriction: { [lang: string]: string };
    };
    promotionConsent: {
      checkbox: { [lang: string]: string };
      isMandatory: boolean;
      disclaimer: { [lang: string]: string };
    };
    extraCheckboxes: {
      text: { [lang: string]: string };
      cta: {
        text: { [lang: string]: string };
        url: string;
      };
    }[];
  };
  paymentSplitting: any;
  splittedPaymentMethods: number;

  eventParticipation: Entity;
  bundle: {
    bundleOffer: BundleOffer;
    currentTier: number;
  };
  ticketDetails: any;

  promoCodes: PromoCode[];
  entryPoint: string;

  /**
   * Constructor
   *
   */
  constructor (order?) {
    order = order || {};

    this._id = order._id;
    this.createdAt = order.createdAt;
    this.expired = order.expired;
    this.completed = order.completed;
    this.id = order.id;
    this.details = order.details || [];
    this.total = order.total;
    this.subtotal = order.subtotal;
    this.tip = order.tip;
    this.buyerProcessingFee = order.buyerProcessingFee;
    this.accumulatedFees = order.accumulatedFees;
    this.currency = order.currency;
    this.VAT = order.VAT;
    this.discount = order.discount;
    this.seating = order.seating;
    this.seatsSavedUntil = order.seatsSavedUntil;
    this.ticketsSavedUntil = order.ticketsSavedUntil;
    this.tickets = order.tickets;
    this.coinz = order.coinz;
    this.paymentMethod = order.paymentMethod;
    this.error = order.error;
    this.pendingApproval = order.pendingApproval;
    this.rejected = order.reviewed && order.reviewed.verdict === 'rejected';
    this.comment = order.reviewed?.comment;
    this.serviceFee = order.serviceFee;
    this.additionalFee = order.additionalFee || [];
    this.taxes = order.taxes || [];
    this.entryPoint = order.entryPoint;
    this.installments = order.installments;

    this.promoCodes = order.promoCodes?.map((x) => new PromoCode(x));

    this.stripe = order.stripe;
    this.paymentService = order.paymentService;

    if (order.termsAndConditions) {
      order.termsAndConditions = cleanDeep.default(order?.termsAndConditions);
    }

    this.termsAndConditions = order.termsAndConditions;

    this.items = order.items;
    this.items.forEach((item) => {
      if (item.ticketType) {
        item.ticketType = new TicketType(item.ticketType);
      }

      if (item.product) {
        item.product = new GeneralProduct(item.product);
      }
    });

    if (order.bundle?.bundleOffer) {
      this.bundle = {
        bundleOffer: new BundleOffer(order.bundle.bundleOffer),
        currentTier: order.bundle.currentTier,
      };
    }

    if (order.venue) {
      this.venue = order.venue instanceof Entity ? order.venue : new Entity(order.venue);
    }

    if (order.eventParticipation) {
      this.eventParticipation =
        order.eventParticipation instanceof Entity ? order.eventParticipation : new Entity(order.eventParticipation);
    } else if (this.venue) {
      this.eventParticipation = this.venue;
    }

    this.paymentSplitting = order.paymentSplitting;
    this.splittedPaymentMethods = order.splittedPaymentMethods;
    this.ticketDetails = order.ticketDetails;
  }

  get discountPromoCodes (): PromoCode[] {
    return this.promoCodes?.filter((x) => x.type === 'discount') || [];
  }
}
