<div *ngIf="album" class="pic-viewer-container" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="background" (click)="onClose()"></div>

  <div class="image-container" fxFlex fxFlexAlign="center center">
    <img [src]="album[position].url" alt="" />
  </div>

  <button mat-icon-button class="close-button" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="nav-buttons" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="album.length > 1">
    <button mat-icon-button class="nav-button mr-8" (click)="onPrev()">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>

    <button mat-icon-button class="nav-button ml-8" (click)="onNext()">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>
