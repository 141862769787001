import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { from, isObservable, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingService } from './loading.service';


@Injectable({
  providedIn: 'root',
})
export abstract class BaseResolver<T> implements Resolve<T> {
  protected constructor (private loadingService: LoadingService, private router: Router) {}

  resolve (route: ActivatedRouteSnapshot): Observable<T> | Promise<T> | T {
    const isNavigationEvent = this.router.getCurrentNavigation().previousNavigation;

    if (isNavigationEvent) {
      this.loadingService.showLoader();

      const result = this.getData(route);

      if (isObservable(result)) {
        return result.pipe(finalize(() => this.loadingService.hideLoader()));
      } else if (result instanceof Promise) {
        return from(result).pipe(finalize(() => this.loadingService.hideLoader()));
      } else {
        this.loadingService.hideLoader();
        return result;
      }
    } else {
      return this.getData(route);
    }
  }

  abstract getData (route: ActivatedRouteSnapshot): Observable<T> | Promise<T> | T;
}
