import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '../../../../@fuse/animations';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { AppService } from '../../../app.service';
import { navigation } from '../../../navigation/navigation';


@Component({
  selector: 'vertical-layout-1',
  templateUrl: './layout-1.component.html',
  styleUrls: [ './layout-1.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class VerticalLayout1Component implements OnInit, OnDestroy, AfterViewInit {
  fuseConfig: any;
  navigation: any;
  showAnimatedBackground$ = this._appService.showAnimatedBackground$;

  embedded;
  hideBranding;
  hideToolbar;
  isBrowser;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  private _unsubscribeAll: Subject<any>;

  constructor (
    private _fuseConfigService: FuseConfigService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _appService: AppService,
    @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) platformId,
    private _router: Router
  ) {
    // Set the defaults
    this.navigation = navigation;
    this.isBrowser = isPlatformBrowser(platformId);

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit (): void {
    this.initQueryAndConfig();
    this.initPlatformChanges();
  }

  getWindow (): Window | null {
    return this._document.defaultView;
  }

  ngAfterViewInit (): void {
    this._location.subscribe((ev: any) => {
      // console.log(ev.url);
      this.lastPoppedUrl = ev.url;
    });
    this._router.events.subscribe((ev: any) => {
      if (this.isBrowser) {
        if (ev instanceof NavigationStart) {
          if (ev.url !== this.lastPoppedUrl) {
            // this.yScrollStack.push(this.scrollableContainer.nativeElement.scrollTop);
            // this.yScrollStack.push(window?.scrollY);
          }
        } else if (ev instanceof NavigationEnd) {
          if (ev.url === this.lastPoppedUrl) {
            this.lastPoppedUrl = undefined;
            setTimeout(() => {
              // window.scrollTo(0, this.yScrollStack.pop());
              // this.scrollableContainer.nativeElement.scrollTop = this.yScrollStack.pop();
            }, 100);
          } else {
            // window?.scrollTo(0, 0);
            // this.scrollableContainer.nativeElement.scrollTop = 0;
            // console.log(this.scrollableContainer);
          }
        }
      }
    });
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private initQueryAndConfig (): void {
    combineLatest([ this._route.queryParams, this._fuseConfigService.config ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([ params, config ]) => {
        this.embedded = params['embed'] === '1';
        this.hideBranding = params['hideBranding'] === '1';
        this.fuseConfig = config;

        this.hideToolbar = this.fuseConfig.layout.toolbar.hidden || params['hideToolbar'] === '1';
        if (this.hideToolbar) {
          this._appService.hideToolbar$.next(this.hideToolbar);
        }
      });
  }

  private initPlatformChanges (): void {
    this._appService.onPlatformChanged
      .pipe(
        filter((x) => !!x),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((platform) => {
        if (!platform?.toolbar?.enabled) {
          this.hideToolbar = true;
        }

        if (this._appService.hideToolbar$.getValue() !== this.hideToolbar) {
          this._appService.hideToolbar$.next(this.hideToolbar);
        }
      });
  }
}
