import { ChillzPicture } from '../shared/models/picture.model';
import { SocialMediaProfile } from '../shared/models/social-media-profile.model';


export class User {
  _id: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  phoneNumber: string;
  displayName: string;
  about: string;
  isModerator: boolean;
  pictures: ChillzPicture[];
  profilePicture: ChillzPicture;
  coverPicture: ChillzPicture;
  birthday: {
    year: number;
    month: number;
    day: number;
  };

  privacy: any;

  coinz: number;
  isSelf: boolean;
  chillzStatus: any;
  joinedAt: Date;
  hasPassword: boolean;

  paymentMethods: any;

  gender: string;

  hometown: {
    formatted: string;
    googlePlaceId: string;
    country: string;
    state: string;
    city: string;
    coords: {
      lat: number;
      lng: number;
    };
  };

  facebook: string;
  instagram: string;
  tiktok: string;
  socialMediaProfiles: SocialMediaProfile[];

  lastPasswordChange: Date;

  private _fullName: string;

  /**
   * Constructor
   *
   */
  constructor (user) {
    {
      this._id = user._id;
      this.id = user.id;
      this.email = user.email;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this._fullName = user.fullName;
      this.about = user.about;
      this.displayName = user.displayName;
      this.pictures = user.pictures?.map((x) => new ChillzPicture(x)) || [];
      this.profilePicture = user.profilePicture ? new ChillzPicture(user.profilePicture) : undefined;
      this.coverPicture = user.coverPicture ? new ChillzPicture(user.coverPicture) : undefined;
      this.hometown = user.hometown;
      this.socialMediaProfiles = user.socialMediaProfiles?.map((x) => new SocialMediaProfile(x)) || [];

      this.privacy = user.privacy;

      this.countryCode = user.countryCode;
      this.phoneNumber = user.phoneNumber;

      this.hasPassword = user.hasPassword;
      this.birthday = user.birthday;
      if (user.joinedAt) {
        this.joinedAt = new Date(user.joinedAt);
      }

      if (user.lastPasswordChange) {
        this.lastPasswordChange = new Date(user.lastPasswordChange);
      }

      this.hasPassword = user.hasPassword;
      this.countryCode = user.countryCode;
      this.phoneNumber = user.phoneNumber;

      this.birthday = user.birthday;
      this.gender = user.gender;
      this.facebook = user.facebook;
      this.tiktok = user.tiktok;
      this.instagram = user.instagram;

      if (!this.displayName) {
        if (this.firstName) {
          this.displayName = this.firstName;

          if (this.lastName) {
            this.displayName += ` ${this.lastName}`;
          }
        }
      }
    }
  }

  get fullName (): string {
    if (this._fullName) {
      return this._fullName;
    }

    let fullName = '';
    if (this.firstName) {
      fullName += this.firstName;
    }

    if (this.firstName + this.lastName) {
      fullName += ' ';
    }

    if (this.lastName) {
      fullName += this.lastName;
    }

    return fullName;
  }
}
