import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { FooterComponent } from './footer.component';


@NgModule({
  declarations: [ FooterComponent ],
  imports: [ RouterModule, MatButtonModule, MatIconModule, MatToolbarModule, FuseSharedModule, MatMenuModule ],
  exports: [ FooterComponent ],
})
export class FooterModule {}
