import { BackgroundStyle } from './background-style.model';
import { BorderStyle } from './border-style.model';
import { ButtonStyle, EntityMenuItemStyle } from './button-style.model';
import { ItemStyle } from './collection-item-style.model';
import { FlexAlignmentStyleProperty } from './flex-style-property';
import { PaddingStyleProperty } from './padding-style-property';
import { SelectableStyle } from './selectable-style.model';
import { StyleProperty } from './style-property.model';


export class CarouselSettings {
  type: 'default' | 'carousel-poster';
  grid?: { [size: string]: number };
  slide?: { [size: string]: number };
  speed?: string;
  pagination?: {
    bullets?: boolean;
    autoPlayControl?: boolean;
    positionConfig?: {
      position?: 'bottom' | 'top';
      placement?: 'start' | 'center' | 'end';
      customPosition?: {
        left?: StyleProperty;
        right?: StyleProperty;
        top?: StyleProperty;
        bottom?: StyleProperty;
        edgeOffset?: StyleProperty; // Defined distance from carousel edges to the pagination block. Default 2%
      };
    };
  };
  navigationActionsVariant?: 'default' | 'round';

  autoPlay?: {
    enabled: boolean;
    interval: number;
  };

  rows?: number;
  aspectRatio?: number;
  minHeight?: StyleProperty;
  maxHeight?: StyleProperty;
  height?: StyleProperty;
  startOffset?: { [size: string]: number };
  endOffset?: { [size: string]: number };

  constructor (settings: any = {}) {
    this.grid = {};
    this.slide = {};
    this.startOffset = {};
    this.endOffset = {};
    this.autoPlay = {
      enabled: false,
      interval: 4000,
    };
    this.speed = '400ms';
    this.minHeight = new StyleProperty();
    this.maxHeight = new StyleProperty();
    this.height = new StyleProperty();
    this.type = 'default';

    this.pagination = {
      bullets: false,
      autoPlayControl: false,
      positionConfig: {
        position: 'bottom',
        placement: 'center',
      },
    };
    this.navigationActionsVariant = 'default';

    this.setValue(settings);
  }

  setValue (value: any = {}): void {
    if (value?.grid) {
      for (const size of Object.keys(value.grid)) {
        this.grid[size] = value.grid[size];
      }
    }

    if (value?.slide) {
      for (const size of Object.keys(value.slide)) {
        this.slide[size] = value.slide[size];
      }
    }

    if (value?.startOffset) {
      for (const size of Object.keys(value.startOffset)) {
        this.startOffset[size] = value.startOffset[size];
      }
    }

    if (value?.endOffset) {
      for (const size of Object.keys(value.endOffset)) {
        this.endOffset[size] = value.endOffset[size];
      }
    }

    if (value.speed !== undefined) {
      this.speed = value.speed?.toString() + 'ms';
    }

    if (value.autoPlay?.interval !== undefined) {
      this.autoPlay.interval = value.autoPlay.interval;
    }

    if (value.autoPlay?.enabled !== undefined) {
      this.autoPlay.enabled = value.autoPlay.enabled;
    }

    if (value.pagination?.bullets !== undefined) {
      this.pagination.bullets = value.pagination.bullets;
    }

    if (value.pagination?.autoPlayControl !== undefined) {
      this.pagination.autoPlayControl = value.pagination.autoPlayControl;
    }

    if (value.pagination?.positionConfig?.position !== undefined) {
      this.pagination.positionConfig.position = value.pagination?.positionConfig?.position;
    }

    if (value.pagination?.positionConfig?.placement !== undefined) {
      this.pagination.positionConfig.placement = value.pagination?.positionConfig?.placement;
    }

    if (value.pagination?.positionConfig?.customPosition !== undefined) {
      this.pagination.positionConfig.customPosition = {};

      const customPosition = value.pagination?.positionConfig?.customPosition;

      if (customPosition?.left !== undefined) {
        this.pagination.positionConfig.customPosition.left = new StyleProperty(customPosition.left);
      }

      if (customPosition?.right !== undefined) {
        this.pagination.positionConfig.customPosition.right = new StyleProperty(customPosition.right);
      }

      if (customPosition?.top !== undefined) {
        this.pagination.positionConfig.customPosition.top = new StyleProperty(customPosition.top);
      }

      if (customPosition?.bottom !== undefined) {
        this.pagination.positionConfig.customPosition.bottom = new StyleProperty(customPosition.bottom);
      }

      if (customPosition?.edgeOffset !== undefined) {
        this.pagination.positionConfig.customPosition.edgeOffset = new StyleProperty(customPosition.edgeOffset);
      }
    }

    if (value.navigationActionsVariant !== undefined) {
      this.navigationActionsVariant = value.navigationActionsVariant;
    }

    if (value.rows !== undefined) {
      this.rows = value.rows;
    }

    if (value.height !== undefined) {
      this.height.setValue(value.height);
    }

    if (value.minHeight !== undefined) {
      this.minHeight.setValue(value.minHeight);
    }

    if (value.maxHeight !== undefined) {
      this.maxHeight.setValue(value.maxHeight);
    }

    if (value.type !== undefined) {
      this.type = value.type;
    }

    this.aspectRatio = value.aspectRatio;

    // TODO: temporal config
    if (value.type !== undefined) {
      this.type = value.type;

      if (value.type === 'carousel-poster') {
        this.pagination = {
          bullets: true,
          autoPlayControl: true,
          positionConfig: {
            position: 'bottom',
            placement: 'center',
            customPosition: {
              left: new StyleProperty('10%'),
              bottom: new StyleProperty('10%'),
            },
          },
        };

        this.navigationActionsVariant = 'round';
      }
    }
  }

  toJson (): any {
    return {
      grid: this.grid,
      slide: this.slide,
      speed: this.speed,
      rows: this.rows,
      pagination: this.pagination,
      navigationActionsVariant: this.navigationActionsVariant,
      aspectRatio: this.aspectRatio,
      height: this.height?.toString(),
      minHeight: this.minHeight?.toString(),
      maxHeight: this.maxHeight?.toString(),
      endOffset: this.endOffset,
      startOffset: this.startOffset,
    };
  }
}

export class CollectionStyle extends SelectableStyle {
  flexDirection?: string;
  flexAlignment?: FlexAlignmentStyleProperty;

  gap?: StyleProperty;
  maxColumns = 2;
  minColumnWidth: StyleProperty;

  carousel?: CarouselSettings;
  gridTemplateCols: 'auto-fill' | 'auto-fit' = 'auto-fill';
  display?: 'grid' | 'flex' = 'grid';

  constructor (style: any = {}) {
    super(style);
    this._id = style._id;
    this.gap = new StyleProperty('16px');
    this.minColumnWidth = new StyleProperty('400px');
    this.flexAlignment = new FlexAlignmentStyleProperty();
    this.carousel = new CarouselSettings();
  }

  setValue (value: any = {}): void {
    super.setValue(value);

    if (value.display !== undefined) {
      this.display = value.display;
    }

    if (value.gridTemplateCols) {
      this.gridTemplateCols = value.gridTemplateCols;
    }

    if (value.maxColumns !== undefined) {
      this.maxColumns = value.maxColumns || 2;
    }

    if (value.flexDirection !== undefined) {
      this.flexDirection = value.flexDirection;
    }

    if (value.flexAlignment) {
      this.flexAlignment.setValue(value.flexAlignment);
    }

    if (value.minColumnWidth) {
      this.minColumnWidth.setValue(value.minColumnWidth);
    }

    if (value.gap) {
      this.gap.setValue(value.gap);
    }

    if (value.carousel) {
      this.carousel.setValue(value.carousel);
    }
  }

  gridTemplateColumns (viewSize?: number): string {
    if (typeof this.gap.value !== 'number') {
      return;
    }

    return `repeat(${this.gridTemplateCols || 'auto-fill'}, minmax(max(min(100%, ${this.minColumnWidth.toString(viewSize)}), calc(${Math.floor(100 / this.maxColumns - 1).toFixed(0)}% - ${Math.ceil(this.gap.value / this.maxColumns).toFixed(0)}${this.gap.unit})), 1fr))`;
  }

  toJson (excludeAttributions = false): any {
    return {
      ...super.toJson(excludeAttributions),
      flexDirection: this.flexDirection,
      flexAlignment: this.flexAlignment?.toJson(),
      gridTemplateCols: this.gridTemplateCols,
      gap: this.gap?.toString(),
      maxColumns: this.maxColumns,
      minColumnWidth: this.minColumnWidth?.toString(),
      carousel: this.carousel?.toJson(),
    };
  }
}

export class ButtonsGroupStyle extends CollectionStyle {
  item?: ButtonStyle;

  constructor (style: any = {}) {
    super(style);
    this.item = new ButtonStyle();
  }

  setValue (value: any = {}): void {
    super.setValue(value);
    if (value.item) {
      this.item.setValue(value.item);
    }
  }

  toJson (excludeAttributions = false): any {
    return {
      ...super.toJson(excludeAttributions),
      item: this.item?.toJson(),
    };
  }
}

export class ItemsCollectionStyle extends CollectionStyle {
  item?: ItemStyle;

  constructor (style: any = {}) {
    super(style);
    this.item = new ItemStyle();
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    super.setValue(value);
    if (value.item) {
      this.item.setValue(value.item);
    }
  }

  toJson (excludeAttributions = false): any {
    return {
      ...super.toJson(excludeAttributions),
      item: this.item?.toJson(),
    };
  }
}

export class EntityMenuStyle extends CollectionStyle {
  border: BorderStyle;
  background: BackgroundStyle;
  expandedBackground: BackgroundStyle;

  maxHeight: StyleProperty;
  minHeight: StyleProperty;
  height: StyleProperty;
  padding: PaddingStyleProperty;
  item: { default?: EntityMenuItemStyle; hover?: EntityMenuItemStyle };

  constructor (style: any = {}) {
    super(style);
    this.item = {};
    this.border = new BorderStyle();
    this.background = new BackgroundStyle();
    this.expandedBackground = new BackgroundStyle();
    this.minHeight = new StyleProperty();
    this.maxHeight = new StyleProperty();
    this.height = new StyleProperty();
    this.padding = new PaddingStyleProperty();
    this.setValue(style);
  }

  setValue (value: any = {}): void {
    super.setValue(value);
    if (value.item?.default) {
      this.item.default = new EntityMenuItemStyle(value.item.default);
    }

    if (value.item?.hover) {
      this.item.hover = new EntityMenuItemStyle(value.item.hover);
    }

    if (value.border !== undefined) {
      this.border.setValue(value.maxHbordereight);
    }

    if (value.padding !== undefined) {
      this.padding.setValue(value.padding);
    }

    if (value.background !== undefined) {
      this.background.setValue(value.background);
    }

    if (value.expandedBackground !== undefined) {
      this.expandedBackground.setValue(value.expandedBackground);
    }

    if (value.maxHeight !== undefined) {
      this.maxHeight.setValue(value.maxHeight);
    }

    if (value.minHeight !== undefined) {
      this.minHeight.setValue(value.minHeight);
    }

    if (value.height !== undefined) {
      this.height.setValue(value.height);
    }
  }

  toJson (): any {
    return {
      ...super.toJson(),
      item: {
        default: this.item?.default?.toJson(),
        hover: this.item?.hover?.toJson(),
      },
      border: this.border?.toJson(),
      background: this.background?.toJson(),
      expandedBackground: this.expandedBackground?.toJson(),
      maxHeight: this.maxHeight?.toString(),
      minHeight: this.minHeight?.toString(),
      height: this.height?.toString(),
      padding: this.padding?.toJson(),
    };
  }
}
