import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';


export const emailValidator = Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,}$');

export const instagramValidator = Validators.pattern('(?!.*\\.\\.)(?!.*\\.$)[^\\W][\\w.]{0,29}');

export const facebookValidator = Validators.pattern('(?:(?:http|https):\\/\\/)?(?:www.)?(?:m.)?facebook.com\\/(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[?\\w\\-]*\\/)?(?:profile.php\\/?\\?id=(?=\\d.*))?([^\\s\\/\\\\&?]*)?');

export const tiktokValidator = Validators.pattern('(?!.*\\.\\.)(?!.*\\.$)[a-z1-9-_\\.]{0,29}');

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
