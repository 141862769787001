<section
  class="chillz-content-section tickets p-12"
  fxLayoutAlign="start stretch"
  [class.highlighted]="highlighted"
  [ngClass]="containerClass"
  [style.border-color]="accentColor"
  [style.box-shadow]="'0 0 15px 8px ' + accentColor + '66'"
  fxLayout="column"
>
  <h2
    class="entity-secondary-headline"
    *ngIf="title"
    [ngClass]="localizationService.textAttr.direction"
    [style.font-family]="headlineStyle?.getFontFamily()"
    [style.color]="headlineStyle?.color?.toString(true) || accentColor"
    [style.text-transform]="headlineStyle?.textTransform"
    [style.font-size]="headlineStyle?.fontSize?.toString()"
    [style.font-weight]="headlineStyle?.fontWeight"
    [style.line-height]="headlineStyle?.lineHeight"
  >
    {{ title[localizationService.currentLang] || title['en'] }}
  </h2>

  <div fxLayout="column" fxLayoutAlign="start" *ngIf="selectedItemsFormArray?.length > 0">
    <div
      class="ticket-type"
      *ngFor="let item of selectedItemsFormArray.controls; let i = index"
      fxFlex="100"
      fxLayout="column"
      fxLayoutAlign="stat stretch"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxFlex="1 0 calc(100% - 175px)" fxLayoutAlign="start center">
          <div>
            <div class="ticket-type-name">
              <strong class="break-word">{{ item.get('item').value.title }}</strong>
            </div>
            <div class="ticket-type-price">
              <span
                *ngIf="
                  item.get('item').value.constructor.name === 'TicketType' &&
                  item.get('item').value.seatRequired &&
                  item.get('item').value.price > 0
                "
              >
                {{ 'VENUE.GENERAL.TICKETS.FROM' | translate }}
              </span>
              <span *ngIf="item.get('item').value.price > 0">
                {{ item.get('item').value.price | currency: item.get('item').value.currency }}
              </span>
              <span *ngIf="item.get('item').value.price === 0"> {{ 'VENUE.GENERAL.TICKETS.FREE' | translate }} </span>
            </div>

            <div
              class="ticket-type-price"
              *ngIf="
                item.get('item').value.nondetachableProducts &&
                (
                  item.get('item').value.nondetachableProducts[localizationService.currentLang] ||
                  item.get('item').value.nondetachableProducts['en']
                )?.length
              "
            >
              {{ 'VENUE.GENERAL.TICKETS.INCLUDES' | translate }}
              <strong>
                {{ ( item.get('item').value.nondetachableProducts[localizationService.currentLang] ||
                item.get('item').value.nondetachableProducts['en'] ).join(', ') }}
              </strong>
            </div>
            <ng-container
              *ngIf="
                description &&
                item.get('item').value.description &&
                (
                  item.get('item').value.description[localizationService.currentLang] ||
                  item.get('item').value.description['en']
                )?.length
              "
            >
              <div
                class="ticket-type-description"
                [@detailExpand]="item.get('item').value.descriptionExpanded ? 'expanded' : 'collapsed'"
                [innerText]="
                  item.get('item').value.description[localizationService.currentLang] ||
                  item.get('item').value.description['en']
                "
              ></div>
              <span
                (click)="item.get('item').value.descriptionExpanded = !item.get('item').value.descriptionExpanded"
                class="ticket-type-more-info-button cursor-pointer font-weight-500"
                *ngIf="item.get('item').value.description.isCollapsed !== false"
              >
                {{ item.get('item').value.descriptionExpanded ? 'Show less' : 'More Info' }}
              </span>
            </ng-container>
          </div>
        </div>
        <div fxFlex="0 0 12px"></div>
        <div fxFlex="1 1 auto" fxLayoutAlign="center start">
          <div
            [style.min-width]="'130px'"
            *ngIf="
              !item.get('item').value.soldOut &&
              !item.get('item').value.unavailable &&
              ['available'].includes(item.get('item').value.status?.status?.id)
            "
          >
            <chillz-button
              [loading]="selectedTicketTypeLoading === item.get('item').value"
              aria-label="Buy Now"
              [disabled]="item.get('item').value === selectedTicketTypeLoading"
              [cta]="true"
              [label]="{ en: 'VENUE.GENERAL.TICKETS.CTA.PICK_SEATS' | translate }"
              (clicked)="onTicketTypeSelect(item.get('item').value)"
              [glow]="false"
              [shadow]="true"
              [padding]="'8px 18px'"
              [outline]="true"
              [outlineShine]="true"
              [letterSpacing]="'0'"
              [isLoader]="true"
              *ngIf="item.get('item').value.seatRequired"
            ></chillz-button>

            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              class="gap-8"
              *ngIf="!item.get('item').value.seatRequired"
            >
              <chillz-button
                [glow]="false"
                [shadow]="true"
                [padding]="'2px'"
                [outline]="true"
                [outlineShine]="true"
                [iconClass]="'s-16'"
                [icon]="'remove'"
                [height]="20"
                [width]="20"
                *ngIf="item.get('quantity').value > 0"
                (clicked)="onSubtractQuantity(item)"
              ></chillz-button>

              <chillz-button
                *ngIf="item.get('quantity').value"
                [classes]="'cursor-default'"
                popAnimation
                [glow]="false"
                [shadow]="true"
                [outline]="true"
                [padding]="'0'"
                [height]="30"
                [fontSize]="'18px'"
                [hoverPush]="false"
                [width]="50"
                [outlineShine]="true"
                [hoverOutlineShine]="false"
                [hoverBackgroundPosition]="false"
                [cta]="true"
                [label]="{ en: item.get('quantity').value.toString() }"
              ></chillz-button>

              <chillz-button
                [glow]="false"
                [shadow]="true"
                [padding]="'4px'"
                [outline]="true"
                [outlineShine]="true"
                [iconClass]="item.get('quantity').value ? 's-16' : 's-24'"
                [icon]="'add'"
                [isLoader]="false"
                [height]="item.get('quantity').value ? 20 : 30"
                [width]="item.get('quantity').value ? 20 : 50"
                [cta]="!item.get('quantity').value"
                (clicked)="onAddQuantity(item)"
              ></chillz-button>
            </div>
          </div>

          <chillz-button
            [glow]="false"
            [width]="130"
            [shadow]="true"
            [outline]="true"
            [hoverPush]="false"
            [fontSize]="'12px'"
            [padding]="'6px 24px'"
            [hoverOutlineShine]="false"
            [outlineShine]="false"
            [label]="
              item.get('item').value.status.status?.id === 'custom'
                ? { en: item.get('item').value.status?.custom }
                : item.get('item').value.status?.status?.name
            "
            *ngIf="!['available'].includes(item.get('item').value.status?.status?.id)"
            (clicked)="(null)"
          ></chillz-button>
        </div>
      </div>
    </div>
  </div>

  <div class="content" fxLayout="column" fxLayoutAlign="start" *ngIf="externalTickets?.url">
    <div class="ticket-type" fxFlex="100" fxLayout="column" fxLayoutAlign="start stretch">
      <a [href]="externalTickets?.url" target="_blank">
        <button
          class="chillz-button w-100-p"
          mat-ripple
          aria-label="External Purchase URL"
          (click)="onExternalTicketsClick()"
        >
          {{ 'VENUE.GENERAL.TICKETS.EXTERNAL_CTA' | translate }}
        </button>
      </a>
      <div class="category mt-4 text-center" *ngIf="externalTickets?.location">{{ externalTickets?.location }}</div>
    </div>
  </div>
</section>

<div fxLayoutAlign="center center" class="mt-12" *ngIf="specialAccessCodeAllowed && venue">
  <chillz-button
    *ngIf="!specialAccessCodeControl"
    popAnimation
    [label]="{ en: 'Unlock Hidden Tickets', he: 'שיחרור כרטיסים נסתרים' }"
    [hoverPush]="true"
    [hoverBackgroundPosition]="true"
    [padding]="'8px 16px'"
    [height]="36"
    [letterSpacing]="'1px'"
    [fontSize]="'12px'"
    [outline]="false"
    [outlineShine]="false"
    [icon]="'lock'"
    [isSvgIcon]="true"
    [iconClass]="'s-20'"
    [expandable]="false"
    (clicked)="enterPassword()"
  ></chillz-button>

  <form
    fxLayout="row"
    fxLayoutAlign="start center"
    class="gap-12 special-access-code"
    hopInAnimation
    [hopInDirection]="'up'"
    *ngIf="specialAccessCodeControl"
  >
    <mat-form-field appearance="fill">
      <mat-label>{{ 'VENUE.GENERAL.TICKETS.PROMOTION_CODE.TITLE' | translate }}</mat-label>
      <input #couponCode [formControl]="specialAccessCodeControl" matInput type="text" />
      <mat-error *ngIf="specialAccessCodeControl.hasError('invalidCode')">
        {{ 'VENUE.GENERAL.TICKETS.PROMOTION_CODE.INVALID' | translate }}
      </mat-error>
      <!--      <mat-hint>{{'VENUE.GENERAL.TICKETS.PROMOTION_CODE.INFO' | translate}}</mat-hint>-->
    </mat-form-field>
    <div class="w-68">
      <chillz-button
        [glow]="true"
        [shadow]="true"
        [outline]="true"
        [padding]="'0 16px'"
        [height]="30"
        [loaderSize]="30"
        [isLoader]="true"
        [loading]="sendingCoupon"
        [fontSize]="'12px'"
        [hoverPush]="true"
        [disabled]="sendingCoupon || specialAccessCodeControl.invalid"
        (clicked)="sendCouponCode(specialAccessCodeControl.value)"
        [outlineShine]="true"
        [label]="{ en: 'Submit' }"
      ></chillz-button>
    </div>
    <chillz-button
      [glow]="false"
      [shadow]="false"
      [outline]="true"
      [width]="24"
      [height]="24"
      [hoverPush]="true"
      [icon]="'close'"
      [iconClass]="'s-16'"
      [disabled]="sendingCoupon"
      (clicked)="specialAccessCodeControl = undefined"
      [outlineShine]="false"
    ></chillz-button>
  </form>
</div>
