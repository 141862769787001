import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';

import { Ticket } from '../../models/ticket.model';


@Injectable()
export class TicketService implements Resolve<any> {
  constructor (private _httpClient: HttpClient, private _router: Router, private _FileSaverService: FileSaverService) {
    // Set the defaults
  }

  /**
   * Resolver
   *
   */
  resolve (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Promise.all([])
        .then(() => {
          resolve(false);
        })
        .catch(() => {
          this._router.navigate([ '/', 'errors', '500' ]);
          reject();
        });
    });
  }

  requestTicket (ticketId): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('/tickets/' + ticketId).subscribe((response: any) => {
        resolve(response);
      }, reject);
    });
  }

  updateTicket (ticketId, update): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put('/tickets/' + ticketId, update).subscribe((response: any) => {
        resolve(response.ticket);
      }, reject);
    });
  }

  getTicket (ticketId, accessKey, email, healthDeclaration): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put('/tickets/' + ticketId, {
          accessKey,
          healthDeclaration,
          email,
        })
        .subscribe((response: any) => {
          resolve(response.ticket);
        }, reject);
    });
  }

  getTicketApplePass (ticket: Ticket): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get('/tickets/' + ticket.id, {
          params: { response: 'apple-wallet' },
          responseType: 'blob',
          observe: 'response',
        })
        .subscribe((response: any) => {
          this._FileSaverService.save(response.body, `${ticket._id}.pkpass`);

          resolve(response);
        }, reject);
    });
  }
}
