<div
  class="venue-card row"
  fxFlex="100"
  [routerLink]="['/', linkTarget, linkId]"
  [fxHide]="style !== 'venue-row'"
  [dir]="textAttr.direction"
>
  <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
    <div class="image-wrapper">
      <img [src]="venue.pictures[0].url" *ngIf="venue.pictures.length > 0 && venue.pictures[0]" />
      <img src="assets/images/default-thumb.jpg" *ngIf="venue.pictures.length === 0 || !venue.pictures[0]" />

      <div class="like">
        <!--                <button mat-icon-button aria-label="Save" (click)="onActivity('like')" matTooltip="Save" matTooltipPosition="below">-->
        <!--                    <mat-icon [class.active]="userStatuses['like']">{{userStatuses['like'] ? 'favorite' : 'favorite_border'}}</mat-icon>-->
        <!--                </button>-->
      </div>
    </div>

    <div class="px-16" fxLayout="column" fxLayoutAlign="center start">
      <div class="parent" *ngIf="parent">
        {{ parent.localLanguage && parent.localLanguage.code === _translateService.currentLang && parent.titleLocal ?
        parent.titleLocal : parent.titleEng }}
      </div>

      <div class="h1 title">
        {{ venue.localLanguage && venue.localLanguage.code === _translateService.currentLang && venue.titleLocal ?
        venue.titleLocal : venue.titleEng }}
      </div>
      <!--      <div class="location">-->
      <!--        {{ item.venueType | titlecase }} in-->
      <!--        <span class="h4 secondary-text">{{ item.location.formatted }}</span>-->
      <!--      </div>-->

      <div class="tags" fxHide fxShow.gt-sm>
        <span class="tag" *ngFor="let tag of venue.tags; let i = index">
          {{ tag.en }} {{ i < venue.tags.length - 1 ? '•' : '' }}
        </span>
      </div>
      <div class="time" *ngIf="venue['timeDisplay'] && time" fxHide fxShow.gt-sm>
        <strong *ngIf="venue['timeDisplay']['prefix']">
          <span *ngIf="venue['timeDisplay']['weekday']">
            {{ venue['timeDisplay']['weekday'][_translateService.currentLang] | titlecase }}
          </span>
          {{ venue['timeDisplay']['prefix'][_translateService.currentLang] }}
        </strong>
        <!--        <ng-container *ngIf="item['timeDisplay']['suffix']">-->
        <!--          {{ item['timeDisplay']['suffix'][_translateService.currentLang] }}-->
        <!--        </ng-container>-->
      </div>
      <button mat-flat-button class="tickets-button" *ngIf="venue.sellingTickets">
        {{ 'VENUE_THUMB.TICKETS' | translate }}
      </button>

      <mat-chip color="accent" *ngIf="venue.distance" class="mt-8">{{ venue.distance | number: '1.1-1' }} km</mat-chip>
    </div>
  </div>

  <!--  <div class="p-8 pt-0 buttons" fxLayout="row" fxLayoutAlign="end center">-->
  <!--    <button mat-raised-button class="mr-8 green-600" *ngIf="item.sellingTickets">Get your tickets</button>-->
  <!--    <button mat-button color="accent">-->
  <!--      More-->
  <!--      <mat-icon>keyboard_arrow_right</mat-icon>-->
  <!--    </button>-->
  <!--  </div>-->

  <div class="rating" fxLayout="column" fxLayoutAlign="center center" *ngIf="rating">
    <div class="score" *ngIf="venue.usersActivity.rating" fxLayout="row" fxLayoutAlign="center center">
      {{ venue.usersActivity.rating.current | number: '1.1-1' }}
      <mat-icon class="s-20">star</mat-icon>
    </div>
    <span class="count text-muted">
      {{ venue.usersActivity.rating ? venue.usersActivity.rating.count + ' ratings' : 'No rating' }}
    </span>
  </div>
</div>

<div
  class="venue-card cubic w-100-p"
  [fxHide]="style !== 'cubic'"
  fxFlex="100"
  [dir]="textAttr.direction"
  [ngStyle]="{ 'text-align': textAttr.align }"
>
  <button mat-icon-button class="like-button" [class.on]="userStatuses['like']" (click)="onActivity('like')">
    <mat-icon
      *ngIf="likeButton"
      matTooltip="{{ userStatuses['like'] ? 'Remove from My Chillz' : 'Add to My Chillz' }}"
      matTooltipPosition="right"
    >
      {{ userStatuses['like'] ? 'favorite' : 'favorite_border' }}
    </mat-icon>
  </button>

  <div class="rating" fxLayout="column" fxLayoutAlign="center center" *ngIf="rating">
    <div class="score" *ngIf="venue.usersActivity.rating" fxLayout="row" fxLayoutAlign="center center">
      {{ venue.usersActivity.rating.current | number: '1.1-1' }}
      <mat-icon class="s-20">star</mat-icon>
    </div>
  </div>

  <div class="image-wrapper" [routerLink]="['/', 'venues', venue.id]">
    <button mat-flat-button class="purple-600 tickets-button" *ngIf="venue.sellingTickets">Tickets</button>

    <img [src]="venue.pictures[0].url" *ngIf="venue.pictures.length > 0 && venue.pictures[0]" />
    <img src="assets/images/default-thumb.jpg" *ngIf="venue.pictures.length === 0 || !venue.pictures[0]" />
  </div>

  <div class="info" [routerLink]="['/', 'venues', venue.id]">
    <div class="title">{{ venue.titleEng }}</div>
    <div class="time" *ngIf="time && venue['timeDisplay']">
      <strong *ngIf="venue['timeDisplay']['prefix']">
        {{ venue['timeDisplay']['prefix'][_translateService.currentLang] }}
      </strong>
      <ng-container *ngIf="venue['timeDisplay']['suffix']">
        {{ venue['timeDisplay']['suffix'][_translateService.currentLang] }}
      </ng-container>
    </div>
    <div class="location" *ngIf="city && !venueType">{{ venue.locationDetails.city }}</div>
    <div class="location" *ngIf="city && venueType">{{ venue.location.city }} 's {{ venue.venueType | titlecase }}</div>
  </div>
</div>
<!-- / UPCOMING CARD -->
