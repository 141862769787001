import { ColorStyle } from './color-style.model';
import { StyleProperty } from './style-property.model';


export class GradientStyle {
  enabled?: boolean;
  angel?: StyleProperty;
  points?: {
    color?: ColorStyle;
    opacity?: StyleProperty;
    fraction?: StyleProperty;
  }[] = [];

  constructor (value: any = {}) {
    this.angel = new StyleProperty('90deg', 'deg');

    this.points = [
      {
        opacity: new StyleProperty(1, 'frac'),
        color: new ColorStyle('#ffffff'),
      },
    ];

    this.setValue(value);
  }

  setValue (value: any = {}): void {
    if (value.angel !== undefined) {
      this.angel.setValue(value.angel);
    }

    this.enabled = value.enabled;

    if (value.points) {
      this.points = value.points.map((x) => ({
        opacity: new StyleProperty(x.opacity, 'frac'),
        fraction: new StyleProperty(x.fraction, 'frac'),
        color: new ColorStyle(x.color?.toString()),
      }));
    }
  }

  addPoint (point: { opacity?: number; color?: string; fraction?: number }): void {
    this.points.push({
      opacity: new StyleProperty(point.opacity, 'frac'),
      fraction: new StyleProperty(point.fraction, 'frac'),
      color: new ColorStyle(point.color?.toString()),
    });
  }

  toString (defaultColor?: string): string {
    if (!this.enabled) {
      return null;
    }

    const gradient = [ this.angel.toString() ];
    const points = [ ...this.points ];
    if (points.length === 1) {
      points.push({
        opacity: new StyleProperty(points[0].opacity?.toString(), 'frac'),
        color: new ColorStyle(points[0].color?.toString()),
      });
    }

    let i = 0;
    for (const point of points) {
      const color = point.color?.toString(true) || defaultColor;
      gradient.push(`${color}${
        color?.length === 7 && point.opacity?.value !== undefined
          ? Math.round(parseFloat(point.opacity.value?.toString()) * 255).toString(16)
          : ''
      } ${(parseFloat(point.fraction?.value?.toString()) || i++ / (points.length - 1 || 1)) * 100}%`);
    }

    return `linear-gradient(${gradient.join(', ')})`;
  }

  toJson (): any {
    return {
      enabled: this.enabled,
      angel: this.angel?.toString(),
      points: this.points?.map((x) => ({
        fraction: x.fraction?.value,
        opacity: x.opacity?.value,
        color: x.color?.toString(),
      })),
    };
  }
}
