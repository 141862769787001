import { ExploreTheme } from './explore-theme.model';


export class ExplorePageSettings {
  enabled: boolean;
  shuffleItems: boolean;
  itemsLimit: number;
  headerBackground: string;
  secondaryTheme: ExploreTheme;
  topTheme: ExploreTheme;
  splashTheme: ExploreTheme;
  groupByDate: boolean;

  constructor (explorePageSettings: ExplorePageSettings) {
    this.enabled = explorePageSettings?.enabled;
    this.shuffleItems = explorePageSettings?.shuffleItems;
    this.itemsLimit = explorePageSettings?.itemsLimit;
    this.headerBackground = explorePageSettings?.headerBackground;
    this.groupByDate = explorePageSettings?.groupByDate;
    this.secondaryTheme = explorePageSettings?.secondaryTheme
      ? new ExploreTheme(explorePageSettings?.secondaryTheme)
      : undefined;
    this.topTheme = explorePageSettings?.topTheme ? new ExploreTheme(explorePageSettings?.topTheme) : undefined;
    this.splashTheme = explorePageSettings?.splashTheme
      ? new ExploreTheme(explorePageSettings?.splashTheme)
      : undefined;
  }
}
