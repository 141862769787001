import { ChillzMediaItem } from '../chillz-media-item.model';
import { ChillzPicture } from '../picture.model';
import { ChillzVideo } from '../video.model';


export class MediaSelectionValue {
  mediaItem?: ChillzMediaItem;
  mediaType?: 'Video' | 'Picture';
  // opacity?: StyleProperty;
  // blurred?: boolean;

  constructor (value: any = {}) {
    // this.opacity = new StyleProperty(undefined, 'frac');
    this.setValue(value);
  }

  setValue (value: any = {}): void {
    // if (value?.opacity) {
    //   this.opacity.setValue(value.opacity);
    // }

    if (value.mediaType !== undefined) {
      this.mediaType = value.mediaType;
    }

    // if (value?.blurred !== undefined) {
    //   this.blurred = value?.blurred;
    // }

    if (value.mediaItem !== undefined) {
      if (value.mediaItem === null) {
        this.mediaItem = null;
      } else {
        if (this.mediaType === 'Video') {
          this.mediaItem = new ChillzVideo(value.mediaItem);
        }

        if (this.mediaType === 'Picture') {
          this.mediaItem = new ChillzPicture(value.mediaItem);
        }
      }
    }
  }

  getPictureSrc (blurred = false): { url: string; isBlurred: boolean } {
    if (this.mediaType === 'Picture') {
      if (this.mediaItem?._id) {
        if (blurred && (this.mediaItem as ChillzPicture)?.blurred?.md) {
          return { url: (this.mediaItem as ChillzPicture)?.blurred?.md, isBlurred: true };
        }

        return { url: (this.mediaItem as ChillzPicture)?.md, isBlurred: false };
      }
    }
  }

  toJson (nullEmpty = false): any {
    return {
      // opacity: this.opacity?.value || (nullEmpty ? null : undefined),
      mediaItem: this.mediaItem || (nullEmpty ? null : undefined),
      mediaType: this.mediaType || (nullEmpty ? null : undefined),
      // blurred: this.blurred || (nullEmpty ? null : undefined)
    };
  }
}
