import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '../../../../@fuse/animations';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { AppLocalizationService } from '../../../app-localization.service';
import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { User } from '../../../auth/user.model';
import { ProfileService } from '../../../main/profile/profile.service';
import { navigation } from '../../../navigation/navigation';
import { CitiesService } from '../../../shared/cities.service';
import { City } from '../../../shared/models/city.model';
import { ExploreFilter } from '../../../shared/models/explore-filter.model';
import { MenuOptionModel } from '../../../shared/models/menu-option.model';
import { Platform as ChillzPlatform } from '../../../shared/models/platform.model';


@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: [ './toolbar.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;

  rightNavbar: boolean;
  userMenuItems: MenuOptionModel[];
  buildVersion = environment.buildVersion;

  @Input() hideAuth;
  @Input() hideBranding;
  @Input() hideLangPicker;
  @Input() hideSearch;

  @ViewChild('citiesPickerOptions') citiesPickerOptions!: ElementRef;
  @ViewChild('noUserMenuTrigger') noUserMenuTrigger!: MatMenuTrigger;
  @ViewChild('languageMenuTrigger') languageMenuTrigger!: MatMenuTrigger;
  @ViewChild('userMenuTrigger') userMenuTrigger!: MatMenuTrigger;

  showFull: boolean;
  hiddenNavbar: boolean;
  userMenuExpanded: boolean;
  isMobile: boolean;

  languages = [
    {
      id: 'en',
      title: 'English',
      flag: 'us',
    },
    {
      id: 'he',
      title: 'Hebrew',
      flag: 'il',
    },
  ];

  menuItems: MenuOptionModel[] = [
    // {
    //   label: 'My Profile',
    //   action: () => this.router.navigate(['/', 'profile']),
    // },
    // {
    //   label: 'My Chillz',
    //   action: () => this.router.navigate(['/', 'activity', 'my-chillz']),
    // },
    {
      label: 'My Orders',
      action: () => this._router.navigate([ '/', 'profile', 'orders' ]),
    },
    {
      label: 'Settings',
      action: () => this._router.navigate([ '/', 'profile', 'settings' ]),
    },
    {
      label: 'Seller Dashboard',
      action: () => this.openDashboard(),
    },
    {
      label: 'Log out',
      icon: 'chillz-exit',
      divide: true,
      action: () => this.logOut(),
    },
  ];

  noUserMenuItems: MenuOptionModel[] = [
    {
      label: 'Log in',
      action: () => this._router.navigate([ '/', 'auth', 'login' ]),
    },
    {
      label: 'Sign Up',
      action: () => this._router.navigate([ '/', 'auth', 'sign-up' ]),
    },
    {
      label: 'Seller Dashboard',
      action: () => this.openDashboard(),
    },
  ];

  platform: ChillzPlatform;
  navigation = navigation;

  selectedLanguage: any;

  signUpPopup: boolean;
  isBrowser: boolean;
  isIntro: boolean;

  userLoaded = false;

  user: User;
  coinz: number = null;

  scrolled = false;
  filter: ExploreFilter = new ExploreFilter();

  cities: City[];
  currentCity: City;
  cityPickerExpanded: boolean;

  private _unsubscribeAll = new Subject();

  constructor (
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _authService: ChillzAuthService,
    private _appService: AppService,
    private _profileService: ProfileService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _platform: Platform,
    private _location: Location,
    private _citiesService: CitiesService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _breakpointObserver: BreakpointObserver,
    public localizationService: AppLocalizationService
  ) {}

  @HostListener('window:scroll') onScrollHost (): void {
    this.scrolled = this._appService.getWindow()?.scrollY >= 50;
  }

  ngOnInit (): void {
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    this._appService.isIntro$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isIntro) => {
      this.isIntro = isIntro;
      this._changeDetectorRef.detectChanges();
    });

    this.isBrowser = this._appService.isBrowser;

    this._appService.onPlatformChanged
      .pipe(
        filter((x) => !!x),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((platform) => {
        this.platform = platform;
        this.languages = platform?.localization.availableLanguages;
      });

    this.languages = this.localizationService.languages;

    this._authService.onUserChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((user) => {
      if (this._platform.isBrowser) {
        this.userLoaded = true;
      }

      if (!user) {
        this.userMenuItems = this.noUserMenuItems;
        return;
      }

      this.userMenuItems = this.menuItems;
      this.user = user;
      this._profileService.getLoyaltySummary();
    });

    this._profileService.onCoinzChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => x !== null)
      )
      .subscribe((coinz) => {
        this.coinz = coinz;
      });

    this._citiesService.onCitiesChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((cities) => {
      this.cities = cities;
    });

    this._citiesService.onCurrentCityChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((currentCity) => {
      this.currentCity = currentCity;

      this.filter.cities = currentCity ? [ currentCity._id ] : [];

      if (!this.currentCity) {
      }
    });

    setTimeout(() => {
      this.showFull = true;
    }, 3000);

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: 'en',
    });

    this.localizationService.onCurrentLangChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((curr) => {
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
          id: curr,
        });
      });

    this.initializeBreakpointObserver();
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen (key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  setLanguage (lang): void {
    localStorage.setItem('selected-lang', lang.id);

    let currentPath = this._location.path().split('?');

    if (currentPath.length) {
      currentPath = currentPath[0].split('/').filter((x) => x !== '');
    }

    currentPath.splice(
      0,
      currentPath?.length && this.localizationService.LANGUAGES?.includes(currentPath[0]) ? 1 : 0,
      lang.id
    );

    this._router.navigate([ ...(currentPath || []) ], { queryParamsHandling: 'preserve', relativeTo: this._route.root });

    this.selectedLanguage = lang;

    this.localizationService.setLanguage(this.selectedLanguage.id);

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  onLocationSelect (location): void {
    if (this.cities?.map((x) => x._id).includes(location)) {
      this._citiesService.setCurrentCity(location);
    } else if (location === 'anywhere') {
      this.filter.cities = undefined;
    }

    this.closeCityPickerModal();
  }

  openDashboard (): void {
    window.open(this.platform?.baseAdminUrl || 'https://dashboard.chillz.com', '_blank');
  }

  logOut (): void {
    this._authService.logout();
    this.user = undefined;
  }

  public closeCityPickerModal (): void {
    this.cityPickerExpanded = false;
    this._appService.closeModal('city-picker');
  }

  public openCityPickerModal (): void {
    this.cityPickerExpanded = true;

    this._appService.openModal('city-picker');

    if (this.citiesPickerOptions?.nativeElement) {
      this.citiesPickerOptions.nativeElement.scrollTo(0, 0);
    }

    if (this.isMobile) {
      this.closeUserMenuModal();
    }
  }

  public collapseMenu (): void {
    this.userMenuExpanded = !this.userMenuExpanded;
    if (this.userMenuExpanded) {
      this._appService.openModal('user-menu');
    } else {
      this._appService.closeModal('user-menu');
    }
  }

  public closeUserMenu (): void {
    this.userMenuExpanded = false;
    this._appService.closeModal('user-menu');
  }

  private closeDesktopUserMenu (): void {
    if (this.noUserMenuTrigger && this.noUserMenuTrigger.menuOpen) {
      this.noUserMenuTrigger.closeMenu();
    }

    if (this.languageMenuTrigger && this.languageMenuTrigger.menuOpen) {
      this.languageMenuTrigger.closeMenu();
    }

    if (this.userMenuTrigger && this.userMenuTrigger.menuOpen) {
      this.userMenuTrigger.closeMenu();
    }
  }

  private closeUserMenuModal (): void {
    this.userMenuExpanded = false;
    this._appService.closeModal('user-menu');
  }

  private initializeBreakpointObserver (): void {
    this._breakpointObserver
      .observe([ Breakpoints.Small, Breakpoints.XSmall ])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value: BreakpointState) => {
        this.isMobile = value.matches;
        if (this.isMobile) {
          this.closeDesktopUserMenu();
        } else {
          this.closeUserMenuModal();
        }
      });
  }
}
