import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SeoService } from '../../../@fuse/services/seo.service';
import { AppService } from '../../app.service';
import { ChillzAuthService } from '../../auth/chillz-auth.service';
import { User } from '../../auth/user.model';
import { VerificationService } from '../../auth/verification/verification.service';
import { SettingsDialogNewComponent } from '../../shared/components/dialogs/settings-dialog-new/settings-dialog-new.component';
import { Platform } from '../../shared/models/platform.model';
import { ProfileService } from './profile.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: [ './profile.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;

  private _unsubscribeAll = new Subject<any>();

  constructor (
    private _profileService: ProfileService,
    private _authService: ChillzAuthService,
    private _title: Title,
    private _appService: AppService,
    private _verificationService: VerificationService,
    private _formBuilder: FormBuilder,
    public _matDialog: MatDialog,
    private _seo: SeoService
  ) {}

  ngOnInit (): void {
    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
        console.log(this.profile);
      });

    this._appService.onPlatformChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((platform) => {
        this.platform = platform;
        this._seo.title = `Profile Settings | ${this._appService?.platform?.brandName?.en || ''}`;
      });
  }

  onOpenDialog (subType: string, title: { [lang: string]: string }, fullScreen = false): void {
    this._matDialog.open(SettingsDialogNewComponent, {
      panelClass: [ 'chillz-dialog', ...(fullScreen ? [ 'full-screen' ] : []) ],
      data: {
        steps: [
          {
            type: 'profile',
            subType,
            title,
            skipAllowed: false,
            closeAllowed: true,
          },
        ],
      },
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
