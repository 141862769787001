import { NgModule } from '@angular/core';

import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { FilterPipe } from './filter.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { KeysPipe } from './keys.pipe';
import { TimeAgoPipe } from './time-ago.pipe';


@NgModule({
  declarations: [ KeysPipe, GetByIdPipe, HtmlToPlaintextPipe, FilterPipe, CamelCaseToDashPipe, TimeAgoPipe ],
  imports: [],
  exports: [ KeysPipe, GetByIdPipe, HtmlToPlaintextPipe, FilterPipe, CamelCaseToDashPipe, TimeAgoPipe ],
})
export class FusePipesModule {}
