import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { SeoService } from '../../../@fuse/services/seo.service';
import { SessionStorage } from '../../../@fuse/services/storage/session-storage';
import { TransferStateHelper } from '../../../@fuse/services/transfer-state-helper';
import { AppService } from '../../app.service';
import { ChillzAuthService } from '../../auth/chillz-auth.service';
import { BaseResolver } from '../../base-resolver.service';
import { LoadingService } from '../../loading.service';
import { OrderPreparationService } from '../../shared/components/order-preparation/order-preparation.service';
import {
  EntitiesRelationshipType,
  EventGuest,
  ExploreItem,
  Relationship,
} from '../../shared/models/explore-item.model';
import { ItemsCollectionStyle } from '../../shared/models/style-components/collection-style.model';
import { EntityLayout } from '../../shared/models/style-components/entity-layout.model';
import { EntityPageLayoutStyle } from '../../shared/models/style-components/entity-page-layout-style.model';
import { SessionInfoService } from '../../shared/session-info.service';
import { Entity } from './entity.model';
import { TicketTypesService } from './ticket-types.service';


@Injectable({
  providedIn: 'root',
})
export class EntityService extends BaseResolver<boolean> {
  private renderer2: Renderer2;

  routeParams: any;
  queryParams;
  style: EntityPageLayoutStyle;

  layout: EntityLayout;
  onLayoutChanged: BehaviorSubject<EntityLayout> = new BehaviorSubject<EntityLayout>(null);

  isBrowser: boolean;
  venue: Entity;

  comments: any = [];
  attendees: any = [];
  followers: any = [];
  feed: any = [];

  distanceFromUser: number;
  userStatuses: any;
  activeOrder: any;

  onEntityChanged: BehaviorSubject<any>;
  onUpcomingSubVenuesChanged: BehaviorSubject<any>;
  onPastSubVenuesChanged: BehaviorSubject<any>;
  onStaticSubVenuesChanged: BehaviorSubject<any>;
  onCommentsChanged: BehaviorSubject<any>;
  onAttendeesChanged: BehaviorSubject<any>;
  onFollowersChanged: BehaviorSubject<any>;
  onDistanceFromUserChanged: BehaviorSubject<any>;
  onUserStatusesChanged: BehaviorSubject<any>;
  onActiveOrderChanged: BehaviorSubject<any>;
  onFeedChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   */
  constructor (
    private _httpClient: HttpClient,
    private _sessionInfoService: SessionInfoService,
    private _router: Router,
    private _sanitizer: DomSanitizer,
    private _authService: ChillzAuthService,
    private _appService: AppService,
    private _platform: Platform,
    @Inject(PLATFORM_ID) platformId,
    @Inject(DOCUMENT) private _document: any,
    private _transferStateHelper: TransferStateHelper,
    private _orderPreparationService: OrderPreparationService,
    private _ticketTypesService: TicketTypesService,
    private _sessionStorage: SessionStorage,
    private _seo: SeoService,
    private _loadingService: LoadingService,
    public _translateService: TranslateService,
    rendererFactory: RendererFactory2
  ) {
    super(_loadingService, _router);

    // Set the defaults

    this.isBrowser = isPlatformBrowser(platformId);
    this.renderer2 = rendererFactory.createRenderer(null, null);

    this.onEntityChanged = new BehaviorSubject(undefined);
    this.onUpcomingSubVenuesChanged = new BehaviorSubject([]);
    this.onPastSubVenuesChanged = new BehaviorSubject([]);
    this.onStaticSubVenuesChanged = new BehaviorSubject([]);
    this.onDistanceFromUserChanged = new BehaviorSubject(undefined);
    this.onUserStatusesChanged = new BehaviorSubject(undefined);
    this.onActiveOrderChanged = new BehaviorSubject(undefined);

    // this.onGuestsListChanged = new BehaviorSubject(undefined);
    this.onCommentsChanged = new BehaviorSubject([]);
    this.onAttendeesChanged = new BehaviorSubject([]);
    this.onFollowersChanged = new BehaviorSubject([]);
    this.onFeedChanged = new BehaviorSubject([]);
  }

  resetBehaviorSubjects (): void {
    this.onEntityChanged.next(undefined);
    this.onUpcomingSubVenuesChanged.next([]);
    this.onPastSubVenuesChanged.next([]);
    this.onStaticSubVenuesChanged.next([]);
    this.onDistanceFromUserChanged.next(undefined);
    this.onUserStatusesChanged.next(undefined);
    this.onActiveOrderChanged.next(undefined);
    this._ticketTypesService.onSellerDetailsChanged.next(undefined);
    this._ticketTypesService.onContactInfoChanged.next(undefined);
    this.onCommentsChanged.next([]);
    this.onAttendeesChanged.next([]);
    // this.onGuestsListChanged.next(undefined);
    this.onFollowersChanged.next([]);
    this._ticketTypesService.onTicketTypesChanged.next([]);
    this.onFeedChanged.next([]);
  }

  /**
   * Resolver
   *
   */
  getData (route: ActivatedRouteSnapshot): Promise<boolean> {
    this.routeParams = route.params;
    this.queryParams = route.queryParams;

    this.resetBehaviorSubjects();

    const promises = [];
    if (this.isBrowser) {
      this._appService.isPreview$.next(false);
      if (!this.venue || this.routeParams.id !== this.venue.id) {
        this._ticketTypesService.currentEntity = undefined;
        this._ticketTypesService.ticketTypes = [];
        this._ticketTypesService.onTicketTypesChanged.next(this._ticketTypesService.ticketTypes);
        this.layout = undefined;
        this.onLayoutChanged.next(this.layout);
        this._orderPreparationService.reset();
        this._ticketTypesService.getTicketTypes(new Entity({ id: this.routeParams.id }), this.queryParams.code);
        promises.push(this.getVenue());
      } else if (this.venue) {
        this._ticketTypesService.currentEntity = this.venue;
        this._orderPreparationService.currentEntity = this.venue;
        this._ticketTypesService.ticketTypes = [];
        this._ticketTypesService.getTicketTypes(this.venue, this.queryParams.code);
        this.getVenue();
        if (!this.venue.layout) {
          this.getLayout().then((layout) => {
            this.venue.layout = layout;
            this.onLayoutChanged.next(this.layout);
            this.onEntityChanged.next(this.venue);
            this.updateSEO();
            this.loadFonts(layout);
          });
        } else {
          this._ticketTypesService.currentEntity = this.venue;
          this.onEntityChanged.next(this.venue);
          this.updateSEO();
          this.layout = this.venue.layout;
          this.onLayoutChanged.next(this.layout);
          this.loadFonts(this.layout);
        }
      }
    } else {
      promises.push(this.getVenue());
    }

    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then(() => {
          resolve(false);
        })
        .catch(() => {
          this._router.navigate([ '/', 'errors', '404' ]);
          reject();
        });
    });
  }

  /**
   * Get layout
   */
  getLayout (): Promise<EntityLayout> {
    return new Promise((resolve, reject) => {
      const params = {};
      if (this.routeParams.page) {
        params['page'] = this.routeParams.page;
      }

      this._httpClient.get('/venues/' + this.routeParams.id + '/layout', params).subscribe((response: any) => {
        if (response.layout) {
          response.layout = new EntityLayout(response.layout);
          this.layout = response.layout;
          this.loadFonts(this.layout);
        }

        resolve(response.layout);
      }, reject);
    });
  }

  /**
   * Get venue
   */
  getVenue (preApprovedForm?, force = false): Promise<Entity> {
    return new Promise((resolve, reject) => {
      const params = {};

      if (this._appService.isBrowser) {
        if (this.queryParams.previewToken || this._sessionStorage.getItem('previewToken')) {
          params['previewToken'] = this.queryParams.previewToken || this._sessionStorage.getItem('previewToken');
        }

        if (this.queryParams.ref) {
          params['ref'] = this.queryParams.ref;
        }

        if (this.queryParams.code) {
          params['code'] = this.queryParams.code;
        }

        if (this._sessionStorage.getItem('preApprovedEntranceForm')) {
          preApprovedForm = JSON.parse(this._sessionStorage.getItem('preApprovedEntranceForm'));
        }

        if (preApprovedForm) {
          if (preApprovedForm.code) {
            params['code'] = preApprovedForm.code;
            delete preApprovedForm.code;
          }

          for (const key of Object.keys(preApprovedForm)) {
            if (preApprovedForm[key]) {
              params[key] = preApprovedForm[key];
            }
          }
        }
      }

      this._httpClient.get('/venues/' + this.routeParams.id, { params }).subscribe((response: any) => {
        const venue = new Entity(response.venue);
        if (!force && this.venue && this.venue.id === venue.id && !venue.privacy?.isPrivate) {
          if (this.venue.pageLayout?._id === venue.pageLayout?._id) {
            delete venue.pageLayout;
          }

          this.layout = venue.layout;
          this.onLayoutChanged.next(this.layout);
          this.style = venue.pageLayout;

          if (this.layout || this.style) {
            this.loadFonts(this.layout);
          }

          _.extend(this.venue, venue);
        } else {
          this.venue = venue;
          this.layout = this.venue?.layout;
          this.onLayoutChanged.next(this.layout);
          this.style = this.venue?.pageLayout;
          this.loadFonts(this.layout);
        }

        if (this.venue.urls.youtube) {
          this.venue.urls.youtube = 'https://www.youtube.com/embed/' + this.venue.urls.youtube;
        }

        Object.keys(this.venue.urls).forEach((key) => {
          if (this.venue.urls[key]) {
            this.venue.urls[key] = this._sanitizer.bypassSecurityTrustResourceUrl(encodeURI(this.venue.urls[key]));
          }
        });

        if (this._ticketTypesService.ticketTypes?.length) {
          this._ticketTypesService.ticketTypes.forEach((ticketType) => {
            ticketType.venue = this.venue;
          });
        }

        if (response.pageIntegrations) {
          this.venue.pageIntegrations = response.pageIntegrations;
        }

        if (this.venue.pixelId) {
          if (!this.venue.pageIntegrations) {
            this.venue.pageIntegrations = {};
          }

          if (!this.venue.pageIntegrations.facebookPixel) {
            this.venue.pageIntegrations.facebookPixel = [];
          }

          this.venue.pageIntegrations.facebookPixel.push(this.venue.pixelId);
          this.venue.pageIntegrations.facebookPixel = _.uniq(this.venue.pageIntegrations.facebookPixel);
        }

        this._ticketTypesService.currentEntity = this.venue;
        this._orderPreparationService.currentEntity = this.venue;

        this.onEntityChanged.next(this.venue);

        this.updateSEO();

        resolve(this.venue);

        if (this.isBrowser) {
          this._appService.isPreview$.next(response.isPreview);

          if (this.venue.privacy?.isPrivate && this.queryParams.invitedEmail) {
            this._router.navigate([ '/', 'auth', 'login' ], {
              queryParams: { ...this.queryParams, backTo: `/${this.venue.id}` },
            });
          }

          // if (navigator?.geolocation && this.venue?.locationDetails?.coords) {
          //   navigator.geolocation.getCurrentPosition(position => {
          //     this.getDistanceToUser(position.coords.latitude, position.coords.longitude);
          //   });
          // }

          // if (response.guestList?.enabled && !this.venue?.privacy?.isPrivate) {
          //   this.getGuests();
          // }
          this.getUserStatuses();
        }
      }, reject);
    });
  }

  updateSEO (): void {
    this._seo.image =
      this.venue.inlinePicture?.xs ||
      this.venue.inlinePicture?.sm ||
      this.venue.coverPicture?.xs ||
      this.venue.coverPicture?.sm;

    this.venue.tags.forEach((tag) => (tag.en = tag.en.replace(/\s/g, '')));

    this._seo.keywords = this.venue.tags.map((x) => x[this._translateService.currentLang] || x['en']);
    this._seo.createHreflangLink(this.venue.id, [
      'en',
      ...(this.venue.localLanguage?.code && this.venue.localLanguage?.code !== 'en'
        ? [ this.venue.localLanguage?.code ]
        : []),
    ]);
    let pageTitle = this.venue.titleEng;
    if (this.venue.sellingTickets && !this.venue.privacy?.isPrivate) {
      pageTitle = 'Tickets for ' + pageTitle;
    }

    if (this.venue?.locationDetails?.city) {
      pageTitle += ', ' + this.venue.locationDetails.city;
    }

    pageTitle += ' | ';
    pageTitle += this._appService.platform?.brandName.en || 'Chillz';
    this._seo.title = pageTitle;
    this._seo.url = `${this._appService.platform?.baseUrl || 'https://chillz.com'}/${this.venue.id}`;

    if (this.venue.privacy?.isPrivate) {
      this._seo.description = `Private ${this._appService.platform?.brandName?.en || 'Chillz'} Page`;
    } else {
      this._seo.description = this.venue.descriptionEng
        ? this.venue.descriptionEng.substring(0, 155).replace(/<[^>]*>?/gm, '')
        : '';
    }
  }

  /**
   * Get user statuses
   */
  getUserStatuses (): Promise<any[]> {
    return new Promise((resolve) => {
      if (!this._authService.loggedIn) {
        return resolve([]);
      }

      this._httpClient
        .get('/venues/' + (this.venue?.id || this.routeParams.id) + '/userStatuses')
        .subscribe((response: any) => {
          this.userStatuses = response.userStatuses;
          this.onUserStatusesChanged.next(this.userStatuses);
          resolve(response.userStatuses);
        }, resolve);
    });
  }

  /**
   * Get guests
   */
  getGuests (): Promise<{ guests: EventGuest[]; style: ItemsCollectionStyle; isExposed: boolean }> {
    return new Promise((resolve) => {
      this._httpClient
        .get('/venues/' + (this.venue?.id || this.routeParams.id) + '/guests')
        .subscribe((response: any) => {
          let style;
          if (response.style) {
            style = new ItemsCollectionStyle(response.style);
          }

          let guests;
          if (response.guests) {
            guests = response.guests.map((x) => {
              x.hostAccent = this.venue.accentColor;
              const guest = new EventGuest(x);
              if (style?.item) {
                guest.setStyle(style.item);
              }

              return guest;
            });
            // this.onGuestsListChanged.next({guests, style, isExposed: response.isExposed});
          }

          resolve({ guests, style, isExposed: response.isExposed });
        }, resolve);
    });
  }

  /**
   * Get related entities
   */
  getRelationships (
    relatedGroup: string,
    limit?: number,
    page?: number,
    timeFilter?: string
  ): Promise<{
      type?: EntitiesRelationshipType;
      items?: ExploreItem[];
      style?: ItemsCollectionStyle;
      total?: number;
      censoredDisclaimer?: any;
    }> {
    const params = {};

    if (limit) {
      params['limit'] = limit.toString();
    }

    if (page !== undefined) {
      params['page'] = page.toString();
    }

    if (timeFilter) {
      params['timeFilter'] = timeFilter.toString();
    }

    return new Promise((resolve) => {
      this._httpClient
        .get('/venues/' + (this.venue?.id || this.routeParams.id) + '/relationships/' + relatedGroup, { params })
        .subscribe((response: any) => {
          const result = {
            total: response.total,
            censoredDisclaimer: response.censoredDisclaimer,
          };

          if (response.guests) {
            result['items'] = response.guests.map((x) => new EventGuest(x));
            if (response.style) {
              result['style'] = new ItemsCollectionStyle(response.style);
            }
          } else if (response.relationships) {
            result['type'] = new EntitiesRelationshipType(response.type);
            result['items'] = response.relationships?.map((r) => {
              return new Relationship({
                entity: r.entity,
                CTAs: r?.CTAs,
                link: r?.link,
                customFields: r.customFields.map((field) => {
                  if ([ 'dropdown' ].includes(field.fieldType)) {
                    field.value = result['type'].customFields
                      .find((cf) => cf.id === field.id)
                      ?.dropdownOptions?.find((op) => op.id === field.value)?.name;
                  } else if ([ 'time' ].includes(field.fieldType)) {
                    field.value = {
                      en: this.generateHourString(field.value, this._appService?.platform?.localization?.timeFormat),
                    };
                  } else {
                    field.value = {
                      en: field.value as string,
                    };
                  }

                  return field;
                }),
              });
            });
            if (response.style || response.type?.style) {
              result['style'] = new ItemsCollectionStyle(response.style || response.type?.style);
            }
          }

          resolve(result);
        }, resolve);
    });
  }

  /**
   * Get active Order
   */
  getActiveOrder (): Promise<any[]> {
    return new Promise((resolve) => {
      if (!this._authService.isLoggedIn()) {
        return resolve([]);
      }

      this._httpClient
        .get('/venues/' + (this.venue?.id || this.routeParams.id) + '/activeOrder')
        .subscribe((response: any) => {
          if (response.order) {
            this.activeOrder = response.order;
          } else {
            this.activeOrder = undefined;
          }

          this.onActiveOrderChanged.next(this.activeOrder);

          resolve(this.activeOrder);
        }, resolve);
    });
  }

  /**
   * Get distance from user
   */
  getDistanceToUser (lat: number, lng: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const location = '?lat=' + lat + '&lng=' + lng;

      this._httpClient
        .get('/venues/' + (this.venue?.id || this.routeParams.id) + '/distanceToUser' + location)
        .subscribe((response: any) => {
          this.distanceFromUser = response.distance;
          this.onDistanceFromUserChanged.next(this.distanceFromUser);

          resolve(response.distance);
        }, reject);
    });
  }

  /**
   * Invoke activity
   */
  invokeActivity (activity, value?): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (!this._authService.isLoggedIn()) {
        return resolve([]);
      }

      const val = value ? '?value=' + value : '';

      this._httpClient
        .post('/venues/' + (this.venue?.id || this.routeParams.id) + '/' + activity + val, null)
        .subscribe((response: any) => {
          this.userStatuses[response.activity] = response.status;
          this.onUserStatusesChanged.next(this.userStatuses);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Request Access
   */
  requestAccess (customFields?): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (!this._authService.isLoggedIn()) {
        return resolve([]);
      }

      const params = {};
      if (this.queryParams.ref) {
        params['ref'] = this.queryParams.ref;
      }

      this._httpClient
        .post('/venues/' + (this.venue?.id || this.routeParams.id) + '/requestAccess', { customFields }, { params })
        .subscribe((response: any) => {
          this.userStatuses['accessRequested'] = response.status;
          this.onUserStatusesChanged.next(this.userStatuses);
          resolve(response);
        }, reject);
    });
  }

  /**
   *
   * Get comments
   */
  getComments (limit?): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const lim = limit ? '?limit=' + limit : '';
      this._httpClient
        .get('/venues/' + (this.venue?.id || this.routeParams.id) + '/comments' + lim)
        .subscribe((response: any) => {
          this.comments = response.comments;
          this.onCommentsChanged.next(this.comments);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Post comment
   */
  postComment (comment): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (!this._authService.isLoggedIn()) {
        return resolve([]);
      }

      this._httpClient
        .post('/venues/' + (this.venue?.id || this.routeParams.id) + '/comments', { comment })
        .subscribe((response: any) => {
          this.comments.push(response.comment);
          this.onCommentsChanged.next(this.comments);
          resolve(response);
        }, reject);
    });
  }

  /**
   * Reply to Comment
   */
  replyToComment (commentIndex, reply): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (!this._authService.isLoggedIn()) {
        return resolve([]);
      }

      this._httpClient
        .post('/venues/' + this.routeParams.id + '/comments/' + this.comments[commentIndex]._id + '/reply', { reply })
        .subscribe((response: any) => {
          this.comments[commentIndex] = response.comment;
          this.onCommentsChanged.next(this.comments);

          resolve(response);
        }, reject);
    });
  }

  generateHourString = (h, format = '24h'): string => {
    if (!h) {
      return null;
    }

    const object = {
      hours: h.slice(0, 2),
      minutes: h.slice(2, 3),
    };

    const hours = parseInt(object.hours, 10);
    let hh = hours;
    let suffix = '';
    if (format === 'AM:PM') {
      hh = hours === 0 ? 12 : hours;
      if (hours > 12) {
        hh = hh - 12;
      }

      suffix = hours < 12 ? ' AM' : ' PM';
    }

    return `${hh.toString().padStart(2, '0')}:${(object.minutes || '00').toString().padStart(2, '0')}${suffix}`;
  };

  loadFonts (style: EntityLayout): void {
    if (!style) {
      return;
    }

    const fontsToLoad = {};

    if (style?.textStyles) {
      if (style.textStyles.default?.fontFamily && style.textStyles.default?.fontFamily !== 'inherit') {
        fontsToLoad[style.textStyles.default.fontFamily] = null;
      }

      if (
        style.textStyles.secondaryHeadline?.fontFamily &&
        style.textStyles?.secondaryHeadline?.fontFamily !== 'inherit'
      ) {
        fontsToLoad[style.textStyles.secondaryHeadline.fontFamily] = null;
      }
    }

    for (const page of style.pages || []) {
      if (page.layout) {
        _.merge(fontsToLoad, page.layout.getFonts());
      }
    }

    this._appService.loadGoogleFonts(fontsToLoad);
  }
}
