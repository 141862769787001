<!--<div-->
<!--  #intersection="intersection"-->
<!--  observeVisibility-->
<!--  [debounceTime]="0"-->
<!--  [isContinuous]="false"-->
<!--  #container-->
<!--  (isIntersecting)="isInView = $event"-->
<!--&gt;-->
<div
  class="items-collection-item"
  [class.base-explore-item]="isBaseExploreItem"
  #intersection="intersection"
  observeVisibility
  [debounceTime]="0"
  [isContinuous]="false"
  #container
  (isIntersecting)="isInView = $event"
  [dir]="localizationService?.textAttr?.direction"
  (mouseup)="onClick(item.action)"
>
  <div
    class="items-collection-item-main"
    [style.padding]="item.style?.padding?.toString()"
    [style.aspect-ratio]="item.style?.aspectRatio"
    [style.background]="item?.style?.background?.enabled ? item.style?.background?.toString(item.entity?.accentColor || item.hostAccent, item.hostAccent) : undefined"
    [style.align-items]="item.style?.flexAlignment?.alignItems"
    [style.justify-content]="item.style?.flexAlignment?.justifyContent"
    [style.height]="item.style?.height?.toString()"
    [style.width]="item.style?.width?.toString()"
    [style.border-radius]="item.style?.border?.radius?.toString()"
  >
    <div
      class="fade-gradient"
      [style.height]="item.style?.gradientFade?.height?.toString()"
      *ngIf="item?.style?.gradientFade?.enabled"
    ></div>

    <div fadeInAnimation *ngIf="item?.style?.background?.enabled" class="items-collection-item-background-media">
      <ng-container *ngIf="intersection._isIntersecting">
        <ng-container
          *ngIf="!!backgroundPicture && (item?.style?.background?.mediaOverlay?.value === 'picture' || (!item.video?.url && item?.style?.background?.mediaOverlay?.value === 'video')); else defaultPoster"
        >
          <chillz-skeleton-block
            class="skeleton-wrapper"
            *ngIf="!fileLoaded && !item.style?.picture?.enabled"
          ></chillz-skeleton-block>
          <img
            loading="lazy"
            [style.opacity]="!fileLoaded ? 0 : item.style?.background?.mediaOverlay?.opacity"
            [class.items-collection-item-media-placeholder-symbol]="backgroundPicture?.isPlaceholder && item.style?.background?.mediaOverlay?.placeholder?.isSymbol"
            [class.runtime-blurred]="!backgroundPicture?.isBlurred && item.style?.background?.mediaOverlay?.blurred"
            [src]="backgroundPicture?.src"
            (load)="fileLoaded = true"
            alt=""
          />
        </ng-container>
        <ng-template #defaultPoster>
          <ng-container *ngIf="isBaseExploreItem">
            <chillz-thumb-placeholder
              [borderRadius]="'16px'"
              [thumbPlaceholder]="platform?.thumbPlaceholder"
            ></chillz-thumb-placeholder>
          </ng-container>
        </ng-template>
      </ng-container>
      <app-video-player
        *ngIf="intersection._isIntersecting && item.video?.url && item?.style?.background?.mediaOverlay?.value === 'video'"
        [style.opacity]="item.style?.background?.mediaOverlay?.opacity"
        [absolute]="true"
        [controls]="false"
        [sources]="[{src: item.video?.url, type: item.video?.type || 'video/mp4'}]"
        (load)="fileLoaded = true"
      ></app-video-player>
    </div>
    <div
      class="items-collection-item-overlay"
      *ngIf="item?.style?.overlay?.enabled"
      [style.border-radius]="item.style?.border?.radius?.toString()"
      [class.animated]="item.style?.overlay?.animated"
      [style.z-index]="item.style?.overlay?.zIndex"
      [style.background]="item?.style?.overlay?.background?.enabled ? item.style?.overlay?.background?.toString(item.entity?.accentColor || item.hostAccent, item.hostAccent) : undefined"
      [style.padding]="item.style?.padding?.toString()"
      [style.font-size]="item.style?.overlay?.textStyle?.fontSize?.toString()"
      [style.line-height]="item.style?.overlay?.textStyle?.lineHeight?.toString()"
      [style.font-weight]="item.style?.overlay?.textStyle?.fontWeight"
      [style.text-transform]="item.style?.overlay?.textStyle?.textTransform"
      [style.color]="item.style?.overlay?.textStyle?.color?.toString(true)"
      [style.font-family]="item.style?.overlay?.textStyle?.getFontFamily(platform)"
    >
      <div
        class="item-overlay-spacer"
        *ngIf="item.style?.picture?.enabled && intersection._isIntersecting"
        [style.aspect-ratio]="item.style?.picture?.aspectRatio"
        [style.height]="item.style?.picture?.height?.toString()"
        [style.width]="!item.style?.picture?.aspectRatio ? '100%' : undefined"
        [style.max-width]="item.style?.picture?.width?.toString()"
      ></div>

      <div
        class="item-overlay-content"
        fadeInAnimation
        *ngIf="intersection._isIntersecting"
        [style.align-items]="item.style?.overlay?.flexAlignment?.alignItems"
        [style.justify-content]="item.style?.overlay?.flexAlignment?.justifyContent"
      >
        <div
          class="item-overlay-badge"
          *ngIf="item?.badge?.en && item?.style?.badge?.enabled"
          [style.display]="'flex'"
          [style.background]="item?.style?.badge?.background?.enabled ? item.style?.badge?.background?.toString(item.entity?.accentColor || item.hostAccent, item.hostAccent) : undefined"
          [style.position]="item.style?.badge?.isAbsolute ? 'absolute' : 'relative'"
          [style.height]="item?.style?.badge?.height?.toString()"
          [style.width]="item?.style?.badge?.width?.toString()"
          [style.z-index]="item.style?.badge?.zIndex"
          [style.aspect-ratio]="item.style?.badge?.aspectRatio"
          [style.border-radius]="item.style?.badge?.border?.radius?.toString()"
          [style.padding]="item.style?.badge?.padding?.toString()"
          [style.font-size]="item.style?.badge?.textStyle?.fontSize?.toString()"
          [style.line-height]="item.style?.badge?.textStyle?.lineHeight?.toString()"
          [style.font-weight]="item.style?.badge?.textStyle?.fontWeight"
          [style.text-transform]="item.style?.badge?.textStyle?.textTransform"
          [style.color]="item.style?.badge?.textStyle?.color?.toString(true)"
          [style.font-family]="item.style?.badge?.textStyle?.getFontFamily(platform)"
          [style.justify-content]="item.style?.badge?.flexAlignment?.justifyContent"
          [style.text-align]="item.style?.badge?.textStyle?.textAlign"
          [style.align-items]="item.style?.badge?.flexAlignment?.alignItems"
          [style.top]="item.style?.badge?.absolutePosition?.top?.toString()"
          [style.bottom]="item.style?.badge?.absolutePosition?.bottom?.toString()"
          [style.left]="item.style?.badge?.absolutePosition?.left?.toString()"
          [style.right]="item.style?.badge?.absolutePosition?.right?.toString()"
          [style.border-color]="item.style?.badge?.border?.color?.toString(true)"
          [style.border-width]="item.style?.badge?.border?.width?.toString()"
          [style.border-style]="(item.style?.badge?.border?.width?.value) ? 'solid' : undefined"
        >
          {{ (item.badge[localizationService.currentLang] || item.badge['en']) }}
        </div>

        <div
          class="item-overlay-content-main"
          [style.text-align]="item.style?.overlay?.textStyle?.textAlign"
          [style.text-transform]="item.style?.overlay?.textStyle?.textTransform"
          [style.flex-grow]="item.style?.overlay?.mainContentGrow ? 1 : undefined"
        >
          <div
            class="items-collection-item-title gap-8"
            [style.text-transform]="item.style?.overlay?.textStyle?.textTransform"
            *ngIf="item.title?.[localizationService.currentLang] || item.title?.['en']"
          >
            <span>{{ item.title[localizationService.currentLang] || item.title['en'] }}</span>
          </div>

          <div
            class="items-collection-item-subtitle"
            [style.text-transform]="item.style?.overlay?.textStyle?.textTransform"
            *ngIf="item.subTitle?.[localizationService.currentLang] || item.subTitle?.['en']"
          >
            {{ item.subTitle[localizationService.currentLang] || item.subTitle['en'] }}
          </div>

          <div
            class="items-collection-item-subtitle"
            [style.text-transform]="item.style?.overlay?.textStyle?.textTransform"
            *ngIf="item.style?.overlay?.location && item.entity?.locationDetails?.formatted"
          >
            {{ item.entity?.locationDetails?.formatted }}
          </div>

          <div
            class="items-collection-item-subtitle"
            [style.text-transform]="item.style?.overlay?.textStyle?.textTransform"
            *ngIf="item.style?.overlay?.time && item.entity?.startsAt?.formatted"
          >
            {{ item.entity?.startsAt?.formatted[localizationService.currentLang] ||
            item.entity?.startsAt?.formatted['en'] }}
          </div>

          <div
            *ngIf="item.additionalInfo?.length"
            fxLayout="column"
            [style.align-items]="item.style?.overlay?.flexAlignment?.alignItems"
          >
            <div class="items-collection-item-additional-info" *ngFor="let field of item.additionalInfo">
              <strong *ngIf="field.key">{{ field.key[localizationService.currentLang] || field.key['en'] }}</strong>
              <span *ngIf="field.value">{{ field.value[localizationService.currentLang] || field.value['en'] }}</span>
            </div>
          </div>

          <div class="item-quick-info" *ngIf="item.quickInfo && item?.style?.overlay?.quickInfo">
            <div
              class="item-quick-info-item"
              *ngFor="let qi of item.quickInfo"
              [style.font-family]="item.style?.overlay?.textStyle?.getFontFamily(platform)"
              [dir]="qi.direction"
              (click)="(qi.onClick ? qi.onClick() : undefined); $event.stopPropagation()"
            >
              <span *ngIf="qi.text">{{ qi.text[localizationService.currentLang] || qi.text['en'] }}</span>
              <mat-icon *ngIf="qi.icon" [ngClass]="qi.iconClass">{{ qi.icon }}</mat-icon>
              <mat-icon *ngIf="qi.svgIcon" [ngClass]="qi.iconClass" [svgIcon]="qi.svgIcon"></mat-icon>
            </div>
          </div>

          <div
            class="item-selected-for-quick-order"
            fxLayout="column"
            fxLayoutAlign="center center"
            *ngIf="selectedForQuickOrder && item.quickOrder.singleSelect"
          >
            <span *ngIf="item.quickOrder.singleSelect">{{ 'VENUE_THUMB.ADDED_TO_ORDER' | translate }}</span>
          </div>

          <!--        <div class="quick-order-status-overlay"-->
          <!--             *ngIf="item?.quickOrder?.singleSelect && !item?.quickOrder?.singleSelect?.ticketType?.status?.status?.availableForPurchase">-->
          <!--          <div class="chillz-badge"-->
          <!--               *ngIf="item?.quickOrder?.singleSelect?.ticketType?.status?.status?.id !== 'custom'">{{ item?.quickOrder?.singleSelect?.ticketType?.status?.status?.name[localizationService.currentLang] || item?.quickOrder?.singleSelect?.ticketType?.status?.status?.name['en'] }}</div>-->
          <!--          <div class="chillz-badge"-->
          <!--               *ngIf="item?.quickOrder?.singleSelect?.ticketType?.status?.status?.id === 'custom' && item?.quickOrder?.singleSelect?.ticketType?.status?.custom">{{ item?.quickOrder?.singleSelect?.ticketType?.status?.custom }}</div>-->
          <!--        </div>-->

          <!--        <div class="items-collection-item-badge" *ngIf="item?.badge?.en" fxLayoutAlign="start">-->
          <!--          <div class="badge"-->
          <!--               *ngIf="item?.badge"-->
          <!--               [ngStyle]="{'background': 'linear-gradient(83.87deg,' + (item.badge?.gradient?.join(', ') || '#039DF4, #03D7F4') + ')'}">{{ (item.badge[localizationService.currentLang] || item.badge['en']) | uppercase }}-->
          <!--          </div>-->
          <!--        </div>-->
        </div>
        <div
          class="items-collection-item-ctas"
          [style.display]="'grid'"
          [style.flex-direction]="item.style?.overlay?.CTA?.style?.flexDirection"
          [style.justify-content]="item.style?.overlay?.CTA?.style?.flexAlignment?.justifyContent"
          [style.align-items]="item.style?.overlay?.CTA?.style?.flexAlignment?.alignItems"
          [style.grid-template-columns]="item.style?.overlay?.CTA?.style?.gridTemplateColumns()"
          [style.gap]="item.style?.overlay?.CTA?.style?.gap?.toString()"
          *ngIf="item.CTAButtons?.length"
        >
          <ng-container *ngFor="let cta of item.CTAButtons">
            <div
              class="items-collection-item-cta"
              *ngIf="!(cta.action?.target === 'quick-order' && item.quickOrder?.ticketTypes)"
              (mouseup)=" $event.stopPropagation(); onClick(cta.action)"
              [style.padding]="cta.style?.padding?.toString()"
              [style.border-radius]="cta.style?.border?.radius?.toString()"
              [style.font-size]="cta.style?.textStyle?.fontSize?.toString()"
              [style.font-weight]="cta.style?.textStyle?.fontWeight"
              [style.justify-content]="cta?.style?.flexAlignment?.justifyContent"
              [style.align-items]="cta?.style?.flexAlignment?.alignItems"
              [style.text-transform]="cta.style?.textStyle?.textTransform"
              [style.color]="cta.style?.textStyle?.color?.toString(true)"
              [style.font-family]="cta.style?.textStyle?.getFontFamily(platform)"
              [style.border-color]="cta.style?.border?.color?.toString(true)"
              [style.border-width]="cta.style?.border?.width?.toString()"
              [style.border-style]="(cta.style?.border?.width?.value) ? 'solid' : undefined"
              [style.background]="cta.style?.background?.color?.toString(true)"
            >
              <span>{{ (cta.label[localizationService.currentLang] || cta.label['en']) }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      class="items-collection-item-foreground-media"
      fadeInAnimation
      *ngIf="item.style?.picture?.enabled && intersection._isIntersecting"
      [style.border-radius]="item.style?.picture?.border?.radius?.toString() || item.style?.border?.radius?.toString()"
      [style.border-color]="item.style?.picture?.border?.color?.toString(true)"
      [style.border-width]="item.style?.picture?.border?.width?.toString()"
      [style.border-style]="item.style?.picture?.border?.width?.value ? 'solid' : undefined"
      [style.aspect-ratio]="item.style?.picture?.aspectRatio"
      [style.height]="item.style?.picture?.height?.toString()"
      [style.max-width]="item.style?.picture?.width?.toString()"
      [style.width]="!item.style?.picture?.aspectRatio ? '100%' : undefined"
      [style.background]="!item.foregroundPicture?.thumb ? item.style?.picture?.placeholder?.background : undefined"
      [style.z-index]="item.style?.picture?.zIndex"
    >
      <img
        loading="lazy"
        *ngIf="item.foregroundPicture?.thumb"
        [src]="item.foregroundPicture?.thumb"
        [alt]="item.title?.['en']"
      />

      <img
        loading="lazy"
        *ngIf="!item.foregroundPicture?.thumb"
        [class.items-collection-item-media-placeholder-symbol]="item.style?.picture?.placeholder?.isSymbol"
        [src]="item.style?.picture?.placeholder?.picture?.thumb"
      />
    </div>
  </div>

  <div
    class="items-collection-item-underneath"
    *ngIf="item.style?.underneath?.enabled"
    [style.background]="item?.style?.underneath?.background?.enabled ? item.style?.underneath?.background?.toString(item.entity?.accentColor || item.hostAccent, item.hostAccent) : undefined"
    [style.padding]="item.style?.underneath?.padding?.toString()"
    [style.font-size]="item.style?.underneath?.textStyle?.fontSize?.toString()"
    [style.line-height]="item.style?.underneath?.textStyle?.lineHeight?.toString()"
    [style.font-weight]="item.style?.underneath?.textStyle?.fontWeight"
    [style.text-transform]="item.style?.underneath?.textStyle?.textTransform"
    [style.color]="item.style?.underneath?.textStyle?.color?.toString(true)"
    [style.font-family]="item.style?.underneath?.textStyle?.fontFamily"
    [style.height]="item.style?.underneath?.height?.toString()"
  >
    <div
      class="item-underneath-content"
      [style.align-items]="item.style?.underneath?.flexAlignment?.alignItems"
      [style.justify-content]="item.style?.underneath?.flexAlignment?.justifyContent"
    >
      <div
        class="item-underneath-content-main"
        [style.text-align]="item.style?.underneath?.textStyle?.textAlign"
        [style.text-transform]="item.style?.underneath?.textStyle?.textTransform"
        [style.flex-grow]="item.style?.underneath?.mainContentGrow ? 1 : undefined"
        [style.gap]="item.style?.underneath?.gap?.toString()"
      >
        <div
          class="items-collection-item-title gap-8"
          [style.text-transform]="item.style?.underneath?.textStyle?.textTransform"
          *ngIf="item.title?.[localizationService.currentLang] || item.title?.['en']"
        >
          <span>{{ item.title[localizationService.currentLang] || item.title['en'] }}</span>
        </div>

        <div
          class="items-collection-item-subtitle"
          [style.text-transform]="item.style?.underneath?.textStyle?.textTransform"
          *ngIf="item.subTitle?.[localizationService.currentLang] || item.subTitle?.['en']"
        >
          {{ item.subTitle[localizationService.currentLang] || item.subTitle['en'] }}
        </div>

        <div
          class="items-collection-item-subtitle"
          [style.text-transform]="item.style?.underneath?.textStyle?.textTransform"
          *ngIf="item.style?.underneath?.location && item.entity?.locationDetails?.formatted"
        >
          {{ item.entity?.locationDetails?.formatted }}
        </div>

        <div
          class="items-collection-item-subtitle"
          [style.text-transform]="item.style?.underneath?.textStyle?.textTransform"
          *ngIf="item.style?.underneath?.time && item.entity?.startsAt?.formatted"
        >
          {{ item.entity?.startsAt?.formatted[localizationService.currentLang] || item.entity?.startsAt?.formatted['en']
          }}
        </div>
      </div>
    </div>
  </div>
</div>
<!--  <div class="item-expansion-collection-item-expansion"-->
<!--       [@cardExpand]="selectedForQuickOrder && item.quickOrder?.ticketTypes && item.quickOrder?.itemsObserver ? 'expanded' : 'collapsed'"-->
<!--  >-->
<!--    <app-select-items fxFlex="grow" *ngIf="selectedForQuickOrder && item.quickOrder?.itemsObserver" [venue]="item.entity"-->
<!--                      class="mb-8" [description]="true" [background]="false"-->
<!--                      [itemsObserver]="item.quickOrder?.itemsObserver" [highlighted]="false"></app-select-items>-->
<!--  </div>-->

<!--</div>-->
