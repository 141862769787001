<div
  #posterContainer
  #intersection="intersection"
  observeVisibility
  class="poster-container"
  (isIntersecting)="isInView = $event"
  (click)="onClick(item.action)"
  [debounceTime]="0"
  [isContinuous]="false"
  [ngClass]="{ 'poster-container-clickable': !!item.action }"
>
  <div *ngIf="item?.style?.background?.enabled && intersection._isIntersecting" class="background-media">
    <chillz-skeleton-block
      [@animate]="{ value: '*', params: { delay: '500ms', opacity: '0' } }"
      [width]="'100%'"
      *ngIf="!fileLoaded"
    ></chillz-skeleton-block>
    <img
      alt=""
      class="fade-animation"
      *ngIfAnimated="!!backgroundPicture && (item?.style?.background?.mediaOverlay?.value === 'picture' || (!item.video?.url && item?.style?.background?.mediaOverlay?.value === 'video'))"
      (load)="fileLoaded = true"
      [ngStyle]=" !fileLoaded ? {width: '0', opacity: '0'} : {width: '100%', opacity: item.style?.background?.mediaOverlay?.opacity}"
      [class.items-collection-item-media-placeholder-symbol]="backgroundPicture?.isPlaceholder && item.style?.background?.mediaOverlay?.placeholder?.isSymbol"
      [src]="backgroundBlurPicture"
    />
    <app-video-player
      class="fade-animation"
      *ngIf="item.video?.url && item?.style?.background?.mediaOverlay?.value === 'video'"
      [style.opacity]="item.style?.background?.mediaOverlay?.opacity"
      [absolute]="true"
      [controls]="false"
      [sources]="[{src: item.video?.url, type: item.video?.type || 'video/mp4'}]"
    >
    </app-video-player>
  </div>

  <div class="content">
    <div
      [chillzOpacityAnimation]="!item.foregroundPicture?.sm || item.foregroundPicture?.sm && foregroundLoaded"
      class="description"
    >
      <div class="items-collection-item-text">
        <div
          class="items-collection-item-subtitle"
          [style.text-transform]="item.style?.overlay?.textStyle?.textTransform"
          *ngIf="(item.style?.overlay?.time && item.entity?.startsAt?.formatted)"
        >
          {{ item.entity?.startsAt?.formatted[localizationService.currentLang] || item.entity?.startsAt?.formatted['en']
          }}
        </div>
        <div
          class="items-collection-item-title"
          [style.text-transform]="item.style?.overlay?.textStyle?.textTransform"
          *ngIf="item.title?.[localizationService.currentLang] || item.title?.['en']"
        >
          <span>{{ item.title[localizationService.currentLang] || item.title['en'] }}</span>
        </div>
      </div>

      <div *ngIf="item?.action?.label" class="main-cta">
        <button (click)="onClick(item.action)">
          <p class="text">{{ item?.action?.label[localizationService.currentLang] || item?.action?.label['en'] }}</p>
        </button>
      </div>
    </div>
    <div class="foreground-image" [chillzOpacityAnimation]="foregroundLoaded" [duration]="'0.4s'">
      <div *ngIf="intersection._isIntersecting" class="foreground-container">
        <img
          loading="lazy"
          *ngIf="item.foregroundPicture?.sm"
          [src]="item.foregroundPicture?.sm"
          [alt]="item.title?.['en']"
          (load)="foregroundLoaded = true"
        />
        <img
          loading="lazy"
          *ngIf="!item.foregroundPicture?.sm"
          [class.items-collection-item-media-placeholder-symbol]="item.style?.picture?.placeholder?.isSymbol"
          [src]="item.style?.picture?.placeholder?.picture?.thumb"
          [alt]="item.title?.['en']"
          (load)="foregroundLoaded = true"
        />
      </div>
    </div>
  </div>
</div>
