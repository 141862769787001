import { Pipe, PipeTransform } from '@angular/core';

import { Entity } from '../../main/entity/entity.model';


@Pipe({
  name: 'searchableValue',
  pure: true,
})
export class SearchableValuePipe implements PipeTransform {
  transform (searchResults: Entity[], searchValue: string): boolean {
    return !!searchResults && !!searchValue && searchValue.length > 2;
  }
}
