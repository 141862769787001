<form [formGroup]="nameFormGroup" class="settings-section-body">
  <!--  <div fxLayout="row wrap">-->
  <mat-form-field floatLabel="auto">
    <mat-label>First Name</mat-label>
    <input matInput formControlName="firstName" />
  </mat-form-field>

  <mat-form-field floatLabel="auto">
    <mat-label>Last Name</mat-label>
    <input matInput formControlName="lastName" />
  </mat-form-field>

  <!--  </div>-->

  <div class="settings-section-actions">
    <chillz-button
      [label]="status !== 'saved' ? { en: 'Save', he: 'שמירה' } : undefined"
      [icon]="status === 'saved' ? 'checkmark' : undefined"
      [isSvgIcon]="true"
      [fontSize]="'14px'"
      [padding]="'6px 20px'"
      [height]="26"
      [loaderSize]="26"
      [hoverPush]="true"
      [expandable]="false"
      [outline]="true"
      [hoverOutlineShine]="true"
      [outlineShine]="true"
      [shadow]="true"
      [glow]="false"
      [isLoader]="true"
      [disabled]="nameFormGroup.invalid || nameFormGroup.pristine || status === 'saving'"
      [loading]="status === 'saving'"
      (clicked)="onSave()"
    ></chillz-button>
  </div>
</form>
