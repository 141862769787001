import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, takeWhile } from 'rxjs/operators';

import { fuseAnimations } from '../../../../../@fuse/animations';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { AppLocalizationService } from '../../../../app-localization.service';
import { Entity } from '../../../../main/entity/entity.model';
import { EntityService } from '../../../../main/entity/entity.service';
import { TicketTypesService } from '../../../../main/entity/ticket-types.service';
import { OrderItem } from '../../../models/order-item.model';
import { EntityPageSectionInstance } from '../../../models/style-components/entity-page-layout-style.model';
import { TextStyle } from '../../../models/style-components/text-style.model';
import { TicketType } from '../../../models/ticket-type.model';
import { locale as english } from '../i18n/en';
import { locale as hebrew } from '../i18n/he';
import { IOrderPreparationState, OrderPreparationService } from '../order-preparation.service';


@Component({
  selector: 'app-select-items',
  templateUrl: './select-items.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './select-items.component.scss' ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    ...fuseAnimations,
  ],
})
export class SelectItemsComponent implements OnInit, OnDestroy {
  @Input() highlighted: boolean;
  @Input() background = true;
  @Input() description = true;

  @Input() optionalItemsSubject: BehaviorSubject<OrderItem[]>;
  @Input() items: OrderItem[];
  @Input() specialAccessCodeAllowed: boolean;
  specialAccessCodeUsed: boolean;
  @Input() title: { [lang: string]: string };
  @Input() headlineStyle: TextStyle;
  @Input() containerClass: string;
  @Input() accentColor = '';
  @Input() baseFontColor = '';
  @Input() venue: Entity;
  @Input() externalTickets: {
    location: string;
    url: string;
  };
  @Input() section: EntityPageSectionInstance;
  state: IOrderPreparationState;

  loading;
  sendingCoupon;

  selectedItemsSub: Subscription;

  specialAccessCodeControl: FormControl;

  @Output() externalLinkClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedTicketType: EventEmitter<TicketType> = new EventEmitter<TicketType>();

  selectedItemsFormArray: FormArray;
  selectedTicketTypeLoading: TicketType;
  private _startChangeTicketQuantity: boolean;
  private _unsubscribeAll = new Subject<any>();

  constructor (
    public localizationService: AppLocalizationService,
    private _orderPreparationService: OrderPreparationService,
    private _formBuilder: FormBuilder,
    private _venueService: EntityService,
    private _ticketTypesService: TicketTypesService,
    private translationLoader: FuseTranslationLoaderService
  ) {
    this.translationLoader.loadTranslations(english, hebrew);
  }

  ngOnInit (): void {
    this.loading = true;

    this.selectedItemsFormArray = this._formBuilder.array([]);

    combineLatest(
      this._orderPreparationService.onSelectedItemsChanged.pipe(filter((x) => !!x)),
      this.optionalItemsSubject.pipe(filter((x) => !!x))
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([ selected, optionalItems ]) => {
        console.log('selected', selected.length, selected);
        console.log('optionalItems', optionalItems, optionalItems?.length);

        selected.forEach((item) => {
          const existing = this.selectedItemsFormArray.controls.find((x) => x.get('item').value._id === item.item._id);
          if (existing) {
            existing.get('quantity').setValue(item.quantity, { emitEvent: false });
          } else if (optionalItems?.find((x) => x._id === item.item._id)) {
            this.selectedItemsFormArray.push(
              this._formBuilder.group({
                item: [ optionalItems.find((x) => x._id === item.item._id) || item.item ],
                quantity: [ item.quantity ],
              }),
              { emitEvent: false }
            );
          }
        });

        this.selectedItemsFormArray.controls.forEach((itemControl) => {
          if (!selected.find((x) => x.item._id === itemControl.get('item').value._id)) {
            itemControl.get('quantity').setValue(0, { emitEvent: false });
          }
        });
      });

    this.optionalItemsSubject
      .pipe(
        filter((x) => !!x),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((items) => {
        this.items = items;

        this.loading = false;

        this._orderPreparationService.updateSelectedItems(
          this.items.map((x) => ({ item: x, quantity: null })),
          this.venue
        );

        if (this.selectedItemsSub) {
          this.selectedItemsSub.unsubscribe();
        }

        this.selectedItemsSub = this.selectedItemsFormArray.valueChanges
          .pipe(
            takeUntil(this._unsubscribeAll),
            takeWhile(() => !this._orderPreparationService.order)
          )
          .subscribe((value) => {
            this._orderPreparationService.updateSelectedItems(value, this.venue);
            console.log('Subscriber 1');
          });
      });

    this._orderPreparationService.onStateChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((s) => {
      this.state = s;
    });

    combineLatest(
      this._orderPreparationService.onOrderChanged.pipe(
        filter((x) => !!x?._id),
        distinctUntilChanged((a, b) => a?._id === b?._id)
      ),
      this.selectedItemsFormArray.valueChanges.pipe(debounceTime(1000))
    )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([ _order, selectedItems ]) => {
        console.log('Subscriber 2');
        this._orderPreparationService.updateSelectedItems(selectedItems, this.venue);
        this._startChangeTicketQuantity = false;
      });
  }

  onAddQuantity (item: AbstractControl): void {
    const newVal = Math.min(
      Math.max(item.get('quantity').value + 1, item.get('item').value.minPerPurchase),
      item.get('item').value.maxQuantity
    );

    if (newVal !== item.get('quantity').value && !this._startChangeTicketQuantity) {
      this._orderPreparationService.startLoading();
      this._startChangeTicketQuantity = true;
    }

    // item.get('quantity').setValue(0);
    // setTimeout(() => {
    item.get('quantity').setValue(newVal);
    // });
  }

  onSubtractQuantity (item: AbstractControl): void {
    if (item.get('quantity').value - 1 < (item.get('item').value.minPerPurchase || 0)) {
      if (item.get('quantity').value !== 0) {
        item.get('quantity').setValue(0);
      }
    } else {
      item.get('quantity').setValue(item.get('quantity').value - 1);
    }

    if (!this._startChangeTicketQuantity) {
      this._orderPreparationService.startLoading();
      this._startChangeTicketQuantity = true;
    }
  }

  onExternalTicketsClick (): void {
    this.externalLinkClicked.emit();
  }

  enterPassword (): void {
    this.specialAccessCodeControl = this._formBuilder.control(null, [ Validators.required, Validators.minLength(3) ]);

    this.specialAccessCodeControl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
      this.specialAccessCodeControl.setValue(value.toUpperCase().trim(), { emitEvent: false });
    });
  }

  sendCouponCode (code): void {
    if (!this.venue || this.sendingCoupon) {
      return;
    }

    this.sendingCoupon = true;
    this._ticketTypesService
      .getTicketTypes(this.venue, code)
      .then((x) => {
        console.log(x);
        if (x.length === 0) {
          this.specialAccessCodeControl.setErrors({ invalidCode: true });
        } else {
          this.specialAccessCodeUsed = true;
          this._orderPreparationService.queryPromoCodes.push(code);
          this.specialAccessCodeControl = undefined;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.sendingCoupon = false;
      });
  }

  onTicketTypeSelect (ticketType: TicketType): void {
    if (this.selectedTicketTypeLoading) {
      return;
    }

    this.selectedTicketType.emit(ticketType);
    this.selectedTicketTypeLoading = ticketType;
  }

  isOrderDisabled (): boolean {
    if (this.selectedItemsFormArray.value.filter((x) => x.quantity > 0).length === 0) {
      return true;
    }

    return false;
  }

  ngOnDestroy (): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
