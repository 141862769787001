<div class="chillz-step-indicator">
  <div
    class="chillz-step-indicator-step"
    [class.chillz-step-indicator-first]="i === 0"
    [class.chillz-step-indicator-last]="i === steps.length - 1"
    [class.chillz-step-indicator-active]="i === currentStep"
    [class.chillz-step-indicator-completed]="i < currentStep"
    *ngFor="let step of steps; let i = index"
  >
    <div class="chillz-step-indicator-line">
      <chillz-button
        [width]="20"
        [height]="20"
        [classes]="'cursor-default'"
        [cta]="i < currentStep"
        [outline]="true"
        [strokeWidth]="i <= currentStep ? '1px' : '1px'"
        [outlineShine]="i <= currentStep"
        [hoverPush]="false"
        [backgroundColor]="'#9641a7'"
        [shadow]="false"
        [glow]="i === currentStep"
        [ctaGlowIntensity]="0"
        [glowIntensity]="8"
        [hoverBackgroundPosition]="false"
        [hoverOutlineShine]="false"
      ></chillz-button>
    </div>
    <div class="chillz-step-indicator-label" fxHide fxShow.gt-sm>
      <span *ngIf="step?.label">{{ step.label[localizationService.currentLang] || step.label['en'] }}</span>
    </div>
  </div>
  <span class="chillz-step-indicator-label" fxShow fxHide.gt-sm *ngIf="steps[currentStep]?.label">
    {{ steps[currentStep].label[localizationService.currentLang] || steps[currentStep].label['en'] }}
  </span>
</div>
