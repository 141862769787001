import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LocalStorage } from '../../@fuse/services/storage/local-storage';
import { AppService } from '../app.service';
import { Platform as ChillzPlatform } from './models/platform.model';


@Injectable({ providedIn: 'root' })
export class SessionInfoService {
  sessionInfo = {};

  sessionId;

  onSessionInfoChanged: BehaviorSubject<any>;

  onSessionIdChanged: BehaviorSubject<any>;

  platform: ChillzPlatform;

  constructor (
    private _httpClient: HttpClient,
    private _route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private _appService: AppService,
    private _platform: Platform,
    private _localStorage: LocalStorage
  ) {
    // Set the defaults
    this.onSessionInfoChanged = new BehaviorSubject({});
    this.onSessionIdChanged = new BehaviorSubject(undefined);

    this._appService.onPlatformChanged.pipe(filter((x) => !!x)).subscribe((platform) => (this.platform = platform));

    this.init();
  }

  async init (): Promise<void> {
    this.sessionInfo['device'] = this.deviceService.getDeviceInfo();
    this.sessionInfo['device']['mobile'] = this.deviceService.isMobile();
    this.sessionInfo['device']['desktop'] = this.deviceService.isDesktop();
    this.sessionInfo['device']['tablet'] = this.deviceService.isTablet();

    this._route.queryParams.subscribe((params) => {
      this.sessionInfo['queryParams'] = params;

      // if (this._platform.isBrowser && navigator.geolocation && !(params['embed'] === '1')) {
      //   navigator.geolocation.getCurrentPosition(position => {
      //     this.sessionInfo['position'] = {
      //       lat: position.coords.latitude,
      //       lng: position.coords.longitude,
      //     };
      //
      //     this.onSessionInfoChanged.next(this.sessionInfo);
      //     this.updateSession();
      //
      //   });
      // }

      this.onSessionInfoChanged.next(this.sessionInfo);

      this.updateSession();
    });
  }

  /**
   * Get session info
   *
   */
  getSessionInfo (): any {
    return this.sessionInfo;
  }

  updateSessionId (id): any {
    this.sessionId = id;
    this.onSessionIdChanged.next(this.sessionId);
    this._localStorage.setItem('session', id);
    this.updateSession();
  }

  updateSession (): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this._platform.isBrowser || !this.sessionId) {
        return resolve(false);
      }

      this._httpClient
        .put('/users/sessions/' + this.sessionId, {
          metaData: this.sessionInfo,
        })
        .subscribe(() => {
          resolve(false);
        }, reject);
    });
  }
}
