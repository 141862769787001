import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '../../../../@fuse/components';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { VideoPlayerModule } from '../../../shared/components/video-player/video-player.module';
import { ContentModule } from '../../components/content/content.module';
import { FooterModule } from '../../components/footer/footer.module';
import { ToolbarModule } from '../../components/toolbar/toolbar.module';
import { VerticalLayout1Component } from './layout-1.component';


@NgModule({
  declarations: [ VerticalLayout1Component ],
  imports: [
    RouterModule,
    FuseSharedModule,
    FuseSidebarModule,
    ContentModule,
    FooterModule,
    ToolbarModule,
    VideoPlayerModule,
  ],
  exports: [ VerticalLayout1Component ],
})
export class VerticalLayout1Module {}
