<div
  class="dialog-content-wrapper"
  fxLayout="column"
  [ngStyle]="{ 'background-color': ticket?.ticketType?.bgColor || '#3c4252' }"
>
  <mat-toolbar matDialogTitle class="m-0" [ngStyle]="{ 'background-color': ticket?.ticketType?.bgColor || '#3c4252' }">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"></span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>

    <mat-toolbar-row
      *ngIf="!ticket && !initializing && requireAccessKey"
      class="toolbar-bottom pb-32"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="subtitle dialog-title">Your ticket is waiting around the corner</div>
    </mat-toolbar-row>

    <mat-toolbar-row
      *ngIf="ticket?.ticketType?.venue"
      class="toolbar-bottom pb-16"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="title dialog-title">{{ ticket.ticketType.venue.titleEng | titlecase }}</div>
      <div class="subtitle dialog-title" *ngIf="ticket?.ticketType?.venue?.locationDetails">
        {{ ticket.ticketType.venue.locationDetails.title }}
      </div>
      <div class="subtitle dialog-title" *ngIf="ticket?.ticketType?.venue?.locationDetails">
        {{ ticket.ticketType.venue.locationDetails.formatted }}
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="px-24 m-0 dialog-content" fxLayout="column" fxLayoutAlign="center center">
    <ng-container *ngIf="ticket">
      <!--            <div class="venue-details">{{ticket.ticketType.venue.startsAt.dateObj | date: 'dd MMMM yyyy'}}-->
      <!--                , {{ticket.ticketType.venue.startsAt.hours}}:{{ticket.ticketType.venue.startsAt.minutes}}</div>-->
      <div class="venue-details">
        <strong>{{ ticket.fullName | titlecase }}</strong>
      </div>
      <span class="mx-8 font-size-12" *ngIf="ticket.idNumber">{{ ticket.idNumber | titlecase }}</span>

      <div class="venue-details">{{ ticket.ticketType.title | titlecase }}</div>
      <form [formGroup]="modificationsFormGroup" fxLayout="column" fxLayoutAlign="center center" class="w-100-p mw-300">
        <div class="py-8 w-100-p mw-300" fxLayout="column" fxLayoutAlign="start center" formGroupName="customFields">
          <div
            class="venue-details w-100-p mw-300"
            *ngFor="let field of ticket.ticketType.customFields"
            [dir]="textAttr.direction"
          >
            <mat-form-field floatLabel="auto" class="w-100-p mw-300">
              <mat-label>{{ field.title[_translateService.currentLang] || field.title.en }}</mat-label>
              <input *ngIf="field.fieldType === 'input'" matInput type="text" [formControlName]="field.key" />
              <textarea
                *ngIf="field.fieldType === 'textArea'"
                matInput
                [formControlName]="field.key"
                rows="4"
              ></textarea>
              <mat-error *ngIf="modificationsFormGroup.get('customFields').get(field.key).hasError('required')">
                {{ 'TICKET.CUSTOM_FIELDS.IS_REQUIRED' | translate }}
              </mat-error>
              <mat-hint *ngIf="field.mutable === 'always'">
                {{ 'TICKET.CUSTOM_FIELDS.ALWAYS_MUTABLE' | translate }}
              </mat-hint>
              <mat-hint *ngIf="field.mutable === 'untilCheckedIn'">
                {{ 'TICKET.CUSTOM_FIELDS.MUTABLE_UNTIL_CHECKED_IN' | translate }}
              </mat-hint>
              <mat-hint *ngIf="field.mutable === '' || !field.mutable">
                {{ 'TICKET.CUSTOM_FIELDS.IMMUTABLE' | translate }}
              </mat-hint>
            </mat-form-field>

            <!--          <strong>-->
            <!--              {{field.title[_translateService.currentLang] || field.title['en']}}:-->
            <!--            </strong>-->
            <!--          <span>-->
            <!--            {{ticket.customFields[field.key]}}-->
            <!--          </span>-->
            <!--          <mat-icon class="cursor-pointer" *ngIf="field.mutable === 'always' || (field.mutable ==='untilCheckedIn' && !ticket.checkedIn?.length)">edit</mat-icon>-->
          </div>

          <button
            mat-flat-button
            class="mt-16"
            *ngIf="ticket.ticketType?.customFields?.length"
            (click)="updateTicket()"
            [disabled]="saving || modificationsFormGroup.invalid || modificationsFormGroup.pristine"
          >
            Save
          </button>
        </div>

        <ng-container *ngIf="ticket.seat">
          <div class="h3" *ngIf="ticket.seat.label">{{ ticket.seat.label }}</div>
          <div class="h3" *ngIf="ticket.seat">{{ ticket.seat.section.title }}</div>
          <div class="h3" *ngIf="ticket.seat">Row {{ ticket.seat.row }} Seat {{ ticket.seat.seat }}</div>
        </ng-container>

        <qr-code [value]="ticket.id" [size]="200"></qr-code>
        <span>{{ ticket.id }}</span>
      </form>
    </ng-container>

    <ng-container *ngIf="!ticket && !initializing && requireAccessKey">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <mat-form-field>
          <mat-label>Access Code</mat-label>
          <input class="digit" #one type="text" matInput [(ngModel)]="accessCode" />
        </mat-form-field>
        <!--        <input-->
        <!--          class="digit"-->
        <!--          [class.full]="accessKey[0] !== null"-->
        <!--          #one-->
        <!--          type="text"-->
        <!--          maxlength="1"-->
        <!--          [(ngModel)]="accessKey[0]"-->
        <!--          (keyup)="accessKey[0] ? two.focus() : null"-->
        <!--          autofocus-->
        <!--        />-->
        <!--        <input-->
        <!--          class="digit"-->
        <!--          [class.full]="accessKey[1] !== null"-->
        <!--          #two-->
        <!--          type="text"-->
        <!--          maxlength="1"-->
        <!--          [(ngModel)]="accessKey[1]"-->
        <!--          (keyup)="accessKey[1] ? three.focus() : null"-->
        <!--        />-->
        <!--        <input-->
        <!--          class="digit"-->
        <!--          [class.full]="accessKey[2] !== null"-->
        <!--          #three-->
        <!--          type="text"-->
        <!--          maxlength="1"-->
        <!--          [(ngModel)]="accessKey[2]"-->
        <!--          (keyup)="accessKey[2] ? four.focus() : null"-->
        <!--        />-->
        <!--        <input-->
        <!--          class="digit"-->
        <!--          [class.full]="accessKey[3] !== null"-->
        <!--          #four-->
        <!--          type="text"-->
        <!--          maxlength="1"-->
        <!--          [(ngModel)]="accessKey[3]"-->
        <!--          (keyup)="four.blur(); onLastCharKeyup()"-->
        <!--        />-->
      </div>

      <div fxLayout="column" dir="rtl" class="text-right" *ngIf="requireHealthDeclaration">
        <strong>הצהרת בריאות</strong>
        <ul>
          <li>במהלך השבוע האחרון ועד היום, חום גופי היה נמוך מ-38 מעלות צלסיוס.</li>
          <li>אינני משתעל.ת למעט שיעול הנובע ממצב כרוני כגון אסטמה או אלרגיה.</li>
          <li>לא באתי במגע קרוב לחולה קורונה בשבועיים האחרונים.</li>
        </ul>

        <mat-checkbox [formControl]="healthDeclarationFormControl">אישור הצהרת הבריאות</mat-checkbox>

        <mat-form-field appearance="fill" *ngIf="requireEmail" class="mt-8">
          <mat-label>E-mail</mat-label>
          <input type="text" matInput [formControl]="emailFormControl" />
        </mat-form-field>
      </div>

      <span *ngIf="error" class="mt-16">{{ error }}</span>

      <button
        mat-flat-button
        class="mt-16"
        [disabled]="
          accessCode.length < 3 || loading || (!healthDeclarationFormControl.value && requireHealthDeclaration)
        "
        (click)="getTicket()"
      >
        View Ticket
      </button>
    </ng-container>

    <mat-spinner *ngIf="loading || initializing" color="accent" diameter="35" class="mt-32"></mat-spinner>
  </div>

  <img
    [src]="ticket?.ticketType?.logo?.url || platform?.logo?.white || 'assets/images/logos/chillz-logo-white.png'"
    alt="Chillz logo"
    class="logo"
  />
  <!--  <img src="assets/images/logos/chillz-symbol-hollow.png" alt="Chillz symbol background watermark" class="background-watermark" />-->
</div>
