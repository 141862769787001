import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { BundleOfferSummaryComponent } from './bundle-offer-summary.component';


@NgModule({
  declarations: [ BundleOfferSummaryComponent ],
  imports: [ CommonModule, FlexLayoutModule ],
  exports: [ BundleOfferSummaryComponent ],
})
export class BundleOfferSummaryModule {}
