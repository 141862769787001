import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChillzDirectivesModule } from '../../directives/chillz-directives.module';
import { GSAPDirectivesModule } from '../../directives/gsap/gsap-directives.module';
import { ChillzButtonModule } from '../chillz-button/chillz-button.module';
import { ChillzSkeletonBlockModule } from '../chillz-skeleton-block/chillz-skeleton-block.module';
import { VideoPlayerModule } from '../video-player/video-player.module';
import { CarouselPosterItemComponent } from './carousel-poster-item.component';


@NgModule({
  declarations: [ CarouselPosterItemComponent ],
  exports: [ CarouselPosterItemComponent ],
  imports: [
    CommonModule,
    VideoPlayerModule,
    ChillzDirectivesModule,
    GSAPDirectivesModule,
    ChillzButtonModule,
    ChillzSkeletonBlockModule,
  ],
})
export class ChillzCarouselPosterItemModule {}
