import { Directive, ElementRef, OnInit } from '@angular/core';

import { CoreAnimationDirective } from './core-animation.directive';


@Directive({
  selector: '[fadeInAnimation]',
})
export class FadeInAnimationDirective extends CoreAnimationDirective implements OnInit {
  constructor (protected element: ElementRef) {
    super(element);
  }

  ngOnInit (): void {
    // perform animation
    this.animateIn();
  }

  protected animateIn (): void {
    this.timeline.from(
      this.element.nativeElement,
      { duration: this.duration, opacity: '0', ease: 'Expo.easeInOut' },
      this.delay
    );
    super.animateIn();
  }
}
