import { Entity } from '../../main/entity/entity.model';
import { GeneralProduct } from './general-product.model';
import { TicketType } from './ticket-type.model';


export class PromoCode {
  _id: string;
  name: string;
  id: string;
  code: string;
  venue: Entity;
  type: 'discount' | 'specialAccess';
  guestPageAccess: boolean;
  discount: {
    amount: number;
    unit: 'ILS' | 'USD' | 'percents';
    limit: {
      limit: number;
      of: 'items' | 'orders';
    };
    used: number;
    applicableTo: [
      {
        ticketType: TicketType;
        product: GeneralProduct;
        limit: number;
        used: number;
      }
    ];
  };

  constructor (coupon?) {
    coupon = coupon || {};
    this._id = coupon._id;
    this.id = coupon.id;

    this.type = coupon.type;

    this.name = coupon.name;
    this.code = coupon.code;
    this.venue = coupon.venue;
    this.guestPageAccess = coupon.guestPageAccess;
    this.discount = {
      amount: coupon.discount?.amount,
      unit: coupon.discount?.unit,
      limit: coupon.discount?.limit,
      used: coupon.discount?.used || 0,
      applicableTo: coupon.discount?.applicableTo || [],
    };
  }
}
