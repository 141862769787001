<div *ngIf="profile" class="settings-page-view">
  <div class="settings-page-header">
    <h1 class="settings-page-title">Update My {{ platform.brandName['en'] || '' }} Profile</h1>
  </div>

  <div class="settings-section">
    <div class="settings-section-header">
      <div class="settings-section-title">Name</div>
    </div>

    <app-profile-name></app-profile-name>
  </div>

  <div class="settings-section">
    <div class="settings-section-header">
      <div class="settings-section-title" (click)="onOpenDialog('general-details', undefined, true)">Details</div>
    </div>
    <app-profile-general-details></app-profile-general-details>
  </div>

  <div class="settings-section">
    <div class="settings-section-header">
      <div class="settings-section-title">Phone Number</div>
    </div>
    <app-profile-phone-number></app-profile-phone-number>
  </div>

  <div class="settings-section">
    <div class="settings-section-header">
      <div class="settings-section-title" (click)="onOpenDialog('email', { en: 'Update your Email Address' })">
        Email
      </div>
    </div>
    <app-profile-email></app-profile-email>
  </div>

  <div class="settings-section">
    <div class="settings-section-header">
      <div class="settings-section-title">Social Media</div>
    </div>
    <app-profile-social-media></app-profile-social-media>
  </div>

  <div class="settings-section" fxLayout="column" fxLayoutAlign="start start">
    <div class="settings-section-header">
      <div class="settings-section-title">Password</div>
    </div>

    <div class="settings-section-body">
      <chillz-button
        class="my-8"
        [fontSize]="'10px'"
        [height]="24"
        [hoverPush]="true"
        [expandable]="false"
        [outline]="true"
        [hoverOutlineShine]="true"
        [outlineShine]="true"
        [shadow]="true"
        [glow]="false"
        [isLoader]="true"
        [loaderSize]="24"
        (clicked)="onOpenDialog('password', { en: 'Set a Password' })"
        [label]="{ en: (profile.hasPassword ? 'Reset' : 'Set a') + ' Password' }"
      ></chillz-button>

      <div *ngIf="profile.lastPasswordChange">Last changed {{ profile.lastPasswordChange?.toString() | timeAgo }}</div>
    </div>
  </div>
</div>
