import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';


@Directive({
  selector: '[chillzOpacityAnimation]',
})
export class OpacityAnimationDirective implements OnInit {
  private _isVisible = false;

  @Input()
  set chillzOpacityAnimation (value: boolean) {
    this._isVisible = value;
    this.applyAnimation();
  }

  @Input() duration = '0.25s';

  constructor (private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit (): void {
    this.renderer.setStyle(this.el.nativeElement, 'transition', `opacity ${this.duration} ease-in-out`);
    this.renderer.setStyle(this.el.nativeElement, 'opacity', this._isVisible ? '1' : '0');
  }

  private applyAnimation (): void {
    const opacityValue = this._isVisible ? '1' : '0';
    this.renderer.setStyle(this.el.nativeElement, 'opacity', opacityValue);
  }
}
