<div class="fuse-countdown-wrapper" [class]="size" popAnimation *ngIfAnimated="countdownInSeconds >= 0">
  <div class="fuse-countdown time-in-spinner">
    <div class="time minutes">
      <div class="value">{{ countdown.minutes }}:{{ countdown.seconds }}</div>
    </div>
  </div>
  <div class="progress-spinner">
    <mat-progress-spinner
      [ngClass]="[
        !countdownInSeconds || countdownInSeconds > twoMinutesInSeconds
          ? 'progress-spinner-timer-success'
          : 'progress-spinner-timer-warn'
      ]"
      class="progress-spinner-timer"
      [mode]="'determinate'"
      [strokeWidth]="progressSpinnerStrokeWidth"
      [value]="countdownInPercentage"
      [diameter]="progressSpinnerDiameter"
    ></mat-progress-spinner>
  </div>
</div>
