<div class="gap-12" [fxLayout]="layout.direction" [fxLayoutAlign]="layout.align">
  <chillz-button
    [icon]="checked ? 'checkmark' : icon"
    [isSvgIcon]="checked ? true : isSvgIcon"
    [iconClass]="'s-20'"
    [padding]="'0'"
    [outline]="outline"
    [hoverPush]="hoverPush"
    [shadow]="shadow"
    [disabled]="disabled || control?.disabled"
    [height]="height"
    [glowIntensity]="glowIntensity"
    [ctaGlowIntensity]="ctaGlowIntensity"
    [strokeWidth]="strokeWidth"
    [hoverOutlineShine]="hoverOutlineShine"
    [hoverBackgroundPosition]="hoverBackgroundPosition"
    [width]="width"
    [cta]="checked"
    [iconPopDuration]="1.2"
    [expandable]="false"
    [ctaColors]="ctaColors"
    [backgroundColor]="backgroundColor"
    (clicked)="$event.stopPropagation(); onCheck()"
  ></chillz-button>

  <div
    *ngIf="label || tooltip"
    fxLayout="row"
    fxLayoutAlign="center center"
    class="gap-8"
    [class.cursor-pointer]="!(disabled || control?.disabled)"
  >
    <span *ngIf="label" (click)="$event.stopPropagation(); onCheck()">
      {{ label[localizationService.currentLang] || label['en'] }}
    </span>

    <mat-icon
      *ngIf="tooltip"
      matTooltipClass="chillz-tooltip"
      [matTooltip]="tooltip[localizationService.currentLang] || tooltip['en']"
      matTooltipPosition="after"
      class="cursor-pointer s-16"
      matTooltipTouchGestures="on"
    >
      info
    </mat-icon>
  </div>
</div>
