import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { AppService } from '../../../app.service';
import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { User } from '../../../auth/user.model';
import { VerificationService } from '../../../auth/verification/verification.service';
import { Platform } from '../../../shared/models/platform.model';
import { ProfileService } from '../profile.service';


@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
  styleUrls: [ './profile-password.component.scss', '../profile.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ProfilePasswordComponent implements OnInit, OnDestroy {
  profile: User;
  platform: Platform;

  passwordError: string;
  passwordFormGroup: FormGroup;

  status: 'saving' | 'saved';
  @Output() status$: EventEmitter<'saving' | 'saved'> = new EventEmitter<'saving' | 'saved'>();

  private _unsubscribeAll = new Subject<any>();

  constructor (
    private _profileService: ProfileService,
    private _authService: ChillzAuthService,
    private _appService: AppService,
    private _formBuilder: FormBuilder,
    private _verificationService: VerificationService
  ) {}

  ngOnInit (): void {
    this.passwordFormGroup = this._formBuilder.group({});

    this._authService.onUserChanged
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((x) => !!x)
      )
      .subscribe((profile) => {
        this.profile = profile;
      });

    this.status$
      .pipe(
        tap((status) => {
          this.status = status;
        }),
        debounceTime(5000),
        tap((status) => {
          if (status === 'saved') {
            this.status = null;
          }
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe();
  }

  onSave (): void {
    this.status$.emit('saving');

    this._authService
      .updatePassword(
        this.passwordFormGroup.get('password').value,
        this._authService.getAccessToken(),
        this.passwordFormGroup.get('currentPassword')?.value
      )
      .then(() => {
        this.status$.emit('saved');
        this.passwordError = undefined;
        this.passwordFormGroup.reset({ emitEvent: false });
      })
      .catch((err) => {
        console.log(err);
        this.passwordError = err?.error?.error;
        console.log(this.passwordError);
        this.status$.emit(null);
      })
      .finally(() => {
        this.passwordFormGroup.markAsPristine();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
