import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { SessionStorage } from '../../../@fuse/services/storage/session-storage';
import { Order } from '../orders/order/order.model';


@Injectable()
export class ProfileService implements Resolve<any> {
  profile: any;
  isSelf: boolean;
  isModerator: boolean;
  activity: any;
  favorites: any;
  loyaltySummary: any;
  upcomingOrders: any;
  pastOrders: any;
  myChillz: any = {};

  onUpcomingOrdersChanged: BehaviorSubject<Order[]>;
  onPastOrdersChanged: BehaviorSubject<Order[]>;
  onMyChillzChanged: BehaviorSubject<any>;

  onProfileChanged: BehaviorSubject<any>;
  onLoyaltyMembershipsChanged: BehaviorSubject<any>;
  onCoinzChanged: BehaviorSubject<any>;
  onChillzStatusChanged: BehaviorSubject<any>;

  routeParams: any;

  /**
   * Constructor
   *
   */
  constructor (private _httpClient: HttpClient, private _router: Router, private _sessionStorage: SessionStorage) {
    // Set the defaults
    this.onProfileChanged = new BehaviorSubject(null);
    this.onCoinzChanged = new BehaviorSubject(null);
    this.onLoyaltyMembershipsChanged = new BehaviorSubject(null);
    this.onChillzStatusChanged = new BehaviorSubject(null);
    this.onUpcomingOrdersChanged = new BehaviorSubject(null);
    this.onPastOrdersChanged = new BehaviorSubject(null);
    this.onMyChillzChanged = new BehaviorSubject(null);
  }

  /**
   * Resolver
   *
   */
  resolve (route: ActivatedRouteSnapshot): Promise<boolean> {
    this.routeParams = route.params;

    this.getOrders();
    this.getMyChillz();
    this.getLoyaltySummary();
    this.getProfile();
    return new Promise((resolve, reject) => {
      Promise.all([])
        .then(() => {
          resolve(false);
        })
        .catch(() => {
          this._router.navigate([ '/', 'errors', '404' ]);
          reject();
        });
    });
  }

  /**
   * Get country codes
   *
   */
  getCountryCodes (): Promise<any> {
    return new Promise((resolve) => {
      this._httpClient.get('assets/data/dial-codes.json').subscribe(
        (response: any) => {
          resolve(response);
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  /**
   * Get profile
   */
  getProfile (): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let url = '/users/';

      url += (this.routeParams.user || 'self') + '/profile';

      this._httpClient.get(url).subscribe((response: any) => {
        this.profile = response.profile;

        this.profile.isSelf = response.isSelf;
        this.profile.isModerator = response.isModerator;

        this.onProfileChanged.next(this.profile);

        resolve(this.profile);
      }, reject);
    });
  }

  /**
   * Get loyalty summary
   */
  getLoyaltySummary (): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('/users/self/loyalty/summary').subscribe((response: any) => {
        this.loyaltySummary = response;

        this.onCoinzChanged.next(this.loyaltySummary?.coinz);
        this.onChillzStatusChanged.next(this.loyaltySummary?.status?.status);
        this.onLoyaltyMembershipsChanged.next(this.loyaltySummary?.byVenue);
        resolve(this.loyaltySummary);
      }, reject);
    });
  }

  /**
   * Get activity
   */
  getActivity (): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('/users/self/activity').subscribe((response: any) => {
        this.activity = response.activity;

        resolve(this.activity);
      }, reject);
    });
  }

  /**
   * Update profile
   *
   */
  updateProfile (update): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put('/users/self', update).subscribe((response: any) => {
        this.getProfile();

        resolve(response);
      }, reject);
    });
  }

  /**
   * Update payment method
   *
   */
  updatePaymentMethod (_id, update): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.put('/users/self/paymentMethods/' + _id, update).subscribe((response: any) => {
        this.getProfile();

        resolve(response);
      }, reject);
    });
  }

  /**
   * Delete payment method
   *
   */
  deletePaymentMethod (_id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete('/users/self/paymentMethods/' + _id).subscribe((response: any) => {
        this.getProfile();

        resolve(response);
      }, reject);
    });
  }

  /**
   * Get orders
   */
  getOrders (): Promise<Order[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('/users/self/orders').subscribe((response: any) => {
        this.upcomingOrders = response.orders[0].map((x) => new Order(x));
        this.pastOrders = response.orders[1].map((x) => new Order(x));

        this.onUpcomingOrdersChanged.next(this.upcomingOrders);
        this.onPastOrdersChanged.next(this.pastOrders);

        resolve(response.orders);
      }, reject);
    });
  }

  /**
   * Get myChillz
   */
  getMyChillz (): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('/users/self/myChillz').subscribe((response: any) => {
        this.myChillz.main = response.list[0];
        this.myChillz.past = response.list[1];

        this.onMyChillzChanged.next(this.myChillz);
        resolve(this.myChillz);
      }, reject);
    });
  }

  removeFromMyChillz (id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post('/venues/' + id + '/like' + '?value=false', null).subscribe((response: any) => {
        this.getMyChillz();

        resolve(response);
      }, reject);
    });
  }

  uploadImage (image: File, type): Promise<any> {
    const formData = new FormData();

    formData.append('image', image, image.name);

    return new Promise((resolve, reject) => {
      this._httpClient.post('/pictures/upload?createObj=1', formData).subscribe((response: any) => {
        const update = {};

        update[type] = response.object._id;

        if (type === 'profilePicture') {
          this._sessionStorage.setItem('profilePicture', JSON.stringify(response.object));
        }

        this.updateProfile(update);

        resolve(response.object);
      }, reject);
    });
  }
}
