import { Platform } from '@angular/cdk/platform';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FuseTranslationLoaderService } from '../../../../@fuse/services/translation-loader.service';
import { ChillzAuthService } from '../../../auth/chillz-auth.service';
import { locale as english } from '../i18n/en';
import { locale as hebrew } from '../i18n/he';
import { VenueThumbService } from './venue-thumb.service';


@Component({
  selector: 'app-venue-thumb',
  templateUrl: './venue-thumb.component.html',
  styleUrls: [ './venue-thumb.component.scss' ],
})
export class VenueThumbComponent implements OnInit {
  @Input() venue: any;
  @Input() style = 'venue-row';
  @Input() title;
  @Input() picture;
  // @Input() width: number;
  @Input() time = true;
  @Input() city = true;
  @Input() venueType: boolean;
  @Input() rating: boolean;
  @Input() likeButton: boolean;
  @Input() linkTarget: string;
  @Input() linkId: string;

  @Output() userActivity: EventEmitter<any> = new EventEmitter<any>();

  textAttr: { direction: any; align: any };

  parent;

  userStatuses = {};

  weekdays = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ];
  today = this.weekdays[new Date().getDay()];

  constructor (
    private datePipe: DatePipe,
    private _venueThumbService: VenueThumbService,
    private _authService: ChillzAuthService,
    public _translateService: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private _platform: Platform
  ) {
    if ([ 'he' ].includes(_translateService.currentLang)) {
      this.textAttr = { align: 'right', direction: 'rtl' };
    } else {
      this.textAttr = { align: 'left', direction: 'ltr' };
    }

    this.translationLoader.loadTranslations(english, hebrew);

    // Use the selected language for translations
    this.setLanguage(this._translateService.currentLang);
  }

  ngOnInit (): void {
    this.userStatuses = this.venue.userStatuses || {};

    this._translateService.onLangChange.subscribe((lang) => {
      this.setLanguage(lang.lang);
    });

    if (this.venue.parents && this.venue.parents.length) {
      this.parent = this.venue.parents[0];
    }

    if (this.venue.tags && this.venue.tags.length > 3) {
      this.venue.tags = this.venue.tags.slice(0, 3);
    }
  }

  onActivity (activity: string, value?): void {
    this.userActivity.emit();

    if (!this._authService.isLoggedIn()) {
      return;
    }

    this._venueThumbService.invokeActivity(this.venue.id, activity, value).then((status) => {
      this.userStatuses[activity] = status;
    });
  }

  setLanguage (lang): void {
    if ([ 'he' ].includes(lang)) {
      this.textAttr = { align: 'right', direction: 'rtl' };
    } else {
      this.textAttr = { align: 'left', direction: 'ltr' };
    }

    // Use the selected language for translations
    this._translateService.use(lang);
  }

  capitalizeFirstLetter (s): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
}
