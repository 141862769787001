import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { CountdownSize } from './countdown.model';


@Component({
  selector: 'fuse-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: [ './countdown.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class FuseCountdownComponent implements OnInit, OnDestroy {
  @Input('eventDate') eventDate;
  @Input() size: CountdownSize = 'small';
  @Output() finished = new EventEmitter<any>();

  countdown: any;
  countdownInPercentage: number;
  countdownInSeconds: number;

  subscription: Subscription;
  countDownInterval: Observable<any>;
  progressSpinnerDiameter: number;
  progressSpinnerStrokeWidth: number;

  readonly twoMinutesInSeconds: number;

  private _unsubscribeAll = new Subject<void>();

  constructor () {
    this.countdown = {
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
    };

    this.twoMinutesInSeconds = 120;
    this.countdownInPercentage = 100;

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        console.log(document.hidden);
        this.subscription.unsubscribe();

        this.countDownInterval = undefined;
        // tab is now inactive
        // temporarily clear timer using clearInterval() / clearTimeout()
      } else {
        // console.log(document.hidden);
        this.start();
        // tab is active again
        // restart timers
      }
    });
  }

  ngOnInit (): void {
    this.start();
    this.setSpinnerSize();
  }

  start (): void {
    const oneMinuteInSeconds = 60;
    const fifteenMinutesInSeconds = 900;

    // const currDate = moment();
    // const eventDate = moment(this.eventDate);

    // // Get the difference in between the current date and event date in seconds
    // let diff = eventDate.diff(currDate, 'seconds');
    //
    // // Calculate the remaining time for the first time so there will be no
    // // delay on the countdown
    // this.countdown = this._secondsToRemaining(diff);
    // console.log(this.eventDate);

    // Create a subscribable interval
    this.countDownInterval = interval(1000);

    // Subscribe to the countdown interval
    this.subscription = this.countDownInterval
      .pipe(takeUntil(this._unsubscribeAll))
      .pipe(map(() => {
        return this._secondsToRemaining();
      }))
      .subscribe((value) => {
        this.countdownInSeconds = +value.seconds + +value.minutes * oneMinuteInSeconds;
        this.countdownInPercentage = (this.countdownInSeconds * 100) / fifteenMinutesInSeconds;
        this.countdown = value;
      });

    // console.log(this.subscription);
    // console.log(this.countDownInterval);
  }

  ngOnDestroy (): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Converts given seconds to a remaining time
   *
   */
  private _secondsToRemaining (): any {
    const currDate = moment();
    const eventDate = moment(this.eventDate);

    // Get the difference in between the current date and event date in seconds
    const diff = eventDate.diff(currDate, 'seconds');
    // console.log(diff);

    const timeLeft = moment.duration(diff, 'seconds');
    if (timeLeft.asMilliseconds() <= 0) {
      this.finished.emit(true);
    }

    return {
      days: timeLeft.asDays().toFixed(0),
      hours: timeLeft.hours().toString().padStart(2, '0'),
      minutes: timeLeft.minutes().toString().padStart(2, '0'),
      seconds: timeLeft.seconds().toString().padStart(2, '0'),
    };
  }

  private setSpinnerSize (): void {
    switch (this.size) {
      case 'small':
        this.progressSpinnerDiameter = 52;
        this.progressSpinnerStrokeWidth = 5;
        break;
      case 'medium':
        this.progressSpinnerDiameter = 61.71;
        this.progressSpinnerStrokeWidth = 4.5;
        break;
      case 'large':
        this.progressSpinnerDiameter = 80;
        this.progressSpinnerStrokeWidth = 6;
        break;
    }
  }
}
